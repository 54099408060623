import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import Content from 'src/views/component/application/Connections';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const Following = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Following | Combat Matrix">
			{/* <Content /> */}
			Following
		</Page>
	);
};

export default Following;
