import { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { saveToStorage, fetchFromStorage } from 'src/helpers/context';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import '../auth.scss';
import { identifiers } from 'src/helpers/constants/identifier';

const VerifyOTPPage = ({ authUser }) => {
	const { email } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	var emailAddress = location?.state !== undefined && location?.state;

	var previousPath = fetchFromStorage(identifiers?.previousPath);
	const resendOTP = async () => {
		const requestData = {
			email: emailAddress ? emailAddress : email ? email : '',
			type: 'register',
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.resendOTP, requestData);

			if (status === 200) {
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const tokenSaveHandler = (data) => {
		let token = data?.token;
		// let user = data?.user_id;
		let user = Object.keys(data?.user_meta)?.length === 0 ? data?.user_id : data?.user_meta?.data;
		let status = data?.profile_status;

		if (token) {
			authUser({ ...data }); // isAdmin: true
			saveToStorage('authData', { ...data }); // isAdmin: true

			if (status) {
				navigate(`/user/${user}`);
				previousPath && navigate(previousPath);
			} else {
				navigate('/profile/details');
			}
		}
	};

	return (
		<Box className="auth-section-box">
			<Grid container className="auth-grid-container">
				<Grid item xs={12} sm={12} md={12} lg={5} className="auth-left-grid">
					<Box className="content">
						<Typography className="title">{t('verify_otp.verify_otp')}</Typography>
						<Typography className="desription">{t('verify_otp.description')}</Typography>
						<Box className="form-content">
							<Formik
								initialValues={{
									otp: '',
								}}
								validationSchema={Yup.object().shape({
									otp: Yup.string().min(4, t('resetpassword.invalid_otp')).required(t('validation.required_message')),
								})}
								onSubmit={async (requestData) => {
									const sendData = {
										email: String(emailAddress ? emailAddress : email ? email : ''),
										otp: requestData.otp,
										type: 'register',
									};

									try {
										const { status, data } = await axiosInstance.post(URLS.verifyOTP, sendData);

										if (status === 200) {
											if (data?.data?.token) {
												// if register successfully, save auth data to redux store
												// authUser(data?.data);
												setApiMessage('success', data?.message);
												// save auth data to local storage
												// saveToStorage('authData', data?.data);
												tokenSaveHandler(data?.data);
											}
										}
									} catch (error) {
										setApiMessage('error', error?.response?.data?.message);
									}
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} className="text-field-grid">
												<MatrixTextField
													error={Boolean(touched.otp && errors.otp)}
													fullWidth
													helperText={touched.otp && errors.otp}
													variant="filled"
													InputProps={{
														endAdornment: touched.otp && errors.otp ? <ErrorIcon /> : null,
													}}
													label={t('verify_otp.otp')}
													placeholder={t('verify_otp.otp')}
													className="matrix-textfield"
													size="small"
													name="otp"
													onBlur={handleBlur}
													onChange={(e) => {
														const re = /^[0-9\b]+$/;

														if (e.target.value === '' || re.test(e.target.value)) {
															handleChange(e);
														}
													}}
													inputProps={{ maxLength: 4 }}
													value={values.otp}
												/>
											</Grid>
											<Grid item xs={12} md={12}>
												<Button variant="contained" fullWidth type="submit" className="signup-button">
													{t('forgotpassword.submit')}
												</Button>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>

							<Typography className="resend-otp-text" onClick={resendOTP}>
								{t('verify_otp.resend_otp')}
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPPage);
