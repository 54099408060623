import { useState, useEffect, Fragment } from 'react';
import { Box, Tab, Typography, Popover, ListItemText, MenuItem, useMediaQuery } from '@material-ui/core';
// import { useLocation } from 'react-router-dom';
import { useSelector, connect, useDispatch } from 'react-redux';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import CommunityPost from 'src/views/component/UI/CommunityPost';
import NewsfeedCommunityPost from 'src/views/component/UI/NewsfeedCommunity';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as BestIcon } from 'src/assets/images/icons/best.svg';
import { ReactComponent as PopularIcon } from 'src/assets/images/icons/popular.svg';
import { ReactComponent as DateIcon } from 'src/assets/images/icons/date.svg';
import { ReactComponent as TopIcon } from 'src/assets/images/icons/top.svg';
// import { ReactComponent as ArrowUp } from 'src/assets/images/icons/up-icon.svg';
// import { ReactComponent as ArrowDown } from 'src/assets/images/icons/community/arrow-down.svg';
import { ReactComponent as RecommendedIcon } from 'src/assets/images/icons/recommend.svg';
import './newsfeed-left.scss';
import moment from 'moment';
// import CustomPagination from 'src/views/component/UI/CustomPaginations';
import { getNewsfeedPostList } from 'src/redux-state/actions/actions';
// import { Config } from 'src/helpers/context/config';
import { saveToStorage, fetchFromStorage } from 'src/helpers/context';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { Config } from 'src/helpers/context/config';
import _ from 'lodash';
import StickiedPost from './StickiedPost';
import CreatePost from './createPost';
import UpdatePost from './UpdatePost';
import AdBlock from '../../Community/CommunityView/LeftSide/Discussions/AD';
import { useTranslation } from 'react-i18next';

const getTabValue = (value) => {
	return value === '1'
		? 'best'
		: value === '2'
		? 'trending'
		: value === '3'
		? 'date'
		: value === '4'
		? 'top'
		: value === '5'
		? 'recommended'
		: 'best';
};

const NewsFeedLeftside = ({
	user,
	loader,
	token,
	isView,
	//  fetchNewsfeedPostList,
	getNewsfeedPostList,
}) => {
	const { t } = useTranslation();

	const isFullScreenScroll = useMediaQuery('(max-width: 1020px)');

	// const location = useLocation();
	const [tab, setTab] = useState('3');
	const [isUpdate, setIsUpdate] = useState(false);
	const [postData, setPostData] = useState({});
	const NewsfeedPostList = useSelector((state) => state.Combat.NewsfeedPostList);
	const [anchorEl, setAnchorEl] = useState(null);
	const [view, setView] = useState('all');
	const [currentPage, setCurrentPage] = useState(1);
	const [recentPage, setRecentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [limit] = useState(5); //setList
	const [allUsersList, setAllUsersList] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [reactionData, setReactionData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [newsfeedPostList, setNewsfeedPostList] = useState([]);

	// FETCH POST DETAILS
	const fetchPostDetails = async (postid) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getPostDetails + `/${postid}`);

			if (status === 200) {
				setNewsfeedPostList((prev) => {
					return prev.map((el) => {
						if (el.id === data.data.post_data.id) {
							return data.data.post_data;
						}

						return el;
					});
				});

				getNewsfeedPostList({
					...NewsfeedPostList,
					stickied_post_list: NewsfeedPostList.stickied_post_list.map((el) => {
						if (el.id === data.data.post_data.id) {
							return data.data.post_data;
						}

						return el;
					}),
				});
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const fetchNewsfeedPostList = async (orderBy, vStatus, pageVal) => {
		const pageNumber = pageVal ? pageVal : recentPage;

		try {
			const { status, data } = await axiosInstance.get(URLS.getNewsfeedPostList, {
				params: {
					order_by: orderBy,
					view_status: vStatus,
					// page: page,
					page: pageNumber,
					limit: limit,
				},
			});

			if (status === 200) {
				const hasMore = data?.data?.post_list?.length < 5 ? false : true;
				// var COMMUNITY_LISTING = pageVal !== undefined && pageVal !== '' ? [] : newsFeedPostList;
				// var mergedArray = recentPage === 1 ? data?.data?.post_list : COMMUNITY_LISTING.concat(data?.data?.post_list);

				if (pageVal) {
					setRecentPage(2);
				} else {
					setRecentPage((prev) => {
						return prev + 1;
					});
				}

				setHasMore(hasMore);

				console.log(pageNumber);

				setNewsfeedPostList((prev) => {
					return pageNumber === 1 ? data.data.post_list : [...prev, ...data.data.post_list];
				});
				//TODO: ACTION
				//TODO: delete and create and fix double requests with scroll

				// getNewsfeedPostList(
				// 	pageNumber === 1
				// 		? data?.data
				// 		: {
				// 				...NewsfeedPostList,
				// 				post_list: [...NewsfeedPostList.post_list, ...data.data.post_list],
				// 		  },
				// );
				getNewsfeedPostList(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const updatePartOfPostsArrayByPostIndex = async (postId) => {
		try {
			const postIndex = newsfeedPostList.findIndex((post) => post.id === postId);
			const pageNumber = postIndex > 0 ? Math.ceil((postIndex + 1) / limit) : 1;
			const { status, data } = await axiosInstance.get(URLS.getNewsfeedPostList, {
				params: {
					order_by: getTabValue(tab),
					view_status: view,
					page: pageNumber,
					limit: limit,
				},
			});

			console.log(postIndex);

			if (status === 200) {
				const post_list = [...newsfeedPostList];
				const from = pageNumber * limit - 5;

				post_list.splice(from, limit, ...data.data.post_list);
				getNewsfeedPostList(data?.data);
				setNewsfeedPostList((prev) => {
					return postIndex !== -1 ? [...post_list] : prev;
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const tabChangeHandler = async (event, newValue) => {
		if (newValue === tab) {
			return;
		}

		setIsLoading(true);

		try {
			const tVal = getTabValue(newValue);

			await fetchNewsfeedPostList(tVal, view, 1);
			setTab(newValue);

			const NF_FILTER = fetchFromStorage('newsfeedfilter');
			var obj = {
				...NF_FILTER,
				order_by: tVal,
			};

			saveToStorage('newsfeedfilter', obj);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	// BUTTON TAG LIST
	const btnList = [
		// HIDE-MVP: CM-75
		// { id: '1', name: t('community.best') },
		{ id: '2', name: t('community.popular') },
		{ id: '3', name: t('community.date') },
		{ id: '4', name: t('community.top') },
		// HIDE-MVP: CM-75
		// { id: '5', name: t('community.recommended') },
	];

	// BUTTON ICON HANDLER
	const btnIconHandler = (type) => {
		switch (type) {
			case '1':
				return <BestIcon />;
			case '2':
				return <PopularIcon />;
			case '3':
				return <DateIcon />;
			case '4':
				return <TopIcon />;
			case '5':
				return <RecommendedIcon />;
			default:
				return <BestIcon />;
		}
	};

	// ADD VOTE
	const addVote = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			vote_name: type,
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addVote, requestParams);

			console.log(data);

			if (status === 200) {
				setApiMessage('success', data?.message);
				// fetchNewsfeedPostList(tabValue, view, currentPage);
				fetchPostDetails(value?.id);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// REACTION
	const addLike = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchPostDetails(value?.id);
				// fetchNewsfeedPostList(tabValue, view, currentPage);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// UPDATE Community POST
	const updateCommunityPost = async (value, status) => {
		setIsLoading(true);

		if (isLoading) {
			return;
		}

		const requestParams = {
			post: {
				id: value?.id,
				sticky: status,
				postList: [],
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParams);

			console.log(data, NewsfeedPostList);

			if (status === 200) {
				setApiMessage('success', data?.message);
				updatePartOfPostsArrayByPostIndex(value?.id);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const updatePostData = async (value) => {
		setIsUpdate(true);
		setPostData(value);
	};

	// DELETE NEWSFEED POST
	const handleDeletePost = async (item) => {
		setIsLoading(true);

		if (isLoading) {
			return;
		}

		try {
			const { status, data } = await axiosInstance.delete(`${URLS.deletePost}/${item?.id}`);

			if (status === 200) {
				setApiMessage('success', data?.message);
				// 2 because after useEffect page from change to 2

				if (recentPage === 2) {
					fetchNewsfeedPostList(getTabValue(tab), view, currentPage);
				} else {
					getNewsfeedPostList({
						...NewsfeedPostList,
						totalRecords: NewsfeedPostList.totalRecords - 1,
						stickied_post_list: NewsfeedPostList.stickied_post_list.filter((post) => post.id !== item.id),
					});
					setNewsfeedPostList((prev) => {
						return prev.filter((post) => post.id !== item.id);
					});
				}

				//fetchNewsfeedPostList(getTabValue(tab), view, currentPage);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (val) => {
		setAnchorEl(null);
		const NF_FILTER = fetchFromStorage('newsfeedfilter');
		var obj = {
			...NF_FILTER,
			view_status: val ? val : view,
		};

		saveToStorage('newsfeedfilter', obj);
	};

	// NEWS FEED PAGINATION
	// const handlePaginationButtonClick = async navDirection => {
	//   if (navDirection === 'prev') {
	//     if (currentPage > 1) {
	//       setCurrentPage(currentPage - 1);
	//       fetchNewsfeedPostList(tabValue, view, currentPage - 1);
	//     }
	//   } else {
	//     if (currentPage < Math.ceil(totalRecord / limit)) {
	//       setCurrentPage(currentPage + 1);
	//       fetchNewsfeedPostList(tabValue, view, currentPage + 1);
	//     }
	//   }
	// };

	// const handlePaginationClick = async (event, page) => {
	//   if (currentPage !== page) {
	//     setCurrentPage(Number(page));
	//     fetchNewsfeedPostList(tabValue, view, Number(page));
	//   }
	// };

	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = urlType;

					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: urlValue,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchAllUsersList();
		const NF_FILTER = fetchFromStorage('newsfeedfilter');
		const obj = {
			order_by: NF_FILTER?.order_by ? NF_FILTER?.order_by : 'date',
			view_status: NF_FILTER?.view_status ? NF_FILTER?.view_status : 'all',
		};
		// fetchNewsfeedPostList('best', 'my', currentPage);
		var orderBY =
			obj?.order_by === 'best'
				? '1'
				: obj?.order_by === 'trending'
				? '2'
				: obj?.order_by === 'date'
				? '3'
				: obj?.order_by === 'top'
				? '4'
				: obj?.order_by === 'recommended'
				? '5'
				: '1';

		setTab(orderBY);
		setView(obj?.view_status);
		// fetchNewsfeedPostList(obj?.order_by, obj?.view_status, currentPage);
		fetchNewsfeedPostList(obj?.order_by, obj?.view_status, '');
		saveToStorage('newsfeedfilter', obj);
		fetchReactions();
	}, []);

	const NF_FILTER = fetchFromStorage('newsfeedfilter');
	const obj = {
		order_by: NF_FILTER?.order_by ? NF_FILTER?.order_by : 'best',
		view_status: NF_FILTER?.view_status ? NF_FILTER?.view_status : 'my',
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Box className="newsfeed-left-box" id="back-to-top-post">
			{isUpdate ? (
				<UpdatePost
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					postData={postData}
					cancelBtnClick={() => setIsUpdate(false)}
					tab={tab}
					loader={loader}
					token={token}
					user={user}
					view={view}
					fetchPostDetails={updatePartOfPostsArrayByPostIndex}
					currentPage={currentPage}
					allUsersList={allUsersList}
				/>
			) : (
				<CreatePost
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					loader={loader}
					token={token}
					user={user}
					isView={isView}
					view={view}
					tab={tab}
					fetchNewsfeedPostList={fetchNewsfeedPostList}
					updatePostData={updatePostData}
					currentPage={currentPage}
					allUsersList={allUsersList}
				/>
			)}
			<Box className="bpdtr-box">
				<TabContext value={String(tab)}>
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
						{btnList?.map((data, index) => {
							return (
								<Tab
									disabled={isLoading}
									key={index}
									icon={btnIconHandler(data?.id)}
									label={data?.name}
									value={data?.id}
									disableRipple
									disableTouchRipple
									disableFocusRipple
								/>
							);
						})}
						{/* HIDE-MVP: CM-75 */}
						{/*<Button endIcon={<ArrowDown />} className="tab-button" onClick={handleClick}>*/}
						{/*	{view === 'my' ? t('newsfeed.my_view') : view === 'all' ? t('newsfeed.all_view') : ''}*/}
						{/*</Button>*/}
					</TabList>
					<Popover
						className="lc-popover"
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<MenuItem
							className={view === 'my' ? 'my-option-menu activelink' : 'my-option-menu'}
							onClick={() => {
								setView('my');
								handleClose('my');
								fetchNewsfeedPostList(getTabValue(tab), 'my', currentPage);
							}}
						>
							<ListItemText primary="My View" />
						</MenuItem>
						<MenuItem
							className={view === 'all' ? 'my-option-menu activelink' : 'my-option-menu'}
							onClick={() => {
								setView('all');
								handleClose('all');
								fetchNewsfeedPostList(getTabValue(tab), 'all', currentPage);
							}}
						>
							<ListItemText primary="All View" />
						</MenuItem>
					</Popover>
				</TabContext>
			</Box>

			{/* STICKIED POSTS */}
			<StickiedPost
				user={user}
				updateCommunityPost={updateCommunityPost}
				updatePostData={updatePostData}
				handleDeletePost={handleDeletePost}
				cancelBtnClick={() => setIsUpdate(false)}
				addVote={addVote}
				addLike={addLike}
				fetchPostDetails={fetchPostDetails}
				reactionData={reactionData}
			/>
			{/* AD ON MOBILE SIZE */}
			<AdBlock className={'show-breakpoint-flex'} />
			{/* COMMUNITY POSTS */}
			{/* <CommunityPost
        isView={true}
        postName="[Post Name]"
        time="2 hours ago"
        postedBy="[Posted By]"
        topVal={45}
        downVal={10}
        contentText="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity."
        // contentImg="/static/collage.png"
        comment={58}
        shares={8}
        referrals={12}
        reactions={3}
        communityPost={true}
      /> */}

			<Box id="scrollableDiv" className="infinity-scroll-view-custom">
				{newsfeedPostList && newsfeedPostList.length > 0 ? (
					// {newsFeedPostList && newsFeedPostList?.length > 0 ? (
					<InfiniteScroll
						dataLength={newsfeedPostList.length}
						// next={() => fetchNewsfeedPostList(obj?.order_by, obj?.view_status, currentPage)}
						next={() => {
							fetchNewsfeedPostList(obj?.order_by, obj?.view_status, '');
						}}
						style={{ width: '100%', overflow: 'unset' }} //To put endMessage and loader to the top.
						inverse={false} //
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
						scrollableTarget={isFullScreenScroll ? 'pageScrollableBody' : 'scrollableDiv'}
					>
						{newsfeedPostList.map((item, index) => {
							const postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');
							var postImage = item?.PostLists.filter((d) => d?.Item?.type === 'image');
							var postVideo = item?.PostLists.filter((d) => d?.Item?.type === 'video');
							var postAudio = item?.PostLists.filter((d) => d?.Item?.type === 'audio');
							var postDoc = item?.PostLists.filter((d) => d?.Item?.type === 'doc');

							return (
								<Fragment key={item.id + 'newsfeed post'}>
									{item?.receiver_type === 'user' ? (
										<NewsfeedCommunityPost
											fetchPostDetails={fetchPostDetails}
											postedBy={item?.user_full_name}
											time={
												item?.timestamp_created !== null &&
												moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
											}
											activityType={item?.receiver_type === 'community' ? item?.receiver_type : ''}
											communityName={item?.receiver_type === 'community' ? item?.community_name : ''}
											topVal={item?.total_vote_up}
											downVal={item?.total_vote_down}
											comment={item?.total_comments}
											referrals={item?.total_referrals}
											postProfile={item?.user_profile_data}
											addVote={addVote}
											likedStatus={item?.like_status}
											reactions={item?.total_likes}
											isJoin={item?.join_status}
											editAccess={item?.edit_access}
											data={item}
											addLike={addLike}
											userURI={item?.user_meta?.data}
											fetchNewsfeedPostList={fetchNewsfeedPostList}
											currentPage={currentPage}
											tab={tab}
											view={view}
											user={user}
											shares={item?.total_shares}
											reactionData={reactionData}
											PostEvent={item?.profile_update_data?.profile_update_type === 'event_fight_record' ? true : false}
											recordData={item?.profile_update_data?.records}
											verifyUser={item?.veritication_status === 'yes' ? true : false}
										/>
									) : (
										<CommunityPost
											fetchPostDetails={fetchPostDetails}
											linkList={postContent?.Item?.links_list}
											isView={true}
											user={user}
											postName={item?.name}
											time={
												item?.timestamp_created !== null &&
												moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
											}
											postedBy={item?.receiver_type === 'organisation' ? item?.organisation_name : item?.user_full_name}
											topVal={item?.total_vote_up}
											downVal={item?.total_vote_down}
											contentText={postContent?.Item?.data}
											contentImg={postImage}
											comment={item?.total_comments}
											badges={item?.total_badges}
											shares={item?.total_shares}
											referrals={item?.total_referrals}
											userURI={item?.user_meta?.data}
											reactions={item?.total_likes}
											communityPost={item?.receiver_type === 'post' ? true : false}
											communityName={
												item?.receiver_type === 'community'
													? item?.community_name
													: item?.receiver_type === 'organisation'
													? item?.user_full_name
													: ''
											}
											activityType={
												item?.receiver_type === 'community'
													? item?.receiver_type
													: item?.receiver_type === 'organisation'
													? 'Posted By'
													: ''
											}
											communityID={
												item?.receiver_type === 'community' || item?.receiver_type === 'organisation'
													? item?.receiver_id
													: ''
											}
											postProfile={
												item?.receiver_type === 'organisation'
													? item?.organisation_profile_data
													: item?.user_profile_data
											}
											editAccess={item?.edit_access}
											postUserId={item?.user_id}
											receiver_type={item?.receiver_type}
											data={item}
											isJoin={item?.join_status}
											PostId={item?.user_id}
											addVote={addVote}
											addLike={addLike}
											updateCommunityPost={updateCommunityPost}
											updatePostData={updatePostData}
											handleDeletePost={handleDeletePost}
											cancelBtnClick={() => setIsUpdate(false)}
											closeCommunity={item?.status === 'closed' ? true : false}
											reactionData={reactionData}
											likedStatus={item?.like_status}
											postVideo={postVideo}
											postAudio={postAudio}
											postDoc={postDoc}
											verifyUser={item?.veritication_status === 'yes' ? true : false}
										/>
									)}
								</Fragment>
							);
						})}
					</InfiniteScroll>
				) : (
					<Typography className="no-post-text">{/* {t('community.no_post_txt')} */}</Typography>
				)}
			</Box>
			{/* <CustomPagination
        paginationArray={NewsfeedPostList?.post_list}
        currentPage={currentPage}
        list={limit}
        handlePaginationButtonClick={handlePaginationButtonClick}
        handlePaginationClick={handlePaginationClick}
        totalRecord={totalRecord}
      /> */}

			{/* <CommunityPost
        isView={true}
        postName="[Post Name]"
        time="2 hours ago"
        postedBy="[Posted By]"
        topVal={45}
        downVal={10}
        // contentText=""
        contentImg="/static/collage.png"
        comment={58}
        shares={8}
        referrals={12}
        reactions={3}
      />

      <CommunityPost
        isView={true}
        postName="[Post Name]"
        time="2 hours ago"
        postedBy="[Posted By]"
        topVal={45}
        downVal={10}
        contentText="[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity."
        contentImg="/static/collage.png"
        comment={58}
        shares={8}
        referrals={12}
        reactions={3}
      /> */}
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getNewsfeedPostList: (data) => dispatch(getNewsfeedPostList(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeedLeftside);
