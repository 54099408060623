import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid } from '@material-ui/core';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { useTranslation } from 'react-i18next';

const AddFightRecord = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Formik
				initialValues={{
					opponent: '',
					date: '',
					eventFight: '',
					eventFightSide: '',
					weightClass: '',
					result: '',
					roundNumber: '',
					score: '',
					time: '',
					method: '',
				}}
				validationSchema={Yup.object().shape({
					opponent: Yup.string().required(t('validation.required_message')),
					date: Yup.string().required(t('validation.required_message')),
					eventFight: Yup.string().required(t('validation.required_message')),
					eventFightSide: Yup.string().required(t('validation.required_message')),
					weightClass: Yup.string().required(t('validation.required_message')),
					result: Yup.string().required(t('validation.required_message')),
					roundNumber: Yup.string().required(t('validation.required_message')),
					score: Yup.string().required(t('validation.required_message')),
					time: Yup.string().required(t('validation.required_message')),
					method: Yup.string().required(t('validation.required_message')),
				})}
				onSubmit={(data) => {}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.opponent && errors.opponent)}
									fullWidth
									helperText={touched.opponent && errors.opponent}
									variant="filled"
									InputProps={{
										endAdornment: touched.opponent && errors.opponent ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.opponent')}
									placeholder={t('myprofile.opponent')}
									className="matrix-textfield"
									size="small"
									name="opponent"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.opponent}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.date && errors.date)}
									fullWidth
									helperText={touched.date && errors.date}
									variant="filled"
									InputProps={{
										endAdornment: touched.date && errors.date ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.date')}
									placeholder={t('myprofile.date')}
									className="matrix-textfield"
									size="small"
									name="date"
									type="date"
									InputLabelProps={{
										shrink: true,
									}}
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.date}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.eventFight && errors.eventFight)}
									fullWidth
									helperText={touched.eventFight && errors.eventFight}
									variant="filled"
									InputProps={{
										endAdornment: touched.eventFight && errors.eventFight ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.event_fight')}
									placeholder={t('myprofile.event_fight')}
									className="matrix-textfield"
									size="small"
									name="eventFight"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.eventFight}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.eventFightSide && errors.eventFightSide)}
									fullWidth
									helperText={touched.eventFightSide && errors.eventFightSide}
									variant="filled"
									InputProps={{
										endAdornment: touched.eventFightSide && errors.eventFightSide ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.event_fight_side')}
									placeholder={t('myprofile.event_fight_side')}
									className="matrix-textfield"
									size="small"
									name="eventFightSide"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.eventFightSide}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.weightClass && errors.weightClass)}
									fullWidth
									helperText={touched.weightClass && errors.weightClass}
									variant="filled"
									InputProps={{
										endAdornment: touched.weightClass && errors.weightClass ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.weight_class')}
									placeholder={t('myprofile.weight_class')}
									className="matrix-textfield"
									size="small"
									name="weightClass"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.weightClass}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.result && errors.result)}
									fullWidth
									helperText={touched.result && errors.result}
									variant="filled"
									InputProps={{
										endAdornment: touched.result && errors.result ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.result')}
									placeholder={t('myprofile.result')}
									className="matrix-textfield"
									size="small"
									name="result"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.result}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.roundNumber && errors.roundNumber)}
									fullWidth
									helperText={touched.roundNumber && errors.roundNumber}
									variant="filled"
									InputProps={{
										endAdornment: touched.roundNumber && errors.roundNumber ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.round_number')}
									placeholder={t('myprofile.round_number')}
									className="matrix-textfield"
									size="small"
									name="roundNumber"
									onBlur={handleBlur}
									onChange={(e) => {
										const re = /^[0-9\b]+$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									value={values.roundNumber}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.score && errors.score)}
									fullWidth
									helperText={touched.score && errors.score}
									variant="filled"
									InputProps={{
										endAdornment: touched.score && errors.score ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.score')}
									placeholder={t('myprofile.score')}
									className="matrix-textfield"
									size="small"
									name="score"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.score}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.time && errors.time)}
									fullWidth
									helperText={touched.time && errors.time}
									variant="filled"
									InputProps={{
										endAdornment: touched.time && errors.time ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.time')}
									placeholder={t('myprofile.time')}
									className="matrix-textfield"
									size="small"
									name="time"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.time}
								/>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.method && errors.method)}
									fullWidth
									helperText={touched.method && errors.method}
									variant="filled"
									InputProps={{
										endAdornment: touched.method && errors.method ? <ErrorIcon /> : null,
									}}
									label={t('myprofile.method')}
									placeholder={t('myprofile.method')}
									className="matrix-textfield"
									size="small"
									name="method"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.method}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default AddFightRecord;
