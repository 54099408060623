import Discussions from './Discussions';
import About from './About';
import Members from './Members';
import Events from './Events';
import Related from './Related';
import Restrictions from '../RightSide/Restrictions';
import Badges from '../RightSide/Badges';
import CategoryDiscipline from '../RightSide/CategoryDiscipline';

const CommunityLeftSide = ({
	loader,
	isView,
	isJoin,
	id,
	token,
	user,
	selected,
	fetchCommunityDetails,
	fetchCommunityPostList,
	selREQ,
}) => {
	const switchCommunityContent = (value) => {
		switch (value) {
			case 'discussions':
				return (
					<Discussions
						loader={loader}
						isView={isView}
						isJoin={isJoin}
						token={token}
						user={user}
						fetchCommunityPostList={fetchCommunityPostList}
					/>
				);
			case 'about':
				return <About isView={isView} isJoin={isJoin} fetchCommunityDetails={fetchCommunityDetails} />;
			case 'members':
				return <Members isView={isView} isJoin={isJoin} selREQ={selREQ} />;
			case 'events':
				return <Events isView={isView} isJoin={isJoin} id={id} />;
			case 'related':
				return <Related isView={isView} isJoin={isJoin} />;
			case 'cat_dis':
				return (
					<CategoryDiscipline
						loader={loader}
						token={token}
						isView={isView}
						isJoin={isJoin}
						fetchCommunityDetails={fetchCommunityDetails}
					/>
				);
			case 'badges':
				return <Badges loader={loader} token={token} isView={isView} isJoin={isJoin} />;
			case 'settings':
				return (
					<Restrictions
						loader={loader}
						token={token}
						isView={isView}
						isJoin={isJoin}
						fetchCommunityDetails={fetchCommunityDetails}
					/>
				);
			default:
				return (
					<Discussions
						fetchCommunityPostList={fetchCommunityPostList}
						loader={loader}
						isView={isView}
						isJoin={isJoin}
						token={token}
						user={user}
					/>
				);
		}
	};

	return switchCommunityContent(selected);
};

export default CommunityLeftSide;
