import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import config from './config';
import { fetchFromStorage, saveToStorage } from '../../helpers/context';

const { defaultNS, fallbackLng, interpolation, supportedLocales } = config;

const parseMissingKeyHandler = (key) => {
	console.warn(`Missing value for key ${key}`);

	return '';
};

// eslint-disable-next-line global-require, import/no-dynamic-require
const getTranslates = (language = fallbackLng) => require(`./locales/${language}.json`);

export const whitelist = supportedLocales.map((locale) => locale.code);

const initI18n = () => {
	const lng = fetchFromStorage('acceptlanguage') ?? fallbackLng;

	const resources = whitelist.reduce(
		(acc, locale) => ({
			...acc,
			[locale]: {
				[defaultNS]: getTranslates(locale),
			},
		}),
		{},
	);

	i18n.on('initialized', ({ lng }) => {
		saveToStorage('acceptlanguage', lng);
	});

	i18n.on('languageChanged', (lng) => {
		saveToStorage('acceptlanguage', lng);
	});

	i18n.use(initReactI18next).init({
		lng,
		whitelist,
		defaultNS,
		resources,
		fallbackLng,
		interpolation,
		useSuspense: true,
		parseMissingKeyHandler,
	});

	return i18n;
};

export default initI18n;
