import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import moment from 'moment';
import { ReactComponent as WhiteEditIcon } from 'src/assets/images/icons/events/white-pencil.svg';
import './event-card.scss';
import { ReactComponent as EditDraft } from 'src/assets/images/icons/edit-draft.svg';
import { useTranslation } from 'react-i18next';

const EventCard = ({
	isView,
	isOwned,
	isDraft,
	isDiscover,
	eventProfile,
	eventType,
	eventName,
	startTime,
	eventId,
	isCommunity,
	joinEvent,
	eventOwner,
	eventTypeList,
	isSearch,
	locationType,
	eventPostId,
	onUpdateEvent,
	isSuperEvent,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	var sDate = moment(startTime).format('MMMM Do YYYY, h:mm:ss a');
	var event_type = eventType && eventTypeList && eventTypeList.filter((item) => item?.id === eventType);

	return (
		<Card className="community-event-card">
			{/* Event Image */}
			{isDraft ? (
				eventProfile ? (
					<CardMedia
						className="com-event-image draft-event"
						style={{
							boxShadow: 'inset 0 0 0 100vw #438ac1b0',
							// backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 100%,rgba(0, 0, 0, 0.5) 100%),url(${'/static/card-1.png'})`
						}}
						// image="/static/card-1.png"
						image={eventProfile}
						title="Event Name"
						onClick={() => {
							navigate(`/events/details/${eventPostId}?eventId=${eventId}`);
						}}
					>
						{/* {t('events.draft')} */}
						<EditDraft />
					</CardMedia>
				) : (
					<>
						<Avatar
							style={{ boxShadow: 'inset 0 0 0 100vw #438ac1b0' }}
							className="com-event-image  draft-event"
							variant="square"
							src={eventProfile}
						>
							<EditDraft />
						</Avatar>
					</>
				)
			) : eventProfile ? (
				<CardMedia
					className="com-event-image"
					image={eventProfile}
					title="Event Name"
					onClick={() => {
						navigate(`/events/details/${eventPostId}?eventId=${eventId}`);
					}}
				/>
			) : (
				<Avatar
					className="com-event-image"
					variant="square"
					src={eventProfile}
					onClick={() => {
						navigate(`/events/details/${eventPostId}?eventId=${eventId}`);
					}}
				/>
			)}
			<CardContent
				onClick={() => {
					navigate(`/events/details/${eventPostId}?eventId=${eventId}`);
				}}
			>
				{event_type && (
					<Box className="event-tag-box">
						<Button
							variant="contained"
							disableElevation
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className="event-tag"
						>
							{isSuperEvent ? 'Super Event' : event_type[0]?.name}
						</Button>
					</Box>
				)}
				<Typography className="event-title">{eventName}</Typography>
				{startTime && <Typography className="event-timing">{sDate}</Typography>}
				{/* {
          isSearch && <Typography className="event-status">
            {locationType}
          </Typography>
        } */}
			</CardContent>
			{!isSearch && (
				<CardActions>
					{isDiscover ? (
						<Button
							className="owned-editdetails"
							onClick={() => {
								eventOwner ? onUpdateEvent(eventId) : navigate(`/events/details/${eventPostId}?eventId=${eventId}`);
							}}
						>
							{eventOwner ? t('events.edit_event') : t('events.view_event')}
						</Button>
					) : isCommunity ? (
						<Button
							className="owned-editdetails"
							onClick={() => {
								joinEvent(eventId);
							}}
						>
							{t('join')}
						</Button>
					) : isDraft ? (
						<Button fullWidth className="draft-edit-event">
							<WhiteEditIcon />
						</Button>
					) : isOwned ? (
						<>
							<Button className="owned-editdetails">{t('events.event_details')}</Button>
							<Button className="owned-edit">
								<WhiteEditIcon />
							</Button>
						</>
					) : (
						<Button variant="contained" fullWidth className="edit-event-btn">
							{isView ? t('community.join') : t('community.edit_event')}
						</Button>
					)}
				</CardActions>
			)}
		</Card>
	);
};

export default EventCard;
