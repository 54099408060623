import { useContext, useEffect, useState } from 'react';
import { Box, Container, Toolbar, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommunityPost from 'src/views/component/UI/CommunityPost';
import './communityPost.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { useSelector, connect } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import moment from 'moment';
import { getCommunityPostList } from 'src/redux-state/actions/actions';
import { setApiMessage } from 'src/helpers/commonFunctions';
import Page from 'src/components/Page';
import { Config } from 'src/helpers/context/config';
import PostDetailsRight from './PostDetailsRight';
import UpdatePost from './UpdatePost';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});
const CommunityPostView = ({ getCommunityPostList }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { id } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const query = parse(location.search);
	const [isUpdate, setIsUpdate] = useState(false);
	const [postData, setPostData] = useState({});
	const [tab, setTab] = useState('1');
	const [postAccess, setPostAccess] = useState(true);
	const [allUsersList, setAllUsersList] = useState([]);
	const [reactionData, setReactionData] = useState([]);
	const [loader, setLoader] = useState(false);

	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');
	const token = fetchFromStorage('authData')?.token;
	const [post_Data, setPost_Data] = useState([]);
	var urlValue = location.pathname.split('/');
	// FETCH COMMUNITY POST
	const fetchCommunityPostList = async (orderBy) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityPostList + `/${id}?order_by=${orderBy}`);

			if (status === 200) {
				getCommunityPostList(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FOR REFERRAL
	const referralUse = async () => {
		var requestParameter = {
			referral_code: query?.referralcode,
			land_type: identifiers?.referralType?.post,
			land_id: Number(id),
			user_id: user?.user_id,
		};

		if (query?.referralcode !== undefined) {
			try {
				const { status, data } = await axiosInstance.post(URLS.referralUse, requestParameter);

				if (status === 200) {
					// TODO: Need BA
				}
			} catch (error) {
				// TODO: Add logging
			}
		}
	};
	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = Config?.DomainName + urlType;

					const object = {
						// ...item,
						id: urlValue,
						display: item?.user_full_name,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.display !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH POST DETAILS
	const fetchPostDetails = async () => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(`${URLS.getPostDetails}/${id}`);

			if (status === 200) {
				// CM-105: make all posts available if not community private
				const hasPublicAccess = data?.data?.community_data?.guest_access === 'public' || data?.data?.post_data;

				getCommunityPostList(data?.data);
				fetchFromStorage('authData') === null && setPostAccess(hasPublicAccess);
				setPostData(data?.data);
				setLoader(false);

				if (!data.data.post_data) {
					navigate('/');
				}
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const communityAction = async (data, status) => {
		var requestParams = {
			user_id: fetchFromStorage('authData')?.user_id,
			community_id: data?.id,
			status: status,
		};

		if (!token) {
			return;
		}

		try {
			const { status, data } = await axiosInstance.post(URLS.joinCommunity, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchPostDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// ADD VOTE
	const addVote = async (value, type) => {
		var requestParams = {
			post_id: Number(id),
			vote_name: type,
			main_post_id: Number(id),
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addVote, requestParams);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// REACTION
	const addLike = async (value, type) => {
		var requestParams = {
			post_id: Number(id),
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb,
			main_post_id: Number(id),
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				fetchPostDetails();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const updateCommunityPost = async (value, status) => {
		setLoader(true);

		if (loader) {
			return;
		}

		const requestParams = {
			post: {
				id: value?.id,
				sticky: status,
				postList: [],
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParams);

			if (status === 200) {
				await fetchPostDetails();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setLoader(false);
		}
	};
	const updatePostData = async (value) => {
		setIsUpdate(true);
		setPost_Data(value);
	};
	const handleDeletePost = async (item) => {
		setLoader(true);

		if (loader) {
			return;
		}

		try {
			const { status, data } = await axiosInstance.delete(`${URLS.deletePost}/${item?.id}`);

			setPostData({});

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		referralUse();
		fetchPostDetails();
		fetchAllUsersList();
		fetchReactions();
	}, []);
	var postAbout = postData?.post_data?.PostLists.find((item) => item?.Item?.type === 'text');
	var postImage = postData?.post_data?.PostLists.filter((item) => item?.Item?.type === 'image');
	var postVideos = postData?.post_data?.PostLists?.filter((d) => d?.Item?.type === 'video');
	var postAudio = postData?.post_data?.PostLists?.filter((d) => d?.Item?.type === 'audio');
	var postDoc = postData?.post_data?.PostLists?.filter((d) => d?.Item?.type === 'doc');

	var post_name = postData?.post_data?.name
		? postData?.post_data?.name
		: postData?.post_data?.user_full_name
		? postData?.post_data?.user_full_name
		: 'Combat Matrix';
	var PageTitle = postAccess ? post_name : 'This post is not publically available';

	const redirectGuestToSignUp = () => {
		if (!token) {
			navigate('/');
		}

		return undefined;
	};

	return (
		<Page className={classes.root} title={`${PageTitle}`}>
			<Container maxWidth="lg">
				<Box className="combat-container newsfeed-container">
					{Object.keys(postData ?? {}).length === 0 && !loader ? (
						<Typography className="post-not-available-text">This post is deleted</Typography>
					) : (
						<>
							{postAccess ? (
								<Box className="newsfeed-grid-container">
									<Box className="newsfeed-left">
										<Toolbar style={{ minHeight: 0, maxHeight: 0 }} id="back-to-top-post" />
										{isUpdate ? (
											<UpdatePost
												postData={postData}
												cancelBtnClick={() => setIsUpdate(false)}
												tab={tab}
												user={user}
												fetchPostDetails={fetchPostDetails}
												communityDetails={postData?.community_data}
												fetchCommunityPostList={fetchCommunityPostList}
												editAccess={postData?.community_data?.edit_access}
											/>
										) : (
											<></>
										)}

										{/* COMMUNITY POSTS */}

										<CommunityPost
											fetchPostDetails={fetchPostDetails}
											linkList={postAbout?.Item?.links_list}
											isView={true}
											isPostList={urlValue && urlValue[1] === 'post' ? true : false}
											postName={postData?.post_data?.name}
											time={
												postData?.post_data?.timestamp_created !== null &&
												moment.utc(postData?.post_data?.timestamp_created).fromNow().replace('in ', '')
											}
											allUsersList={allUsersList}
											postedBy={
												postData?.post_data?.receiver_type === 'organisation'
													? postData?.post_data?.organisation_name
													: postData?.post_data?.user_full_name
											}
											communityID={
												postData?.post_data?.receiver_type === 'community' ||
												postData?.post_data?.receiver_type === 'organisation'
													? postData?.post_data?.receiver_id
													: ''
											}
											topVal={postData?.post_data?.total_vote_up}
											downVal={postData?.post_data?.total_vote_down}
											contentText={postAbout?.Item?.data}
											contentImg={postImage}
											comment={postData?.post_data?.total_comments}
											shares={postData?.post_data?.total_shares}
											referrals={postData?.post_data?.total_referrals}
											reactions={postData?.post_data?.total_likes}
											postProfile={
												postData?.post_data?.receiver_type === 'organisation'
													? postData?.post_data?.organisation_profile_data
													: postData?.post_data?.user_profile_data
											}
											communityName={
												postData?.post_data?.receiver_type === 'community'
													? postData?.community_data?.name
													: postData?.post_data?.receiver_type === 'organisation'
													? postData?.post_data?.user_full_name
													: ''
											}
											activityType={
												postData?.post_data?.receiver_type === 'community'
													? postData?.post_data?.receiver_type
													: postData?.post_data?.receiver_type === 'organisation'
													? 'Posted By'
													: ''
											}
											addVote={addVote}
											addLike={addLike}
											user={user}
											data={postData?.post_data}
											receiver_type={postData?.post_data?.receiver_type}
											postUserId={postData?.post_data?.user_id}
											fetchCommunityPostList={fetchCommunityPostList}
											editAccess={postData?.community_data?.edit_access}
											handleDeletePost={handleDeletePost}
											updateCommunityPost={updateCommunityPost}
											updatePostData={updatePostData}
											cancelBtnClick={() => setIsUpdate(false)}
											communityData={postData?.community_data}
											userURI={postData?.post_data?.user_id}
											isJoin={
												Number(fetchFromStorage('authData')?.user_id) ===
												Number(postData?.community_data?.user_owner_id)
													? true
													: postData?.post_data?.receiver_type === 'post'
													? true
													: postData?.post_data?.receiver_type === 'user'
													? true
													: postData?.community_data?.join_status === 'accepted'
											}
											postDetails={true}
											closeCommunity={postData?.community_data?.status === 'closed' ? true : false}
											reactionData={reactionData}
											likedStatus={postData?.post_data?.like_status}
											postVideo={postVideos}
											postAudio={postAudio}
											postDoc={postDoc}
											verifyUser={postData?.post_data?.UserData?.veritication_status === 'yes' ? true : false}
										/>
									</Box>

									<Box className="newsfeed-right hide-1279">
										<PostDetailsRight
											redirectGuestToSignUp={redirectGuestToSignUp}
											token={token}
											communityDetails={postData?.community_data}
											user={user}
											communityAction={communityAction}
											postData={postData}
											fetchPostDetails={fetchPostDetails}
										/>
									</Box>
									<Box className="newsfeed-right show-1279">
										<PostDetailsRight
											redirectGuestToSignUp={redirectGuestToSignUp}
											token={token}
											communityDetails={postData?.community_data}
											user={user}
											communityAction={communityAction}
											postData={postData}
											fetchPostDetails={fetchPostDetails}
										/>
									</Box>
								</Box>
							) : (
								<Typography className="post-not-available-text">This post is not publically available</Typography>
							)}
						</>
					)}
				</Box>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunityPostList: (data) => dispatch(getCommunityPostList(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPostView);
