import { Box, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

const ProfileAbout = ({ user }) => {
	let loader = false;
	let isView = true;
	let dob = user?.OtherUserDetails?.dob || 'N/A';

	// COUNT AGE
	const getAge = (d1) => {
		let d2 = new Date();
		var diff = d2.getTime() - d1.getTime();

		return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	};
	// WEIGHT CLASS
	let weight_class =
		user?.UserOtherMetaData?.filter((data) => data?.type === 'weight_class')
			?.map((x) => x?.data)
			.join(', ') || 'N/A';

	// ORGANIZATION NAME
	let organisation_name =
		user?.OtherUserDetails?.OrganizationData !== null
			? user?.OtherUserDetails?.OrganizationData?.name
				? user?.OtherUserDetails?.OrganizationData?.name
				: 'N/A'
			: 'N/A';

	// FIGHTING LOCATION
	let location_fighting =
		user?.OtherUserDetails?.UserMetaData?.filter((data) => data?.type === 'location_fighting')[0]?.data || 'N/A';

	// FIGHT RECORD
	var stastics = user?.UserStatisticsDetails?.fightRecord;
	let fight_record = `${stastics?.win !== undefined ? stastics?.win : 0}-${
		stastics?.loss !== undefined ? stastics?.loss : 0
	}-${stastics?.draw !== undefined ? stastics?.draw : 0} (${
		stastics?.NoContest !== undefined ? stastics?.NoContest : 0
	} NC)`;

	// COUNTRY NAME
	var userCounty = user?.OtherUserDetails?.country ? user?.OtherUserDetails?.country : 'N/A';

	return (
		<Box className="left-profile-details-box">
			<Box display="flex" justifyContent="space-between">
				<Typography className="profile-left-title">Profile</Typography>
				{loader ? null : isView ? null : (
					<IconButton>
						<BpencilIcon />
					</IconButton>
				)}
			</Box>

			<Box className="profile-details-data">
				{/* USERNAME */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Username' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : user?.OtherUserDetails?.user_name}
					</Typography>
				</Box>

				{/* DATE OF BIRTH */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Date of Birth' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? (
							<Skeleton variant="text" width={'100px'} />
						) : (
							<> {user?.OtherUserDetails?.dob !== null ? moment.utc(dob).format('MM/DD/YYYY') : 'N/A'}</>
						)}
					</Typography>
				</Box>

				{/* AGE */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Age' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? (
							<Skeleton variant="text" width={'100px'} />
						) : (
							<>{user?.OtherUserDetails?.dob !== null ? dob && getAge(new Date(dob)) : 'N/A'}</>
						)}
					</Typography>
				</Box>

				{/* WEIGHT CLASSES */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Weight Range:' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : weight_class}
					</Typography>
				</Box>

				{/* COUNTRY */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Country' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : userCounty}
					</Typography>
				</Box>

				{/* ORGANISATIONS */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Organisation/s' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : organisation_name}
					</Typography>
				</Box>

				{/* FIGHTING OUT OF */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Fighting Out Of' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : location_fighting}
					</Typography>
				</Box>

				{/* FIGHT RECORDS */}
				<Box display="flex">
					<Typography className="profile-details-field">
						{loader ? <Skeleton variant="text" width={'100px'} /> : 'Fight Record' + ':'}
					</Typography>
					<Typography className="profile-details-field-val">
						{loader ? <Skeleton variant="text" width={'100px'} /> : fight_record}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default ProfileAbout;
