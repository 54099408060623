import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	// Chip,
	Fade,
	Grid,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CircularProgress,
	Link,
} from '@material-ui/core';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './update-post.scss';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import BoldSvg from 'src/assets/images/icons/community/bold.svg';
import ItalicSvg from 'src/assets/images/icons/community/italic.svg';
import UnderlineSvg from 'src/assets/images/icons/community/underlined.svg';
import AlignLeftSvg from 'src/assets/images/icons/community/align-left.svg';
import AlignRightSvg from 'src/assets/images/icons/community/align-right.svg';
import AlignCenterSvg from 'src/assets/images/icons/community/align-center.svg';
import LinkSvg from 'src/assets/images/icons/community/link.svg';
import NumberedSvg from 'src/assets/images/icons/community/numbered.svg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import UploadIcon from 'src/assets/images/icons/cameraTextEditor.svg';
import ReactPlayer from 'react-player';
import htmlToDraft from 'html-to-draftjs';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import draftToHtml from 'draftjs-to-html';
import CustomOption from '../../../../UI/CustomOption';

const UpdatePost = ({
	postData,
	cancelBtnClick,
	fetchCommunityPostList,
	tab,
	communityDetails,
	fetchPostDetails,
	editAccess,
	user,
}) => {
	const { id } = useParams();
	const { t } = useTranslation();
	const auth = useSelector((state) => state.Combat.authData);
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	const { post_data } = postData;

	// console.log(post_data.comments === 'yes');

	// const communityDetails = useSelector(state => state.Combat.communityDetails);
	const userImage = communityDetails && communityDetails?.owner_item_profile_data;
	// const [checked, setChecked] = useState([]);
	const [sStatus, setSponsoredPost] = useState(false);
	const [stickyPost, setStickyPost] = useState(false);
	const [allowComments, setAllowCommentPost] = useState(false);
	const [deletedItemArray, setDeletedItemArray] = useState([]);
	const [text, setText] = useState(EditorState.createEmpty());
	const [textError, setTextError] = useState('');
	const [allImages, setAllImages] = useState([]);
	const [imageStatus, setImageStatus] = useState(true);
	const [allUsersList, setAllUsersList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	var postDescription = postData && postData?.post_data?.PostLists.find((item) => item?.Item?.type === 'text');

	// const typeList = [
	//     // { id: 0, name: 'Featured Post' },
	//     { id: 1, name: 'Sticky Post' },
	//     { id: 2, name: 'Allow for comments' }
	// ];

	const handleImageLoaded = () => {
		setImageStatus(false);
	};

	useEffect(() => {
		fetchAllUsersList();
		const contentBlock = htmlToDraft(postDescription?.Item?.data);

		setSponsoredPost(post_data.sponsored === 'yes');
		setStickyPost(post_data.sticky === 'yes');
		setAllowCommentPost(post_data.comments === 'yes');

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

			setText(EditorState.createWithContent(contentState));
		} else {
			setText(EditorState.createWithContent());
		}
	}, []);

	const deletePostItem = (id) => {
		var deletedImage = allImages && allImages?.filter((item, index) => index !== id);

		setDeletedItemArray(deletedImage);
		setAllImages(deletedImage);
	};
	const handleChangeEditor = (editorState) => {
		setText(editorState);
		setTextError('');
	};

	const setImageList = (img) => {
		setAllImages(img);
	};

	const updateCommunityPost = async (data) => {
		setIsLoading(true);

		if (text.getCurrentContent().hasText() || Boolean(allImages.length)) {
			const rawHTML = text ? draftToHtml(convertToRaw(text.getCurrentContent())) : '';

			var postListArray = [...allImages];
			var filteredArray = [];

			postListArray.map((item, i) => {
				var object = {
					type: item?.type,
					item_id: item?.id,
				};

				return (filteredArray[i] = object);
			});

			filteredArray.push({
				type: 'text',
				data: rawHTML,
			});

			var requestParameter = {
				post: {
					id: postData?.post_data?.id,
					sponsored: sStatus ? 'yes' : 'no',
					sticky: stickyPost ? 'yes' : 'no',
					comments: allowComments ? 'yes' : 'no',
					name: data?.title,
					postList: filteredArray,
				},
			};

			try {
				const { status, data } = await axiosInstance.put(URLS.updatePost, requestParameter);

				if (status === 200) {
					const tabValue =
						tab === '1'
							? 'best'
							: tab === '2'
							? 'trending'
							: tab === '3'
							? 'date'
							: tab === '4'
							? 'top'
							: tab === '5'
							? 'recommended'
							: 'best';

					setApiMessage('success', data?.message);
					fetchCommunityPostList(tabValue);

					if (fetchPostDetails) {
						fetchPostDetails();
					}

					cancelBtnClick();
					// setCreateClick(false);
					// setChecked([]);
					// setSponsoredPost(false)
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			} finally {
				setIsLoading(false);
			}
		} else {
			setTextError(t('validation.required_message'));
			setIsLoading(false);
		}
	};
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const showItems = (item, index) => {
		switch (item?.type) {
			case 'doc':
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
			case 'image':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<Avatar
							src={imageStatus === true ? loaderGif : URLS.mediaURL + item?.location}
							alt="post-image"
							onError={(e) => {
								e.onerror = null;
								e.target.src = placeholder;
							}}
							onLoad={handleImageLoaded}
						/>
					</Box>
				);
			case 'video':
				return (
					<Box width="200px" className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player audio-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
		}
	};

	// const checkHandleToggle = value => () => {
	//     const currentIndex = checked.indexOf(value);
	//     const newChecked = [...checked];

	//     if (currentIndex === -1) {
	//         newChecked.push(value);
	//     } else {
	//         newChecked.splice(currentIndex, 1);
	//     }
	//     setChecked(newChecked);
	// };
	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = urlType;

					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: urlValue,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		if (postData) {
			var postAllData = postData?.post_data?.PostLists.filter((item) => item?.Item?.type !== 'text');
			var formattedAllData =
				postAllData &&
				postAllData?.map((item, index) => {
					var splitdata = item?.Item?.data.split('/');

					splitdata = splitdata && splitdata[splitdata.length - 1];

					return {
						id: item?.item_id,
						type: item?.Item?.type,
						location: splitdata,
					};
				});

			setAllImages(formattedAllData);
		}
	}, [postData]);

	console.log(postData);

	return (
		<Fade in>
			<Box className="creating-post-box">
				<Box className="user-details">
					<Avatar src={post_data.user_profile_data} />
					<Typography className="user-name">{post_data.user_full_name}</Typography>
				</Box>
				<Box className="text-field-grid post-title-input">
					<Formik
						initialValues={{
							title: postData?.post_data?.name ? postData?.post_data?.name : '',
							text: postDescription?.Item?.data ? postDescription?.Item?.data : '',
						}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required(t('validation.required_message')),
							text: Yup.string().required(t('validation.required_message')),
						})}
						onSubmit={(requestData) => {
							updateCommunityPost(requestData);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12} className="text-field-grid">
										<MatrixTextField
											error={Boolean(touched.title && errors.title)}
											fullWidth
											helperText={
												touched.title ? (
													touched.title && (
														<Box disply="flex" justifyContent="center">
															{errors.title}
															<span className="field-character-count">{values.title.length}/150</span>
														</Box>
													)
												) : (
													<span className="field-character-count">{values.title.length}/150</span>
												)
											}
											variant="filled"
											InputProps={{
												maxLength: 500,
												endAdornment: touched.title && errors.title ? <ErrorIcon /> : null,
											}}
											label={t('community.post_title')}
											placeholder={t('community.post_title')}
											className="matrix-textfield"
											size="small"
											name="title"
											onBlur={handleBlur}
											onChange={handleChange}
											inputProps={{ maxLength: 150 }}
											value={values.title}
										/>
									</Grid>
									<Grid item xs={12} md={12} className="text-field-grid cp-cnt">
										<Box className="custome-text-editior">
											<Editor
												handleBeforeInput={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													if (val && textLength >= 1000) {
														return 'handled';
													}

													return 'not-handled';
												}}
												handlePastedText={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													return val.length + textLength >= 1000;
												}}
												mention={{
													separator: ' ',
													trigger: '@',
													suggestions: allUsersList,
												}}
												editorState={text}
												toolbarCustomButtons={[
													<CustomOption
														setImageList={setImageList}
														deletedItemArray={deletedItemArray}
														token={token}
													/>,
												]}
												wrapperClassName="demo-wrapper"
												editorClassName="demo-editor"
												onEditorStateChange={(editorState) => {
													handleChangeEditor(editorState);
												}}
												placeholder={t('community.text')}
												toolbar={{
													options: ['link'],
													// HIDE-MVP: CM-75 CM-33
													//options: ['inline', 'link', 'textAlign', 'list'],
													// inline: {
													// 	inDropdown: false,
													// 	className: 'demo-option-custom',
													// 	options: ['bold', 'italic', 'underline'],
													// 	bold: {
													// 		icon: BoldSvg,
													// 	},
													// 	italic: {
													// 		icon: ItalicSvg,
													// 	},
													// 	underline: {
													// 		icon: UnderlineSvg,
													// 	},
													// },
													// textAlign: {
													// 	options: ['left', 'right', 'center'],
													// 	left: {
													// 		icon: AlignLeftSvg,
													// 	},
													// 	right: {
													// 		icon: AlignRightSvg,
													// 	},
													// 	center: {
													// 		icon: AlignCenterSvg,
													// 	},
													// },
													// list: {
													//   inDropdown: false,
													//   className: undefined,
													//   component: undefined,
													//   dropdownClassName: undefined,
													//   options: ['ordered'],
													//   ordered: {
													//     icon: NumberedSvg,
													//   },
													// },
													link: {
														inDropdown: false,
														className: undefined,
														component: undefined,
														dropdownClassName: undefined,
														options: ['link'],
														link: {
															icon: LinkSvg,
														},
													},
												}}
											/>
											<span className="field-character-count" style={{ paddingRight: '18px', marginTop: '4px' }}>
												{`${values.text.length}/${1000}`}
											</span>
											<span className="editior-error-message">{textError}</span>
										</Box>
									</Grid>
									<Grid item xs={12} md={12} className="image-wrapper">
										<Box className="image-wrapper-inner">
											{allImages.map((imageSrc, index) => {
												return showItems(imageSrc, index);
											})}
										</Box>
									</Grid>

									<>
										{user?.isAdmin && (
											<Grid item xs={12} md={12}>
												<Box className="select-posting-type">
													<Typography className="check-heading">{t('community.verified_user_options')}</Typography>
													<List>
														<ListItem dense button onClick={() => setSponsoredPost(!sStatus)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={sStatus}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{ 'aria-labelledby': 'checkbox-list-label' }}
																/>
															</ListItemIcon>
															<ListItemText
																id="checkbox-list-label"
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{t('community.sponsored_post')}</Box>
																	</Box>
																}
															/>
														</ListItem>
													</List>
												</Box>
											</Grid>
										)}

										<Grid item xs={12} md={12}>
											<Box className="select-posting-type">
												<Typography className="check-heading">{t('community.admin_options')}</Typography>
												{user?.isAdmin && (
													<List>
														<ListItem dense button onClick={() => setStickyPost(!stickyPost)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={stickyPost}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{ 'aria-labelledby': 'checkbox-list-label1' }}
																/>
															</ListItemIcon>
															<ListItemText
																id="checkbox-list-label1"
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{t('community.sticky_post')}</Box>
																	</Box>
																}
															/>
														</ListItem>
													</List>
												)}

												<List>
													<ListItem dense button onClick={() => setAllowCommentPost(!allowComments)}>
														<ListItemIcon>
															<Checkbox
																icon={<CheckBoxIcon />}
																checkedIcon={<CheckedBoxIcon />}
																edge="start"
																checked={allowComments}
																tabIndex={-1}
																disableRipple
																disableTouchRipple
																disableFocusRipple
																inputProps={{ 'aria-labelledby': 'checkbox-list-label2' }}
															/>
														</ListItemIcon>
														<ListItemText
															id="checkbox-list-label2"
															primary={
																<Box className="type-text-block">
																	<Box className="label-name">{t('community.allow_for_comments')}</Box>
																</Box>
															}
														/>
													</ListItem>
												</List>
											</Box>
										</Grid>
									</>

									<Grid item xs={12} md={12} className="form-btn">
										<Button
											fullWidth
											variant="contained"
											type="submit"
											className="create-post-btn cancel-btn"
											onClick={cancelBtnClick}
										>
											{t('button_text.cancel')}
										</Button>
										<Button
											fullWidth
											variant="contained"
											type="submit"
											className="create-post-btn"
											disabled={isLoading}
										>
											{t('community.edit_post')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
		</Fade>
	);
};

export default UpdatePost;
