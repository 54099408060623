import { useContext, useState } from 'react';
import { Box, Container, Grid, Typography, Button, IconButton, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../badge.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { identifiers } from 'src/helpers/constants/identifier';

const BadgeCategoryForm = () => {
	const [badgeImage, setBadgeImage] = useState('');
	const [loader, setLoader] = useState(false);
	const { t } = useTranslation();

	const addBadgeCategory = async (data) => {
		setLoader(true);
		var requestParams = {
			name: data?.badgecategoryname,
			description: data?.badgecategorydescription,
			...(badgeImage !== '' && { itemIconId: badgeImage?.id }),
			status: identifiers?.status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.createBadgeCategory, requestParams);

			if (status === 200) {
				// setBadgeCategory(data?.getBadgeCategoryData);
				setLoader(false);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
			setLoader(false);
		}
	};
	const ProfileAvatar = () => {
		let token = fetchFromStorage('authData')?.token;

		const imgHandler = async (e) => {
			e.persist();
			let formData = new FormData();

			if (e.target.files.length > 0) {
				let type = e.target.files[0].type.includes('image')
					? 'image'
					: e.target.files[0].type.includes('video')
					? 'video'
					: e.target.files[0].type.includes('text')
					? 'text'
					: e.target.files[0].type.includes('audio')
					? 'audio'
					: e.target.files[0].type.includes('blob')
					? 'blob'
					: '';

				formData.append('location', e.target.files[0]);
				formData.append('type', type);

				try {
					setLoader(true);
					const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					});

					if (status === 200) {
						setBadgeImage(data?.data);
						setApiMessage('success', data?.message);
						setLoader(false);
					}
				} catch (error) {
					setApiMessage('error', error?.response?.data?.message);
					setLoader(false);
				}
			}
		};

		return (
			<>
				<input
					accept="image/*"
					id="admin-badge-file"
					type="file"
					multiple
					onChange={imgHandler}
					style={{ display: 'none' }}
				/>
				<label htmlFor="admin-badge-file">
					<IconButton component="span" className="p-0">
						<Avatar src={URLS.mediaURL + badgeImage?.location} />
						<CameraIcon className="camera-icon" />
					</IconButton>
				</label>
			</>
		);
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Typography className="admin-page-haeding">{/* {t('admin_panel.combat_users.title')} */}</Typography>
					<Box className="form-content">
						<ProfileAvatar />
						<Formik
							initialValues={{
								badgecategoryname: '',
								badgecategorydescription: '',
							}}
							validationSchema={Yup.object().shape({
								badgecategoryname: Yup.string().required(t('validation.required_message')),
								badgecategorydescription: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => addBadgeCategory(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.badgecategoryname && errors.badgecategoryname)}
												fullWidth
												helperText={touched.badgecategoryname && errors.badgecategoryname}
												variant="filled"
												placeholder={t('admin_panel.combat_badge.badgecategoryname')}
												label={t('admin_panel.combat_badge.badgecategoryname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.badgecategoryname && errors.badgecategoryname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="badgecategoryname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.badgecategoryname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.badgecategorydescription && errors.badgecategorydescription)}
												fullWidth
												helperText={touched.badgecategorydescription && errors.badgecategorydescription}
												variant="filled"
												placeholder={t('admin_panel.combat_badge.badgecategorydescription')}
												label={t('admin_panel.combat_badge.badgecategorydescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment:
														touched.badgecategorydescription && errors.badgecategorydescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="badgecategorydescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.badgecategorydescription}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default BadgeCategoryForm;
