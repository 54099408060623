import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Avatar, Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as ProfileUploadIcon } from 'src/assets/images/icons/profileUpload.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import './profile_details.scss';
import { useTranslation } from 'react-i18next';

const PersonalDetails = ({ profileDetails, data, message, token, authData }) => {
	const prof = data?.profile_details;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const url = authData?.UserData?.user_profile;
	const spliturl = url !== undefined && url.split('/');
	const imageName = spliturl[spliturl.length - 1];
	const [previewImg, setPreviewImg] = useState(imageName || prof?.image?.img || '');
	const [itemId, setItemId] = useState(prof?.image?.itemId || null);

	const ImageUploadHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setPreviewImg(data?.data?.location);
				setItemId(data?.data?.itemId || data?.data?.id);
			}
		}
	};

	return (
		<Box className="profile-details-form">
			<Typography className="profile-details-title">{t('personal_details.title')}</Typography>

			<Box className="profile-picture-box">
				{previewImg !== '' ? (
					<Box textAlign={'center'}>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={ImageUploadHandler}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="primary" aria-label="upload picture" component="span" style={{ position: 'relative' }}>
								<Avatar src={URLS.mediaURL + previewImg} alt="profile-picture" />
								<CameraIcon className="camera-icon" />
							</IconButton>
						</label>
					</Box>
				) : (
					<Box textAlign={'center'}>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={ImageUploadHandler}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="primary" aria-label="upload picture" component="span">
								<ProfileUploadIcon />
							</IconButton>
						</label>
					</Box>
				)}

				<Box className="profile-pic-recomandations">
					<Typography className="rec-heding">{t('profile_picture.img_rec')}</Typography>
					<Typography className="rec-types">{`.jpg ${t('or')} .png`}</Typography>
					<Typography className="rec-types">500x500</Typography>
					<Typography className="rec-types">{t('profile_picture.lessthan')}</Typography>
				</Box>
			</Box>

			{/* Profile Details Form */}
			<Box className="form-content">
				<Formik
					initialValues={{
						firstName: authData?.OauthUserData?.first_name || prof?.firstName || '',
						surName: authData?.OauthUserData?.last_name || prof?.surName || '',
						username: prof?.username || '',
					}}
					validationSchema={Yup.object().shape({
						firstName: Yup.string().required(t('validation.required_message')),
						surName: Yup.string().required(t('validation.required_message')),
						// username: Yup.string().required(
						//   t('validation.required_message')
						// )
					})}
					onSubmit={(requestData) => {
						let data = {
							...requestData,
							image: { img: previewImg, itemId: itemId },
						};

						profileDetails(data);
						navigate('/profile/type');
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.firstName && errors.firstName)}
										fullWidth
										helperText={touched.firstName && errors.firstName}
										variant="filled"
										InputProps={{
											endAdornment: touched.firstName && errors.firstName ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.firstName')}
										placeholder={t('personal_details.firstName')}
										className="matrix-textfield"
										size="small"
										name="firstName"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.firstName}
									/>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.surName && errors.surName)}
										fullWidth
										helperText={touched.surName && errors.surName}
										variant="filled"
										InputProps={{
											endAdornment: touched.surName && errors.surName ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.surName')}
										placeholder={t('personal_details.surName')}
										className="matrix-textfield"
										size="small"
										name="surName"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.surName}
									/>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.username && errors.username)}
										fullWidth
										helperText={touched.username && errors.username}
										variant="filled"
										InputProps={{
											endAdornment: touched.username && errors.username ? <ErrorIcon /> : null,
										}}
										label={`${t('personal_details.username')}`}
										placeholder={`${t('personal_details.username')}`}
										className="matrix-textfield"
										size="small"
										name="username"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.username}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="profile-details-btn">
									<Button variant="contained" fullWidth type="submit" className="profile-btn">
										{t('next')}
									</Button>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};

export default PersonalDetails;
