/* eslint-disable import/order */
import { useState, useEffect, useContext } from 'react';
import * as rdd from 'react-device-detect';
import { Avatar, Box, Container, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import { ViewPortContext } from 'src/App';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage, saveToStorage, removeFromStorage, clearStorage } from 'src/helpers/context';
import { URLS } from 'src/helpers/constants/urls';
import { connect } from 'react-redux';
import { userVerifyCount } from 'src/redux-state/actions/userProfile';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { identifiers } from 'src/helpers/constants/identifier';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import MobileDrawer from 'src/views/component/UI/mobileDrawer';
import LeftProfile from './LeftBlock';
import RightProfile from './RightBlock';
import MobileBlock from './MobileBlock';
import UploadContent from './MobileBlock/UploadContent';
import './myProfile.scss';
import { parse } from 'query-string';
// import { Navigate } from 'react-router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const ProfileView = ({ auth, userVerifyCount }) => {
	const { id } = useParams();
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const query = parse(location.search);

	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const owner_user_id = auth ? auth?.user_id : fetchFromStorage('authData')?.user_id;
	const idToPass = fetchFromStorage('authData') !== null ? owner_user_id : id;
	let visitorId =
		fetchFromStorage(identifiers.visitorUserId) !== null ? fetchFromStorage(identifiers.visitorUserId) : idToPass;
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	const owner_user_uri = auth ? auth?.user_meta?.data : fetchFromStorage('authData')?.user_meta?.data;

	let check = owner_user_uri === id || owner_user_id === Number(id) ? false : true;
	// let check = Number(owner_user_id) === Number(visitorId) ? false : true;
	// let check = Number(owner_user_id) === Number(id) ? false : true;

	const [loader, setLoader] = useState(false);
	const [isView, setIsView] = useState(true);
	const [user, setUser] = useState({});
	const [userNotFound, setUserNotFound] = useState(false);
	const [badgeLoader, setBadgeLoader] = useState(false);
	const [connectionLoader, setConnectionLoader] = useState(false);
	const [followingLoader, setFollowingLoader] = useState(false);
	const [followersLoader, setFollowersLoader] = useState(false);
	const [communitiesList, setCommunityList] = useState(false);
	const [fightLoader, setFightLoader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [raPage, setRCPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [endorsPage, setEndorsePage] = useState(1);
	const [endorseTotalCount, setEndorseTotalCount] = useState('');
	const [endorseLimit] = useState(5);
	const [endorseTabVal, setEndorseTabVal] = useState('discipline');
	const [list] = useState(6); //setList

	const [fightData, setFightData] = useState([]);
	// const [communityLoader, setCommunityLoader] = useState(false);
	const [disciplineLoader, setDisciplineLoader] = useState(false);

	const [following, setFollowing] = useState([]);
	const [followers, setFollowers] = useState([]);
	const [connections, setConnections] = useState([]);
	const [badges, setBadges] = useState([]);
	const [userType, setUserType] = useState([]);
	const [disciplines, setDisciplines] = useState([]);
	const [skillsData, setSkills] = useState([]);
	const [fightMethod, setFightMethod] = useState([]);
	const [endorseloader, setendorseloader] = useState(false);
	const [disciplineData, setDisciplineData] = useState([]);
	const [recentActivityData, setRecentActivity] = useState([]);
	const [endorsementData, setEndorsementData] = useState([]);
	const [recentPage, setRecentPage] = useState(1);
	const [fightTotalRecord, setFightTotalRecord] = useState(''); //setTotalRecords
	const [fightLimit] = useState(4);
	const [activityTotal, setActivityTotal] = useState('');
	const [CPage, setCPage] = useState(1);
	const [activityLimit] = useState(4);
	const [hasMore, setHasMore] = useState(true);
	// const [notiData, setNotiData] = useState(location.state !== null ? (location.state.showSelectedTab === 'member') ? true : false : false);

	const authHeader = {
		headers: {
			Authorization: token && `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	// OPEN OR CLOSE DRAWER

	// FETCH PROFILE TYPE DATA
	const fetchProfileTypeData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getUserTypes, authHeader);

			if (status === 200) {
				const restTypeList = [
					// { id: 0, name: t('profile_type.enthusiast') },
					{ id: 1, name: t('profile_type.athelete') },
					{ id: 2, name: t('profile_type.coach_instructor') },
					{ id: 4, name: t('profile_type.manager') },
					{ id: 8, name: t('profile_type.promoter') },
					{ id: 16, name: t('profile_type.sponsor') },
					{ id: 32, name: t('profile_type.journalist') },
					{ id: 64, name: t('profile_type.official') },
					// { id: 128, name: t('profile_type.gym') },
					// { id: 256, name: t('profile_type.promotion') },
					// { id: 512, name: t('profile_type.product_service') }
				];

				const result = _.intersectionWith(restTypeList, data?.userType, (o, num) => o.id == num);

				setUserType(result);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH SKILLS
	const fetchSkills = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getSkills, authHeader);

			if (status === 200) {
				setSkills(data?.getSkill);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH FIGHT METHOD
	const fetchMetaFightMethod = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getFightsMethod, authHeader);

			if (status === 200) {
				setFightMethod(data?.getMethod);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplines, authHeader);

			if (status === 200) {
				setDisciplineData(data?.getDiscipline);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH RECENET ACTIVITY DATA
	const fetchRecentActivity = async (userid, page) => {
		var apiId = userid !== undefined && userid !== '' ? userid : visitorId;
		var pageVal = page ? page : raPage;

		try {
			const { status, data } = await axiosInstance.get(
				URLS.getRecentActivity + `?user_id=${apiId}&page=${pageVal}&limit=${activityLimit}`,
			);

			if (status === 200) {
				var hasMore = data?.recent_activity_list?.length < 4 ? false : true;

				setRCPage(raPage + 1);

				setRecentActivity((prevState) => {
					return [...prevState, ...data.recent_activity_list];
				});
				setActivityTotal(data?.totalRecords);
				setHasMore(hasMore);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH ENDORSEMENT DATA
	const fetchEndorsementData = async (uId, page, tab, isTab) => {
		var apiId = uId !== undefined && uId !== '' ? uId : visitorId;

		setendorseloader(true);
		var tab_value = tab ? tab : endorseTabVal;
		var pageVal = isTab ? 1 : page;

		isTab && setEndorsePage(1);

		try {
			let { status, data } = await axiosInstance.get(
				// URLS.getEndorsement + `${id}?type=all`,
				URLS.getEndorsement + `${apiId}?type=${tab_value}&page=${pageVal}&limit=${endorseLimit}`,
				// authHeader
			);

			if (status === 200) {
				setendorseloader(false);
				setEndorsementData(data);
				setEndorseTotalCount(data?.totalRecord);
				// const [endorsPage, setEndorsePage] = useState(1);
				// const [endorseTotalCount, setEndorseTotalCount] = useState('');
			}
		} catch (error) {
			setendorseloader(false);
		}
	};
	const tabHandlerForEndorsement = (val) => {
		setEndorseTabVal(val);
	};

	// ENDORSEMENT PAGINATION
	const handleEndorsementPagination = async (navDirection) => {
		if (navDirection === 'prev') {
			if (endorsPage > 1) {
				setEndorsePage(endorsPage - 1);
				fetchEndorsementData('', endorsPage - 1);
			}
		} else {
			if (endorsPage < Math.ceil(totalRecord / list)) {
				setEndorsePage(endorsPage + 1);
				fetchEndorsementData('', endorsPage + 1);
			}
		}
	};
	const handleEndorsementPaginationClick = async (event, page) => {
		if (endorsPage !== page) {
			setEndorsePage(Number(page));
			fetchEndorsementData('', Number(page));
		}
	};

	// FETCH USER BADGES
	const fetchUserBadges = async (uId) => {
		var apiId = uId !== undefined ? uId : visitorId;

		setBadgeLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				// URLS.getUserBadge + id,
				URLS.getUserBadge + apiId,
				authHeader,
			);

			if (status === 200) {
				setBadges(data?.authUser);
				setBadgeLoader(false);
			}
		} catch (error) {
			setBadgeLoader(false);
		}
	};

	// FETCH USER FOLLOWING DATA
	const fetchFollowingData = async (userid) => {
		setFollowingLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getFollowing + `&userID=${userid}`, authHeader);

			if (status === 200) {
				setFollowing(data?.followingUser);
				setFollowingLoader(false);
			}
		} catch (error) {
			setFollowingLoader(false);
		}
	};
	//FETCH USER COMMUNITIES DATA
	const fetchCommunitiesData = async (userid) => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getCommunitiesByUser + `?user_id=${userid}`, authHeader);

			if (status === 200) {
				setCommunityList(data);
				// setFollowing(data?.followingUser);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH USER FOLLOWERS DATA
	const fetchFollowersData = async (userid) => {
		setFollowersLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getFollowers + `&userID=${userid}`, authHeader);

			if (status === 200) {
				setFollowers(data?.followersUser);
				setFollowersLoader(false);
			}
		} catch (error) {
			setFollowersLoader(false);
		}
	};

	// FETCH CONNECTIONS DATA
	const fetchConnectionData = async (uId) => {
		setConnectionLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getConnection + `?userId=${uId}`, authHeader);

			if (status === 200) {
				setConnections(data?.connectionUser);
				setConnectionLoader(false);
			}
		} catch (error) {
			setConnectionLoader(false);
		}
	};

	// FIGHT DATA
	const fetchUserFight = async (uId, page) => {
		var apiId = uId !== undefined && uId !== '' ? uId : visitorId;

		setFightLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getUserFight(apiId), {
				params: {
					page: page,
					limit: fightLimit,
				},
			});

			if (status === 200) {
				setFightTotalRecord(data?.totalRecords);
				setFightData(data?.getAllEventsData);
				setFightLoader(false);
			}
		} catch (error) {
			setFightLoader(false);
		}
	};

	// FETCH USER DISCIPLINES
	const fetchUserDisciplines = async (uId, page) => {
		var apiId = uId !== undefined && uId !== '' ? uId : visitorId;

		setDisciplineLoader(true);

		try {
			let { status, data } = await axiosInstance.get(
				// URLS.getUserDisciplines + id,
				URLS.getUserDisciplines + apiId + `?page=${page}&limit=${list}`,
				token !== undefined && token !== null && authHeader,
			);

			if (status === 200) {
				setDisciplineLoader(false);
				setDisciplines(data?.getUserDisciplineData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			setDisciplineLoader(false);
		}
	};

	// FOR REFERRAL
	const referralUse = async (item) => {
		var requestParameter = {
			referral_code: query?.referralcode,
			land_type: identifiers?.referralType?.user,
			land_id: item?.id,
			user_id: fetchFromStorage('authData')?.user_id,
		};

		if (query?.referralcode !== undefined) {
			try {
				const { status, data } = await axiosInstance.post(URLS.referralUse, requestParameter);

				if (status === 200) {
					// console.log(data)
				}
			} catch (error) {
				// TODO: Add logging
			}
		}
	};

	const getUserData = (uriVal) => {
		let AUTH_DATA = fetchFromStorage('authData');

		const uriValue = uriVal !== undefined ? uriVal : id;

		uriVal !== undefined && AUTH_DATA?.user_meta?.data === uriVal && setIsView(false);

		let url =
			check && AUTH_DATA?.user_meta?.data !== id && Number(AUTH_DATA?.user_id) !== Number(id)
				? // ? URLS.getUserProfile + id
				  URLS.getUserProfile + uriValue
				: URLS.getMyprofile;

		return axiosInstance.get(url, token !== undefined && authHeader);
	};

	// GET USER PROFILE
	const getMyProfile = async (uriVal) => {
		setUser({});
		setRecentActivity([]);
		setRCPage(1);
		removeFromStorage(identifiers.visitorUserId);
		let AUTH_DATA = fetchFromStorage('authData');

		setLoader(true);
		setUserNotFound(false);

		try {
			const { status, data } = await getUserData(uriVal);

			if (status === 200) {
				data?.data?.id && saveToStorage(identifiers.visitorUserId, data?.data?.id);
				setLoader(false);

				const allProfileDataPromises = [
					fetchConnectionData(data?.data?.id),
					fetchUserFight(data?.data?.id, recentPage),
					fetchUserBadges(data?.data?.id),
					fetchUserDisciplines(data?.data?.id, currentPage),
					fetchEndorsementData(data?.data?.id, endorsPage),
					referralUse(data?.data),
					fetchFollowingData(data?.data?.id),
					fetchFollowersData(data?.data?.id),
					fetchCommunitiesData(data?.data?.id),
					fetchRecentActivity(data?.data?.id, CPage),
				];

				setUser(data);
				userVerifyCount(data?.verify_user_count);

				Promise.allSettled(allProfileDataPromises).then(() => {
					console.log('All profile data fetched.');
				});

				let obj = {
					...AUTH_DATA,
					profile_pic: data?.data?.user_profile,
					OauthUserData: {
						...AUTH_DATA?.OauthUserData,
						first_name: data?.data?.first_name,
						last_name: data?.data?.last_name,
						user_name: data?.data?.user_name,
						profile_pic: data?.data?.user_profile,
					},
				};

				fetchFromStorage('authData') !== null &&
					fetchFromStorage('authData')?.user_id === data?.data?.id &&
					saveToStorage(identifiers.authData, obj);
			}
		} catch (error) {
			setLoader(false);
			setUserNotFound(true);
			AUTH_DATA === null && navigate('/');

			// console.log(error);
			if (isView === false) {
				clearStorage();
				window.location = '/';
			}
		}
	};

	// CHECK USER IS OWNER OR VISITER
	useEffect(() => {
		setIsView(check);
		// eslint-disable-next-line
	}, [owner_user_id]);

	// DISCIPLINE PAGINATION
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchUserDisciplines('', currentPage - 1);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(currentPage + 1);
				fetchUserDisciplines('', currentPage + 1);
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchUserDisciplines('', Number(page));
		}
	};

	// FIGHT PAGINATION
	const handlePaginationButtonOnClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setRecentPage(recentPage - 1);
				fetchUserFight('', recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(fightTotalRecord / list)) {
				setRecentPage(recentPage + 1);
				fetchUserFight('', recentPage + 1);
			}
		}
	};

	const handlePaginationOnClick = async (event, page) => {
		if (recentPage !== page) {
			setRecentPage(Number(page));
			fetchUserFight('', Number(page));
		}
	};

	// RECENT ACTIVITY PAGINATION
	// const paginationButtonClick = async navDirection => {
	//   if (navDirection === 'prev') {
	//     if (CPage > 1) {
	//       setCPage(CPage - 1);
	//       fetchRecentActivity('', CPage - 1);
	//     }
	//   } else {
	//     if (CPage < Math.ceil(totalRecord / list)) {
	//       setCPage(CPage + 1);
	//       fetchRecentActivity('', CPage + 1);
	//     }
	//   }
	// };

	// const paginationClick = async (event, page) => {
	//   if (CPage !== page) {
	//     setCPage(Number(page));
	//     // fetchRecentActivity('', Number(page));
	//   }
	// };

	useEffect(() => {
		if (check) {
			setIsView(true);
		} else {
			setIsView(false);
			// fetchFollowingData();
			// fetchFollowersData();
			// fetchConnectionData();
		}

		setUser({});
		setUserNotFound(false);
		getMyProfile();

		// fetchUserBadges();
		// fetchUserDisciplines();
		// fetchUserFight();
		// fetchFromStorage('authData') !== null && fetchEndorsementData();
		fetchFromStorage('authData') !== null && fetchDisciplineTypesData();
		fetchFromStorage('authData') !== null && fetchMetaFightMethod();
		fetchFromStorage('authData') !== null && fetchSkills();
		fetchFromStorage('authData') !== null && fetchProfileTypeData();

		// eslint-disable-next-line
	}, [id]);

	// USER DETAILS
	let userData = user?.data;

	// PAGE TITLE SET BASED ON USER DETAILS
	let PageTitle = loader
		? 'Combat Matrix'
		: userNotFound
		? 'User Not Found'
		: `${userData?.first_name ? userData?.first_name : ''} ${userData?.last_name ? userData?.last_name : ''} ${
				userData?.first_name || userData?.last_name ? '|' : ''
		  } Combat Matrix`;

	return (
		<Page className={classes.root} title={`${PageTitle}`}>
			<Container maxWidth="lg">
				<Box className="my-profile-page combat-container">
					{!userNotFound ? (
						<Box className="myprofile-grid-container">
							{windowWidth > breakPoint ? (
								// SHOW IN TABLET AND DESKTOP SIZE
								<>
									<Box className="myprofile-left">
										<LeftProfile
											fetchUserBadges={fetchUserBadges}
											isView={isView}
											user={user}
											loader={loader}
											token={token}
											getMyProfile={getMyProfile}
											fetchUserDisciplines={fetchUserDisciplines}
											disciplineLoader={disciplineLoader}
											disciplines={disciplines}
											userType={userType}
											fetchProfileTypeData={fetchProfileTypeData}
											fetchSkills={fetchSkills}
											fetchMetaFightMethod={fetchMetaFightMethod}
											fetchDisciplineTypesData={fetchDisciplineTypesData}
											fetchEndorsementData={fetchEndorsementData}
											fetchRecentActivity={fetchRecentActivity}
											endorsPage={endorsPage}
											tabHandlerForEndorsement={tabHandlerForEndorsement}
											handleEndorsementPagination={handleEndorsementPagination}
											handleEndorsementPaginationClick={handleEndorsementPaginationClick}
											endorseTotalCount={endorseTotalCount}
											skillsData={skillsData}
											fightMethod={fightMethod}
											endorseloader={endorseloader}
											endorsementData={endorsementData}
											endorseLimit={endorseLimit}
											disciplineData={disciplineData}
											recentActivityData={recentActivityData}
											setRecentActivity={setRecentActivity}
											currentPage={currentPage}
											limit={list}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={totalRecord}
											// CPage={CPage}
											activityLimit={activityLimit}
											// paginationButtonClick={paginationButtonClick}
											// paginationClick={paginationClick}
											activityTotal={activityTotal}
											hasMore={hasMore}
										/>
									</Box>

									<Box className="myprofile-right">
										<RightProfile
											fightTotalRecord={fightTotalRecord}
											handlePaginationButtonOnClick={handlePaginationButtonOnClick}
											handlePaginationOnClick={handlePaginationOnClick}
											recentPage={recentPage}
											fightLimit={fightLimit}
											isView={isView}
											user={user}
											loader={loader}
											token={token}
											badgeLoader={badgeLoader}
											badges={badges}
											connectionCFFCLoader={connectionLoader}
											followingCFFCLoader={followingLoader}
											followersCFFCLoader={followersLoader}
											fightLoader={fightLoader}
											followingCFFC={following}
											followersCFFC={followers}
											connectionsCFFC={connections}
											communitiesList={communitiesList}
											fightData={fightData}
											fetchUserFight={fetchUserFight}
											ProfileAvatar={
												<ProfileAvatar
													src={user?.data?.user_profile}
													getMyProfile={getMyProfile}
													token={token}
													check={check}
												/>
											}
											getMyProfile={getMyProfile}
											location={location}
										/>
									</Box>
								</>
							) : (
								// SHOW IN MOBILE SIZE
								<div className="myprofile-mobile">
									<MobileBlock
										hasMore={hasMore}
										setRecentActivity={setRecentActivity}
										fightTotalRecord={fightTotalRecord}
										handlePaginationButtonOnClick={handlePaginationButtonOnClick}
										handlePaginationOnClick={handlePaginationOnClick}
										recentPage={recentPage}
										fightLimit={fightLimit}
										isView={isView}
										user={user}
										loader={loader}
										token={token}
										badgeLoader={badgeLoader}
										badges={badges}
										connectionCFFCLoader={connectionLoader}
										followingLoader={followingLoader}
										followersLoader={followersLoader}
										fightLoader={fightLoader}
										following={following}
										followers={followers}
										connections={connections}
										communitiesList={communitiesList}
										fightData={fightData}
										fetchUserFight={fetchUserFight}
										ProfileAvatar={
											<ProfileAvatar
												src={user?.data?.user_profile}
												getMyProfile={getMyProfile}
												token={token}
												check={check}
											/>
										}
										getMyProfile={getMyProfile}
										fetchUserBadges={fetchUserBadges}
										fetchUserDisciplines={fetchUserDisciplines}
										disciplineLoader={disciplineLoader}
										disciplines={disciplines}
										userType={userType}
										fetchProfileTypeData={fetchProfileTypeData}
										fetchSkills={fetchSkills}
										fetchMetaFightMethod={fetchMetaFightMethod}
										fetchDisciplineTypesData={fetchDisciplineTypesData}
										fetchEndorsementData={fetchEndorsementData}
										endorseTotalCount={endorseTotalCount}
										tabHandlerForEndorsement={tabHandlerForEndorsement}
										fetchRecentActivity={fetchRecentActivity}
										endorsPage={endorsPage}
										endorseLimit={endorseLimit}
										handleEndorsementPagination={handleEndorsementPagination}
										handleEndorsementPaginationClick={handleEndorsementPaginationClick}
										skillsData={skillsData}
										fightMethod={fightMethod}
										endorseloader={endorseloader}
										endorsementData={endorsementData}
										disciplineData={disciplineData}
										recentActivityData={recentActivityData}
										currentPage={currentPage}
										list={list}
										handlePaginationButtonClick={handlePaginationButtonClick}
										handlePaginationClick={handlePaginationClick}
										totalRecord={totalRecord}
										// CPage={CPage}
										activityLimit={activityLimit}
										// paginationButtonClick={paginationButtonClick}
										// paginationClick={paginationClick}
										activityTotal={activityTotal}
										check={check}
									/>
								</div>
							)}
						</Box>
					) : (
						<Typography className="user-not-found-text">{t('user_not_found')}</Typography>
					)}
				</Box>
			</Container>
		</Page>
	);
};

// PROFILE PICTURE COMPONENT
const ProfileAvatar = ({ src, getMyProfile, token }) => {
	const { t } = useTranslation();

	const [user, setUser] = useState('');
	const [openDrawer, setOpenDrawer] = useState(false);
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	// OPEN THE MOBILE BOTTOM DRAWER
	const toggleDrawer = (open) => {
		setOpenDrawer(open);
	};

	const imgHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			//  CHECK MEDIA TYPE
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					let id = data?.data?.itemId || data?.data?.id;
					let requestData = { item_profile_id: id };

					try {
						let { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profilepic_updated_success'));
							getMyProfile();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		if (src !== '') {
			setUser(src);
			EventEmitter.dispatch('user_image_change', src);
		}
		// eslint-disable-next-line
	}, [src]);

	return (
		<>
			{windowWidth > breakPoint ? (
				<>
					<input
						accept="image/*"
						id="contained-button-file"
						type="file"
						onChange={imgHandler}
						style={{ display: 'none' }}
					/>

					<label htmlFor="contained-button-file">
						<IconButton component="span">
							<Avatar src={user ? user : UserPlaceholderIcon} />
							<CameraIcon className="camera-icon" />
						</IconButton>
					</label>
				</>
			) : (
				<>
					{rdd.isIOS && (
						<input
							accept="image/*"
							id="contained-button-file"
							type="file"
							onChange={imgHandler}
							style={{ display: 'none' }}
						/>
					)}

					<label htmlFor={rdd.isIOS && 'contained-button-file'}>
						<IconButton
							component="span"
							onClick={
								rdd.isIOS
									? () => {}
									: () => {
											toggleDrawer(true);
									  }
							}
						>
							<Avatar src={user ? user : UserPlaceholderIcon} />
							<CameraIcon className="camera-icon" />
						</IconButton>
					</label>
					<MobileDrawer
						anchor={'bottom'}
						open={openDrawer}
						onClose={() => toggleDrawer(false)}
						className="take-upload-photo"
						title={t('myprofile.upload_profile_cover_image')}
						content={<UploadContent imgHandler={imgHandler} toggleDrawer={toggleDrawer} />}
					/>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		userVerifyCount: (data) => dispatch(userVerifyCount(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
