import { useState, useEffect } from 'react';
import { Box, ListItem, IconButton, ButtonGroup, ListItemText, Button } from '@material-ui/core';
// import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import './edit-cat-dis.scss';
import CustomDropDown from 'src/views/component/UI/CustomDropDown';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import _, { filter } from 'lodash';
import { setApiMessage, getFields } from 'src/helpers/commonFunctions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EditCategoryDiscipline({ communityDetails, token, fetchCommunityDetails, handleClose }) {
	const { id } = useParams();
	const { t } = useTranslation();
	const [type, setType] = useState('discipline');
	const [categoryData, setCategoryData] = useState([]);
	const [disciplineData, setDisciplineData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedDiscipline, setSelectedDiscipline] = useState([]);

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const typeChangeHandler = (value) => {
		setType(value);
	};

	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplinesData, authHeader);

			if (status === 200) {
				const resultArray =
					communityDetails &&
					_.differenceWith(
						data?.getDisciplinesData,
						communityDetails?.community_discipline,
						(x, y) => x.id === y.discipline_id,
					);

				setDisciplineData(resultArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH CATEGORY TYPES DATA
	const fetchCategoryData = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory);

			if (status === 200) {
				const resultArray =
					communityDetails &&
					_.differenceWith(
						data?.metaCategoriesData,
						communityDetails?.community_category,
						(x, y) => x.id === y.meta_category_id,
					);

				setCategoryData(resultArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// REMOVE CATEGORY FROM LIST AND ADD TO DROPDOWN
	const handleCategoryDelete = async (value) => {
		// add to dropdown
		var initialArray = [...categoryData];
		var obj = {
			description: value?.MetaCategory?.description,
			id: value?.meta_category_id,
			name: value?.MetaCategory?.name,
		};

		initialArray.push(obj);
		setCategoryData(initialArray);
		// remove from list
		var filteredArray = selectedCategory.filter((item) => item?.meta_category_id !== value?.meta_category_id);

		setSelectedCategory(filteredArray);
	};
	// REMOVE DISCIPLINE FROM LIST AND ADD TO DROPDOWN
	const handleDisciplineDelete = async (value) => {
		// add to dropdown
		var initialArray = [...disciplineData];
		var obj = {
			description: value?.Discipline?.description,
			id: value?.discipline_id,
			name: value?.Discipline?.name,
		};

		initialArray.push(obj);
		setDisciplineData(initialArray);
		// remove from list
		var filteredArray = selectedDiscipline.filter((item) => item?.discipline_id !== value?.discipline_id);

		setSelectedDiscipline(filteredArray);
	};
	// REMOVE CATEGORY FROM DROPDOWN AND ADD TO LIST
	const handleAddCategory = async (value) => {
		// add to list
		var initialArray = [...selectedCategory];
		var obj = {
			MetaCategory: {
				description: value?.description,
				name: value?.name,
			},
			meta_category_id: value?.id,
		};

		initialArray.push(obj);
		setSelectedCategory(initialArray);
		// remove from dropdown
		var filteredArray = categoryData.filter((item) => item?.id !== value?.id);

		setCategoryData(filteredArray);
	};
	// REMOVE DISCIPLINE FROM DROPDOWN AND ADD TO LIST
	const handleAddDiscipline = async (value) => {
		// add to list
		var initialArray = [...selectedDiscipline];
		var obj = {
			Discipline: {
				description: value?.description,
				name: value?.name,
			},
			discipline_id: value?.id,
		};

		initialArray.push(obj);
		setSelectedDiscipline(initialArray);
		// remove from dropdown
		var filteredArray = disciplineData.filter((item) => item?.id !== value?.id);

		setDisciplineData(filteredArray);
	};

	// UPDATE CATEGORY AND DISCIPLINE
	const updateCommunity = async () => {
		var requestParams = {
			community: {
				id: Number(id),
			},
			communityDiscipline: getFields(selectedDiscipline, 'discipline_id'),
			metaCategoriesArr: getFields(selectedCategory, 'meta_category_id'),
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateCommunity, requestParams);

			if (status === 200) {
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchDisciplineTypesData();
		fetchCategoryData();
	}, []);

	useEffect(() => {
		setSelectedCategory(communityDetails?.community_category);
		setSelectedDiscipline(communityDetails?.community_discipline);
	}, [communityDetails]);

	return (
		<Box className="addedit-dcmain">
			<Box className="top-container">
				<Box className="cd-off-switch-btn">
					<ButtonGroup size="small" color="secondary">
						<Button
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className={type === 'discipline' ? 'selected-com-type' : 'other-com-type'}
							variant={type === 'discipline' ? 'contained' : 'outlined'}
							onClick={() => {
								typeChangeHandler('discipline');
							}}
						>
							{t('community.disciplines')}
						</Button>
						<Button
							disableRipple
							disableTouchRipple
							disableFocusRipple
							className={type === 'category' ? 'selected-com-type' : 'other-com-type'}
							variant={type === 'category' ? 'contained' : 'outlined'}
							onClick={() => {
								typeChangeHandler('category');
							}}
						>
							{t('community.categories')}
						</Button>
					</ButtonGroup>
				</Box>
				{type === 'discipline' ? (
					<>
						<CustomDropDown
							labelValue={t('community.disciplines')}
							dropdownArray={disciplineData?.filter((item) => item?.name !== null)}
							handleAdd={handleAddDiscipline}
						/>
						<Box className="dropdown-listing-main">
							{communityDetails &&
								selectedDiscipline.map((value, index) => {
									return (
										<>
											<ListItem key={index} disablePadding>
												<ListItemText primary={value?.Discipline?.name} />
												<IconButton onClick={() => handleDisciplineDelete(value)}>
													<DeleteIcon />
												</IconButton>
											</ListItem>
										</>
									);
								})}
						</Box>
					</>
				) : (
					<>
						<CustomDropDown
							labelValue={t('community.categories')}
							dropdownArray={categoryData?.filter((item) => item?.name !== null)}
							handleAdd={handleAddCategory}
						/>
						<Box className="dropdown-listing-main">
							{communityDetails &&
								selectedCategory.map((value, index) => {
									return (
										<>
											<ListItem key={index} disablePadding>
												<ListItemText primary={value?.MetaCategory?.name} />
												<IconButton onClick={() => handleCategoryDelete(value)}>
													<DeleteIcon />
												</IconButton>
											</ListItem>
										</>
									);
								})}
						</Box>
					</>
				)}
			</Box>
			<Box className="dialog-submit-button">
				<Button variant="contained" className="dialog-submit" onClick={() => updateCommunity()}>
					{t('save')}
				</Button>
			</Box>
		</Box>
	);
}
export default EditCategoryDiscipline;
