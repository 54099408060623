import { useEffect, useState } from 'react';
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Typography,
	Button,
	InputAdornment,
	TextField,
	withStyles,
	Grid,
	Chip,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

const CreateGroupModal = ({ windowWidth, breakPoint, user, handleClose, userSocket, fetchGroupList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [checked, setChecked] = useState([]);
	const [checkedAll, setCheckedAll] = useState([]);
	const [searchString, setSearchString] = useState('');
	const [allUsersList, setAllUsersList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState();
	const [btnLoader, setBtnLoader] = useState(false);
	const [limit] = useState(windowWidth < breakPoint ? 6 : 10);
	const [groupName, setGroupName] = useState();

	const fetchAllUsersList = async (page, name) => {
		try {
			const { status, data } = await axiosInstance.get(
				URLS.getAllUsersList + `?page=${page}&limit=${limit}&name=${name}&type=user`,
			);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var finalArray = filteredData.filter((item) => item?.user_full_name !== '');

				setAllUsersList(finalArray);
				setTotalRecord(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchAllUsersList(Number(page), searchString);
		}
	};
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchAllUsersList(currentPage - 1, searchString);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1);
				fetchAllUsersList(currentPage + 1, searchString);
			}
		}
	};
	const handleChange = (e) => {
		setCurrentPage(1);
		fetchAllUsersList(1, e.target.value);
		setSearchString(e.target.value);
	};
	const onMemberSelect = (value) => {
		const currentIndex = checked.indexOf(value?.id);
		const newChecked = [...checked];
		const objChecked = [...checkedAll];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
		setCheckedAll(objChecked);
	};
	const onAddGroup = async () => {
		var requestParams = {
			name: groupName,
			// item_profile_id: 1,
			private_group: checked?.length > 1 ? 0 : 1,
			user_id: checked,
		};

		setBtnLoader(true);

		try {
			const { status, data } = await axiosInstance.post(URLS.createGroup, requestParams);

			if (status === 200) {
				handleClose();
				fetchGroupList();
				data?.createGroup?.group_status && setApiMessage('success', data?.message);
				checked?.length > 1 &&
					userSocket.emit('system-createGroup', {
						group_id: data?.createGroup?.id,
						performed_by: {
							id: user?.user_id,
							name: user?.OauthUserData?.user_name,
							profile: user?.profile_pic,
						},
						type: 'create_group',
						chatType: 'group',
						members: checked,
					});
				var groupId = data?.createGroup?.id;

				navigate(`/messages/${groupId}`);
			}
		} catch (error) {
			setBtnLoader(true);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchAllUsersList(currentPage, searchString);
	}, []);

	return (
		<>
			<Box className="member-listing-main">
				<Box className="search-box">
					<SearchTextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder="Search"
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Box style={{ marginTop: '10px' }}>
						<MatrixTextField
							fullWidth
							variant="filled"
							placeholder={t('messages.group_name')}
							label={t('messages.group_name')}
							className="matrix-textfield"
							size="small"
							name="groupname"
							onChange={(e) => setGroupName(e.target.value)}
							value={groupName}
						/>
					</Box>

					{checkedAll?.length > 0 && (
						<Box className="restriction-box">
							<Box className="restriction-chips">
								{checkedAll?.map((data, index) => {
									return (
										<Grid item key={index} className="rest-chip">
											<Chip
												size="small"
												className="checked-chip"
												deleteIcon={<CancelIcon />}
												label={data?.user_full_name}
												onDelete={() => onMemberSelect(data)}
												// onClick={() => onMemberSelect(data)}
												// onClick={handleClick}
											/>
										</Grid>
									);
								})}
							</Box>
						</Box>
					)}
					<Box className="member-inner">
						<List>
							{allUsersList && allUsersList?.length ? (
								<>
									{allUsersList.map((item, index) => {
										return (
											<ListItem key={index}>
												<Avatar src={item?.item_profile_data} />
												<ListItemText>
													<Typography variant="span" className="member-name">
														{item?.user_full_name}
													</Typography>
												</ListItemText>
												<Button
													disableRipple
													disableFocusRipple
													disableTouchRipple
													edge="end"
													className={checked.indexOf(item?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '}
													onClick={() => onMemberSelect(item)}
												>
													{checked.indexOf(item?.id) !== -1 ? t('messages.added') : t('messages.add')}
												</Button>
											</ListItem>
										);
									})}
								</>
							) : (
								<Typography className="no-data-txt">{t('community.no_member_found')}</Typography>
							)}
						</List>
					</Box>
					<Box className="create-btn-main dialog-sticky-btn">
						<Button
							disableRipple
							disableFocusRipple
							disableTouchRipple
							edge="end"
							className="create-button"
							disabled={btnLoader}
							onClick={() => {
								if (checked?.length === 1) {
									onAddGroup();
								} else if (checked?.length > 1) {
									if (groupName) {
										onAddGroup();
									} else {
										setApiMessage('error', t('messages.creategrpname'));
									}
								} else {
									setApiMessage('error', t('messages.creategrpmsg'));
								}
							}}
						>
							{t('messages.create_chat')}
						</Button>
					</Box>
					<CustomPagination
						paginationArray={allUsersList}
						currentPage={currentPage}
						list={limit}
						handlePaginationButtonClick={handlePaginationButtonClick}
						handlePaginationClick={handlePaginationClick}
						totalRecord={totalRecord}
					/>
				</Box>
			</Box>
		</>
	);
};

export default CreateGroupModal;
