export default {
	defaultNS: 'translations',
	interpolation: {
		escapeValue: false,
	},
	fallbackLng: 'en',
	supportedLocales: [
		{
			code: 'en',
		},
		{
			code: 'th',
		},
		{
			code: 'pt',
		},
		// {
		// 	code: 'ru',
		// },
	],
};
