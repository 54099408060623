import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid } from '@material-ui/core';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/bFaceBook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/bInstagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/bTwitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/bTapology.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/bLinkedin.svg';
import { ReactComponent as ShareDogIcon } from 'src/assets/images/icons/bsherdog.svg';
import { ReactComponent as SnapChatIcon } from 'src/assets/images/icons/bSnapchat.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/bTiktok.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/bTwitch.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/bYouTube.svg';
import { useTranslation } from 'react-i18next';

const EditSocialLink = ({ data, token, getMyProfile, closeDialog }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Formik
				initialValues={{
					facebook: data.filter((data) => data.type === 'URL_facebook')[0]?.data || '',
					instagram: data.filter((data) => data.type === 'URL_instagram')[0]?.data || '',
					twitter: data.filter((data) => data.type === 'URL_twitter')[0]?.data || '',
					tapology: data.filter((data) => data.type === 'URL_tapology')[0]?.data || '',
					snapchat: data.filter((data) => data.type === 'URL_snapchat')[0]?.data || '',
					youtube: data.filter((data) => data.type === 'URL_youtube')[0]?.data || '',
					twitch: data.filter((data) => data.type === 'URL_twitch')[0]?.data || '',
					linkedin: data.filter((data) => data.type === 'URL_linkedin')[0]?.data || '',
					tiktok: data.filter((data) => data.type === 'URL_tiktok')[0]?.data || '',
					sherdog: data.filter((data) => data.type === 'URL_sherdog')[0]?.data || '',
				}}
				validationSchema={Yup.object().shape({
					facebook: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					instagram: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					twitter: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					tapology: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					snapchat: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					youtube: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					twitch: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					linkedin: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					tiktok: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
					sherdog: Yup.string().matches(
						/((http|https?):\/\/)?(www.)?[a-z0-9@:%._]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9@:%._\\+~#?&//=*]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9]+&?)?$/,
						'Enter correct url!',
					),
				})}
				onSubmit={async (val) => {
					let requestData = [
						{ type: 'URL_facebook', data: val.facebook },
						{ type: 'URL_instagram', data: val.instagram },
						{ type: 'URL_twitter', data: val.twitter },
						{ type: 'URL_tapology', data: val.tapology },
						{ type: 'URL_snapchat', data: val.snapchat },
						{ type: 'URL_youtube', data: val.youtube },
						{ type: 'URL_twitch', data: val.twitch },
						{ type: 'URL_linkedin', data: val.linkedin },
						{ type: 'URL_tiktok', data: val.tiktok },
						{ type: 'URL_sherdog', data: val.sherdog },
					];

					try {
						const { status } = await axiosInstance.put(
							URLS.updateProfile,
							{
								user_meta: requestData,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
									'Content-Type': 'application/json',
								},
							},
						);

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							getMyProfile();
							closeDialog();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<FaceBookIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.facebook && errors.facebook)}
										fullWidth
										helperText={touched.facebook && errors.facebook}
										variant="filled"
										InputProps={{
											endAdornment: touched.facebook && errors.facebook ? <ErrorIcon /> : null,
										}}
										label={t('facebook')}
										placeholder={t('facebook')}
										className="matrix-textfield"
										size="small"
										name="facebook"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.facebook}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<InstagramIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.instagram && errors.instagram)}
										fullWidth
										helperText={touched.instagram && errors.instagram}
										variant="filled"
										InputProps={{
											endAdornment: touched.instagram && errors.instagram ? <ErrorIcon /> : null,
										}}
										label={t('instagram')}
										placeholder={t('instagram')}
										className="matrix-textfield"
										size="small"
										name="instagram"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.instagram}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<TwitterIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.twitter && errors.twitter)}
										fullWidth
										helperText={touched.twitter && errors.twitter}
										variant="filled"
										InputProps={{
											endAdornment: touched.twitter && errors.twitter ? <ErrorIcon /> : null,
										}}
										label={t('twitter')}
										placeholder={t('twitter')}
										className="matrix-textfield"
										size="small"
										name="twitter"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.twitter}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<TapologyIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.tapology && errors.tapology)}
										fullWidth
										helperText={touched.tapology && errors.tapology}
										variant="filled"
										InputProps={{
											endAdornment: touched.tapology && errors.tapology ? <ErrorIcon /> : null,
										}}
										label={t('tapology')}
										placeholder={t('tapology')}
										className="matrix-textfield"
										size="small"
										name="tapology"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.tapology}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<SnapChatIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.snapchat && errors.snapchat)}
										fullWidth
										helperText={touched.snapchat && errors.snapchat}
										variant="filled"
										InputProps={{
											endAdornment: touched.snapchat && errors.snapchat ? <ErrorIcon /> : null,
										}}
										label={t('snapchat')}
										placeholder={t('snapchat')}
										className="matrix-textfield"
										size="small"
										name="snapchat"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.snapchat}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<YouTubeIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.youtube && errors.youtube)}
										fullWidth
										helperText={touched.youtube && errors.youtube}
										variant="filled"
										InputProps={{
											endAdornment: touched.youtube && errors.youtube ? <ErrorIcon /> : null,
										}}
										label={t('youtube')}
										placeholder={t('youtube')}
										className="matrix-textfield"
										size="small"
										name="youtube"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.youtube}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<TwitchIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.twitch && errors.twitch)}
										fullWidth
										helperText={touched.twitch && errors.twitch}
										variant="filled"
										InputProps={{
											endAdornment: touched.twitch && errors.twitch ? <ErrorIcon /> : null,
										}}
										label={t('twitch')}
										placeholder={t('twitch')}
										className="matrix-textfield"
										size="small"
										name="twitch"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.twitch}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<LinkedInIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.linkedin && errors.linkedin)}
										fullWidth
										helperText={touched.linkedin && errors.linkedin}
										variant="filled"
										InputProps={{
											endAdornment: touched.linkedin && errors.linkedin ? <ErrorIcon /> : null,
										}}
										label={t('linkedin')}
										placeholder={t('linkedin')}
										className="matrix-textfield"
										size="small"
										name="linkedin"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.linkedin}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<TikTokIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.tiktok && errors.tiktok)}
										fullWidth
										helperText={touched.tiktok && errors.tiktok}
										variant="filled"
										InputProps={{
											endAdornment: touched.tiktok && errors.tiktok ? <ErrorIcon /> : null,
										}}
										label={t('tiktok')}
										placeholder={t('tiktok')}
										className="matrix-textfield"
										size="small"
										name="tiktok"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.tiktok}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={2} sm={1} md={1} lg={1} className="age-field h-45">
									<ShareDogIcon />
								</Grid>
								<Grid item xs={11} sm={11} md={11} lg={11} className="date-field">
									<MatrixTextField
										error={Boolean(touched.sherdog && errors.sherdog)}
										fullWidth
										helperText={touched.sherdog && errors.sherdog}
										variant="filled"
										InputProps={{
											endAdornment: touched.sherdog && errors.sherdog ? <ErrorIcon /> : null,
										}}
										label={t('sherdog')}
										placeholder={t('sherdog')}
										className="matrix-textfield"
										size="small"
										name="sherdog"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.sherdog}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default EditSocialLink;
