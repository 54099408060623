import { useState, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ViewPortContext } from 'src/App';
import moment from 'moment';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { Avatar, Button, Box, Typography, Grid, Link, IconButton } from '@material-ui/core';
import { fetchFromStorage } from 'src/helpers/context';
import CombatDialog from 'src/views/component/UI/dialogBox';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import ProfilePictureView from 'src/views/component/UI/ProfilePictureView';
import ButtonGroups from 'src/views/component/UI/ButtonGroups';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/tapology.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
import { ReactComponent as SmoothcompIcon } from 'src/assets/images/icons/smoothcomp.svg';
import SherdogIcon from 'src/assets/images/icons/bsherdog.png';
import { ReactComponent as AddConnection } from 'src/assets/images/icons/add-connection.svg';
import { ReactComponent as ConnectedIcon } from 'src/assets/images/icons/connected-icon.svg';
import { ReactComponent as ConnectPendingIcon } from 'src/assets/images/icons/connect-pending.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'src/assets/images/icons/Unverified User.svg';
import { ReactComponent as ShadowUserIcon } from 'src/assets/images/icons/shadow-user.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import './card.scss';
import { useTranslation } from 'react-i18next';

var linksObj = {
	facebook: 'https://www.facebook.com',
	instagram: 'https://www.instagram.com',
	twitter: 'https://twitter.com',
	tapology: 'https://tapology.com/fightcenter/fighters',
	snapchat: 'https://www.snapchat.com',
	youtube: 'https://youtube.com/channel/',
	twitch: 'https://twitch.tv',
	linkedin: 'https://www.linkedin.com/in',
	tiktok: 'https://www.tiktok.com/@',
	sherdog: 'https://sherdog.com/fighter/',
	smoothcomp: 'https://smoothcomp.com/en/profile',
};

// SOCIAL MEDIA ICON HANDLER
function SocialIconHandler(data) {
	var checkForSlash = data?.data?.charAt(0) === '/' ? true : false;

	switch (data?.type) {
		case 'URL_facebook':
			var socialLink = checkForSlash ? linksObj?.facebook + data?.data : linksObj?.facebook + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<FaceBookIcon />
				</Link>
			);
		case 'URL_instagram':
			socialLink = checkForSlash ? linksObj?.instagram + data?.data : linksObj?.instagram + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<InstagramIcon />
				</Link>
			);
		case 'URL_twitter':
			socialLink = checkForSlash ? linksObj?.twitter + data?.data : linksObj?.twitter + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitterIcon />
				</Link>
			);
		case 'URL_tapology':
			socialLink = checkForSlash ? linksObj?.tapology + data?.data : linksObj?.tapology + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TapologyIcon />
				</Link>
			);
		case 'URL_snapchat':
			socialLink = checkForSlash ? linksObj?.snapchat + data?.data : linksObj?.snapchat + '/add/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SnapchatIcon />
				</Link>
			);
		case 'URL_youtube':
			socialLink = checkForSlash ? linksObj?.youtube + data?.data : linksObj?.youtube + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<YouTubeIcon />
				</Link>
			);
		case 'URL_twitch':
			socialLink = checkForSlash ? linksObj?.twitch + data?.data : linksObj?.twitch + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitchIcon />
				</Link>
			);
		case 'URL_linkedin':
			socialLink = checkForSlash ? linksObj?.linkedin + data?.data : linksObj?.linkedin + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<LinkedInIcon />
				</Link>
			);
		case 'URL_tiktok':
			socialLink = checkForSlash ? linksObj?.tiktok + data?.data : linksObj?.tiktok + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TikTokIcon />
				</Link>
			);
		case 'URL_sherdog':
			socialLink = checkForSlash ? linksObj?.sherdog + data?.data : linksObj?.sherdog + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<img src={SherdogIcon} alt="sherdog-icon" />
				</Link>
			);
		case 'URL_smoothcomp':
			socialLink = checkForSlash ? linksObj?.smoothcomp + data?.data : linksObj?.smoothcomp + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SmoothcompIcon />
				</Link>
			);
		default:
		// socialLink = checkForSlash
		//   ? linksObj?.facebook + data?.data
		//   : linksObj?.facebook + '/' + data?.data;
		// return (
		//   <Link href={socialLink} target="_blank">
		//     <FaceBookIcon />
		//   </Link>
		// );
	}
}

const UserConnectionCard = ({
	user,
	ownerID,
	fetchUsers,
	fetchConnectionData,
	fetchFollowingData,
	fetchPendingConnectionUser,
	fetchFollowersData,
	tab,
	currentPage,
	fetchReferralData,
	isSearchPage,
	updateUserData,
	userPage,
}) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState({});
	const [readMore, setReadMore] = useState(false);
	const navigate = useNavigate();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	let socialMeta = user?.authUser?.UserMetaData?.filter((data) => data?.data !== '');
	// DIALOG HANDLERS
	const combatDialogHandler = (data) => {
		setOpen(true);
		setTitle(data);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	var user_was =
		Number(tab) === 4 ? user?.user_following_id : user?.login_user_sent_request ? user?.user_friend_id : user?.user_id;

	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// FOLLOW USER
	const followUser = async () => {
		if (token) {
			let requestData = { user_following_id: user_was };

			try {
				let { status } = await axiosInstance.post(URLS.doFollow, requestData, headers);

				if (status === 200) {
					isSearchPage && updateUserData('users', userPage, user);
					window.location.pathname === '/users' && fetchUsers(currentPage, user);
					Number(tab) === 1 && fetchConnectionData(currentPage);
					Number(tab) === 2 && fetchPendingConnectionUser(currentPage);
					Number(tab) === 3 && fetchFollowersData(currentPage);
					Number(tab) === 4 && fetchFollowingData(currentPage);
					Number(tab) === 7 && fetchReferralData(currentPage);
				}
			} catch (error) {
				// TODO: Add logging
			}
		} else {
			navigate('/');
		}
	};

	// UNFOLLOW USER
	const unFollowUser = async () => {
		if (token) {
			let requestData = { user_following_id: user_was };

			try {
				let { status } = await axiosInstance.post(URLS.doUnfollow, requestData, headers);

				if (status === 200) {
					isSearchPage && updateUserData('users', userPage, user);
					window.location.pathname === '/users' && fetchUsers(currentPage, user);
					Number(tab) === 1 && fetchConnectionData(currentPage);
					Number(tab) === 2 && fetchPendingConnectionUser(currentPage);
					Number(tab) === 3 && fetchFollowersData(currentPage);
					Number(tab) === 4 && fetchFollowingData(currentPage);
					Number(tab) === 7 && fetchReferralData(currentPage);
				}
			} catch (error) {
				// TODO: Add logging
			}
		} else {
			navigate('/');
		}
	};

	// FOR ADD NEW CONNECTION
	const addConnection = async () => {
		if (token) {
			const requestData = { user_friend_id: user?.user_id };

			try {
				let { status } = await axiosInstance.post(URLS.addConnection, requestData, headers);

				if (status === 200) {
					isSearchPage && updateUserData('users', userPage, user);
					window.location.pathname === '/users' && fetchUsers(currentPage, user);
					Number(tab) === 1 && fetchConnectionData(currentPage);
					Number(tab) === 2 && fetchPendingConnectionUser(currentPage);
					Number(tab) === 3 && fetchFollowersData(currentPage);
					Number(tab) === 4 && fetchFollowingData(currentPage);
					Number(tab) === 7 && fetchReferralData(currentPage);
				}
			} catch (error) {
				// TODO: Add logging
			}
		} else {
			navigate('/');
		}
	};

	// CONNECTION ACCEPT-REJECT-BLOCK
	const connectionChangeHandler = async (data) => {
		if (token) {
			try {
				let requestData = {
					status: data,
					user_friend_id: user_was,
				};

				let { status } = await axiosInstance.put(URLS.connectionRequestUpdate, requestData, headers);

				if (status === 200) {
					isSearchPage && updateUserData('users', userPage, user);
					window.location.pathname === '/users' && fetchUsers(currentPage, user);
					Number(tab) === 1 && fetchConnectionData(currentPage);
					Number(tab) === 2 && fetchPendingConnectionUser(currentPage);
					Number(tab) === 3 && fetchFollowersData(currentPage);
					Number(tab) === 4 && fetchFollowingData(currentPage);
					Number(tab) === 7 && fetchReferralData(currentPage);
				}
			} catch (error) {
				// TODO: Add logging
			}
		} else {
			navigate('/');
		}
	};

	// FOR CONNECTION REMOVE-REQUEST_CANCEL
	const removeConnectionHandler = async () => {
		if (token) {
			var IDTOPASS = user_was;

			try {
				let { status } = await axiosInstance.delete(URLS.deleteConnection + `?user_friend_id=${IDTOPASS}`, headers);

				if (status === 200) {
					isSearchPage && updateUserData('users', userPage, user);
					window.location.pathname === '/users' && fetchUsers(currentPage, user);
					Number(tab) === 1 && fetchConnectionData(currentPage);
					Number(tab) === 2 && fetchPendingConnectionUser(currentPage);
					Number(tab) === 3 && fetchFollowersData(currentPage);
					Number(tab) === 4 && fetchFollowingData(currentPage);
					Number(tab) === 7 && fetchReferralData(currentPage);
				}
			} catch (error) {
				// TODO: Add logging
			}
		} else {
			navigate('/');
		}
	};
	const isOwner = Number(ownerID) === Number(user_was) ? true : false;
	const bioText = readMore
		? user?.authUser?.UserBioData?.PostLists[0]?.Item?.data
		: user?.authUser?.UserBioData?.PostLists[0]?.Item?.data.substring(0, 100 - 3) + '...';

	var userUrlValue = `/user/${user?.user_meta?.data !== '' ? user?.user_meta?.data : user?.authUser?.user_id}`;
	var urlValue = user?.type === 'user_other' ? `/user/shadow/${user?.user_following_id}` : userUrlValue;

	let buttonGroups = {
		accepted: [
			{ title: t('remove_connection'), clickFn: removeConnectionHandler },
			{ title: t('block'), clickFn: () => connectionChangeHandler('blocked') },
		],
		received: [
			{ title: t('accept'), clickFn: () => connectionChangeHandler('accepted') },
			{ title: t('reject'), clickFn: () => connectionChangeHandler('rejected') },
			{ title: t('block'), clickFn: () => connectionChangeHandler('blocked') },
		],
		sent: [
			{ title: t('revoke_connection'), clickFn: removeConnectionHandler },
			{ title: t('block'), clickFn: () => connectionChangeHandler('blocked') },
		],
		addConnect: [
			{ title: t('add_connection'), clickFn: addConnection },
			{ title: t('block'), clickFn: () => connectionChangeHandler('blocked') },
		],
	};

	return (
		<Box className="connection-user">
			<Box className="user-image">
				{/* USER PROFILE PICTURE */}
				<Avatar
					onClick={() => {
						combatDialogHandler({
							name: `${user?.authUser?.first_name ? user?.authUser?.first_name : ''} ${
								user?.authUser?.last_name ? user?.authUser?.last_name : ''
							}${!user?.authUser?.first_name && !user?.authUser?.last_name ? 'Combat Matrix User' : ''}`,
							img: user?.authUser?.item_profile_id ? user?.authUser?.item_profile_id : UserPlaceholderIcon,
						});
					}}
					src={user?.authUser?.item_profile_id ? user?.authUser?.item_profile_id : UserPlaceholderIcon}
				/>
				<Box className="user-name">
					<NavLink to={urlValue} className="username-icon">
						<Typography
							className="name"
							// onClick={() => {
							//   navigate(
							//     `/user/${
							//       user?.user_meta?.data !== ''
							//         ? user?.user_meta?.data
							//         : user?.authUser?.user_id
							//     }`
							//   );
							// }}
						>
							{/* {`${user?.authUser?.first_name ? user?.authUser?.first_name : ''
              } ${user?.authUser?.last_name ? user?.authUser?.last_name : ''}${!user?.authUser?.first_name && !user?.authUser?.last_name
                ? 'Combat Matrix User'
                : ''
              }`} */}
							{`${user?.authUser?.user_full_name ? user?.authUser?.user_full_name : 'Combat Matrix User'}`}
						</Typography>
						<IconButton className="user-icon">
							{user?.authUser?.type === 'user' && user?.authUser?.veritication_status === 'yes' ? (
								<VerifiedIcon />
							) : user?.authUser?.type === 'user' && user?.authUser?.veritication_status === 'no' ? (
								<UnverifiedIcon />
							) : user?.authUser?.type === 'user_other' ? (
								<ShadowUserIcon />
							) : (
								<></>
							)}
						</IconButton>
					</NavLink>
					<Typography className="user-dob">
						{t('connections.member_since')} {moment(user?.authUser?.Member_Since).format('Do MMMM YYYY')}
					</Typography>
					<Box className="social-icons">
						<Grid container>
							{socialMeta
								? socialMeta.length > 0
									? socialMeta
											?.filter((data) => data?.type !== 'other')
											?.map((data, index) => {
												return (
													<Grid item className="social-link" key={index}>
														{SocialIconHandler(data)}
													</Grid>
												);
											})
									: null
								: null}
						</Grid>
					</Box>
				</Box>
			</Box>

			{/* PROFILE CARD BUTTONS */}
			<Box className="border-gray tool-tip-conainer profile-btns-container">
				{/* FOLLOW BUTTON */}
				{user?.isFriendStatus === 'blackFromUser' ? (
					<Button fullWidth disableRipple disableTouchRipple variant="contained" className="profile-following-btn">
						{t('user_blocked_you')}
					</Button>
				) : user?.isFollow ? (
					<Button
						fullWidth
						disableRipple
						disableTouchRipple
						variant="contained"
						disabled={
							isOwner
								? true
								: user?.isFriendStatus === 'blackFromUser'
								? true
								: user?.isFriendStatus === 'blackUser'
								? true
								: false
						}
						className="profile-following-btn"
						onClick={() => {
							!isOwner && unFollowUser();
						}}
					>
						{t('following')}
					</Button>
				) : (
					<Button
						variant="contained"
						fullWidth
						className="user-f-btn"
						onClick={() => {
							!isOwner && followUser();
						}}
						disabled={
							isOwner
								? true
								: user?.isFriendStatus === 'blackFromUser'
								? true
								: user?.isFriendStatus === 'blackUser'
								? true
								: false
						}
					>
						{t('follow')}
					</Button>
				)}

				{/* CONNECTION BUTTON */}
				{user?.isFriendStatus === 'blackFromUser' ? (
					<Button
						disableElevation
						disableTouchRipple
						disableRipple
						disableFocusRipple
						style={{ width: '100%' }}
						variant="contained"
						className="connection-have-btn b-btn"
						onClick={() => {
							!isOwner && connectionChangeHandler('blocked');
						}}
					>
						{t('block_user_back')}
					</Button>
				) : user?.isFriendStatus === 'blackUser' ? (
					<Button
						disableElevation
						disableTouchRipple
						disableRipple
						disableFocusRipple
						style={{ width: '100%' }}
						variant="contained"
						disabled={isOwner ? true : false}
						className="connection-have-btn"
						onClick={() => {
							!isOwner && connectionChangeHandler('unblock');
						}}
					>
						{t('unblock')}
					</Button>
				) : // MONITOR THE WINDOW SIZE
				windowWidth > breakPoint ? (
					<Box display="flex" justifyContent="space-between">
						{user?.isFriendStatus === 'Received' ? (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								style={{ width: '100%' }}
								variant="contained"
								className="connection-have-btn mr-8"
								disabled={isOwner ? true : false}
								onClick={() => {
									!isOwner && connectionChangeHandler('accepted');
								}}
							>
								{t('accept')}
							</Button>
						) : user?.isFriendStatus === 'sent' ? (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								style={{ width: '100%' }}
								variant="contained"
								disabled={isOwner ? true : false}
								className="connection-have-btn mr-8"
								onClick={() => {
									!isOwner && removeConnectionHandler();
								}}
							>
								{t('connection_pending')}
							</Button>
						) : user?.isFriendStatus === 'accepted' ? (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								style={{ width: '100%' }}
								variant="contained"
								disabled={isOwner ? true : false}
								className="connection-have-btn mr-8"
								onClick={() => {
									!isOwner && removeConnectionHandler();
								}}
							>
								{t('remove_connection')}
							</Button>
						) : (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								style={{ width: '100%' }}
								variant="contained"
								startIcon={<AddConnection />}
								disabled={isOwner ? true : false}
								className="connection-have-btn b-btn mr-8"
								onClick={() => {
									!isOwner && addConnection();
								}}
							>
								{t('add_connection')}
							</Button>
						)}

						{user?.isFriendStatus === 'Received' ? (
							<Button
								disableElevation
								disableTouchRipple
								disableRipple
								disableFocusRipple
								style={{ width: '100%' }}
								variant="contained"
								disabled={isOwner ? true : false}
								className="connection-have-btn mr-8"
								onClick={() => {
									!isOwner && connectionChangeHandler('rejected');
								}}
							>
								{t('reject')}
							</Button>
						) : null}

						<Button
							disableElevation
							disableTouchRipple
							disableRipple
							disableFocusRipple
							style={{ width: '100%' }}
							variant="contained"
							className="connection-have-btn b-btn"
							disabled={isOwner ? true : false}
							onClick={() => {
								!isOwner && connectionChangeHandler('blocked');
							}}
						>
							{t('block')}
						</Button>
					</Box>
				) : (
					<Box className="mobile-btn-group">
						{user?.isFriendStatus === 'accepted' ? (
							<ButtonGroups
								title={t('connected')}
								content={buttonGroups.accepted}
								className={'btngroup-can-click'}
								disabled={isOwner ? true : false}
								startIcon={<ConnectedIcon />}
							/>
						) : user?.isFriendStatus === 'Received' ? (
							<ButtonGroups
								title={t('connection_pending')}
								content={buttonGroups.received}
								className={'btngroup-can-click'}
								disabled={isOwner ? true : false}
								startIcon={<ConnectPendingIcon />}
							/>
						) : user?.isFriendStatus === 'sent' ? (
							<ButtonGroups
								title={t('connection_pending')}
								content={buttonGroups.sent}
								className={'btngroup-cannot-click'}
								disabled={isOwner ? true : false}
								startIcon={<ConnectPendingIcon />}
							/>
						) : (
							<ButtonGroups
								title={t('add_connection')}
								content={buttonGroups.addConnect}
								className={'btngroup-can-click'}
								disabled={isOwner ? true : false}
								startIcon={<AddConnection />}
							/>
						)}
					</Box>
				)}
			</Box>

			{/* SOCIAL STASTICS */}
			<Box className="right-prof-counts">
				<Box className="social-count-bx">
					<Typography className="social-count">{user?.authUser?.UserStatisticsDetails?.followers}</Typography>
					<Typography className="social-act-name">{t('myprofile.folowers')}</Typography>
				</Box>

				<Box className="social-count-bx">
					<Typography className="social-count">{user?.authUser?.UserStatisticsDetails?.connections}</Typography>
					<Typography className="social-act-name">{t('myprofile.connections')}</Typography>
				</Box>

				<Box className="social-count-bx">
					<Typography className="social-count">{user?.authUser?.UserStatisticsDetails?.following}</Typography>
					<Typography className="social-act-name">{t('myprofile.following')}</Typography>
				</Box>

				<Box className="social-count-bx">
					<Typography className="social-count">{user?.authUser?.UserStatisticsDetails?.refferrals}</Typography>
					<Typography className="social-act-name">{t('myprofile.refferals')}</Typography>
				</Box>
			</Box>
			{user?.authUser?.UserBioData !== null && (
				<Box className="pt-14">
					<Typography className="bio-head">bio</Typography>
					<Typography className="bio-description">
						{bioText && // USER PROFILE TYPE ABOUT TEXT
							renderHTML(
								anchorme({
									input: bioText.toString().replace(/\n/g, '<br/>'),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
					</Typography>
					{Number(user?.authUser?.UserBioData?.PostLists[0]?.Item?.data.length) > 100 ? (
						readMore ? (
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="readmore-btn"
								onClick={() => {
									setReadMore(false);
								}}
							>
								{t('read_less')}
							</Button>
						) : (
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="readmore-btn"
								onClick={() => {
									setReadMore(true);
								}}
							>
								{t('read_more')}
							</Button>
						)
					) : null}
				</Box>
			)}

			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title?.name}
				content={<ProfilePictureView src={title?.img} />}
			/>
		</Box>
	);
};

export default UserConnectionCard;
