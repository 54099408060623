import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CommunityContent from 'src/views/component/application/Community/CommunityList';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const EventsPage = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Communities | Combat Matrix">
			<CommunityContent />
		</Page>
	);
};

export default EventsPage;
