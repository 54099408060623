import { useContext } from 'react';
import { ViewPortContext } from 'src/App';
import Welcome from './Welcome';
import Restrictions from './Restrictions';
import Badges from './Badges';
import AdBlock from './AD';
import CategoryDiscipline from './CategoryDiscipline';

const CommunityRightSide = ({ isView, isJoin, loader, token, fetchCommunityDetails }) => {
	const viewPortContext = useContext(ViewPortContext);
	const width = viewPortContext?.width;
	const breakpoint = viewPortContext?.breakPoint;

	return (
		<>
			<Welcome
				loader={loader}
				token={token}
				isView={isView}
				isJoin={isJoin}
				fetchCommunityDetails={fetchCommunityDetails}
			/>
			{/* <>
        {
          isView ? null : <Restrictions
            loader={loader}
            token={token}
            isView={isView}
            isJoin={isJoin}
            fetchCommunityDetails={fetchCommunityDetails}
          />
        }
      </>
      <CategoryDiscipline
        loader={loader}
        token={token}
        isView={isView}
        isJoin={isJoin}
        fetchCommunityDetails={fetchCommunityDetails}
      />
      <Badges
        loader={loader}
        token={token}
        isView={isView}
        isJoin={isJoin}
      /> */}
			{width > breakpoint ? (
				<>
					{isView ? null : (
						<Restrictions
							loader={loader}
							token={token}
							isView={isView}
							isJoin={isJoin}
							fetchCommunityDetails={fetchCommunityDetails}
						/>
					)}
					{/* MVP-HIDE: CM-109 */}
					{/*<CategoryDiscipline*/}
					{/*	loader={loader}*/}
					{/*	token={token}*/}
					{/*	isView={isView}*/}
					{/*	isJoin={isJoin}*/}
					{/*	fetchCommunityDetails={fetchCommunityDetails}*/}
					{/*/>*/}
					{/*<Badges loader={loader} token={token} isView={isView} isJoin={isJoin} />*/}
				</>
			) : (
				<></>
			)}

			<AdBlock />
		</>
	);
};

export default CommunityRightSide;
