import { useContext } from 'react';
import { Box, Container, Typography, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './faq.scss';
import Category from './Category';

const Faq = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="faq_banner">
				<Box className="faq_banner_overlay"></Box>
				<Container maxWidth="lg" className="faq_container">
					<Box className="combat-container">
						<Typography className="banner-head">{t('faq.title')}</Typography>
					</Box>
				</Container>
			</Box>

			<Container maxWidth="lg">
				<Box className="combat-container">
					<Box className="faq-tab-question">
						<Category />
					</Box>
				</Box>
			</Container>
		</>
	);
};
export default Faq;
