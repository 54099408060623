import { useState, useEffect, useContext } from 'react';
import { Avatar, Box, Container, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import { URLS } from 'src/helpers/constants/urls';
import { connect } from 'react-redux';
import { userVerifyCount } from 'src/redux-state/actions/userProfile';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import LeftProfile from './LeftBlock';
import RightProfile from './RightBlock';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const ProfileView = ({ auth, userVerifyCount }) => {
	const classes = useStyles();
	const isView = false;
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const { t } = useTranslation();
	const { id } = useParams();
	let visitorId =
		fetchFromStorage(identifiers.visitorUserId) !== null ? fetchFromStorage(identifiers.visitorUserId) : id;
	const [loader, setLoader] = useState(true);

	const [user, setUser] = useState({});
	const [userNotFound, setUserNotFound] = useState(false);

	const getMyProfile = async () => {
		setLoader(true);
		setUserNotFound(false);
		// let url = URLS.getUserProfile + id;
		let url = URLS.getUserProfile + visitorId;

		try {
			const { status, data } = await axiosInstance.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				setLoader(false);
				setUser(data);
				userVerifyCount(data?.verify_user_count);
			}
		} catch (error) {
			setLoader(false);
			setUserNotFound(true);
		}
	};

	useEffect(() => {
		getMyProfile();
		// eslint-disable-next-line
	}, [id]);
	let userData = user?.data;

	return (
		<Page
			className={classes.root}
			title={
				loader
					? 'User Profile'
					: `${userData?.first_name ? userData?.first_name : null} ${userData?.last_name ? userData?.last_name : null}`
			}
		>
			<Container maxWidth="lg">
				<Box className="my-profile-page combat-container">
					{!userNotFound ? (
						<Box className="myprofile-grid-container">
							<Box className="myprofile-right show-1279">
								<RightProfile
									isView={isView}
									loader={loader}
									user={user}
									token={token}
									ProfileAvatar={
										<ProfileAvatar src={user?.data?.user_profile} getMyProfile={getMyProfile} token={token} />
									}
									getMyProfile={getMyProfile}
								/>
							</Box>

							<Box className="myprofile-left">
								<LeftProfile isView={isView} user={user} loader={loader} token={token} getMyProfile={getMyProfile} />
							</Box>

							<Box className="myprofile-right hide-1279">
								<RightProfile
									isView={isView}
									user={user}
									loader={loader}
									token={token}
									ProfileAvatar={
										<ProfileAvatar src={user?.data?.user_profile} getMyProfile={getMyProfile} token={token} />
									}
									getMyProfile={getMyProfile}
								/>
							</Box>
						</Box>
					) : (
						<Typography className="user-not-found-text">{t('user_not_found')}</Typography>
					)}
				</Box>
			</Container>
		</Page>
	);
};

const ProfileAvatar = ({ src, getMyProfile, token }) => {
	const { t } = useTranslation();

	const [user, setUser] = useState('');
	const imgHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					let id = data?.data?.itemId || data?.data?.id;
					let requestData = { item_profile_id: id };

					try {
						let { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profilepic_updated_success'));
							getMyProfile();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		if (src !== '') {
			setUser(src);
		}
		// eslint-disable-next-line
	}, [src]);

	return (
		<>
			<input
				accept="image/*"
				id="contained-button-file"
				type="file"
				multiple
				onChange={imgHandler}
				style={{ display: 'none' }}
			/>
			<label htmlFor="contained-button-file">
				<IconButton component="span">
					<Avatar src={user} />
					<CameraIcon className="camera-icon" />
				</IconButton>
			</label>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state?.Combat?.authData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		userVerifyCount: (data) => dispatch(userVerifyCount(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
