import { useContext } from 'react';
import { Box, Container, Button, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import { ReactComponent as BadgeIcon } from 'src/assets/images/icons/badge_icon.svg';
import './viewallbadge.scss';

const ViewAllBadges = () => {
	const { t } = useTranslation();

	const viewallbadges = [
		{
			name: t('badge.follower'),
		},
		{
			name: t('badge.followed'),
		},
		{
			name: t('badge.bitch_badge'),
		},
	];

	return (
		<>
			<Container maxWidth="lg">
				<Box className="badge-page combat-container">
					<Box className="badge_block_wrapper">
						<Box className="back-to-profile">
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								startIcon={<BackIcon />}
								className="back-text"
							>
								{t('badge.backtoprofile')}
							</Button>
						</Box>
						<Box className="badge_block">
							<Typography className="badge_title">{t('badge.title')}</Typography>
							<Grid container spacing={1}>
								{viewallbadges.map((item) => {
									return (
										<>
											<Grid item xs={4}>
												<Box className="badge_follower">
													<BadgeIcon />
													<Box className="badge_content">
														<Typography className="badge_block_title">{item.name}</Typography>
														<Typography className="achieved_date">{t('badge.Achieved_on_DD_MMM_YYYY')}</Typography>
													</Box>
												</Box>
											</Grid>
										</>
									);
								})}
							</Grid>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
};
export default ViewAllBadges;
