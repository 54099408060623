import { useState, useEffect, useRef } from 'react';
import { Box, Button, Chip, Grid, IconButton, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@material-ui/icons/Cancel';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
// import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import './addEditDiscipline.scss';
import moment from 'moment';
import Autocompletes, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { useTranslation } from 'react-i18next';

const AddEditDiscipline = ({
	message,
	type,
	discData,
	token,
	handleClose,
	getMyProfile,
	fetchUserDisciplines,
	currentPage,
	isModalLoading,
	setIsModalLoading,
}) => {
	const { t } = useTranslation();

	const [editData, setEditData] = useState({});
	const [organisationData, setOrganisationData] = useState([]);
	const [usersList, setUsersList] = useState([]);
	const [disciplineData, setDisciplineData] = useState([]);
	const [selectedDiscipline, setSelectedDiscipline] = useState([]);
	const [createdDiscipline, setCreatedDiscipline] = useState([]);
	const [ranksuggestion, setRankSuggestion] = useState([]);
	const [grantedByValue, setGrantedByValue] = useState('');
	const [grantedAtValue, setGrantedAtValue] = useState('');
	const [formData, setFormData] = useState({});
	const [validationObject, setValidationObj] = useState({});
	const [grantedByObject, setGrantedByObject] = useState({});
	const [grantedAtObject, setGrantedAtObject] = useState({});

	const formRef = useRef();

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	// FETCH DISCIPLINE TYPES DATA
	const fetchDisciplineTypesData = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getdisciplineTypes, authHeader);

			if (status === 200) {
				var filterData = data?.getDisciplinesData?.filter((item) => item?.name !== null && item?.name !== '');

				setDisciplineData(filterData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH USER LIST
	const fetchUsersList = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getAllUsers, authHeader);

			if (status === 200) {
				var filteredData = data?.usersData;
				var formatted_Array = [];

				filteredData.map((item, i) => {
					var USER_NAME =
						item?.first_name !== null && item?.last_name !== null
							? `${item?.first_name} ${item?.last_name}`
							: item?.first_name !== null
							? item?.first_name
							: item?.last_name !== null
							? item?.last_name
							: '';
					// var dropVal = USER_NAME !== '' ? USER_NAME : item?.email;
					var dropVal = USER_NAME !== '' ? USER_NAME : null;
					var object = {
						...item,
						fullName: dropVal,
					};

					return (formatted_Array[i] = object);
				});
				var finalArray = formatted_Array.filter((item) => item.fullName !== null);

				setUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH ORGANISATIONS
	const fetchOrgDatas = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getOrgs, authHeader);

			if (status === 200) {
				var filteredData = data?.orgData.filter((item) => item?.name !== '');

				setOrganisationData(filteredData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// FETCH DISCIPLINE RANKS FOR SUGGESTIONS
	const fetchRankSuggestions = async (id) => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getDisciplineRankSuggestions + id, authHeader);

			if (status === 200) {
				setRankSuggestion([...ranksuggestion, { id: id, data: data?.getDisciplineRankData?.map((x) => x?.data) }]);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// REMOVE SELECTED DISCIPLINE WHEN USER ADD DISCIPLINE
	const removeSelectedDiscipline = (id) => {
		let updateData = selectedDiscipline.filter((ss) => ss.id !== id);
		let updatedCreatedData = createdDiscipline?.filter((ff) => ff?.discipline_id !== id);

		setCreatedDiscipline(updatedCreatedData);
		setSelectedDiscipline(updateData);
	};

	// DISCIPLINE SAVE HANDLER
	const saveInput = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
			// if (formRef.current.isValid) {
			//   console.log('inside submit')
			// }
		}
	};

	const saveChangeHandler = async (requestdata) => {
		// IF ADD NEW DISCIPLINE
		if (type === 'add') {
			let values = createdDiscipline.map((x, i) => {
				// let usersData = usersList
				//   ?.filter(n => n.user_id !== null)
				//   ?.filter(n => n.first_name !== null && n.last_name !== null)
				//   ?.map(x => {
				//     return {
				//       id: x?.user_id,
				//       name: `${x?.first_name ? x?.first_name : ''} ${
				//         x?.last_name ? x?.last_name : ''
				//       }`
				//     };
				//   });
				let rank = `rank${i}`,
					grantedat = `grantedat${i}`,
					grantedby = `grantedby${i}`;

				let orghasId = organisationData.filter((o) => o.name === requestdata[grantedat])[0]?.id;

				// let orghasId = organisationData.filter(
				//   o => o.name === x?.organisation
				// )[0]?.id;

				// let userHasId = usersData?.filter(u => u.name === x?.userName)[0]?.id;
				return {
					discipline_id: x?.discipline_id,
					rankStartDate: x?.rankStartDate,
					// user_issuer_id: !isNaN(Number(userHasId)) ? Number(userHasId) : null,
					// userName: !isNaN(Number(userHasId)) ? null : String(x?.organisation),
					// rank: !isNaN(Number(x?.rank)) ? Number(x?.rank) : null, //checkval?.length > 0 ? x?.rank : null
					// rank_other: !isNaN(Number(x?.rank)) ? null : String(x?.rank), //checkval?.length > 0 ? null : x?.rank
					rank: !isNaN(Number(requestdata[rank])) ? Number(requestdata[rank]) : null, //checkval?.length > 0 ? x?.rank : null
					rank_other: !isNaN(Number(requestdata[rank])) ? null : String(requestdata[rank]), //checkval?.length > 0 ? null : x?.rank
					// organisation_id: !isNaN(Number(orghasId)) ? Number(orghasId) : null,
					// orgName: !isNaN(Number(orghasId)) ? null : String(x?.organisation),

					...(grantedAtObject[grantedat] &&
						Object.prototype.hasOwnProperty.call(grantedAtObject[grantedat], 'id') === false && {
							orgName: grantedAtObject[grantedat]?.inputValue,
						}),
					...(grantedAtObject[grantedat] &&
						Object.prototype.hasOwnProperty.call(grantedAtObject[grantedat], 'id') && {
							organisation_id: Number(grantedAtObject[grantedat]?.id),
						}),

					user_type: grantedByObject[grantedby]
						? Object.prototype.hasOwnProperty.call(grantedByObject[grantedby], 'user_id')
							? grantedByObject[grantedby]?.type
							: 'user_other'
						: null,
					...(grantedByObject[grantedby] &&
						Object.prototype.hasOwnProperty.call(grantedByObject[grantedby], 'user_id') === false && {
							userName: grantedByObject[grantedby]?.inputValue,
						}),
					...(grantedByObject[grantedby] &&
						Object.prototype.hasOwnProperty.call(grantedByObject[grantedby], 'user_id') && {
							user_issuer_id: Number(grantedByObject[grantedby]?.user_id),
						}),

					// user_type: grantedByValue
					//   ? Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id')
					//     ? grantedByValue?.type
					//     : 'user_other'
					//   : null,
					// ...(grantedByValue &&
					//   Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id') === false && {
					//   userName: grantedByValue?.inputValue
					// }),
					// ...(grantedByValue &&
					//   Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id') && {
					//   user_issuer_id: Number(grantedByValue?.user_id)
					// })
				};
			});

			try {
				setIsModalLoading(true);
				let requestData = { userDisciplines: values };
				let { status, data } = await axiosInstance.post(URLS.createUserDisciplineRank, requestData, authHeader);

				if (status === 200) {
					fetchUserDisciplines('', currentPage);
					handleClose();
					getMyProfile();
					setApiMessage('success', data?.message);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}

		// IF EDIT DISCIPLINE
		if (type === 'edit') {
			try {
				setIsModalLoading(true);
				// let usersData = usersList
				//   ?.filter(n => n.user_id !== null)
				//   ?.filter(n => n.first_name !== null && n.last_name !== null)
				//   ?.map(x => {
				//     return {
				//       id: x?.user_id,
				//       name: `${x?.first_name ? x?.first_name : ''} ${
				//         x?.last_name ? x?.last_name : ''
				//       }`
				//     };
				//   });

				let orghasId = organisationData.filter((o) => o.name === editData?.organisation)[0]?.id;

				// let userHasId = usersData?.filter(u => u.name === editData?.userName)[0]
				//   ?.id;

				let requestData = {
					userDisciplines: [
						{
							id: editData?.id,
							discipline_id: editData?.discipline_id,
							rankStartDate: editData?.rankStartDate,
							// user_issuer_id: !isNaN(Number(userHasId))
							//   ? Number(userHasId)
							//   : null,
							// userName: !isNaN(Number(userHasId))
							//   ? null
							//   : String(editData?.userName),
							rank: !isNaN(Number(editData?.rank)) ? Number(editData?.rank) : null,
							rank_other: !isNaN(Number(editData?.rank)) ? null : String(editData?.rank),

							// organisation_id: !isNaN(Number(orghasId))
							//   ? Number(orghasId)
							//   : null,
							// orgName: !isNaN(Number(orghasId))
							//   ? null
							//   : String(editData?.organisation),
							...(grantedAtValue &&
								Object.prototype.hasOwnProperty.call(grantedAtValue, 'id') === false && {
									orgName: grantedAtValue?.inputValue,
								}),
							...(grantedAtValue &&
								Object.prototype.hasOwnProperty.call(grantedAtValue, 'id') && {
									organisation_id: Number(grantedAtValue?.id),
								}),
							// user_type: grantedByValue
							//   ? Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id')
							//     ? grantedByValue?.type
							//     : 'user_other'
							//   : null,

							...(grantedByValue?.user_id && {
								user_type: grantedByValue?.type,
							}),

							...(grantedByValue &&
								Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id') === false && {
									userName: grantedByValue?.inputValue,
								}),
							...(grantedByValue &&
								Object.prototype.hasOwnProperty.call(grantedByValue, 'user_id') && {
									user_issuer_id: Number(grantedByValue?.user_id),
								}),
						},
					],
				};

				let { status, data } = await axiosInstance.put(URLS.updateUserDiscipline, requestData, authHeader);

				if (status === 200) {
					fetchUserDisciplines('', currentPage);
					// getMyProfile();
					handleClose();
					setApiMessage('success', data?.message);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		fetchDisciplineTypesData();
		fetchOrgDatas();
		fetchUsersList();

		if (type === 'edit') {
			// let user = {
			//   user_id: discData?.user_details?.id,
			//   user_type: discData.user_type,
			//   fullName:
			//     discData?.user_details?.first_name &&
			//       discData?.user_details?.last_name
			//       ? `${discData?.user_details?.first_name
			//         ? discData?.user_details?.first_name
			//         : ''
			//       } ${discData?.user_details?.last_name
			//         ? discData?.user_details?.last_name
			//         : ''
			//       }`
			//       : ''
			// };

			let setData = {
				id: discData?.id,
				rank: discData?.discipline_meta_rank_sort_order
					? discData?.discipline_meta_rank_sort_order
					: discData?.rank_other,
				discipline_id: discData?.discipline_id,
				rankStartDate: discData?.date_start ? moment(discData?.date_start).format('YYYY-MM-DD') : '',
				// userName:
				//   discData?.user_type === 'user_other'
				//     ? String(discData?.user_details?.name_first)
				//     : user,

				// discData?.user_type === 'user_other'
				// ? String(discData?.user_details?.name_first)
				// : user,
				userName: discData?.user_details?.user_name,
				organisation: discData?.Organization?.name ? discData?.Organization?.name : '',
			};

			setEditData(setData);
			fetchRankSuggestions(discData?.discipline_id);
		}
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		setGrantedByValue(editData?.userName);
		setGrantedAtValue(editData?.Organization?.name);
	}, [editData]);
	// }, []);
	const filter = createFilterOptions();

	// let y = createdDiscipline.reduce((acc, elem) => {
	//   console.log('createdDiscipline', acc, elem)
	//   acc[elem] = '' // or what ever object you want inside
	//   return acc
	// }, {})
	useEffect(() => {
		createdDiscipline &&
			createdDiscipline.map((item, i) => {
				let rank = `rank${i}`,
					grantedat = `grantedat${i}`;
				const TEMPOBJ = {
					[rank]: '',
					[grantedat]: '',
				};

				setFormData({ ...formData, ...TEMPOBJ });
				var validationObj = {
					[rank]: Yup.string().required(t('validation.required_message')),
					[grantedat]: Yup.string().required(t('validation.required_message')),
				};

				setValidationObj({ ...validationObject, ...validationObj });
			});
	}, [createdDiscipline]);

	return (
		<Box className="add-edit-descipline-box">
			{type === 'add' ? (
				<Box className="discipline-dropdown-cover">
					{/* SEARCH TOP DISCIPLINES AND SELECT FROM THE LIST */}
					<Autocomplete
						multiple
						id="rank-suggestion"
						value={selectedDiscipline}
						onChange={(event, newValue) => {
							let newIds = selectedDiscipline.map((r) => r.id);
							let newValueIds = newValue.map((r) => r.id);
							let check = newValue.filter((s) => {
								return newIds.indexOf(s?.id) === -1;
							});

							let values = [...createdDiscipline];

							// IF NEW DISCIPLINE SELECTED
							if (newValue.length > selectedDiscipline.length) {
								let haveCheck = ranksuggestion.filter((x) => x?.id === check[0]?.id);

								if (haveCheck.length === 0) {
									fetchRankSuggestions(check[0]?.id);
								}

								values.push({
									discipline_id: check[0]?.id,
									discipline_name: check[0]?.name,
									rank: '',
									rankStartDate: '',
									userName: '',
									organisation: '',
								});
								setCreatedDiscipline(values);
							}

							// IF REMOVE SELECTED DISCIPLINE
							if (newValue.length < selectedDiscipline.length) {
								let removedId = selectedDiscipline.filter((x) => {
									return newValueIds.indexOf(x?.id) === -1;
								})[0]?.id;

								let selectVall = values.filter((x) => x?.discipline_id !== removedId);

								setCreatedDiscipline(selectVall);
							}

							setSelectedDiscipline(newValue);
						}}
						options={disciplineData}
						getOptionLabel={(option) => option?.name}
						renderTags={(tagValue, getTagProps) =>
							tagValue.map((option, index) => (
								<Chip
									size="small"
									deleteIcon={<CancelIcon />}
									className="discipline-chip"
									label={option?.name}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<MatrixTextField
								{...params}
								fullWidth
								variant="filled"
								label={t('myprofile.discipline')}
								placeholder={t('myprofile.discipline')}
								className="matrix-textfield"
								size="small"
							/>
						)}
					/>
				</Box>
			) : (
				// EDIT SELECTED DISCIPLINES
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} className="text-field-grid">
						<Typography className="selected-d-title">{discData?.Discipline?.name}</Typography>
					</Grid>

					{/* DISCIPLINE RANK SUGGESTIONS */}
					<Grid item xs={12} md={12} className="text-field-grid">
						<Autocomplete
							id="combo-box-demo"
							options={ranksuggestion?.filter((x) => x?.id === editData?.discipline_id)[0]?.data || []}
							freeSolo
							value={String(editData?.rank)}
							onChange={(e, newVal) => {
								let updatedData = { ...editData, rank: newVal ? newVal : '' };

								setEditData(updatedData);
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option);

								if (inputValue !== '' && !isExisting) {
									filtered.push(inputValue);
								}

								return filtered;
							}}
							renderInput={(params) => (
								<MatrixTextField
									fullWidth
									{...params}
									variant="filled"
									label={t('myprofile.rank')}
									placeholder={t('myprofile.rank')}
									className="matrix-textfield"
									size="small"
									// type="number"
								/>
							)}
						/>
					</Grid>

					{/* GRANTED BY */}
					<Grid item xs={12} md={12} className="text-field-grid">
						<Autocompletes
							id="combtant"
							options={usersList}
							value={grantedByValue}
							// value={String(editData.userName)}
							onChange={(e, newValue) => {
								setGrantedByValue(newValue);
								// let combs = sideData.filter(
								//   s => s?.id === team?.id
								// )[0]?.combtanats;

								// let newComb = combs?.map(x => {
								//   if (x?.id === comb?.id) {
								//     return { ...x, name: newValue };
								//   }
								//   return x;
								// });

								// let updateData = sideData.map(x => {
								//   if (x?.id === team?.id) {
								//     return { ...x, combtanats: newComb };
								//   }
								//   return x;
								// });
								// setSideData(updateData);
								// combtantDetailsHandler(updateData);
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								const { inputValue } = params;

								// Suggest the creation of a new value
								let isExisting = options.some((option) => inputValue === option.fullName);

								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										fullName: `Add "${inputValue}"`,
									});
								}

								return filtered;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								if (typeof option === 'string') {
									return option;
								}

								// Add "xxx" option created dynamically
								if (option.inputValue) {
									return option.inputValue;
								}

								// Regular option
								return String(option.fullName);
							}}
							renderOption={(props, option) => {
								return (
									<span {...props} style={{ color: props?.type === 'user_other' && '#438ac1' }}>
										{props.fullName}
									</span>
								);
							}}
							// renderOption={(props, option) => {
							//   return props.fullName;
							// }}
							renderInput={(params) => (
								<MatrixTextField
									fullWidth
									// error={validCheck && comb?.name === ''}
									// helperText={
									//   validCheck &&
									//   comb?.name === '' &&
									//   'This field is required'
									// }
									{...params}
									variant="filled"
									label={t('myprofile.granted_by')}
									placeholder={t('myprofile.granted_by')}
									className="matrix-textfield"
									size="small"
								/>
							)}
						/>
						{/* <Autocomplete
              id="granted-by-edit"
              options={usersList
                ?.filter(n => n.user_id !== null)
                ?.filter(n => n.first_name !== null && n.last_name !== null)
                ?.map(
                  x =>
                    `${x?.first_name ? x?.first_name : ''} ${x?.last_name ? x?.last_name : ''
                    }`
                )}
              freeSolo
              value={String(editData.userName)}
              onInputChange={(e, newVal) => {
                let updatedData = {
                  ...editData,
                  userName: newVal
                };

                setEditData(updatedData);
              }}
              renderInput={params => (
                <MatrixTextField
                  fullWidth
                  {...params}
                  variant="filled"
                  label="Granted By"
                  placeholder="Granted By"
                  className="matrix-textfield"
                  size="small"
                // type="number"
                />
              )}
            /> */}
					</Grid>
					{/* GRANTED AT */}
					<Grid item xs={12} md={12} className="text-field-grid">
						<Autocomplete
							id="granted-at-edit"
							// options={organisationData?.map(x => x?.name)}
							options={organisationData}
							freeSolo
							value={String(editData.organisation)}
							onChange={(e, newValue) => {
								setGrantedAtValue(newValue);
							}}
							// onInputChange={(e, newVal) => {
							//   let updatedData = {
							//     ...editData,
							//     organisation: newVal
							//   };
							//   setEditData(updatedData);
							// }}

							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								const { inputValue } = params;

								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.name);

								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										name: `Add "${inputValue}"`,
									});
								}

								return filtered;
							}}
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								if (typeof option === 'string') {
									return option;
								}

								// Add "xxx" option created dynamically
								if (option.inputValue) {
									return option.inputValue;
								}

								// Regular option
								return String(option.name);
							}}
							renderOption={(props, option) => {
								return props.name;
							}}
							// renderOption={(props, option) => {
							//   return (
							//     <span {...props} style={{ color: '#438ac1' }}>
							//       {props.name}
							//     </span>
							//   );
							// }}
							renderInput={(params) => (
								<MatrixTextField
									fullWidth
									{...params}
									variant="filled"
									label={t('myprofile.granted_at')}
									placeholder={t('myprofile.granted_at')}
									className="matrix-textfield"
									size="small"
								/>
							)}
						/>
					</Grid>

					{/* DATE GRANTED */}
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label={t('myprofile.date_granted')}
							placeholder={t('myprofile.date_granted')}
							className="matrix-textfield"
							size="small"
							type="date"
							InputLabelProps={{
								shrink: true,
							}}
							value={editData?.rankStartDate}
							onChange={(e) => {
								let updatedData = {
									...editData,
									rankStartDate: e.target.value,
								};

								setEditData(updatedData);
							}}
						/>
					</Grid>
				</Grid>
			)}

			{/* ADD NEW DISCIPLINE DATA */}
			{/* DISCIPLINE FORM SHOWS WHEN USER SELECT ATLEAST 1 DISCIPLINE */}
			{createdDiscipline?.length > 0 ? (
				<>
					<Formik
						initialValues={{
							rank0: '',
							rank1: '',
							rank2: '',
							rank3: '',
							rank4: '',
							rank5: '',
							rank6: '',
							rank7: '',
							rank8: '',
							rank9: '',
							rank10: '',
							rank11: '',
							rank12: '',
							rank13: '',
							rank14: '',
							grantedat0: '',
							grantedat1: '',
							grantedat2: '',
							grantedat3: '',
							grantedat4: '',
							grantedat5: '',
							grantedat6: '',
							grantedat7: '',
							grantedat8: '',
							grantedat9: '',
							grantedat10: '',
							grantedat11: '',
							grantedat12: '',
							grantedat13: '',
							grantedat14: '',
						}}
						// initialValues={formData}
						// validationSchema={Yup.object().shape({
						//   rank0: Yup.string().required('This field is required.'),
						//   rank1: Yup.string().required('This field is required.'),
						//   rank2: Yup.string().required('This field is required.'),
						// })}

						validationSchema={Yup.object().shape(validationObject)}
						innerRef={formRef}
						onSubmit={(requestdata) => saveChangeHandler(requestdata)}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
							<>
								{createdDiscipline?.map((disc, index) => {
									let RANK_NAME = `rank${index}`,
										GRANTED_AT = `grantedat${index}`,
										GRANTED_BY = `grantedby${index}`;

									return (
										<Box key={index}>
											<hr className="discipline-hr" />
											<Box className="selected-discipline-block">
												<Box display="flex" justifyContent={'space-between'} mb={2}>
													{/* SELECTED DISCIPLINE NAME */}
													<Typography className="selected-d-title">{disc?.discipline_name}</Typography>

													{/* REMOVE DISCIPLINE BUTTON */}
													<IconButton
														disableRipple
														disableFocusRipple
														disableTouchRipple
														onClick={() => {
															removeSelectedDiscipline(disc?.discipline_id);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</Box>

												<form>
													<Grid container spacing={3}>
														{/* RANK SUGGESTIONS */}
														<Grid item xs={12} md={12} className="text-field-grid">
															<Autocomplete
																id="rank-suggestion-add"
																options={ranksuggestion?.filter((x) => x?.id === disc?.discipline_id)[0]?.data || []}
																freeSolo
																// value={String(disc?.rank)}
																value={String(values[RANK_NAME])}
																onBlur={handleBlur}
																// onChange={handleChange}
																InputProps={{
																	endAdornment: touched?.[RANK_NAME] && errors?.[RANK_NAME] ? <ErrorIcon /> : null,
																}}
																onChange={(e, newVal) => {
																	setFieldValue(RANK_NAME, newVal);
																	// handleChange();
																	let updatedData = createdDiscipline.map((val) => {
																		if (val?.discipline_id === disc?.discipline_id) {
																			return {
																				...val,
																				rank: newVal ? newVal : '',
																			};
																		}

																		return val;
																	});

																	setFieldValue(`rank${index}`, newVal);
																	setCreatedDiscipline(updatedData);
																}}
																filterOptions={(options, params) => {
																	const filtered = filter(options, params);
																	const { inputValue } = params;
																	// Suggest the creation of a new value
																	const isExisting = options.some((option) => inputValue === option);

																	if (inputValue !== '' && !isExisting) {
																		filtered.push(inputValue);
																	}

																	return filtered;
																}}
																renderInput={(params) => (
																	<MatrixTextField
																		error={Boolean(touched?.[RANK_NAME] && errors?.[RANK_NAME])}
																		helperText={touched?.[RANK_NAME] && errors?.[RANK_NAME]}
																		fullWidth
																		{...params}
																		variant="filled"
																		label={t('myprofile.rank')}
																		placeholder={t('myprofile.rank')}
																		className="matrix-textfield"
																		size="small"
																		name={RANK_NAME}
																		// type="number"
																	/>
																)}
															/>
														</Grid>

														{/* GRANTED BY */}
														<Grid item xs={12} md={12} className="text-field-grid">
															<Autocompletes
																id="combtant"
																options={usersList}
																// value={comb?.name}
																onChange={(e, newValue) => {
																	setGrantedByValue(newValue);
																	setFieldValue(`grantedat${index}`, newValue);
																	setGrantedByObject({ ...grantedByObject, [GRANTED_BY]: newValue });
																	// let combs = sideData.filter(
																	//   s => s?.id === team?.id
																	// )[0]?.combtanats;

																	// let newComb = combs?.map(x => {
																	//   if (x?.id === comb?.id) {
																	//     return { ...x, name: newValue };
																	//   }
																	//   return x;
																	// });

																	// let updateData = sideData.map(x => {
																	//   if (x?.id === team?.id) {
																	//     return { ...x, combtanats: newComb };
																	//   }
																	//   return x;
																	// });
																	// setSideData(updateData);
																	// combtantDetailsHandler(updateData);
																}}
																filterOptions={(options, params) => {
																	const filtered = filter(options, params);
																	const { inputValue } = params;

																	// Suggest the creation of a new value
																	const isExisting = options.some((option) => inputValue === option.fullName);

																	if (inputValue !== '' && !isExisting) {
																		filtered.push({
																			inputValue,
																			fullName: `Add "${inputValue}"`,
																		});
																	}

																	return filtered;
																}}
																selectOnFocus
																clearOnBlur
																handleHomeEndKeys
																getOptionLabel={(option) => {
																	// Value selected with enter, right from the input
																	if (typeof option === 'string') {
																		return option;
																	}

																	// Add "xxx" option created dynamically
																	if (option.inputValue) {
																		return option.inputValue;
																	}

																	// Regular option
																	return String(option.fullName);
																}}
																renderOption={(props, option) => {
																	return (
																		<span {...props} style={{ color: props?.type === 'user_other' && '#438ac1' }}>
																			{props.fullName}
																		</span>
																	);
																}}
																// renderOption={(props, option) => {
																//   return props.fullName;
																// }}
																renderInput={(params) => (
																	<MatrixTextField
																		fullWidth
																		// error={validCheck && comb?.name === ''}
																		// helperText={
																		//   validCheck &&
																		//   comb?.name === '' &&
																		//   'This field is required'
																		// }
																		{...params}
																		variant="filled"
																		label={t('myprofile.granted_by')}
																		placeholder={t('myprofile.granted_by')}
																		className="matrix-textfield"
																		size="small"
																	/>
																)}
															/>
														</Grid>
														{/* <Grid item xs={12} md={12} className="text-field-grid">
                      <Autocomplete
                        id="grantedby-add"
                        options={usersList
                          ?.filter(n => n.user_id !== null)
                          ?.filter(
                            n => n.first_name !== null && n.last_name !== null
                          )
                          ?.map(
                            x =>
                              `${x?.first_name ? x?.first_name : ''} ${
                                x?.last_name ? x?.last_name : ''
                              }`
                          )}
                        freeSolo
                        value={String(disc.userName)}
                        onInputChange={(e, newVal) => {
                          let updatedData = createdDiscipline.map(val => {
                            if (val?.discipline_id === disc?.discipline_id) {
                              return {
                                ...val,
                                userName: newVal
                              };
                            }
                            return val;
                          });

                          setCreatedDiscipline(updatedData);
                        }}
                        renderInput={params => (
                          <MatrixTextField
                            fullWidth
                            {...params}
                            variant="filled"
                            label="Granted By"
                            placeholder="Granted By"
                            className="matrix-textfield"
                            size="small"
                            // type="number"
                          />
                        )}
                      />
                    </Grid> */}

														{/* GRANTED AT */}
														<Grid item xs={12} md={12} className="text-field-grid">
															<Autocomplete
																// onBlur={handleBlur}
																id="grantedat-add"
																options={organisationData}
																onChange={(e, newValue) => {
																	setGrantedAtValue(newValue);
																	setGrantedAtObject({ ...grantedAtValue, [GRANTED_AT]: newValue });
																}}
																filterOptions={(options, params) => {
																	const filtered = filter(options, params);
																	const { inputValue } = params;

																	// Suggest the creation of a new value
																	const isExisting = options.some((option) => inputValue === option.name);

																	if (inputValue !== '' && !isExisting) {
																		filtered.push({
																			inputValue,
																			name: `Add "${inputValue}"`,
																		});
																	}

																	return filtered;
																}}
																getOptionLabel={(option) => {
																	// Value selected with enter, right from the input
																	if (typeof option === 'string') {
																		return option;
																	}

																	// Add "xxx" option created dynamically
																	if (option.inputValue) {
																		return option.inputValue;
																	}

																	// Regular option
																	return String(option.name);
																}}
																renderOption={(props, option) => {
																	return props.name;
																}}
																freeSolo
																// value={disc?.organisation}
																renderInput={(params) => (
																	<MatrixTextField
																		fullWidth
																		{...params}
																		variant="filled"
																		label={t('myprofile.granted_at')}
																		placeholder={t('myprofile.granted_at')}
																		className="matrix-textfield"
																		size="small"
																	/>
																)}
															/>
														</Grid>

														{/* DATE GRANTED */}
														<Grid item xs={12} md={12} className="text-field-grid">
															<MatrixTextField
																fullWidth
																variant="filled"
																label={t('myprofile.date_granted')}
																placeholder={t('myprofile.date_granted')}
																className="matrix-textfield"
																size="small"
																type="date"
																value={disc?.rankStartDate}
																InputLabelProps={{
																	shrink: true,
																}}
																onChange={(e) => {
																	let updatedData = createdDiscipline.map((val) => {
																		if (val?.discipline_id === disc?.discipline_id) {
																			return {
																				...val,
																				rankStartDate: e.target.value,
																			};
																		}

																		return val;
																	});

																	setCreatedDiscipline(updatedData);
																}}
															/>
														</Grid>
													</Grid>
												</form>
											</Box>
											{/* <Box className="discipline-partition">
                {index !== 0 && !selectedDiscipline?.length && (
                  <hr className="discipline-partition-hr" />
                )}
              </Box> */}
										</Box>
									);
								})}
								{/* <Box className="add-row">
            <IconButton>
              <BaddIcon />
            </IconButton>
          </Box> */}
							</>
						)}
					</Formik>
				</>
			) : (
				<Box className="blank-discipline-cover" />
			)}

			{/* SAVE DISCIPLINE BUTTON */}
			{type === 'add' && createdDiscipline.length === 0 ? (
				''
			) : (
				<Box className="dialog-submit-button dialog-sticky-btn">
					<Button
						variant="contained"
						//todo doesnt work, mb need to fix this
						onClick={() => (type === 'add' ? saveInput() : saveChangeHandler())}
						// onClick={saveChangeHandler}
						className="dialog-submit"
						disabled={isModalLoading}
					>
						{t('save')}
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default AddEditDiscipline;
