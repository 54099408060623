import React, {
	useContext,
	// useEffect,
	// useState
} from 'react'; // useContext
import { Box, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
// import axiosInstance from 'src/helpers/axios/axiosInstance';
// import { URLS } from 'src/helpers/constants/urls';
import NewsFeedRightSide from './Right';
import NewsFeedLeftside from './Left';
// import ComingSoon from '../../UI/comingSoon';
import './news-feed.scss';

const NewsFeed = () => {
	const { t } = useTranslation();

	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');
	const token = fetchFromStorage('authData')?.token;

	// const fetchNewsfeedPostList = async (orderBy, vStatus) => {
	//   try {
	//     const { status, data } = await axiosInstance.get(URLS.getNewsfeedPostList, {
	//       params: {
	//         order_by: orderBy,
	//         view_status: vStatus,
	//         page: 1,
	//         limit: 5
	//       },
	//     });
	//     if (status === 200) {
	//       getNewsfeedPostList(data?.data);
	//       setTotalRecords(data?.data?.totalRecords)
	//     }
	//   } catch (error) { }
	// }
	// useEffect(() => {
	//   fetchNewsfeedPostList('best', 'my');
	// }, []);

	return (
		<Box>
			<Container maxWidth="lg">
				<Box className="combat-container newsfeed-container">
					{/* <ComingSoon /> */}

					<Box className="newsfeed-grid-container">
						<Box className="newsfeed-right show-breakpoint">
							<NewsFeedRightSide />
						</Box>
						<Box className="newsfeed-left">
							<NewsFeedLeftside
								token={token}
								// fetchNewsfeedPostList={fetchNewsfeedPostList}
								user={user}
							/>
						</Box>
						<Box className="newsfeed-right hide-breakpoint">
							<NewsFeedRightSide />
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default NewsFeed;
