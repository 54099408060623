import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Avatar,
	Box,
	Button,
	Checkbox, // Chip,
	Fade,
	CircularProgress,
	Grid,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	Link,
	ListItemText,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ReactPlayer from 'react-player';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import './create-post.scss';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BoldSvg from 'src/assets/images/icons/community/bold.svg';
import ItalicSvg from 'src/assets/images/icons/community/italic.svg';
import UnderlineSvg from 'src/assets/images/icons/community/underlined.svg';
import LinkSvg from 'src/assets/images/icons/community/link.svg';
import AlignRightSvg from 'src/assets/images/icons/community/align-right.svg';
import AlignCenterSvg from 'src/assets/images/icons/community/align-center.svg';
import AlignLeftSvg from 'src/assets/images/icons/community/align-left.svg';
import NumberedSvg from 'src/assets/images/icons/community/numbered.svg';
import UploadIcon from 'src/assets/images/icons/cameraTextEditor.svg';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import { useTranslation } from 'react-i18next';
import CustomOption from '../../../../../../UI/CustomOption';

const CreatePost = ({ loader, token, user, isView, isJoin, fetchCommunityPostList, tab, allUsersList, editAccess }) => {
	const { id } = useParams();
	const { t } = useTranslation();

	const verificationWall = fetchFromStorage('verificationWall');
	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	// const userImage =
	//   communityDetails &&
	//   communityDetails?.communityData?.owner_item_profile_data;
	const userImage = user?.profile_pic;
	const [allImages, setAllImages] = useState([]);
	const [pImages, setPreviewImg] = useState([]);
	const [deletedItemArray, setDeletedItemArray] = useState([]);
	const [createClick, setCreateClick] = useState(false);
	const [flag, setFlag] = useState('');
	// const [selected, setSelected] = useState([]);
	const [checked, setChecked] = useState([2]);
	const [sStatus, setSponsoredPost] = useState(false);
	const [postFlag, setPostFlag] = useState(false);
	const [text, setText] = useState(EditorState.createEmpty());
	const [textError, setTextError] = useState('');
	const [maxLength] = useState(1000);
	const [imageStatus, setImageStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	var textLength = text.getCurrentContent().getPlainText('').length;
	var charcterLimit = maxLength - textLength > 0 ? maxLength - textLength : 0;
	// const restTypeList = [
	//   { id: 1, name: t('profile_type.atheletes') },
	//   { id: 2, name: t('profile_type.coaches') },
	//   { id: 3, name: t('profile_type.fans') },
	//   { id: 4, name: t('profile_type.under_18s') },
	//   { id: 5, name: t('profile_type.promoters') },
	//   { id: 6, name: t('profile_type.instructors') },
	//   { id: 7, name: t('profile_type.unverified') },
	//   { id: 8, name: t('profile_type.sponsors') },
	//   { id: 9, name: t('profile_type.gyms') }
	// ];

	const typeList = [
		// { id: 0, name: 'Featured Post' },
		{ id: 1, name: 'Sticky Post' },
		{ id: 2, name: 'Allow for comments' },
	];

	// const handleToggle = value => () => {
	//   const currentIndex = selected.indexOf(value);
	//   const newSelected = [...selected];

	//   if (currentIndex === -1) {
	//     newSelected.push(value);
	//   } else {
	//     newSelected.splice(currentIndex, 1);
	//   }

	//   setSelected(newSelected);
	// };

	const checkHandleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};
	const handleChangeEditior = (editorState) => {
		setText(editorState);
		setTextError('');
	};
	const createCommunityPost = async (data) => {
		setIsLoading(true);
		const rawHTML = text ? draftToHtml(convertToRaw(text.getCurrentContent())) : '';
		var postListArray = [...allImages];
		var filteredArray = [];

		postListArray.map((item, i) => {
			var object = {
				type: item?.type,
				item_id: item?.id,
			};

			return (filteredArray[i] = object);
		});
		filteredArray.push({
			type: 'text',
			data: rawHTML,
		});

		if (text.getCurrentContent().hasText() || Boolean(allImages.length)) {
			var requestParameter = {
				post: {
					community_id: Number(id),
					sponsored: sStatus ? 'yes' : 'no',
					sticky: checked.includes(1) ? 'yes' : 'no',
					comments: checked.includes(2) ? 'yes' : 'no',
					status: 'active',
					name: data?.title,
					postList: filteredArray,
				},
			};

			try {
				const { status, data } = await axiosInstance.post(URLS.createPost, requestParameter);

				if (status === 200) {
					const tabValue =
						tab === '1'
							? 'best'
							: tab === '2'
							? 'trending'
							: tab === '3'
							? 'date'
							: tab === '4'
							? 'top'
							: tab === '5'
							? 'recommended'
							: 'best';

					setApiMessage('success', data?.message);
					fetchCommunityPostList(tabValue);
					setCreateClick(false);
					setChecked([2]);
					setText(EditorState.createEmpty());
					setSponsoredPost(false);
					setAllImages([]);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			} finally {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
			setTextError(t('validation.required_message'));
		}
	};
	const deletePostItem = (id) => {
		var deletedImage = allImages && allImages?.filter((item, i) => i !== id);

		setDeletedItemArray(deletedImage);
		setAllImages(deletedImage);
	};
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const handleImageLoaded = () => {
		setImageStatus(false);
	};
	const showItems = (item, index) => {
		switch (item?.type) {
			case 'doc':
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
			case 'image':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<Avatar
							src={imageStatus === true ? loaderGif : URLS.mediaURL + item?.location}
							alt="post-image"
							onError={(e) => {
								e.onerror = null;
								e.target.src = placeholder;
							}}
							onLoad={handleImageLoaded}
						/>
					</Box>
				);
			case 'video':
				return (
					<Box width="200px" className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player audio-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
		}
	};

	useEffect(() => {
		var postFlag =
			communityDetails?.communityData?.user_access === 'post_create' ||
			communityDetails?.communityData?.user_owner_id === fetchFromStorage('authData')?.user_id
				? true
				: false;

		setPostFlag(postFlag);

		const flag =
			communityDetails?.communityData?.join_status === 'accepted' ||
			communityDetails?.communityData?.user_owner_id === fetchFromStorage('authData')?.user_id
				? true
				: false;

		communityDetails?.communityData?.user_access === 'post_create' && setChecked([2]);
		setFlag(flag);
	}, [communityDetails]);

	const setImageList = (img, pimg) => {
		setAllImages(img);
		setPreviewImg(pimg);
	};

	return createClick ? (
		<Fade in>
			<Box className="creating-post-box" id="create-post-box">
				<Box className="user-details">
					<Avatar src={userImage} />
					<Typography className="user-name">
						{/* {communityDetails &&
              communityDetails?.communityData?.owner_user_full_name} */}
						{user?.OauthUserData?.user_name}
					</Typography>
				</Box>

				<Box className="text-field-grid post-title-input">
					<Formik
						initialValues={{
							title: '', // text: ''
						}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required(t('validation.required_message')), // text: Yup.string().required(t('validation.required_message'))
						})}
						onSubmit={(requestData) => {
							createCommunityPost(requestData);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12} className="text-field-grid">
										<MatrixTextField
											error={Boolean(touched.title && errors.title)}
											fullWidth
											helperText={
												touched.title ? (
													touched.title && (
														<Box disply="flex" justifyContent="center">
															{errors.title}
															<span className="field-character-count">{values.title.length}/150</span>
														</Box>
													)
												) : (
													<span className="field-character-count">{values.title.length}/150</span>
												)
											}
											variant="filled"
											InputProps={{
												maxLength: 500,
												endAdornment: touched.title && errors.title ? <ErrorIcon /> : null,
											}}
											label={t('community.post_title')}
											placeholder={t('community.post_title')}
											className="matrix-textfield"
											size="small"
											name="title"
											onBlur={handleBlur}
											onChange={handleChange}
											inputProps={{ maxLength: 150 }}
											value={values.title}
										/>
									</Grid>
									{/* <Grid item xs={12} md={12}>
                    <Box className="cp-restriction-box">
                      <Typography className="restriction-txt">
                        {t('community.restriction_txt')}
                      </Typography>

                      <Box className="restriction-chips">
                        <Grid container>
                          {restTypeList?.map((data, index) => {
                            return (
                              <Grid item key={index}>
                                <Chip
                                  size="small"
                                  className={
                                    selected.indexOf(data?.id) !== -1
                                      ? 'rest-chip checked-chip'
                                      : 'rest-chip'
                                  }
                                  icon={
                                    selected.indexOf(data?.id) !== -1 ? (
                                      <FilterCheckIcon />
                                    ) : null
                                  }
                                  label={data?.name}
                                  onClick={handleToggle(data?.id)}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Box>
                  </Grid> */}
									<Grid item xs={12} md={12} className="text-field-grid cp-cnt">
										<Box className="custome-text-editior">
											<Editor
												handleBeforeInput={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													if (val && textLength >= maxLength) {
														return 'handled';
													}

													return 'not-handled';
												}}
												handlePastedText={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													return val.length + textLength >= maxLength;
												}}
												mention={{
													separator: ' ',
													trigger: '@',
													suggestions: allUsersList,
												}}
												editorState={text}
												toolbarCustomButtons={[
													<CustomOption
														setImageList={setImageList}
														deletedItemArray={deletedItemArray}
														token={token}
													/>,
												]}
												wrapperClassName="demo-wrapper"
												editorClassName="demo-editor"
												onEditorStateChange={(editorState) => {
													handleChangeEditior(editorState);
												}}
												placeholder={t('community.text')}
												toolbar={{
													options: ['link'], // HIDE-MVP: CM-75 CM-33
													//options: ['inline', 'link', 'textAlign', 'list'],
													// inline: {
													// 	inDropdown: false,
													// 	className: 'demo-option-custom',
													// 	options: ['bold', 'italic', 'underline'],
													// 	bold: {
													// 		icon: BoldSvg,
													// 	},
													// 	italic: {
													// 		icon: ItalicSvg,
													// 	},
													// 	underline: {
													// 		icon: UnderlineSvg,
													// 	},
													// },
													// textAlign: {
													// 	options: ['left', 'right', 'center'],
													// 	left: {
													// 		icon: AlignLeftSvg,
													// 	},
													// 	right: {
													// 		icon: AlignRightSvg,
													// 	},
													// 	center: {
													// 		icon: AlignCenterSvg,
													// 	},
													// },
													// list: {
													//   inDropdown: false,
													//   className: undefined,
													//   component: undefined,
													//   dropdownClassName: undefined,
													//   options: ['ordered'],
													//   ordered: {
													//     icon: NumberedSvg,
													//   },
													// },
													link: {
														inDropdown: false,
														className: undefined,
														component: undefined,
														dropdownClassName: undefined,
														options: ['link'],
														link: {
															icon: LinkSvg,
														},
													},
													image: {
														icon: UploadIcon,
														className: 'demo-option-custom',
														popupClassName: 'demo-popup-custom',
													},
												}}
											/>
											<span className="field-character-count" style={{ paddingRight: '18px', marginTop: '4px' }}>
												{`${charcterLimit}/${maxLength}`}
											</span>
											<span className="editior-error-message">{textError}</span>
										</Box>
									</Grid>
									<Grid item xs={12} md={12} className="image-wrapper">
										<Box className="image-wrapper-inner">
											{allImages.map((imageSrc, index) => {
												return showItems(imageSrc, index);
											})}
										</Box>
									</Grid>
									{/* <Grid item xs={12} md={12} className="text-field-grid cp-cnt">
                    <MatrixTextField
                      error={Boolean(touched.text && errors.text)}
                      fullWidth
                      helperText={
                        touched.text ? (
                          touched.text && (
                            <Box disply="flex" justifyContent="center">
                              {errors.text}
                              <span className="field-character-count">
                                {values.text.length}/500
                              </span>
                            </Box>
                          )
                        ) : (
                          <span className="field-character-count">
                            {values.text.length}/500
                          </span>
                        )
                      }
                      variant="filled"
                      InputProps={{
                        maxLength: 500,
                        endAdornment:
                          touched.text && errors.text ? <ErrorIcon /> : null
                      }}
                      label={t('community.text')}
                      placeholder={t('community.text')}
                      className="matrix-textfield"
                      size="small"
                      name="text"
                      multiline
                      rows={8}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 500 }}
                      value={values.text}
                    />
                  </Grid> */}
									<>
										{user?.isAdmin && (
											<Grid item xs={12} md={12}>
												<Box className="select-posting-type">
													<Typography className="check-heading">{t('community.verified_user_options')}</Typography>
													<List>
														<ListItem dense button onClick={() => setSponsoredPost(!sStatus)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={sStatus}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{
																		'aria-labelledby': 'checkbox-list-label',
																	}}
																/>
															</ListItemIcon>
															<ListItemText
																id="checkbox-list-label"
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{t('community.sponsored_post')}</Box>
																	</Box>
																}
															/>
														</ListItem>
													</List>
												</Box>
											</Grid>
										)}

										<Grid item xs={12} md={12}>
											<Box className="select-posting-type">
												<Typography className="check-heading">{t('community.admin_options')}</Typography>
												<List>
													{typeList.map((type, index) => {
														const labelId = `checkbox-list-label-${type?.id}`;

														return type.id === 1 ? (
															user?.isAdmin ? (
																<ListItem
																	key={index}
																	role={undefined}
																	dense
																	button
																	onClick={checkHandleToggle(type?.id)}
																>
																	<ListItemIcon>
																		<Checkbox
																			icon={<CheckBoxIcon />}
																			checkedIcon={<CheckedBoxIcon />}
																			edge="start"
																			checked={checked.indexOf(type?.id) !== -1}
																			tabIndex={-1}
																			disableRipple
																			disableTouchRipple
																			disableFocusRipple
																			inputProps={{ 'aria-labelledby': labelId }}
																		/>
																	</ListItemIcon>
																	<ListItemText
																		id={labelId}
																		primary={
																			<Box className="type-text-block">
																				<Box className="label-name">{type?.name}</Box>
																			</Box>
																		}
																	/>
																</ListItem>
															) : (
																<></>
															)
														) : (
															<ListItem key={index} role={undefined} dense button onClick={checkHandleToggle(type?.id)}>
																<ListItemIcon>
																	<Checkbox
																		icon={<CheckBoxIcon />}
																		checkedIcon={<CheckedBoxIcon />}
																		edge="start"
																		checked={checked.indexOf(type?.id) !== -1}
																		tabIndex={-1}
																		disableRipple
																		disableTouchRipple
																		disableFocusRipple
																		inputProps={{ 'aria-labelledby': labelId }}
																	/>
																</ListItemIcon>
																<ListItemText
																	id={labelId}
																	primary={
																		<Box className="type-text-block">
																			<Box className="label-name">{type?.name}</Box>
																		</Box>
																	}
																/>
															</ListItem>
														);
													})}
												</List>
											</Box>
										</Grid>
									</>
									<Grid item xs={12} md={12}>
										<Button
											fullWidth
											variant="contained"
											type="submit"
											disabled={isLoading}
											className="create-post-btn"
										>
											{t('button_text.create')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
		</Fade>
	) : user ? (
		<Box className="create-post-show-box">
			<Avatar src={userImage} />
			<Box
				onClick={() => {
					communityDetails?.communityData?.status === 'active' &&
						(verificationWall?.isPost
							? postFlag && flag && setCreateClick(true)
							: isJoin
							? setCreateClick(true)
							: setApiMessage('success', 'You must be verified to make this action'));
				}}
				// onClick={() => {
				//   verificationWall?.isPost
				//     ? !isView && setCreateClick(true)
				//     : isJoin
				//       ? setCreateClick(true)
				//       : setApiMessage(
				//         'success',
				//         'You must be verified to make this action'
				//       );
				// }}
				className="create-post-text"
			>
				{t('newsfeed.create_post')}
			</Box>
		</Box>
	) : null;
};

export default CreatePost;
