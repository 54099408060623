import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdminUsers from 'src/views/component/admin/users';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
	},
});

const AdminUserView = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Users">
			<AdminUsers />
		</Page>
	);
};

export default AdminUserView;
