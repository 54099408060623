import { Box, Typography } from '@material-ui/core';
import StickiedCommunityPost from 'src/views/component/UI/StickiedCommunityPost';
import { ReactComponent as PinnedIcon } from 'src/assets/images/icons/community/stickied-icon.svg';
import './stickied-post.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const StickiedPost = ({
	isView,
	handleDeletePost,
	updateCommunityPost,
	updatePostData,
	cancelBtnClick,
	addVote,
	addLike,
	user,
	editAccess,
	closeCommunity,
	reactionData,
	isJoin,
	updateCommunityPostDetails,
}) => {
	const { t } = useTranslation();

	const communityPostList = useSelector((state) => state.Combat.communityPostList);

	const Slength = communityPostList && communityPostList?.stickied_post_list?.length;

	// console.log(Slength, "111111");
	// console.log('communityPostList', communityPostList?.stickied_post_list?.length)
	return (
		<Box className="stickied-post-container">
			<Box className="stickied-box">
				{communityPostList && communityPostList?.stickied_post_list?.length !== 0 && (
					<Box className="box-header">
						<PinnedIcon />
						<Typography className="box-title">{t('community.stickied_post')}</Typography>
					</Box>
				)}
				{/* {communityPostList &&
          communityPostList?.stickied_post_list?.length === 0 && (
            <Typography className="no-post-text">
              {t('community.no_post_txt')}
            </Typography>
          )} */}
				<Box
					className={
						Slength > 2 ? 'post-conatainer community-post-sec post-scroll' : 'post-conatainer community-post-sec'
					}
				>
					{
						communityPostList && communityPostList?.stickied_post_list?.length > 0 && (
							<>
								{communityPostList?.stickied_post_list.map((item, index) => {
									{
										/* console.log('datadatadata', item); */
									}
									var postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');
									var postImage = item?.PostLists.find((d) => d?.Item?.type === 'image');

									return (
										<>
											<StickiedCommunityPost
												fetchPostDetails={updateCommunityPostDetails}
												key={index}
												postName={item?.name}
												topVal={item?.total_vote_up}
												downVal={item?.total_vote_down}
												date={
													item?.timestamp_created !== null &&
													moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
												}
												postedBy={item?.user_full_name}
												isView={isView}
												contentText={postContent?.Item?.data}
												contentImg={postImage?.Item?.data}
												comment={item?.total_comments}
												referrals={item?.total_referrals}
												shares={item?.total_shares}
												reactions={item?.total_likes}
												data={item}
												user={user}
												postUserId={item?.user_id}
												editAccess={editAccess}
												// PostId={item?.user_id}
												handleDeletePost={handleDeletePost}
												updateCommunityPost={updateCommunityPost}
												updatePostData={updatePostData}
												cancelBtnClick={cancelBtnClick}
												addVote={addVote}
												addLike={addLike}
												userURI={item?.user_meta?.data}
												closeCommunity={closeCommunity}
												reactionData={reactionData}
												likedStatus={item?.like_status}
												isJoin={isJoin}
											/>
										</>
									);
								})}
							</>
						)
						// : (
						//   <Typography className="no-post-text">
						//     {t('community.no_post_txt')}
						//   </Typography>
						// )
					}
				</Box>
			</Box>
		</Box>
	);
};

export default StickiedPost;
