import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
// import * as Yup from 'yup';
// import moment from 'moment';
import {
	Box,
	Button,
	Chip,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Grid,
	MenuItem,
	Typography,
	ListItem,
	ListItemIcon,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import { countries } from 'country-data';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocompletes, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { URLS } from 'src/helpers/constants/urls';
import CombatDialog from 'src/views/component/UI/dialogBox';
import ClaimProfile from 'src/views/component/UI/ClaimProfile';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as InfoIcon } from 'src/assets/images/icons/info-icon.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import { ReactComponent as VerifiedUser } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as GhostProfile } from 'src/assets/images/icons/ghost-profile.svg';
import './additional.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
// import { VerifiedUser } from '@material-ui/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const AdditionalDetails = ({ message, dataa, additionDetails, token }) => {
	const prof = dataa?.additional_details;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [limit] = useState(5); //setList
	const [similarData, setSimilarData] = useState(prof?.similarData || []);
	const [clickedId, setClickedId] = useState(null);
	const [open, setOpen] = useState(false);
	const [searchAddress, SetSearchAddress] = useState(prof?.address || '');
	const [city, setCity] = useState(prof?.city || '');
	const [state, setState] = useState(prof?.state || '');
	const [country, setCountry] = useState(prof?.country || '');
	const [lattitude, setLattitude] = useState(prof?.lat || '');
	const [longitude, setLongitude] = useState(prof?.long || '');
	const [allSimilarData, setAllSimilarData] = useState([]);
	const [selectedClaims, setSelecetedclaims] = useState(prof?.claimProfileID || null);
	const [measurment, setMeasurement] = useState(prof?.measurment || '');
	// const [weightClasses, setWeightClasses] = useState([]);
	const [checkedItem, setCheckedItem] = useState([]);
	const formikRef = useRef();
	const isProfileChecked = selectedClaims === clickedId ? true : false;

	// console.log('checkedItem', checkedItem)
	const filter = createFilterOptions();
	const fetchSimilarProfile = async (page) => {
		try {
			let { status, data } = await axiosInstance.get(URLS.searchUser, {
				params: {
					first_name: dataa?.profile_details?.firstName,
					last_name: dataa?.profile_details?.surName,
					page: page,
					limit: limit,
				},
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			if (status === 200) {
				let getUnique = data?.getUserBySearch.filter((x) => {
					return similarData.map((y) => y.id).indexOf(x?.id) === -1;
				});
				let allPrevData = [...similarData, ...getUnique];

				// setSimilarData(allPrevData);
				setAllSimilarData(allPrevData);
				setSimilarData(data?.getUserBySearch);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// const fetchWeightClasses = async () => {
	//   try {
	//     let { status, data } = await axiosInstance.get(URLS.getWeightClasses, {
	//       headers: {
	//         Authorization: `Bearer ${token}`,
	//         'Content-Type': 'application/json'
	//       }
	//     });

	//     if (status === 200) {
	//       setWeightClasses(data?.getMetaWeightClassesData);
	//     }
	//   } catch (error) {
	// TODO: Add logging
	// }
	// };

	const AddressHandler = (address) => {
		SetSearchAddress(address);
	};

	const changeMeasurementHandler = (val) => {
		setMeasurement(val);
	};

	// COUNT AGE
	const getAge = (d1) => {
		let d2 = new Date();
		var diff = d2.getTime() - d1.getTime();

		return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	};

	const checkClickedOnId = (value, check) => {
		if (check) {
			setSelecetedclaims(value);
		} else if (selectedClaims === value) {
			setSelecetedclaims(null);
		} else {
			setSelecetedclaims(value);
		}
	};
	const handleToggle = (value) => () => {
		const currentIndex = checkedItem.indexOf(value);
		const newChecked = [...checkedItem];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			// main.splice(currentIndex, 1);
		}

		setCheckedItem(newChecked);
	};
	const HandleSelectAddress = (address) => {
		geocodeByAddress(address).then((results) => {
			setCity('');
			setState('');
			setLattitude('');
			setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			SetSearchAddress(name);
			setLattitude(geoCode.lat());
			setLongitude(geoCode.lng());

			address.map((val) => {
				return val.types.map((com) => {
					switch (com) {
						case 'country':
							setCountry(val.long_name);

							return val.long_name;
						case 'administrative_area_level_1':
							setState(val.long_name);

							return val.long_name;
						case 'administrative_area_level_2':
							setCity(val.long_name);

							return val.long_name;
						default:
							return val;
					}
				});
			});
		});
	};

	// DIALOG HANDLERS
	const combatDialogHandler = (id) => {
		setOpen(true);
		setClickedId(id);
	};

	const closeDialog = () => {
		setOpen(false);
	};

	const backHandler = () => {
		if (dataa?.setup_org_check) {
			navigate('/profile/organisation');
		} else {
			navigate('/profile/type');
		}
	};

	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchSimilarProfile(currentPage - 1);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1);
				fetchSimilarProfile(currentPage + 1);
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchSimilarProfile(Number(page));
		}
	};

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setFieldValue('address', searchAddress);
			formikRef.current.setFieldValue('city', city);
			formikRef.current.setFieldValue('state', state);
			formikRef.current.setFieldValue('country', country);
		}
	}, [searchAddress, city, state, country]);
	// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	useEffect(() => {
		fetchSimilarProfile(currentPage);
		// fetchWeightClasses();
		// eslint-disable-next-line
	}, []);

	var USER_NAME =
		allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_first !== null &&
		allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_last !== null
			? `${allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_first} ${
					allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_last
			  }`
			: allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_first !== null
			? allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_first
			: allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_last !== null
			? allSimilarData?.filter((x) => x?.id === selectedClaims)[0]?.name_last
			: '';

	const selectedUserList = _.intersectionWith(allSimilarData, checkedItem, (o, num) => o.id == num);

	return (
		<Box className="profile-details-form additional-details">
			{similarData?.length > 0 && (
				<>
					<Typography className="profile-details-title">{t('additional_details.title')}</Typography>
					<Typography className="additional-desc">{t('additional_details.description')}</Typography>
					<Typography className="matching-p-txt">
						{t('additional_details.matching_profile')} ({similarData?.length || 0})
					</Typography>
				</>
			)}

			<Box className="profile-list">
				{/* {selectedClaims !== null ? (
          <Box className="selected-pl-is">
            <Box className="pl-i">
              <Checkbox
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckedBoxIcon />}
                edge="start"
                checked={true}
                tabIndex={-1}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={() => {
                  checkClickedOnId(selectedClaims);
                }}
              />
              <Typography
                onClick={() => {
                  combatDialogHandler(selectedClaims);
                }}
              >
                {USER_NAME}
              </Typography>
            </Box>
          </Box>
        ) : null} */}
				<Box className="selected-pl-is">
					{selectedUserList?.map((obj, index) => {
						return (
							<Box className={obj?.claimStatus === 'locked' ? 'opacity-0 pl-i' : 'pl-i'} key={index}>
								<ListItem key={index} role={undefined} dense button onClick={handleToggle(obj?.id)}>
									<ListItemIcon>
										<Checkbox
											icon={<CheckBoxIcon />}
											checkedIcon={<CheckedBoxIcon />}
											edge="start"
											checked={checkedItem.indexOf(obj?.id) !== -1}
											tabIndex={-1}
											disableRipple
											disableTouchRipple
											disableFocusRipple
											onClick={() => {
												checkClickedOnId(obj?.id);
											}}
										/>
										<Typography
											className="user-name"
											onClick={() => {
												combatDialogHandler(obj?.id);
											}}
										>
											{`${obj.name_first ? obj.name_first : ''} ${obj.name_last ? obj.name_last : ''}${
												!obj.name_first && !obj.name_last ? 'Combat Matrix' : ''
											}`}
											{obj?.claimStatus === 'locked' ? (
												<VerifiedUser />
											) : obj?.claimStatus === 'active' ? (
												<GhostProfile />
											) : (
												''
											)}
										</Typography>
									</ListItemIcon>
								</ListItem>
							</Box>
						);
					})}
				</Box>
				{/* {similarData
          .filter(x => x.id !== selectedClaims) */}
				{similarData?.map((obj, index) => {
					return (
						<Box className={obj?.claimStatus === 'locked' ? 'opacity-0 pl-i' : 'pl-i'} key={index}>
							<ListItem key={index} role={undefined} dense button onClick={handleToggle(obj?.id)}>
								<ListItemIcon>
									<Checkbox
										icon={<CheckBoxIcon />}
										checkedIcon={<CheckedBoxIcon />}
										edge="start"
										checked={checkedItem.indexOf(obj?.id) !== -1}
										// checked={selectedClaims === obj?.id ? true : false}
										tabIndex={-1}
										disableRipple
										disableTouchRipple
										disableFocusRipple
										onClick={() => {
											checkClickedOnId(obj?.id);
										}}
									/>
									<Typography
										className="user-name"
										onClick={() => {
											combatDialogHandler(obj?.id);
										}}
									>
										{`${obj.name_first ? obj.name_first : ''} ${obj.name_last ? obj.name_last : ''}${
											!obj.name_first && !obj.name_last ? 'Combat Matrix' : ''
										}`}
										{obj?.claimStatus === 'locked' ? (
											<VerifiedUser />
										) : obj?.claimStatus === 'active' ? (
											<GhostProfile />
										) : (
											''
										)}
									</Typography>
								</ListItemIcon>
							</ListItem>
						</Box>
					);
				})}
				<CustomPagination
					paginationArray={similarData}
					currentPage={currentPage}
					list={limit}
					handlePaginationButtonClick={handlePaginationButtonClick}
					handlePaginationClick={handlePaginationClick}
					totalRecord={totalRecord}
				/>
			</Box>

			{/* ADDITIONAL DETAILS FORM */}
			<Box className="additional-form">
				<Typography className="additional-tle">{t('additional_details.optional_details')}</Typography>
				<Typography className="addtext-desc">{t('additional_details.additional_desc')}</Typography>

				<Formik
					innerRef={formikRef}
					initialValues={{
						dob: prof?.dob || '',
						age: prof?.age || '',
						phone: prof?.phone || '',
						address: prof?.address || '',
						country: prof?.country || '',
						bio: prof?.bio || '',
						lower_weight: prof?.lower_weight || '',
						height_weight: prof?.height_weight || '',
						fighting_out_of: prof?.fighting_out_of || '',
						// weight_class: prof?.weight_class || []
					}}
					// validationSchema={Yup.object().shape({
					//   dob: Yup.string()
					//     .required(t('validation.required_message'))
					//     .test('dob', t('personal_details.wrong_dob'), function (dob) {
					//       return (
					//         dob <
					//         moment(
					//           new Date(new Date()).setDate(new Date(new Date()).getDate())
					//         ).format()
					//       );
					//     }),
					//   age: Yup.string()
					//     .required(t('validation.required_message'))
					//     .test('dob', t('personal_details.wrong_age'), function (age) {
					//       return Number(age) === getAge(new Date(this.parent.dob));
					//     }),
					//   // phone: Yup.string().required(t('validation.required_message')),
					//   phone: Yup.string()
					//     .required(t('validation.required_message'))
					//     .matches(phoneRegExp, 'Phone number is not valid'),
					//   address: Yup.string().required(
					//     t('validation.required_message')
					//   ),
					//   country: Yup.string().required(
					//     t('validation.required_message')
					//   ),
					//   bio: Yup.string().required(t('validation.required_message')),
					//   fighting_out_of: Yup.string().required(
					//     t('validation.required_message')
					//   ),
					//   weight_classes: Yup.string().required(
					//     t('validation.required_message')
					//   )
					// })}
					onSubmit={(requestData) => {
						let values = {
							...requestData,
							claimProfileID: checkedItem,
							similarData: similarData,
							city: city,
							state: state,
							lat: lattitude,
							long: longitude,
							measurment: measurment,
						};

						additionDetails(values);
						navigate('/profile/confirm');
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
									<Grid item xs={12} sm={12} md={10} lg={10} className="date-field">
										<MatrixTextField
											error={Boolean(touched.dob && errors.dob)}
											fullWidth
											helperText={touched.dob && errors.dob}
											variant="filled"
											InputProps={{
												endAdornment: touched.dob && errors.dob ? <ErrorIcon /> : null,
											}}
											label={`${t('personal_details.dob')}`}
											placeholder={`${t('personal_details.dob')}`}
											className="matrix-textfield"
											size="small"
											name="dob"
											type="date"
											onBlur={handleBlur}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={(e) => {
												let vall = getAge(new Date(e.target.value));

												handleChange(e);
												setFieldValue('age', vall > 0 && vall < 150 ? vall : '');
											}}
											value={values.dob}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} className="age-field">
										<MatrixTextField
											error={Boolean(touched.age && errors.age)}
											fullWidth
											helperText={touched.age && errors.age}
											variant="filled"
											InputProps={
												({
													endAdornment: touched.age && errors.age ? <ErrorIcon /> : null,
												},
												{ readOnly: true })
											}
											label={t('personal_details.age')}
											placeholder={t('personal_details.age')}
											className="matrix-textfield"
											size="small"
											name="age"
											onBlur={handleBlur}
											onChange={(e) => {
												const re = /^[0-9\b]+$/;

												if (e.target.value === '' || re.test(e.target.value)) {
													handleChange(e);
												}
											}}
											inputProps={{ maxLength: 4 }}
											value={values.age}
										/>
									</Grid>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.phone && errors.phone)}
										fullWidth
										helperText={touched.phone && errors.phone}
										variant="filled"
										InputProps={{
											endAdornment: touched.phone && errors.phone ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.phone')}
										placeholder={t('personal_details.phone')}
										className="matrix-textfield"
										size="small"
										name="phone"
										onBlur={handleBlur}
										onChange={(e) => {
											const re = /^[0-9\b]+$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										inputProps={{ maxLength: 12 }}
										value={values.phone}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<PlacesAutocomplete value={searchAddress} onChange={AddressHandler} onSelect={HandleSelectAddress}>
										{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div>
												<MatrixTextField
													error={Boolean(touched.address && errors.address)}
													fullWidth
													helperText={touched.address && errors.address}
													variant="filled"
													InputProps={{
														endAdornment: touched.address && errors.address ? <ErrorIcon /> : null,
													}}
													label={t('personal_details.address')}
													placeholder={t('personal_details.address')}
													className="matrix-textfield"
													size="small"
													name="address"
													{...getInputProps()}
													onBlur={handleBlur}
												/>

												<div className="autocomplete-dropdown-container">
													{suggestions.map((suggestion, index) => {
														const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? {
																	backgroundColor: '#fff',
																	color: '#000',
																	cursor: 'pointer',
															  }
															: {
																	backgroundColor: 'transparent',
																	cursor: 'pointer',
															  };

														return (
															<div
																key={index}
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
															>
																<MenuItem
																	disableGutters
																	dense
																	style={{
																		border: '1px solid #f2f2f2',
																		paddingLeft: '10px',
																		paddingRight: '10px',
																	}}
																>
																	{suggestion.description}
																</MenuItem>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</PlacesAutocomplete>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.country && errors.country)}
										fullWidth
										helperText={touched.country && errors.country}
										variant="filled"
										InputProps={{
											endAdornment: touched.country && errors.country ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.country')}
										placeholder={t('personal_details.country')}
										className="matrix-textfield"
										size="small"
										name="country"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.country}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.bio && errors.bio)}
										fullWidth
										helperText={touched.bio && errors.bio}
										variant="filled"
										InputProps={{
											endAdornment: touched.bio && errors.bio ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.bio')}
										placeholder={t('personal_details.bio')}
										className="matrix-textfield"
										size="small"
										name="bio"
										multiline
										rows={10}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.bio}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<hr className="details-hr" />
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<Autocompletes
										id="country-list"
										options={countries.all.map((x) => x?.name)}
										value={values.fighting_out_of}
										// onChange={(e, newValue) => {
										//   setFieldValue('fighting_out_of', newValue);
										// }}
										onChange={(e, newValue) => {
											setFieldValue('fighting_out_of', newValue);
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										filterOptions={(options, params) => {
											const filtered = filter(options, params);
											const { inputValue } = params;

											// Suggest the creation of a new value
											const isExisting = options.some((option) => inputValue === option.name);

											if (inputValue !== '' && !isExisting) {
												filtered.push({
													inputValue,
													name: `Add "${inputValue}"`,
												});
											}

											return filtered;
										}}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === 'string') {
												return option;
											}

											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.inputValue;
											}

											// Regular option
											return String(option.name);
										}}
										// renderOption={(props, option) => {
										//   return (<span {...props} >
										//     {props}
										//   </span>
										//   );
										// }}
										renderInput={(params) => (
											<MatrixTextField
												{...params}
												error={Boolean(touched.fighting_out_of && errors.fighting_out_of)}
												fullWidth
												helperText={touched.fighting_out_of && errors.fighting_out_of}
												variant="filled"
												label={t('personal_details.fighting_out_of')}
												placeholder={t('personal_details.fighting_out_of')}
												className="matrix-textfield"
												size="small"
											/>
										)}
									/>
								</Grid>

								<Grid item xs={12} md={12} className="text-field-grid">
									<hr className="details-hr" />
								</Grid>

								{/* <Grid item xs={12} md={12} className="text-field-grid">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={values.weight_class}
                    onChange={(event, newValue) => {
                      setFieldValue('weight_class', newValue);
                    }}
                    options={weightClasses.map(x => x?.name)}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          deleteIcon={<CancelIcon />}
                          className="weightclass-chip"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <MatrixTextField
                        {...params}
                        fullWidth
                        variant="filled"
                        label={t('myprofile.weight_class')}
                        placeholder={t('myprofile.weight_class')}
                        className="matrix-textfield"
                        size="small"
                      />
                    )}
                  />
                </Grid> */}

								{/* WEIGHT RANGE */}
								<Grid item xs={12} md={12} className="text-field-grid">
									<Typography className="weight-range-title">
										{t('search.weight_range')}
										<LightTooltip
											placement="right"
											arrow
											style={{ maxWidth: 250 }}
											title={
												<>
													<Typography
														style={{
															fontSize: 14,
															marginBottom: 9,
															color: '#000',
															fontWeight: 600,
														}}
													>
														{t('search.weight_range')}
													</Typography>

													<Typography style={{ fontSize: 10, color: '#000' }}>
														{t('additional_details.weight_range_info')}
													</Typography>
												</>
											}
										>
											<InfoIcon style={{ marginLeft: 9 }} />
										</LightTooltip>
									</Typography>

									<Typography className="measurment-text">{t('search.select_unit')}</Typography>

									<FormGroup className="weighclass-measurments">
										<FormControlLabel
											control={
												<Checkbox
													icon={<CheckBoxIcon />}
													checkedIcon={<CheckedBoxIcon />}
													edge="start"
													checked={measurment === 'Kilograms'}
													tabIndex={-1}
													disableRipple
													disableTouchRipple
													disableFocusRipple
													onClick={() => {
														changeMeasurementHandler('Kilograms');
													}}
												/>
											}
											label={t('additional_details.kilograms')}
										/>

										<FormControlLabel
											control={
												<Checkbox
													icon={<CheckBoxIcon />}
													checkedIcon={<CheckedBoxIcon />}
													edge="start"
													checked={measurment === 'Pounds'}
													tabIndex={-1}
													disableRipple
													disableTouchRipple
													disableFocusRipple
													onClick={() => {
														changeMeasurementHandler('Pounds');
													}}
												/>
											}
											label={t('additional_details.pounds')}
										/>
									</FormGroup>
								</Grid>

								{/* LOWER WEIGHT */}
								<Grid item xs={12} md={12} className="text-field-grid">
									<MatrixTextField
										error={Boolean(touched.lower_weight && errors.lower_weight)}
										fullWidth
										helperText={touched.lower_weight && errors.lower_weight}
										variant="filled"
										InputProps={{
											endAdornment: touched.lower_weight && errors.lower_weight ? <ErrorIcon /> : null,
										}}
										label={t('search.lower_weight')}
										placeholder={t('search.lower_weight')}
										className="matrix-textfield"
										size="small"
										name="lower_weight"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.lower_weight}
									/>
								</Grid>

								{/* HEIGHEST WEIGHT */}
								<Grid item xs={12} md={12} className="text-field-grid" style={{ marginBottom: 18 }}>
									<MatrixTextField
										error={Boolean(touched.height_weight && errors.height_weight)}
										fullWidth
										helperText={touched.height_weight && errors.height_weight}
										variant="filled"
										InputProps={{
											endAdornment: touched.height_weight && errors.height_weight ? <ErrorIcon /> : null,
										}}
										label={t('search.upper_weight')}
										placeholder={t('search.upper_weight')}
										className="matrix-textfield"
										size="small"
										name="height_weight"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.height_weight}
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Box display="flex" justifyContent="space-between" className="additional-actions">
										<Button variant="contained" className="profile-type-sbmtbtn" onClick={backHandler}>
											{t('back')}
										</Button>
										<Button variant="contained" type="submit" className="profile-type-sbmtbtn">
											{t('next')}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>

			{/* ADD-EDIT FORM DIALOG */}
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={t('additional_details.title')}
				className={'claim-profile-dialog'}
				content={
					<ClaimProfile
						closeDialog={closeDialog}
						id={clickedId}
						isChecked={isProfileChecked}
						checkClickedOnId={checkClickedOnId}
					/>
				}
			/>
		</Box>
	);
};

export default AdditionalDetails;
