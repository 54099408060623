import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import ContentFightRecord from 'src/views/component/application/FightRecord';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const FightRecordPage = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Fight Record | Combat Matrix">
			<ContentFightRecord />
		</Page>
	);
};

export default FightRecordPage;
