import { Box, Container, Typography } from '@material-ui/core';
import './dashboard.scss';

const AdminDashboardView = () => {
	return (
		<Box className="admin-container-box">
			<Container maxWidth="lg">
				<Typography className="admin-page-haeding">Admin Dashboard</Typography>
			</Container>
		</Box>
	);
};

export default AdminDashboardView;
