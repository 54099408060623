import { useNavigate } from 'react-router-dom'; //useNavigate
import { Box, Typography, Link } from '@material-ui/core';
import FollowRequest from 'src/views/component/UI/RequestBlock';
import { ReactComponent as NotificationLogo } from 'src/assets/images/icons/noti-tras-logo.svg';
import './notification.scss';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';

const Notification = ({ name, image, request, requestData, notificationItem, readNotification, time, user }) => {
	const navigate = useNavigate();
	// let userData = requestData;

	// const redirectToUser = () => {
	//   userData?.data && navigate(`/user/${userData?.data}`);
	// };
	var notificationType =
		notificationItem && typeof notificationItem?.payload === 'string'
			? JSON.parse(notificationItem?.payload)
			: notificationItem?.payload;

	const notificationRedirect = (notificationType) => {
		var ACCOUNT_VERIFIED = 'account_verified';
		var FRIEND_REQUEST = 'friend_request';
		var FOLLOW_USER = 'follow_user';
		var COMMUNITY_CONNECT = 'community_connect';
		var COMMUNITY_ASSIGN_ROLE = 'community_assign_role';
		var POST_DELETE = 'post_delete';
		var POST_COMMENT = 'post_comment';

		switch (notificationType?.type) {
			case ACCOUNT_VERIFIED:
				// navigate(`/notification`)
				return `/notification`;
			case FRIEND_REQUEST:
				// navigate(`/user/${notificationType?.from_userId}`)
				return `/user/${notificationType?.from_userId}`;
			case FOLLOW_USER:
				// navigate(`/user/${notificationType?.from_userId}`)
				return `/user/${notificationType?.from_userId}`;
			case COMMUNITY_CONNECT:
				return `/communities`;
			case COMMUNITY_ASSIGN_ROLE:
				return `/communities`;
			default:
				// navigate(`/user/${notificationType?.from_userId}`)
				return `/user/${notificationType?.from_userId}`;
		}
	};

	var redirectPath =
		notificationType?.type === 'account_verified'
			? `/user/${user?.user_id}`
			: notificationType?.type === 'friend_request'
			? `/user/${notificationType?.from_userId}`
			: notificationType?.type === 'post_reaction'
			? `/post/${notificationType?.main_post_id}`
			: notificationType?.type === 'follow_user'
			? `/user/${notificationType?.from_userId}`
			: notificationType?.type === 'community_assign_role'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'post_delete'
			? `/communities`
			: notificationType?.type === 'accept_request'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'reject_request'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'block_request'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'mention_community'
			? `/post/${notificationType?.post_id}`
			: notificationType?.type === 'community_connect'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'community_connect'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'invite_community'
			? `/communities/${notificationType?.from_community_id}`
			: notificationType?.type === 'transfer_owner'
			? `/communities/${notificationType?.community_id}`
			: notificationType?.type === 'post_comment'
			? `/post/${notificationType?.main_post_id}`
			: notificationType?.type === 'mention_organisation'
			? `/post/${notificationType?.post_id}`
			: notificationType?.type === 'mention_user'
			? `/post/${notificationType?.post_id}`
			: notificationType?.type === 'invite_organisation'
			? `/organisation-details/${notificationType?.organisation_id}`
			: notificationType?.type === 'follow_organization'
			? `/organisation-details/${notificationType?.organisation_id}`
			: notificationType?.type === 'unblock_organisation'
			? `/organisation-details/${notificationType?.organisation_id}`
			: notificationType?.type === 'block_organisation'
			? `/organisation-details/${notificationType?.organisation_id}`
			: notificationType?.type === 'organisation_assign_role'
			? `/organisation-details/${notificationType?.organisation_id}`
			: notificationType?.type === 'chat_message'
			? `/messages/${notificationType?.group_id}`
			: notificationType?.type === 'event_invitation'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_reaction' && notificationType?.parent_event_id
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`
			: notificationType?.type === 'event_reaction'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_vote' && notificationType?.parent_event_id
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`
			: notificationType?.type === 'event_vote'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_fight_reaction' && notificationType?.parent_event_id
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`
			: notificationType?.type === 'event_fight_reaction'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_fight_vote' && notificationType?.parent_event_id
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.parent_event_id}`
			: notificationType?.type === 'event_fight_vote'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_admin'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'event_comment'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'join_rsvp'
			? `/events/details/${notificationType?.main_post_id}?eventId=${notificationType?.event_id}`
			: notificationType?.type === 'post_vote'
			? `/post/${notificationType?.main_post_id}`
			: `/communities`;

	// community_connect
	// community_assign_role
	// post_delete
	// post_comment
	// account_verified
	// follow_user
	// friend_request

	var tabValue =
		notificationType?.type === 'transfer_owner'
			? 'members'
			: notificationType?.type === 'community_connect'
			? 'members'
			: notificationType?.type === 'accept_request'
			? 'members'
			: notificationType?.type === 'invite_community'
			? 'discussions'
			: notificationType?.type === 'community_assign_role'
			? 'members'
			: notificationType?.type === 'reject_request'
			? 'discussions'
			: notificationType?.type === 'block_request'
			? 'members'
			: notificationType?.type === 'unblock_request'
			? 'members'
			: 'discussions';

	var nestedTab = notificationType?.type === 'community_connect' ? 'request' : 'member';
	var fromUserId = notificationType?.type === 'invite_community' ? notificationType?.from_userId : '';
	var memeberTabValue = notificationType?.type === 'transfer_owner' ? 'transfer' : 'accepted';

	return (
		<Box
			className="notification-item"
			style={{ background: notificationItem?.status === 'unread' && '#438ac1' }}
			onClick={() => {
				readNotification(notificationItem?.id);
				navigate(redirectPath, {
					state: {
						modalText: name,
						memeberTabValue: memeberTabValue,
						showSelectedTab: tabValue,
						showNestedTab: nestedTab,
						passUserId: fromUserId,
						fromWhere: notificationType?.type,
						// comment_id: notificationItem?.id,
						post_id: notificationType?.post_id,
						comment_id:
							notificationType?.type === 'post_reaction' || notificationType?.type === 'post_vote'
								? notificationType?.post_comment_id
								: notificationType?.post_id,
						post_comment_id: notificationType?.post_comment_id,
						category_id: notificationType?.meta_category_id,
						fight_id: notificationType?.event_id,
					},
				});
				// notificationRedirect(notificationType)
				// notificationType?.type && notificationType?.type !== "account_verified" && redirectToUser()
			}}
		>
			<Link to={{ pathname: redirectPath, state: { showSelectedTab: tabValue } }}>
				<Box
					className="noti-left"
					// onClick={() => {
					//   navigate(redirectPath, { state: { showSelectedTab: tabValue } })
					// }}
				>
					<Box className="notification-image">
						<NotificationLogo />
						{/* <Avatar src={image} /> */}
					</Box>
					<Box className="notification-description">
						<Typography className="desc-txt">
							{renderHTML(
								anchorme({
									input: name.toString(),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
						</Typography>
						<Typography
							style={{
								color: notificationItem?.status === 'unread' ? '#D9E8F3' : '#9D9D9D',
							}}
							className="time-ago"
						>
							{time}
						</Typography>
					</Box>
				</Box>
			</Link>
			{request && (
				<Box className="noti-right">
					<FollowRequest acceptText={'Accept'} ignoreText={'Ignore'} id={12} onAccept={() => {}} onIgnore={() => {}} />
				</Box>
			)}
		</Box>
	);
};

export default Notification;
