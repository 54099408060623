import { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../RightSide.scss';

const GeneralSetting = ({ user }) => {
	const { t } = useTranslation();

	return (
		<Box className="General-setting-sec">
			<Box className="header-sec">
				<Typography className="header-text">{t('acount_setting.General_Account_Settings')}</Typography>
			</Box>
			<Grid container className="content-sec">
				<Grid xs={2} className="content-title">
					{t('acount_setting.name')}
				</Grid>
				<Grid xs={10} className="content-name">
					{user?.OauthUserData?.user_name}
				</Grid>
			</Grid>
		</Box>
	);
};
export default GeneralSetting;
