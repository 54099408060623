import { useState } from 'react';
import { Box, Button, Checkbox, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './report.scss';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useTranslation } from 'react-i18next';

const ReportProfile = ({ reportReasonList, type, receiverId, handleClose }) => {
	const { t } = useTranslation();

	const [checked, setChecked] = useState([]);
	// const typeList = [
	//   { id: 0, name: t('report_type.sexual_content') },
	//   { id: 1, name: t('report_type.sensitive_information') },
	//   { id: 2, name: t('report_type.self_harm') },
	//   { id: 2, name: t('report_type.copyright') },
	//   { id: 2, name: t('report_type.incorrect') }
	// ];

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleClick = async () => {
		var reasonArray = [];

		checked.map((item, i) => {
			var object = {
				reported_reason_id: item,
			};

			return (reasonArray[i] = object);
		});
		var requestParams = {
			receiver_type: type,
			receiver_id: receiverId,
			reasonArr: reasonArray,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addReport, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Box className="report-profile-dialog">
			<Typography className="report-title-txt">{t('report_text')}</Typography>

			{/* REPORT FOR TYPE LIST */}
			<Box className="select-report-for">
				<List>
					{reportReasonList.map((type, index) => {
						const labelId = `checkbox-list-label-${type?.id}`;

						return (
							<ListItem key={index} role={undefined} dense button onClick={handleToggle(type?.id)}>
								<ListItemIcon>
									<Checkbox
										icon={<CheckBoxIcon />}
										checkedIcon={<CheckedBoxIcon />}
										edge="start"
										checked={checked.indexOf(type?.id) !== -1}
										tabIndex={-1}
										disableRipple
										disableTouchRipple
										disableFocusRipple
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={
										<Box className="type-text-block">
											<Box className="label-name">{type?.title}</Box>
										</Box>
									}
								/>
							</ListItem>
						);
					})}
				</List>
			</Box>

			{/* REPORT BUTTON */}
			<Box className="dialog-submit-button dialog-sticky-btn">
				<Button variant="contained" type="submit" className="dialog-submit" onClick={() => handleClick()}>
					{t('report')}
				</Button>
			</Box>
		</Box>
	);
};

export default ReportProfile;
