import {
	// Avatar,
	Box,
	// Button,
	// Typography,
	// Grid
} from '@material-ui/core';
import './DisciplineRank.scss';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import DisciplinerankData from './DisciplinerankData';

const DisciplineRank = ({ data, createUserDisciplineRankEndorsement, user, deleteUserDisciplineRankEndorsement }) => {
	return (
		<Box className="discipline-rank-sec">
			{data && data?.profile_update_data?.records?.length > 0 ? (
				<>
					{data?.profile_update_data?.records.map((sitem, index) => {
						return (
							<DisciplinerankData
								sitem={sitem}
								createUserDisciplineRankEndorsement={createUserDisciplineRankEndorsement}
								deleteUserDisciplineRankEndorsement={deleteUserDisciplineRankEndorsement}
								user={user}
							/>
						);
					})}
				</>
			) : (
				'No data'
			)}
			{/* <Box className='discipline-rank-data'>
                <Box className='cover-img-sec'>
                    <Avatar variant='square' className='cover-img' />
                </Box>
                <Avatar className='profile-img' />

                <Typography className='game-name'>
                    Karate
                </Typography>
                <Box className='updated-discipline-rank'>
                    <Typography className='discpline-rank-title'>
                        {t('myprofile.rank')}
                    </Typography>
                    <Typography className='discpline-rank-data'>
                        5th Kyu - Blue belt
                    </Typography>
                </Box>
                <Box className='updated-discipline-rank'>
                    <Typography className='discpline-rank-title'>
                        {t('myprofile.granted_by')}
                    </Typography>
                    <Typography className='discpline-rank-data'>
                        The Creation
                    </Typography>
                </Box>
                <Box className='updated-discipline-rank'>
                    <Typography className='discpline-rank-title' >
                        {t('myprofile.granted_at')}
                    </Typography>
                    <Typography className='discpline-rank-data'>
                        The Beginning
                    </Typography>
                </Box>
                <Box className='updated-discipline-rank'>
                    <Typography className='discpline-rank-title'>
                        {t('myprofile.date_granted')}
                    </Typography>
                    <Typography className='discpline-rank-data'>
                        31/12/2005
                    </Typography>
                </Box>
                <Grid item xs={12} md={12} className='endorsed-btn-sec'>
                    <Button
                        variant="contained"
                        fullWidth
                        className="endorsed-btn"
                    >
                        <FilterCheckIcon />
                        {t('myprofile.endorsed')}
                    </Button>
                </Grid>
            </Box> */}
		</Box>
	);
};
export default DisciplineRank;
