import { Box, Dialog, DialogTitle, Grow, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import './ui.scss';

const DialogBox = ({ handleClose, open, title, noTitle, content, className, onDialogTransitionEnd }) => {
	return (
		<Dialog
			className={`combat-dialog ${className}`}
			maxWidth="md"
			onClose={handleClose}
			open={open}
			TransitionComponent={Grow}
			TransitionProps={{
				onExited: onDialogTransitionEnd,
			}}
		>
			<Box className="dialog-content-box">
				<DialogTitle>
					<Box className="combat-dialog-title">
						<Typography className="dialog-title">
							{noTitle ? '' : title}
							<IconButton className="dialog-close" onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Typography>
					</Box>
				</DialogTitle>
			</Box>
			{content}
		</Dialog>
	);
};

export default DialogBox;
