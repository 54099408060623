import { useState } from 'react';
import {
	Box,
	// Button,
	Typography,
} from '@material-ui/core';
import CombatDialog from 'src/views/component/UI/dialogBox';
import AddFightRecord from './AddFightRecord';
import { useTranslation } from 'react-i18next';

const FightRecordBlock = () => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [title] = useState(''); //setTitle

	// const combatDialogHandler = (type, title) => {
	//   setOpen(true);
	//   setTitle(title);
	// };

	const closeDialog = () => {
		setOpen(false);
	};

	return (
		<>
			<Box className="fight-record-container">
				<Typography className="record-title">
					{t('myprofile.fight_record')}

					{/* <Button
            disableElevation
            disableTouchRipple
            disableFocusRipple
            disableRipple
            className="see-all-btn-right"
          >
            {t('see_all')}
          </Button> */}
				</Typography>

				{/* {isView ? null : (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="add-record-btn"
            onClick={() => {
              combatDialogHandler(
                'add',
                t('myprofile.add_fight_record')
              );
            }}
          >
            {t('myprofile.add')}
          </Button>
        )} */}

				<Typography className="cmng-cntr-txt">This feature is coming soon.</Typography>

				{/* <Box className="fight-record-mainbox">
          <FightRecord
            name={'Jason Ponet'}
            weightclass={'Lightweight'}
            date={'04 Nov 2017'}
            location={'Bangkok, Thailand'}
            result={'Loss'}
            round={1}
            time={'3:35'}
            method={'Soccer Kicks'}
          />

          <FightRecord
            name={'Will Chope'}
            weightclass={'Lightweight'}
            date={'25 Feb 2017'}
            location={'Bangkok, Thailand'}
            result={'Win'}
            round={''}
            time={''}
            method={'Unanimous Decision'}
          />

          <FightRecord
            name={'Asikerbai Jinensibieke'}
            weightclass={'Featherweight'}
            date={'06 Jan 2017'}
            location={''}
            result={'Win'}
            round={''}
            time={''}
            method={'Von Flue Choke'}
          />

          <FightRecord
            name={'Arben Escayo'}
            weightclass={'Lightweight'}
            date={'19 Mar 2016'}
            location={'Phuket, Thailand'}
            result={'Win'}
            round={1}
            time={'2:04'}
            method={'Elbows'}
          />
        </Box> */}
			</Box>

			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={<AddFightRecord handleClose={closeDialog} />}
			/>
		</>
	);
};

export default FightRecordBlock;
