import { Component } from 'react';
import { Box, Container } from '@material-ui/core';
import { Config } from 'src/helpers/context/config';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { SocketEventEmitter } from 'src/helpers/EventEmitter';
import { getIdFromUrl } from 'src/helpers/commonFunctions';
import ComingSoon from '../../UI/comingSoon';
import MessagesPage from './MessagesPage';

class Messages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chatHistory: [],
		};
		this.fetchMessageList = this.fetchMessageList.bind(this);
		SocketEventEmitter.subscribe('AddMessage', this.addMessage);
		SocketEventEmitter.subscribe('DeleteMessage', this.deleteMessage);
	}

	// componentDidMount() {
	//   this.fetchMessageList();
	// }
	deleteMessage = (data) => {
		const groupID = getIdFromUrl();

		if (typeof groupID !== 'undefined' && groupID === data?.group_id) {
			const { chatHistory } = this.state;
			const groupChatHistory = [...chatHistory];
			var fileterData = groupChatHistory.filter((item) => item?._id !== data?._id);

			this.setState({ chatHistory: fileterData });
		}
	};

	addMessage = (data) => {
		const groupID = getIdFromUrl();

		if (typeof groupID !== 'undefined' && groupID === data?.group_id) {
			this.setState((state) => ({
				chatHistory: [...state.chatHistory, data],
			}));
		}
	};

	fetchMessageList = async () => {
		const groupID = getIdFromUrl();

		try {
			const { status, data } = await axiosInstance.get(URLS.getMessageList + `?group_id=${groupID}`);

			if (status === 200) {
				// var groupMessageList = data?.messages_list.reverse()
				this.setState({ chatHistory: data?.messages_list.reverse() });
				// var otherUserChat = msgGroup?.filter(currentGroupChat => currentGroupChat?.user?._id !== user?.user_id)
				// var unseenMessageArray = otherUserChat.reverse
				//     ().slice((otherUserChat.length - currentGroupChat?.total_unread_count), otherUserChat.length)
				// console.log(unseenMessageArray, msgGroup, 'unseenMessageArray')
				// setUnReadMessages(unseenMessageArray)
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	render() {
		const { chatHistory } = this.state;

		return (
			<Box>
				<Container maxWidth="lg">
					<Box className="combat-container">
						{Config.isMessageUnable === 'true' ? (
							<MessagesPage chatHistory={chatHistory} fetchMessageList={this.fetchMessageList} />
						) : (
							<ComingSoon />
						)}
					</Box>
				</Container>
			</Box>
		);
	}
}

export default Messages;
