import {
	Box,
	// Button,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import Activity from './Activity';

const RecentActivity = ({ isView }) => {
	const { t } = useTranslation();

	return (
		<Box className="discipline-tab">
			<Box className="title-block">
				<Typography className="tab-block-title" style={{ marginBottom: '5px' }}>
					{t('myprofile.recent_activity')}

					{/* <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            className="see-all-btn-left"
          >
            {t('see_all')}
          </Button> */}
				</Typography>
			</Box>

			{/* {[{}, {}, {}, {}].map((data, index) => {
        return (
          <Activity
            key={index}
            type={'[Activity Type]'}
            community={'[community Name]'}
            owner={'[Activity Owner]'}
            time={'1 hour ago'}
            text={
              '[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]'
            }
          />
        );
      })} */}

			{/* No Activity Text */}
			<Typography className="no-info-txt">
				{isView ? t('myprofile.user_has_no_activity') : t('myprofile.you_have_no_activity')}
			</Typography>
		</Box>
	);
};

export default RecentActivity;
