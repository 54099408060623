import {
	Box,
	Button,
	Grid,
	IconButton,
	Typography,
	// MenuItem
} from '@material-ui/core';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import './addEditDiscipline.scss';
import { useTranslation } from 'react-i18next';

const AddEditDiscipline = () => {
	const { t } = useTranslation();

	return (
		<Box className="add-edit-descipline-box">
			<Box className="discipline-dropdown-cover">
				<MatrixTextField
					fullWidth
					variant="filled"
					label="Discipline"
					placeholder="Discipline"
					className="matrix-textfield"
					size="small"
					// SelectProps={{
					//   native: true
					// }}
					select
				></MatrixTextField>
			</Box>
			<hr className="discipline-hr" />

			<Box className="selected-discipline-block">
				<Box display="flex" justifyContent={'space-between'} mb={2}>
					<Typography className="selected-d-title">Chinese Martial Arts</Typography>
					<IconButton disableRipple disableFocusRipple disableTouchRipple>
						<DeleteIcon />
					</IconButton>
				</Box>

				<Grid container spacing={3}>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Rank"
							placeholder="Rank"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Granted At"
							placeholder="Granted At"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Date Granted"
							placeholder="Date Granted"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
					<Box className="discipline-partition">
						<hr className="discipline-partition-hr" />
					</Box>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Rank"
							placeholder="Rank"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Granted At"
							placeholder="Granted At"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
					<Grid item xs={12} md={12} className="text-field-grid">
						<MatrixTextField
							fullWidth
							variant="filled"
							label="Date Granted"
							placeholder="Date Granted"
							className="matrix-textfield"
							size="small"
						/>
					</Grid>
				</Grid>
			</Box>

			<Box className="dialog-submit-button dialog-sticky-btn">
				<Button variant="contained" type="submit" className="dialog-submit">
					{t('save')}
				</Button>
			</Box>
		</Box>
	);
};

export default AddEditDiscipline;
