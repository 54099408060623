import { useState } from 'react';
import {
	Box,
	// Button,
	Tab,
	Typography,
	//  List
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useTranslation } from 'react-i18next';

const CFFCBlock = ({ isView }) => {
	const { t } = useTranslation();

	const [tab, setTab] = useState('1');
	const list = [
		{ id: 1, name: t('myprofile.tab_connections') },
		{ id: 2, name: t('myprofile.tab_followers') },
		{ id: 3, name: t('myprofile.tab_following') },
		{ id: 4, name: t('myprofile.tab_communities') },
	];

	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Box className="social-activity-container">
			<TabContext value={String(tab)}>
				<Box className="actions-header">
					<TabList onChange={tabChangeHandler} aria-label="action tabs">
						{list.map((obj, index) => {
							return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
						})}
					</TabList>
				</Box>
				<Box className="act-data-list">
					{/* Connection */}
					<TabPanel value="1">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_connections')} (0)
								{/* <Button
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  className="see-all-btn-right"
                >
                  {t('see_all')}
                </Button> */}
							</Typography>

							{/* <Box className="cffc-data-container">
                <List dense>
                  {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (data, index) => {
                      return (
                        <AssetData
                          key={index}
                          img={'/static/1.png'}
                          name={'Scott Watson'}
                        />
                      );
                    }
                  )}
                </List>
              </Box> */}

							{/* No Data Text */}
							<Typography className="no-data-txt">
								{isView ? t('myprofile.user_has_no_connections') : t('myprofile.you_have_no_connections')}
							</Typography>
						</Box>
					</TabPanel>

					{/* Followers */}
					<TabPanel value="2">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_followers')} (0)
								{/* <Button
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  className="see-all-btn-right"
                >
                  {t('see_all')}
                </Button> */}
							</Typography>

							{/* <Box className="cffc-data-container">
                <List dense>
                  {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (data, index) => {
                      return (
                        <AssetData
                          key={index}
                          img={'/static/2.png'}
                          name={'Sophie Nakajima'}
                        />
                      );
                    }
                  )}
                </List>
              </Box> */}

							{/* No Data Text */}
							<Typography className="no-data-txt">
								{isView ? t('myprofile.user_has_no_followers') : t('myprofile.you_have_no_followers')}
							</Typography>
						</Box>
					</TabPanel>

					{/* Followering */}
					<TabPanel value="3">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_following')} (0)
								{/* <Button
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  className="see-all-btn-right"
                >
                  {t('see_all')}
                </Button> */}
							</Typography>

							{/* <Box className="cffc-data-container">
                <List dense>
                  {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (data, index) => {
                      return (
                        <AssetData
                          key={index}
                          img={'/static/3.png'}
                          name={'Kieran Lea'}
                        />
                      );
                    }
                  )}
                </List>
              </Box> */}

							{/* No Data Text */}
							<Typography className="no-data-txt">
								{isView ? t('myprofile.user_has_no_following') : t('myprofile.you_have_no_following')}
							</Typography>
						</Box>
					</TabPanel>

					{/* Communities */}
					<TabPanel value="4">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_communities')} (0)
								{/* <Button
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  className="see-all-btn-right"
                >
                  {t('see_all')}
                </Button> */}
							</Typography>

							{/* <Box className="cffc-data-container">
                <List dense>
                  {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(
                    (data, index) => {
                      return (
                        <AssetData
                          key={index}
                          img={'/static/4.png'}
                          name={'John Smith'}
                        />
                      );
                    }
                  )}
                </List>
              </Box> */}

							{/* No Data Text */}
							<Typography className="no-data-txt">
								{isView ? t('myprofile.user_has_no_communities') : t('myprofile.you_have_no_communities')}
							</Typography>
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	);
};

export default CFFCBlock;
