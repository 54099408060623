import { useState, useContext } from 'react';
import { Box, makeStyles, Tabs, Badge } from '@material-ui/core';
import { ReactComponent as NewsFeedIcon } from 'src/assets/images/icons/mobileMenu/newsFeed.svg';
import { ReactComponent as CommunitiesIcon } from 'src/assets/images/icons/mobileMenu/communities.svg';
// import { ReactComponent as MessagesIcon } from 'src/assets/images/icons/mobileMenu/messages.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/images/icons/mobileMenu/profile.svg';
import { ReactComponent as NotificationsIcon } from 'src/assets/images/icons/mobileMenu/notifications.svg';
import { ReactComponent as UsersIcon } from 'src/assets/images/icons/mobileMenu/users.svg';
// import { ReactComponent as EventIcon } from 'src/assets/images/icons/mobileMenu/Icon_Event.svg';
import TabContext from '@material-ui/lab/TabContext';
import { fetchFromStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';
// import items from '../menu';
import { useLocation } from 'react-router-dom';
import MenuItem from './mobileMenuItem';
import 'src/layouts/layout.scss';

const useStyles = makeStyles((theme) => ({
	root: { backgroundColor: '#151515' },
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '21px',
		fontFamily: `Staatliches`,
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent', color: '#438AC1' },
		'&:last-child': {
			marginRight: 0,
		},
	},
	avatar: { width: 36, height: 36, cursor: 'pointer' },
	active: {
		color: '#438AC1',
		fontSize: '21px',
		fontFamily: `Staatliches`,
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent' },
		'&:last-child': {
			marginRight: 0,
		},
	},
}));
const MobileMenu = ({ auth, user_statics }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	var USER_DATA = fetchFromStorage('authData') !== null && fetchFromStorage('authData')?.user_meta;
	let USER_ID = USER_DATA !== undefined ? USER_DATA?.data : auth?.user_id;
	let USERID = USER_ID !== '' ? USER_ID : fetchFromStorage('authData')?.user_id;

	const [tab, setTab] = useState('/news-feed');
	const location = useLocation();
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<>
			<TabContext value={String(tab)}>
				<Box className="tablet-menu">
					{/* <Tabs
            variant="scrollable"
            scrollButtons="off"
            aria-label="action tabs"
            onChange={tabChangeHandler}
          >
            {items.map(item => {
              let menuPath =
                item.href === '/events/discover' ? '/events' : item?.href;
              return (
                <NavLink to={item.href} key={item.title} icon={item.icon}>
                  <Button
                    disableRipple
                    disableElevation
                    disableTouchRipple
                    disableFocusRipple
                    value={item.href}
                    label={item?.title}
                    className={
                      location.pathname.includes(menuPath)
                        ? `${classes.active} app-menu-btn`
                        : `${classes.button} app-menu-btn`
                    }
                  >
                    {item?.title}
                  </Button>
                </NavLink>
              );
            })}
            {auth?.isAdmin && (
              <NavLink to="admin/users">
                <Button
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  value="admin"
                  label="Dashboard"
                  className={`${classes.button} app-menu-btn`}
                >
                  Dashboard
                </Button>
              </NavLink>
            )}
            <NavLink to="/search">
              <Button
                disableRipple
                disableElevation
                disableTouchRipple
                disableFocusRipple
                className={
                  location.pathname.includes('/search')
                    ? `${classes.active} app-menu-btn`
                    : `${classes.button} app-menu-btn`
                }
              >
                Search
              </Button>
            </NavLink>
            <NavLink to="/notification">
              <Button
                disableRipple
                disableElevation
                disableTouchRipple
                disableFocusRipple
                className={
                  location.pathname.includes('/notification')
                    ? `${classes.active} app-menu-btn`
                    : `${classes.button} app-menu-btn`
                }
              >
                Notification
              </Button>
            </NavLink>
            <NavLink to={`/user/${USERID}`}>
              <Button
                disableRipple
                disableElevation
                disableTouchRipple
                disableFocusRipple
                className={
                  location.pathname.includes(`/user/${USERID}`)
                    ? `${classes.active} app-menu-btn`
                    : `${classes.button} app-menu-btn`
                }
              >
                My Profile
              </Button>{' '}
            </NavLink>
            <Button
              label="Logout"
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                window.location = '/';
                clearStorage();
              }}
              className={`${classes.button} app-menu-btn`}
            >
              Logout
            </Button>
          </Tabs> */}
				</Box>
				{/* This part will show the mobile size top menu */}
				<Box className="mobile-top-menu">
					<Tabs variant="scrollable" scrollButtons="off" aria-label="action tabs" onChange={tabChangeHandler}>
						<MenuItem href={'/news-feed'} icon={<NewsFeedIcon />} title={t('top_menu_options.newsfeed')} />
						<MenuItem
							href={'/communities'}
							icon={<CommunitiesIcon fill="red" />}
							title={t('top_menu_options.communities')}
						/>

						{/* HIDE-MVP: CM-74 */}
						{/*<MenuItem href={'/events/discover'} icon={<EventIcon fill="red" />} title={t('top_menu_options.events')} />*/}

						{/* HIDE-MVP: CM-76 */}
						{/*<MenuItem href={'/messages'} icon={<MessagesIcon />} title={t('top_menu_options.messages')} />*/}
						<MenuItem href={`/user/${USERID}`} icon={<ProfileIcon />} title={t('top_menu_options.profile')} />
						<MenuItem href={`/users`} icon={<UsersIcon />} title={t('top_menu_options.members')} />
						{/* <MenuItem
              href={'/notification'}
              icon={<EventsIcon/>}
              title={t('top_menu_options.events')}
            /> */}
						<MenuItem
							href={'/notification'}
							icon={
								<Badge badgeContent={user_statics?.unreadNotification} color="error">
									<NotificationsIcon />
								</Badge>
							}
							title={t('top_menu_options.notifications')}
						/>
					</Tabs>
				</Box>
			</TabContext>
		</>
	);
};
export default MobileMenu;
