import { useContext, useState, useEffect } from 'react'; // useContext
import {
	Box,
	Container,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Grid,
	IconButton,
	Tab,
	Button,
	Popover,
	TextField,
	withStyles,
	InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './referral-main.scss';
import { ReactComponent as CopyIcon } from 'src/assets/images/icons/copy.svg';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import CombatDialog from 'src/views/component/UI/dialogBox';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { ReactComponent as ThreeDots } from 'src/assets/images/icons/3-vertical-dots.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/edit-blue.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
// import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from 'moment';
import { Config } from 'src/helpers/context/config';
import { NavLink } from 'react-router-dom';
import AddEditReferral from '../AddEditReferral';

const SearchTextField = withStyles({
	root: {
		backgroundColor: '#202020',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);
const ReferralSection = () => {
	const { t } = useTranslation();
	const [opens, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [tab, setTab] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState('');
	const [limit] = useState(10);
	const [referralCodeList, setReferralCodes] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [modal, setModal] = useState('');
	const [clickedItem, setClickedItem] = useState({});
	const [searchVal, setSearchVal] = useState('');
	const [activeCodeList, setActiveCodeList] = useState([]);
	// const [copied, setCopied] = useState(false)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const ids = open ? 'simple-popover' : undefined;
	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;
	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
		setTitle(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const tabList = [
		{ id: 1, name: t('referral.my_referral_code') },
		{ id: 2, name: t('referral.shared_by_you') },
		{ id: 3, name: t('referral.shared_by_ou') },
		{ id: 4, name: t('referral.signup_invitations') },
		{ id: 5, name: t('referral.profile_shares') },
	];
	const fetchActiveReferralCode = async () => {
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getReferralCodes,
				{
					params: {
						status: identifiers?.active,
					},
				},
				headers,
			);

			if (status === 200) {
				setActiveCodeList(data?.getReferralCode);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchReferralCode = async (page, searchString, tabVal) => {
		try {
			let { status, data } = await axiosInstance.get(
				URLS.getReferralCodes,
				{
					params: {
						page: page,
						limit: limit,
						name: searchString,
						tab: tabVal,
						// status: identifiers?.active
					},
				},
				headers,
			);

			if (status === 200) {
				setReferralCodes(data?.getReferralCode);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
		fetchReferralCode(currentPage, searchVal, newValue);
	};
	const handleChange = (e) => {
		setSearchVal(e.target.value);
		fetchReferralCode(currentPage, e.target.value, tab);
	};
	const deactiveCode = async () => {
		var requestData = {
			status: clickedItem?.status === 'active' ? 'cancelled' : 'active',
		};

		try {
			const { status, data } = await axiosInstance.delete(URLS.deactiveCode + clickedItem?.code, {
				data: requestData,
				headers: headers,
			});

			if (status === 200) {
				fetchActiveReferralCode();
				fetchReferralCode(currentPage, searchVal, tab);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// PAGINATION
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchReferralCode(currentPage - 1, searchVal, tab);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				fetchReferralCode(currentPage + 1, searchVal, tab);
			}
		}
	};
	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchReferralCode(Number(page), searchVal, tab);
		}
	};
	const handleCopied = (item) => {
		var urlValue = `${Config?.DomainName}sign-up?referralcode=${item?.code}`;

		navigator.clipboard.writeText(urlValue);
		setApiMessage('success', t('referral.copied'));
	};

	useEffect(() => {
		fetchReferralCode(currentPage, searchVal, tab);
		fetchActiveReferralCode();
	}, []);

	return (
		<Box>
			<Container maxWidth="lg">
				<Box className="combat-container referral-main">
					<Grid container spacing={3}>
						<Grid item xs={12} md={8}>
							<Box className="about-referral">
								<Typography className="ref-head">{t('referral.why_should')}</Typography>
								<Typography className="content">{t('referral.content_1')}</Typography>
								<Typography className="content">{t('referral.content_2')}</Typography>
								<Typography className="content">{t('referral.content_3')}</Typography>
								<Typography className="content">{t('referral.content_4')}</Typography>
								<Typography className="content">{t('referral.content_5')}</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4} className="yrc-main">
							<Box className="referral-codes">
								<Typography className="rc-title">{t('referral.your_ref_code')}</Typography>
								<Box className="codes-table">
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>{t('referral.referral_name')}</TableCell>
													<TableCell>{t('referral.referral_code')}</TableCell>
													<TableCell></TableCell>
												</TableRow>
											</TableHead>
											{activeCodeList && activeCodeList.length > 0 ? (
												<>
													<TableBody>
														{activeCodeList.map((item, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{item?.label}</TableCell>
																	<TableCell>{item?.code}</TableCell>
																	<TableCell onClick={() => handleCopied(item)} className="d-flex cursor-pointer">
																		<CopyIcon />
																		{t('referral.copy')}
																	</TableCell>
																</TableRow>
															);
														})}
													</TableBody>
												</>
											) : (
												<TableRow>
													<TableCell colSpan={4} className="no-data-txt">
														{t('referral.no_referral_txt')}
													</TableCell>
												</TableRow>
											)}
										</Table>
									</TableContainer>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Box className="my-referrals">
						<Typography className="ref-head">{t('referral.my_referral')}</Typography>
						<Box className="referral-codes">
							<TabContext value={String(tab)}>
								<Box className="actions-header">
									<TabList
										variant="scrollable"
										scrollButtons="off"
										onChange={tabChangeHandler}
										aria-label="action tabs"
									>
										{tabList.map((obj, index) => {
											return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
										})}
									</TabList>
								</Box>
								{/*SEARCH REFERRAL  */}
								<Box className="referral-search-box">
									<Box className="search-field-box">
										<SearchTextField
											onChange={handleChange}
											fullWidth
											placeholder={t('search.title')}
											size="small"
											variant="outlined"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
											}}
										/>
									</Box>
								</Box>
								{/* MY REFERRAL CODES */}
								<TabPanel className="p-0" value="1">
									<Box className="mt-18">
										<Box className="codes-table">
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>{t('referral.referral_name')}</TableCell>
															<TableCell>{t('referral.referral_code')}</TableCell>
															<TableCell>{t('referral.date')}</TableCell>
															<TableCell>{t('referral.cstatus')}</TableCell>
															<TableCell className="row-right">
																<IconButton
																	onClick={() => {
																		combatDialogHandler(t('referral.add_new_referral'));
																		setModal('add');
																	}}
																	className="add-btn"
																>
																	<BaddIcon />
																	{t('referral.new_ref_code')}
																</IconButton>
															</TableCell>
														</TableRow>
													</TableHead>
													{referralCodeList && referralCodeList.length > 0 ? (
														<>
															<TableBody>
																{referralCodeList.map((item, index) => {
																	return (
																		<TableRow key={index}>
																			<TableCell className="ref-name">
																				<IconButton
																					className="p-0"
																					onClick={() => {
																						combatDialogHandler(t('referral.update_referral'));
																						setModal('update');
																						setClickedItem(item);
																					}}
																				>
																					<EditIcon />
																				</IconButton>
																				{item?.label}
																			</TableCell>
																			<TableCell>{item?.code}</TableCell>
																			<TableCell>{moment(item?.timestamp_created).format('MM/DD/YYYY')}</TableCell>
																			<TableCell>{item?.status}</TableCell>
																			<TableCell className="d-flex cursor-pointer">
																				<Typography
																					className="d-flex "
																					variant="span"
																					onClick={() => handleCopied(item)}
																				>
																					<CopyIcon />
																					{t('referral.copy')}
																				</Typography>
																				<Button
																					onClick={(e) => {
																						setClickedItem(item);
																						handleClick(e);
																					}}
																					className="btn-toggle"
																				>
																					<ThreeDots />
																				</Button>
																				<Popover
																					className="ad-popover"
																					id={ids}
																					open={open}
																					anchorEl={anchorEl}
																					onClose={handleClose}
																					anchorOrigin={{
																						vertical: 'bottom',
																						horizontal: 'left',
																					}}
																				>
																					<Button
																						onClick={() => {
																							handleClose();
																							deactiveCode();
																						}}
																					>
																						{clickedItem?.status === 'active'
																							? t('referral.deactive_code')
																							: t('referral.active_code')}
																					</Button>
																				</Popover>
																			</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</>
													) : (
														<TableRow>
															<TableCell colSpan={5} className="no-data-txt">
																{t('referral.no_referral_txt')}
															</TableCell>
														</TableRow>
													)}
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</TabPanel>
								{/* SHARED BY YOU */}
								<TabPanel className="p-0" value="2">
									<Box className="mt-18">
										<Box className="codes-table">
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>{t('referral.referral_code_used')}</TableCell>
															<TableCell>{t('referral.referral_code')}</TableCell>
															<TableCell>{t('referral.shared_from')}</TableCell>
															<TableCell>{t('referral.type')}</TableCell>
															<TableCell>{t('referral.table_title')}</TableCell>
															<TableCell>{t('referral.shared_to')}</TableCell>
															<TableCell>{t('referral.count')}</TableCell>
															<TableCell>{t('referral.date')}</TableCell>
														</TableRow>
													</TableHead>
													{referralCodeList && referralCodeList.length > 0 ? (
														<>
															<TableBody>
																{referralCodeList.map((item, index) => {
																	var landSlug =
																		item?.land_type === 'organisation'
																			? `/organisation-details/${item?.land_id}`
																			: item?.land_type === 'community'
																			? `/communities/${item?.land_id}`
																			: item?.land_type === 'post'
																			? `/post/${item?.land_id}`
																			: item?.land_type === 'user'
																			? `/user/${item?.land_id}`
																			: item?.land_type === 'user_signup'
																			? `/user/${item?.land_id}`
																			: `/referral-code`;

																	return (
																		<TableRow key={index}>
																			<TableCell>{item?.label}</TableCell>
																			<TableCell>{item?.code}</TableCell>
																			<TableCell>
																				<NavLink to={`/user/${item?.from_user_id}`} className="logo-anchor">
																					{item?.from_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.land_type}</TableCell>
																			<TableCell>
																				<NavLink to={landSlug} className="logo-anchor">
																					{item?.title}
																				</NavLink>
																			</TableCell>
																			<TableCell>
																				<NavLink to={`/user/${item?.user_id}`} className="logo-anchor">
																					{item?.to_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.hit}</TableCell>
																			<TableCell>{moment(item?.timestamp_created).format('MM/DD/YYYY')}</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</>
													) : (
														<TableRow>
															<TableCell colSpan={5} className="no-data-txt">
																{t('referral.no_referral_txt')}
															</TableCell>
														</TableRow>
													)}
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</TabPanel>
								{/* SHARED BY OTHER USERS */}
								<TabPanel className="p-0" value="3">
									<Box className="mt-18">
										<Box className="codes-table">
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>{t('referral.referral_code_used')}</TableCell>
															<TableCell>{t('referral.referral_code')}</TableCell>
															<TableCell>{t('referral.shared_from')}</TableCell>
															<TableCell>{t('referral.type')}</TableCell>
															<TableCell>{t('referral.table_title')}</TableCell>
															<TableCell>{t('referral.count')}</TableCell>
															<TableCell>{t('referral.date')}</TableCell>
														</TableRow>
													</TableHead>
													{referralCodeList && referralCodeList.length > 0 ? (
														<>
															<TableBody>
																{referralCodeList.map((item, index) => {
																	var landSlug =
																		item?.land_type === 'organisation'
																			? `/organisation-details/${item?.land_id}`
																			: item?.land_type === 'community'
																			? `/communities/${item?.land_id}`
																			: item?.land_type === 'post'
																			? `/post/${item?.land_id}`
																			: item?.land_type === 'user'
																			? `/user/${item?.land_id}`
																			: item?.land_type === 'user_signup'
																			? `/user/${item?.land_id}`
																			: `/referral-code`;

																	return (
																		<TableRow key={index}>
																			<TableCell>{item?.label}</TableCell>
																			<TableCell>{item?.code}</TableCell>
																			<TableCell>
																				<NavLink to={`/user/${item?.from_user_id}`} className="logo-anchor">
																					{item?.from_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.land_type} </TableCell>
																			<TableCell>
																				<NavLink to={landSlug} className="logo-anchor">
																					{item?.title}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.hit}</TableCell>
																			<TableCell>{moment(item?.timestamp_created).format('MM/DD/YYYY')}</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</>
													) : (
														<TableRow>
															<TableCell colSpan={5} className="no-data-txt">
																{t('referral.no_referral_txt')}
															</TableCell>
														</TableRow>
													)}
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</TabPanel>
								{/* SIGN UP INVITATIONS */}
								<TabPanel className="p-0" value="4">
									<Box className="mt-18">
										<Box className="codes-table">
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>{t('referral.referral_code_used')}</TableCell>
															<TableCell>{t('referral.referral_code')}</TableCell>
															<TableCell>{t('referral.shared_to')}</TableCell>
															<TableCell>{t('referral.count')}</TableCell>
															<TableCell>{t('referral.date')}</TableCell>
														</TableRow>
													</TableHead>
													{referralCodeList && referralCodeList.length > 0 ? (
														<>
															<TableBody>
																{referralCodeList.map((item, index) => {
																	return (
																		<TableRow key={index}>
																			<TableCell>{item?.label}</TableCell>
																			<TableCell>{item?.code}</TableCell>
																			<TableCell>
																				<NavLink to={`/user/${item?.user_id}`} className="logo-anchor">
																					{item?.to_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.hit}</TableCell>
																			<TableCell>{moment(item?.timestamp_created).format('MM/DD/YYYY')}</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</>
													) : (
														<TableRow>
															<TableCell colSpan={5} className="no-data-txt">
																{t('referral.no_referral_txt')}
															</TableCell>
														</TableRow>
													)}
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</TabPanel>
								{/* PROFILE SHARES */}
								<TabPanel className="p-0" value="5">
									<Box className="mt-18">
										<Box className="codes-table">
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>{t('referral.shared_from')}</TableCell>
															<TableCell>{t('referral.referral_code')}</TableCell>
															<TableCell>{t('referral.shared_to')}</TableCell>
															<TableCell>{t('referral.count')}</TableCell>
															<TableCell>{t('referral.date')}</TableCell>
														</TableRow>
													</TableHead>
													{referralCodeList && referralCodeList.length > 0 ? (
														<>
															<TableBody>
																{referralCodeList.map((item, index) => {
																	return (
																		<TableRow key={index}>
																			<TableCell>
																				<NavLink to={`/user/${item?.from_user_id}`} className="logo-anchor">
																					{item?.from_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.code}</TableCell>
																			<TableCell>
																				<NavLink to={`/user/${item?.user_id}`} className="logo-anchor">
																					{item?.to_user_name}
																				</NavLink>
																			</TableCell>
																			<TableCell>{item?.hit}</TableCell>
																			<TableCell>{moment(item?.timestamp_created).format('MM/DD/YYYY')}</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</>
													) : (
														<TableRow>
															<TableCell colSpan={5} className="no-data-txt">
																{t('referral.no_referral_txt')}
															</TableCell>
														</TableRow>
													)}
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</TabPanel>
								<CustomPagination
									paginationArray={referralCodeList}
									currentPage={currentPage}
									list={limit}
									handlePaginationButtonClick={handlePaginationButtonClick}
									handlePaginationClick={handlePaginationClick}
									totalRecord={totalRecord}
								/>
							</TabContext>
						</Box>
					</Box>
				</Box>
			</Container>
			<CombatDialog
				open={opens}
				handleClose={closeDialog}
				title={title}
				content={
					modal === 'add' ? (
						<AddEditReferral
							fetchReferralCode={fetchReferralCode}
							searchVal={searchVal}
							currentPage={currentPage}
							closeDialog={closeDialog}
							isUpdate={false}
							fetchActiveReferralCode={fetchActiveReferralCode}
							tab={tab}
						/>
					) : (
						<AddEditReferral
							fetchReferralCode={fetchReferralCode}
							searchVal={searchVal}
							currentPage={currentPage}
							closeDialog={closeDialog}
							isUpdate={true}
							item={clickedItem}
							fetchActiveReferralCode={fetchActiveReferralCode}
							tab={tab}
						/>
					)
				}
			/>
		</Box>
	);
};

export default ReferralSection;
