import { useContext, useState } from 'react';
import { Box, Grid, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../RightSide.scss';
import CombatDialog from 'src/views/component/UI/dialogBox';
import ChangeEmail from './Email';
import ChangePassWord from './Password';

const SecurityLogin = ({ user, token }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState();
	const [modal, setModal] = useState('email');
	const [userNewEmail, setUserNewEmail] = useState('');

	const setNewEmailHandler = (val) => {
		setUserNewEmail(val);
	};

	const handleDialog = (heading) => {
		setOpen(true);
		setTitle(heading);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	return (
		<>
			<Box className="General-setting-sec">
				<Box className="header-sec">
					<Typography className="header-text">{t('acount_setting.Security_and_Login')}</Typography>
				</Box>
				<Box className="content-sec">
					<Grid container>
						<Grid sm={3} xs={12} className="content-title">
							{t('acount_setting.Email')}
						</Grid>
						<Grid sm={8} xs={11} className="content-name">
							{userNewEmail !== '' ? userNewEmail : user?.email}
						</Grid>
						<Grid
							xs={1}
							className="content-edit"
							onClick={() => {
								handleDialog(t('acount_setting.Change_email'));
							}}
						>
							<Link className="edit-link"> {t('acount_setting.edit')}</Link>
						</Grid>
					</Grid>
					<Grid container>
						<Grid sm={3} xs={12} className="content-title">
							{t('acount_setting.Password')}
						</Grid>
						<Grid sm={8} xs={11} className="content-name">
							********
						</Grid>
						<Grid
							xs={1}
							className="content-edit"
							onClick={() => {
								handleDialog(t('acount_setting.change_Password'));
								setModal('password');
							}}
						>
							<Link className="edit-link">{t('acount_setting.edit')} </Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					modal === 'email' ? (
						<ChangeEmail token={token} setNewEmailHandler={setNewEmailHandler} closeDialog={closeDialog} />
					) : modal === 'password' ? (
						<ChangePassWord closeDialog={closeDialog} />
					) : (
						<></>
					)
				}
			/>
		</>
	);
};
export default SecurityLogin;
