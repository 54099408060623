import { memo, useState, useEffect, Fragment } from 'react';
import {
	Box,
	// Button,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
// import CustomPagination from 'src/views/component/UI/CustomPaginations';
import CommunityPost from 'src/views/component/UI/CommunityPost';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import Activity from './Activity';
import NewsfeedCommunityPost from 'src/views/component/UI/NewsfeedCommunity';
import { useTranslation } from 'react-i18next';

const RecentActivity = ({
	isView,
	recentActivityData,
	// currentPage,
	// limit,
	// handlePaginationButtonClick,
	// handlePaginationClick,
	// totalRecord,
	fetchRecentActivity,
	setRecentActivity,
	hasMore,
	user,
}) => {
	const { t } = useTranslation();

	const [reactionData, setReactionData] = useState([]);

	const isFullScreenScroll = useMediaQuery('(max-width: 1020px)');
	const getPostDetails = (id) => {
		return axiosInstance.get(`${URLS.getPostDetails}/${id}`);
	};

	// ADD VOTE

	const fetchAndUpdateActivityPostData = async (id) => {
		try {
			const { data, status } = await getPostDetails(id);

			if (status === 200) {
				setRecentActivity((prev) =>
					prev.map((activity) => {
						if (id === activity.id) {
							return {
								...activity,
								...data.data.post_data,
							};
						}

						return activity;
					}),
				);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const addVote = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			vote_name: type,
			main_post_id: value?.id,
		};

		try {
			const { status } = await axiosInstance.post(URLS.addVote, requestParams);

			if (status === 200) {
				await fetchAndUpdateActivityPostData(value?.id);

				// TODO: mb later need to add this
				// setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// REACTION
	const addLike = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				//TODO: refactor later,when will we add reactions
				// fetchRecentActivity('', 1);
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		fetchReactions();
	}, []);

	return (
		<>
			<Box className="discipline-tab recent-activity">
				<Box className="title-block">
					<Typography className="tab-block-title" style={{ marginBottom: '5px' }}>
						{t('myprofile.recent_activity')}

						{/* <Button
              disableRipple
              disableFocusRipple
              disableTouchRipple
              disableElevation
              className="see-all-btn-left"
            >
              {t('see_all')}
            </Button> */}
					</Typography>
				</Box>

				{/* {[{}, {}, {}, {}].map((data, index) => {
        return (
          <Activity
            key={index}
            type={'[Activity Type]'}
            community={'[community Name]'}
            owner={'[Activity Owner]'}
            time={'1 hour ago'}
            text={
              '[Content of activity Content of activity Content of activity Content of activity Content of activity Content of activity]'
            }
          />
        );
      })} */}
				<Box
					id="scrollableDiv"
					// className='infinity-scroll-view'
					className="infinity-scroll-view-custom"
				>
					{recentActivityData && recentActivityData?.length > 0 ? (
						<InfiniteScroll
							dataLength={recentActivityData?.length}
							next={fetchRecentActivity}
							style={{ width: '100%' }} //To put endMessage and loader to the top.
							inverse={false} //
							hasMore={hasMore}
							loader={<h4>Loading...</h4>}
							scrollableTarget={isFullScreenScroll ? 'pageScrollableBody' : 'scrollableDiv'}
						>
							{recentActivityData?.map((data, index) => {
								const postContent = data?.PostLists.find((d) => d?.Item?.type === 'text');
								// (data?.receiver_type==="post")?(PostId=data?.id):(ComId=data?.id);
								var postImage = data?.PostLists.filter((d) => d?.Item?.type === 'image');
								var postVideos = data?.PostLists.filter((d) => d?.Item?.type === 'video');
								var postAudio = data?.PostLists.filter((d) => d?.Item?.type === 'audio');
								var postDoc = data?.PostLists.filter((d) => d?.Item?.type === 'doc');

								return (
									<Fragment key={data.id + 'recent activity'}>
										{data?.receiver_type === 'community' || data?.receiver_type === 'post' ? (
											<CommunityPost
												fetchPostDetails={fetchAndUpdateActivityPostData}
												linkList={postContent?.Item?.links_list}
												isView={true}
												user={user}
												postName={data?.name}
												time={
													data?.timestamp_created !== null &&
													moment.utc(data?.timestamp_created).fromNow().replace('in ', '')
												}
												postedBy={
													data?.receiver_type === 'organisation' ? data?.organisation_name : data?.user_full_name
												}
												topVal={data?.total_vote_up}
												downVal={data?.total_vote_down}
												contentText={postContent?.Item?.data}
												contentImg={postImage}
												comment={data?.total_comments}
												badges={data?.total_badges}
												shares={data?.total_shares}
												referrals={data?.total_referrals}
												userURI={data?.user_meta?.data}
												reactions={data?.total_likes}
												communityPost={data?.receiver_type === 'post' ? true : false}
												communityName={
													data?.receiver_type === 'community'
														? data?.community_name
														: data?.receiver_type === 'organisation'
														? data?.user_full_name
														: ''
												}
												activityType={
													data?.receiver_type === 'community'
														? data?.receiver_type
														: data?.receiver_type === 'organisation'
														? 'Posted By'
														: ''
												}
												communityID={
													data?.receiver_type === 'community' || data?.receiver_type === 'organisation'
														? data?.receiver_id
														: ''
												}
												postProfile={
													data?.receiver_type === 'organisation'
														? data?.organisation_profile_data
														: data?.user_profile_data
												}
												editAccess={data?.edit_access}
												postUserId={data?.user_id}
												receiver_type={data?.receiver_type}
												data={data}
												// isJoin={data?.join_status}
												isJoin={true}
												PostId={data?.user_id}
												addVote={addVote}
												addLike={addLike}
												reactionData={reactionData}
												postVideo={postVideos}
												postAudio={postAudio}
												postDoc={postDoc}
												// updateCommunityPost={updateCommunityPost}
												// updatePostData={updatePostData}
												// handleDeletePost={handleDeletePost}
												// cancelBtnClick={() => setIsUpdate(false)}
												closeCommunity={data?.status === 'closed' ? true : false}
												likedStatus={data?.like_status}
												verifyUser={data?.veritication_status === 'yes' ? true : false}
											/>
										) : data?.receiver_type === 'user' ? (
											<NewsfeedCommunityPost
												postedBy={data?.user_full_name}
												time={
													data?.timestamp_created !== null &&
													moment.utc(data?.timestamp_created).fromNow().replace('in ', '')
												}
												activityType={data?.receiver_type === 'community' ? data?.receiver_type : ''}
												communityName={data?.receiver_type === 'community' ? data?.community_name : ''}
												topVal={data?.total_vote_up}
												downVal={data?.total_vote_down}
												comment={data?.total_comments}
												referrals={data?.total_referrals}
												postProfile={data?.user_profile_data}
												addVote={addVote}
												reactions={data?.total_likes}
												isJoin={data?.join_status}
												editAccess={data?.edit_access}
												data={data}
												userURI={data?.user_meta?.data}
												fetchNewsfeedPostList={fetchRecentActivity}
												// currentPage={currentPage}
												// tab={tab}
												reactionData={reactionData}
												view={isView}
												user={user}
												shares={data?.total_shares}
												verifyUser={data?.veritication_status === 'yes' ? true : false}
											/>
										) : (
											<Activity
												key={index}
												type={data?.receiver_type}
												community={data?.community_name}
												owner={data?.user_full_name}
												time={
													data?.timestamp_created !== null &&
													moment.utc(data?.timestamp_created).fromNow().replace('in ', '')
												}
												text={postContent?.Item?.data}
												// PostId={data?.receiver_type === "post" ? data?.id : ''}
												PostId={data?.receiver_type === 'comments' ? data?.main_post_id : data?.id}
												ComId={data?.receiver_type === 'community' ? data?.receiver_id : ''}
											/>
										)}
									</Fragment>
								);
							})}
						</InfiniteScroll>
					) : (
						<Typography className="no-info-txt">
							{isView ? t('myprofile.user_has_no_activity') : t('myprofile.you_have_no_activity')}
						</Typography>
					)}
				</Box>
				{/* No Activity Text */}
				{/* <Typography className="no-info-txt">
        {isView
          ? t('myprofile.user_has_no_activity')
          : t('myprofile.you_have_no_activity')}
      </Typography> */}

				{/* <CustomPagination
          paginationArray={recentActivityData}
          currentPage={currentPage}
          list={limit}
          handlePaginationButtonClick={handlePaginationButtonClick}
          handlePaginationClick={handlePaginationClick}
          totalRecord={totalRecord}
        /> */}
			</Box>
		</>
	);
};

export default memo(RecentActivity);
