import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import './leftside.scss';
import { ReactComponent as SettingIcon } from 'src/assets/images/icons/setting-icon.svg';
import { ReactComponent as SecurityIcon } from 'src/assets/images/icons/securityIcon.svg';

const LeftSide = ({ onClick }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(1);

	const handleSelect = (data) => {
		setSelected(data);
	};

	return (
		<Box className="Acoount-set-left-sec">
			<Typography className="header-text">{t('acount_setting.account_settings')}</Typography>
			<Box className="account-setting-details">
				<Box
					className={selected === 1 ? 'account-setting selected-setting' : 'account-setting'}
					onClick={() => {
						onClick(1);
						handleSelect(1);
					}}
				>
					<SettingIcon />
					<Typography className="setting-name">{t('acount_setting.General_Account_Settings')}</Typography>
				</Box>
				<Box
					className={selected === 2 ? 'account-setting selected-setting' : 'account-setting'}
					onClick={() => {
						onClick(2);
						handleSelect(2);
					}}
				>
					<SecurityIcon />
					<Typography className="setting-name">{t('acount_setting.Security_and_Login')}</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default LeftSide;
