export default [
	{ href: '/admin/users', title: 'Users' },
	{ href: '/admin/communities', title: 'COMMUNITIES' },
	{ href: '/admin/badge', title: 'badge' },
	{ href: '/admin/fight', title: 'FIGHT' },
	// HIDE-MVP: CM-74
	// { href: '/admin/events', title: 'EVENTS' },
	// HIDE-MVP: CM-76
	// { href: '/admin/messages', title: 'MESSAGES' },
	// { href: '/admin/connections', title: 'CONNECTIONS' },
	{ href: '/news-feed', title: 'GO TO MAIN SITE' },
];
