import { useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Box, Grid, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PreLoader from 'src/components/loader';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { Config } from 'src/helpers/context/config';
import { OldSocialLogin as SocialLogin } from 'react-social-login';
// import AppleLogin from 'react-apple-login';
import { saveToStorage, fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as FacebookLogo } from 'src/assets/images/social/facebook.svg';
// import { ReactComponent as AppleLogo } from 'src/assets/images/social/apple.svg';
import { ReactComponent as GoogleLogo } from 'src/assets/images/social/google.svg';
import { ReactComponent as EyeSlash } from 'src/assets/images/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'src/assets/images/icons/eye.svg';
import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';
import { identifiers } from 'src/helpers/constants/identifier';
import '../auth.scss';
import { SocketContext } from 'src/helpers/context/socket';

const LoginPage = ({ authUser }) => {
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [flag, setFlag] = useState(false);
	const userSocket = useContext(SocketContext)?.socket;
	const { t } = useTranslation();
	var previousPath = fetchFromStorage(identifiers?.previousPath);
	const SocialLoginHandler = async (token, type) => {
		const requestData = { access_token: token, loginType: 'login' };

		setLoader(true);

		try {
			const { status, data } = await axiosInstance.post(URLS.socialLogin + `?type=${type}`, requestData);

			if (status === 200) {
				setLoader(false);

				if (data?.data?.token) {
					setApiMessage('success', data?.message);
					tokenSaveHandler(data?.data);
					previousPath && navigate(previousPath);
				}
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const tokenSaveHandler = (data) => {
		let token = data?.token;
		let status = data?.profile_status;

		if (token) {
			authUser({ ...data }); // isAdmin: true
			saveToStorage('authData', { ...data }); // isAdmin: true

			if (status) {
				navigate('/news-feed');
			} else {
				navigate('/profile/details');
			}
		}
	};

	// const appleSignIn = () => {
	//   // console.log('apple login click');
	// };

	return (
		<Box className="auth-section-box">
			{loader ? <PreLoader /> : null}
			<Grid container className="auth-grid-container">
				<Grid item xs={12} sm={12} md={12} lg={5} className="auth-left-grid">
					<Box className="content sign-container">
						<Box className="mobile-auth-logo">
							<img src="/static/logo.svg" alt="auth-logo" />
						</Box>
						<Typography className="title">{t('login.signin')}</Typography>
						<Typography className="desription">{t('login.description')}</Typography>

						<Box className="form-content">
							<Formik
								initialValues={{
									email: '',
									password: '',
								}}
								validationSchema={Yup.object().shape({
									email: Yup.string()
										.email(t('validation.valid_email'))
										.max(255)
										.required(t('validation.required_message')),
									password: Yup.string()
										.required(t('validation.required_message'))
										.min(6, t('validation.passowrd_length')),
								})}
								onSubmit={async (requestData) => {
									setLoader(true);

									try {
										const { status, data } = await axiosInstance.post(URLS.signIn, requestData);

										if (status === 200) {
											setLoader(false);

											if (data?.isVerify === false) {
												setTimeout(() => {
													navigate(`/verify-otp`, { state: `${data?.data?.email}` });
													// navigate(`/verify-otp/${data?.data?.email}`);
												}, 3000);
											}

											setApiMessage('success', data?.message);
											tokenSaveHandler(data?.data);
											previousPath && navigate(previousPath);
											userSocket.emit('JoinSocket', {
												user_id: data?.data?.user_id,
											});

											// if (data?.data?.status === 'pending') {
											//   navigate(`/verify-otp/${data?.data?.email}`);
											// }
										}
									} catch (error) {
										setLoader(false);
										setApiMessage('error', error?.response?.data?.message);
									}
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} className="text-field-grid">
												<MatrixTextField
													error={Boolean(touched.email && errors.email)}
													fullWidth
													helperText={touched.email && errors.email}
													variant="filled"
													placeholder={`${t('email')}*`}
													label={`${t('email')}*`}
													className="matrix-textfield"
													InputProps={{
														endAdornment: touched.email && errors.email ? <ErrorIcon /> : null,
													}}
													size="small"
													name="email"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.email}
												/>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid position-relative">
												<MatrixTextField
													error={Boolean(touched.password && errors.password)}
													fullWidth
													helperText={touched.password && errors.password}
													variant="filled"
													placeholder={`${t('password')}*`}
													label={`${t('password')}*`}
													className="matrix-textfield"
													InputProps={{
														endAdornment: touched.password && errors.password ? <ErrorIcon /> : null,
													}}
													size="small"
													name="password"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.password}
													type={!flag ? 'password' : 'text'}
												/>
												<IconButton className="eye-icons" onClick={() => setFlag(!flag)}>
													{!flag ? <EyeSlash /> : <EyeIcon />}
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12}>
												<Button variant="contained" fullWidth type="submit" className="signup-button">
													{t('login.signin')}
												</Button>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>
						</Box>

						<p className="quote">
							<span>{t('or')}</span>
						</p>

						{/* {/ Signup Using Social Media /} */}
						<Box className="social-login-block">
							<SocialLogin
								provider="facebook"
								scope="email,public_profile"
								appId={Config.FacebookAppID}
								callback={(user, error) => {
									if (user) {
										SocialLoginHandler(user['_token'].accessToken, 'facebook');
									}
								}}
							>
								<IconButton>
									<FacebookLogo />
								</IconButton>
							</SocialLogin>

							{/* <IconButton onClick={appleSignIn}>
                <AppleLogo />
              </IconButton> */}

							{/* <AppleLogin
                clientId="au.com.wearunitedsid"
                redirectURI="https://wearunited.com.au/"
              /> */}

							<SocialLogin
								provider="google"
								appId={Config.GoogleAppID}
								scope="email,profile"
								callback={(user, error) => {
									if (user) {
										SocialLoginHandler(user['_token'].accessToken, 'google');
									}
								}}
							>
								<IconButton>
									<GoogleLogo />
								</IconButton>
							</SocialLogin>
						</Box>

						<Box className="fancy-link">
							<Box style={{ marginBottom: '9px' }}>
								<NavLink to="/forgot-password" className="switch_to_signin">
									{t('login.forgot_password')}
								</NavLink>
							</Box>
							<Button
								fullWidth
								variant="contained"
								className="donthve-acc-btn"
								onClick={() => {
									navigate('/sign-up');
								}}
							>
								{t('login.dont_have_account')}
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
