import { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import CommunityEventCard from 'src/views/component/UI/EventCard';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import './event.scss';
import { useTranslation } from 'react-i18next';

const CommunityEvents = ({ id, isView }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [communityEvents, setCommunityEvents] = useState([]);
	const [taggedEventsList, setTaggedEventsList] = useState([]);

	const fetchCommunityEventList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityEvent + `${id}`);

			if (status === 200) {
				setCommunityEvents(data?.eventDetails);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const fetchTaggedEventList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getTaggedEvents + `${id}`);

			if (status === 200) {
				setTaggedEventsList(data?.eventDetails);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const joinEvent = async (eventId) => {
		var requestParams = {
			event_id: eventId,
			community_id: id,
			type: 'community',
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.joinEvent, requestParams);

			if (status === 200) {
				// TODO: Need BA
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		id && fetchCommunityEventList();
		id && fetchTaggedEventList();
	}, [id]);

	return (
		<>
			<Box className="event-container mb-9">
				<Box className="event-title-box">
					<Typography className="event-title">{t('community.events_create_for_communiy')}</Typography>

					{isView ? null : (
						<IconButton onClick={() => navigate('/events/create')}>
							<BaddIcon />
						</IconButton>
					)}
				</Box>

				<Box className="community-event-list-box">
					{communityEvents && communityEvents?.length > 0 ? (
						<Grid container spacing={3}>
							{communityEvents?.map((event, index) => {
								return (
									<>
										<Grid item xs={12} sm={12} md={6} lg={6} key={index}>
											<CommunityEventCard
												isDiscover={false}
												isCommunity={true}
												eventProfile={event?.event_profile_data}
												eventType={event?.event_type}
												eventName={event?.name}
												startTime={event?.event_date[0]?.start_time}
												eventId={event?.id}
												joinEvent={joinEvent}
												eventOwner={event?.event_owner === 'yes' ? true : false}
												eventPostId={event?.post_id}
											/>
										</Grid>
									</>
								);
							})}
						</Grid>
					) : (
						<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
					)}
				</Box>
			</Box>
			<Box className="event-container mb-9">
				<Box className="event-title-box">
					<Typography className="event-title">{t('community.tagged_events')}</Typography>

					{/* {isView ? null : (
          <IconButton>
            <BaddIcon />
          </IconButton>
        )} */}
				</Box>

				<Box className="community-event-list-box">
					{taggedEventsList && taggedEventsList?.length > 0 ? (
						<Grid container spacing={3}>
							{taggedEventsList?.map((event, index) => {
								return (
									<>
										<Grid item xs={12} sm={12} md={6} lg={6} key={index}>
											<CommunityEventCard
												isDiscover={true}
												eventProfile={event?.event_profile_data}
												eventType={event?.event_type}
												eventName={event?.name}
												startTime={event?.event_date[0]?.start_time}
												eventId={event?.id}
												eventOwner={event?.event_owner === 'yes' ? true : false}
												eventPostId={event?.post_id}
											/>
										</Grid>
									</>
								);
							})}
						</Grid>
					) : (
						<Typography className="no-data-text">{t('newsfeed.no_data_found')}</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};

export default CommunityEvents;
