import { useMemo } from 'react';
import { Paper, Box, Grid, Typography, MenuItem } from '@material-ui/core';
import { Config } from 'src/helpers/context/config';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnIcon } from 'src/assets/images/icons/language/en.svg';
import { ReactComponent as RuIcon } from 'src/assets/images/icons/language/ru.svg';
import { ReactComponent as ThIcon } from 'src/assets/images/icons/language/th.svg';
import { ReactComponent as PtIcon } from 'src/assets/images/icons/language/pt.svg';

import { whitelist } from '../../utils/i18n';

const LANGUAGE_CODE_TO_ICON = {
	ru: RuIcon,
	en: EnIcon,
	th: ThIcon,
	pt: PtIcon,
};

const getLanguageOptions = (t) =>
	whitelist
		.map((code) => ({
			code,
			label: t(`language.${code}`),
			icon: LANGUAGE_CODE_TO_ICON[code],
		}))
		.sort((langA, langB) => langA.label.localeCompare(langB.label));

const Footer = ({ auth, user_statics, verify_count }) => {
	const { t, i18n } = useTranslation();

	const handleChangeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	const languageOptions = useMemo(() => getLanguageOptions(t), [t]);

	return (
		<Paper className="footer-main" style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} elevation={3}>
			<Box className="footer-container">
				<Typography className="cm-user-number">
					{auth ? (
						<Typography className="cm-user-title">
							CM USERS:&nbsp;<span>{user_statics?.verify_user_count}</span>
						</Typography>
					) : verify_count !== undefined ? (
						verify_count !== null ? (
							<Typography className="cm-user-title">
								CM USERS:&nbsp;
								<span>{verify_count !== undefined ? (verify_count !== null ? verify_count : null) : null}</span>
							</Typography>
						) : null
					) : null}
				</Typography>
				<Typography>Version {Config.siteVersion}</Typography>
				<div className="language-dd">
					<Grid item xs={12} md={12}>
						<MatrixTextField
							select
							fullWidth
							size="small"
							variant="filled"
							SelectProps={{
								MenuProps: {
									PopoverClasses: {
										paper: 'sitelanguage-paper',
									},
								},
							}}
							value={i18n.language}
							className="matrix-textfield language-selector"
							onChange={handleChangeLanguage}
						>
							{languageOptions.map(({ code, icon: Icon, label }) => (
								<MenuItem key={code} value={code}>
									<Icon className="language-selector__icon" />
									<Typography className="flag-name">{label}</Typography>
								</MenuItem>
							))}
						</MatrixTextField>
					</Grid>
				</div>
			</Box>
		</Paper>
	);
};

export default Footer;
