import { useState, useEffect } from 'react';
import {
	Avatar,
	Box,
	Button,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	TextField,
	Typography,
	withStyles,
	IconButton,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { ReactComponent as ApproveMemberIcon } from 'src/assets/images/icons/community/member-approve-icon.svg';
import { ReactComponent as RemoveMemberIcon } from 'src/assets/images/icons/community/remove-member-icon.svg';
import './member.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams, useLocation } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { useSelector } from 'react-redux';
import MakeAdminModeratorModal from './MakeAdminModeratorModal';
import InviteAddMembers from './InviteAddMembers';
import { useTranslation } from 'react-i18next';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

const CommunityMembers = ({ isView, selREQ }) => {
	// getCommunityMember
	const location = useLocation();
	const { t } = useTranslation();
	const { id } = useParams();
	const [open, setOpen] = useState(false);
	const [tab, setTab] = useState(selREQ === 'request' ? 'sent' : 'accepted');
	const [searchString] = useState(''); //setSearchString
	const [memberList, setMemberList] = useState([]);
	const [allMemberList, setAllMemberList] = useState([]);
	const [title, setTitle] = useState('');
	const [modal, setModal] = useState('');

	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	// FETCH COMMUNITY MEMBER
	const fetchCommunityMember = async (tabVal, ss, type) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getCommunityMember + `/${id}`, {
				params: {
					type: tabVal,
					name: ss,
					...(type && { admin_type: type }),
				},
			});

			if (status === 200) {
				setMemberList(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH ALL MEMBER LIST
	const fetchAllMemberList = async (ss) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList, {
				params: {
					community_id: Number(id),
					name: ss,
				},
			});

			if (status === 200) {
				setAllMemberList(data?.getAllUsers);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const combatDialogHandler = (title) => {
		setOpen(true);
		setTitle(title);
	};
	const closeDialog = () => {
		setOpen(false);
		fetchCommunityMember(tab, searchString);
	};

	const tabChangeHandler = (val) => {
		setTab(val);
		val !== 'addinvite' ? fetchCommunityMember(val, searchString) : fetchAllMemberList(searchString);
	};
	// if (selREQ === true) {
	//   tabChangeHandler('sent');
	// }

	const handleChange = (e) => {
		tab === 'addinvite' ? fetchAllMemberList(e.target.value) : fetchCommunityMember(tab, e.target.value);
	};
	const communityAction = async (data) => {
		var status = tab === 'accepted' ? 'blocked' : tab === 'sent' ? 'accepted' : tab === 'blocked' ? 'unblock' : '';
		var requestParams = {
			user_id: data?.user_id,
			community_id: data?.community_id,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.joinCommunity, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchCommunityMember(tab, searchString);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const inviteMember = async (data) => {
		var requestParams = {
			user_id: data?.id,
			community_id: Number(id),
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.inviteMember, requestParams);

			if (status === 200) {
				fetchAllMemberList(searchString);
				setApiMessage('error', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const handleClick = async (item, type, status) => {
		var requestParams = {
			user_id: item?.user_id,
			community_id: item?.community_id,
			type: type,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.assignRole, requestParams);

			if (status === 200) {
				// updateMemberList(tab, '')
				setApiMessage('success', data?.message);
				fetchCommunityMember(tab, searchString, modal);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const transferOwnership = async (data) => {
		var requestData = {
			user_id: data?.user_id,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.transferOwnership + `${id}`, requestData);

			if (status === 200) {
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchCommunityMember(tab, searchString);
		fetchAllMemberList(searchString);
	}, []);

	useEffect(() => {
		selREQ !== 'request' && location?.state?.memeberTabValue && setTab(location?.state?.memeberTabValue);
	}, [location?.state?.memeberTabValue]);

	useEffect(() => {
		communityDetails?.communityData?.join_status === 'accepted' && fetchCommunityMember('accepted', searchString);
		communityDetails?.communityData?.join_status === null && fetchCommunityMember('accepted', searchString);
	}, [communityDetails?.communityData?.join_status]);

	return (
		<>
			{/* // isView ? (
    //   // VISITOR VIEW
    //   <Box className="community-members-box">
    //     <Typography className="member-title">
    //       {t('community.members')}
    //     </Typography>

    //     <Box className="member-list-box no-tb">
    //       <Box className="search-box">
    //         <SearchTextField */}
			{/* //           variant="outlined"
    //           fullWidth
    //           size="small"
    //           placeholder="Search"
    //           InputProps={{
    //             startAdornment: (
    //               <InputAdornment position="start">
    //                 <SearchIcon />
    //               </InputAdornment>
    //             )
    //           }}
    //         />

    //         <Box className="member-list-container">
    //           <List dense>
    //             {[1, 2, 3].map((obj, index) => {
    //               return (
    //                 <ListItem key={index}>
    //                   <ListItemAvatar>
    //                     <Avatar src="" />
    //                   </ListItemAvatar>
    //                   <ListItemText primary="User1" />
    //                 </ListItem>
    //               );
    //             })}
    //           </List>
    //         </Box>
    //       </Box>
    //     </Box>
    //   </Box>
    // ) : (
    // OWNER VIEW */}

			{/* MEMBERS */}
			<Box className="community-members-box mb-9">
				<Box className="community-am-box">
					<Typography className="member-title">{t('community.members')}</Typography>
					{/* {
            communityDetails?.communityData?.status !== 'closed' && (
              <IconButton
                onClick={() => {
                  setModal('addinvite');
                  combatDialogHandler(t('community.invite_add_member'));
                }}
              >
                <BaddIcon />
              </IconButton>
            )
          } */}
				</Box>
				<Box className="active-request-tab-box">
					<Button
						onClick={() => {
							tabChangeHandler('accepted');
						}}
						varinat="contained"
						className={tab === 'accepted' ? 'ar-tab ar-active' : 'ar-tab'}
					>
						{t('community.active')}
					</Button>
					{!isView && (
						<Button
							onClick={() => {
								tabChangeHandler('sent');
							}}
							varinat="contained"
							className={tab === 'sent' ? 'ar-tab ar-active' : 'ar-tab'}
						>
							{t('community.request')}
						</Button>
					)}
					{!isView && (
						<Button
							onClick={() => {
								tabChangeHandler('blocked');
							}}
							varinat="contained"
							className={tab === 'blocked' ? 'ar-tab ar-active' : 'ar-tab'}
						>
							{t('community.ban_list')}
						</Button>
					)}
					{!isView && (
						<Button
							onClick={() => {
								tabChangeHandler('addinvite');
							}}
							varinat="contained"
							className={tab === 'addinvite' ? 'ar-tab ar-active' : 'ar-tab'}
						>
							{t('community.invite_add_member')}
						</Button>
					)}
					{!isView && (
						<Button
							onClick={() => {
								tabChangeHandler('transfer');
							}}
							varinat="contained"
							className={tab === 'transfer' ? 'ar-tab ar-active' : 'ar-tab'}
						>
							{t('community.transfer_ownership')}
						</Button>
					)}
				</Box>

				{/* {tab === 'active' ? ( */}
				{/* ALREADY MEMBER LIST */}

				<Box className="member-list-box">
					<Box className="search-box">
						{communityDetails?.communityData?.status !== 'closed' && (
							<SearchTextField
								variant="outlined"
								fullWidth
								size="small"
								placeholder="Search"
								onChange={handleChange}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						)}

						<Box className="member-list-container">
							{tab === 'addinvite' ? (
								<List dense>
									{allMemberList && (
										<>
											{allMemberList.length > 0 ? (
												<>
													{allMemberList.map((obj, index) => {
														return (
															<ListItem key={index}>
																<ListItemAvatar>
																	<Avatar src={obj?.item_profile_data} />
																</ListItemAvatar>
																<ListItemText primary={obj?.user_full_name} />
																<ListItemSecondaryAction>
																	{!isView && (
																		<Button
																			disableRipple
																			disableFocusRipple
																			disableTouchRipple
																			edge="end"
																			className="member-btn"
																			onClick={() => inviteMember(obj)}
																			// startIcon={
																			//   tab === 'accepted' ? (
																			//     <RemoveMemberIcon />
																			//   ) : (
																			//     <ApproveMemberIcon />
																			//   )
																			// }
																		>
																			{t('community.invite')}
																		</Button>
																	)}
																</ListItemSecondaryAction>
															</ListItem>
														);
													})}
												</>
											) : (
												<Typography className="no-member-txt">{t('community.no_member_found')}</Typography>
											)}
										</>
									)}
								</List>
							) : tab === 'transfer' ? (
								<List dense>
									{memberList?.connectionAdminData && (
										<>
											{memberList?.connectionAdminData.length > 0 ? (
												<>
													{memberList?.connectionAdminData.map((obj, index) => {
														return (
															<ListItem key={index}>
																<ListItemAvatar>
																	<Avatar src={obj?.item_profile_data} />
																</ListItemAvatar>
																<ListItemText primary={obj?.user_name} />
																<ListItemSecondaryAction>
																	{!isView && (
																		<Button
																			disableRipple
																			disableFocusRipple
																			disableTouchRipple
																			edge="end"
																			className="member-btn"
																			onClick={() => transferOwnership(obj)}
																			// startIcon={
																			//   tab === 'accepted' ? (
																			//     <RemoveMemberIcon />
																			//   ) : (
																			//     <ApproveMemberIcon />
																			//   )
																			// }
																		>
																			{t('community.transfer')}
																		</Button>
																	)}
																</ListItemSecondaryAction>
															</ListItem>
														);
													})}
												</>
											) : (
												<Typography className="no-member-txt">{t('community.no_member_found')}</Typography>
											)}
										</>
									)}
								</List>
							) : (
								<List dense>
									{memberList?.connectionCommunityData && (
										<>
											{memberList?.connectionCommunityData.length > 0 ? (
												<>
													{memberList?.connectionCommunityData.map((obj, index) => {
														return (
															<ListItem key={index}>
																<ListItemAvatar>
																	<Avatar src={obj?.item_profile_data} />
																</ListItemAvatar>
																<ListItemText primary={obj?.user_name} />
																{obj?.type !== 'owner' && (
																	<ListItemSecondaryAction>
																		{!isView && (
																			<Button
																				disableRipple
																				disableFocusRipple
																				disableTouchRipple
																				edge="end"
																				className="member-btn"
																				onClick={() => communityAction(obj)}
																				startIcon={tab === 'accepted' ? <RemoveMemberIcon /> : <ApproveMemberIcon />}
																			>
																				{tab === 'accepted'
																					? t('community.ban_member')
																					: tab === 'sent'
																					? t('community.approve_request')
																					: t('community.unban_member')}
																			</Button>
																		)}
																	</ListItemSecondaryAction>
																)}
															</ListItem>
														);
													})}
												</>
											) : (
												<Typography className="no-member-txt">{t('community.no_member_found')}</Typography>
											)}
										</>
									)}
								</List>
							)}
						</Box>
					</Box>
				</Box>
				{/* // ) : (
        // APPROVE MEMBER REQUEST LIST
        // <Box className="member-list-box">
        //   <Box className="search-box">
        //     <SearchTextField
        //       variant="outlined"
        //       fullWidth
        //       size="small"
        //       placeholder="Search"
        //       InputProps={{
        //         startAdornment: (
        //           <InputAdornment position="start">
        //             <SearchIcon />
        //           </InputAdornment>
        //         )
        //       }}
        //     />
        //     <Box className="member-list-container">
        //       <List dense>
        //         {[1, 2, 3].map((obj, index) => {
        //           return (
        //             <ListItem key={index}>
        //               <ListItemAvatar>
        //                 <Avatar src="" />
        //               </ListItemAvatar>
        //               <ListItemText primary="User1" />
        //               <ListItemSecondaryAction>
        //                 <Button
        //                   disableRipple
        //                   disableFocusRipple
        //                   disableTouchRipple
        //                   edge="end"
        //                   className="member-btn"
        //                   startIcon={<ApproveMemberIcon />}
        //                 >
        //                   {t('community.approve_request')}
        //                 </Button>
        //               </ListItemSecondaryAction>
        //             </ListItem>
        //           );
        //         })}
        //       </List>
        //     </Box>
        //   </Box>
        // </Box>
      // )} */}
			</Box>

			{/* ADMIN */}
			{!isView && tab === 'accepted' && (
				<Box className="community-members-box mb-9">
					<Box className="community-am-box">
						<Typography className="member-title">{t('community.admins')}</Typography>
						{communityDetails?.communityData?.status !== 'closed' && (
							<IconButton
								onClick={() => {
									setModal('admin');
									combatDialogHandler(t('community.admins'));
								}}
							>
								<BaddIcon />
							</IconButton>
						)}
					</Box>
					<Box className="member-list-box">
						<Box className="member-list-container">
							<List dense>
								{memberList?.connectionAdminData && (
									<>
										{memberList?.connectionAdminData.length > 0 ? (
											<>
												{memberList?.connectionAdminData.map((obj, index) => {
													return (
														<ListItem key={index}>
															<ListItemAvatar>
																<Avatar src={obj?.item_profile_data} />
															</ListItemAvatar>
															<ListItemText primary={obj?.user_name} />
															{obj?.type !== 'owner' && (
																<ListItemSecondaryAction>
																	<Button
																		disableRipple
																		disableFocusRipple
																		disableTouchRipple
																		edge="end"
																		className="member-btn"
																		onClick={() => handleClick(obj, 'admin', 'cancelled')}
																		startIcon={<RemoveMemberIcon />}
																	>
																		{/* {tab === 'accepted'
                                    ? t('community.ban_member')
                                    : tab === 'sent'
                                      ? t('community.approve_request')
                                      : t('community.unban_member')} */}
																		{t('community.remove_as_admin')}
																	</Button>
																</ListItemSecondaryAction>
															)}
														</ListItem>
													);
												})}
											</>
										) : (
											<Typography className="no-member-txt">{t('community.no_member_found')}</Typography>
										)}
									</>
								)}
							</List>
						</Box>
					</Box>
				</Box>
			)}

			{/* MODERATORS */}
			{!isView && tab === 'accepted' && (
				<Box className="community-members-box">
					<Box className="community-am-box">
						<Typography className="member-title">{t('community.moderators')}</Typography>
						{communityDetails?.communityData?.status !== 'closed' && (
							<IconButton
								onClick={() => {
									setModal('moderator');
									combatDialogHandler(t('community.moderators'));
								}}
							>
								<BaddIcon />
							</IconButton>
						)}
					</Box>

					<Box className="member-list-box">
						<Box className="member-list-container">
							<List dense>
								{memberList?.connectionModeratorData && (
									<>
										{memberList?.connectionModeratorData.length > 0 ? (
											<>
												{memberList?.connectionModeratorData.map((obj, index) => {
													return (
														<ListItem key={index}>
															<ListItemAvatar>
																<Avatar src={obj?.item_profile_data} />
															</ListItemAvatar>
															<ListItemText primary={obj?.user_name} />
															<ListItemSecondaryAction>
																<Button
																	disableRipple
																	disableFocusRipple
																	disableTouchRipple
																	edge="end"
																	className="member-btn"
																	onClick={() => handleClick(obj, 'moderator', 'cancelled')}
																	startIcon={<RemoveMemberIcon />}
																>
																	{t('community.remove_as_moderator')}
																</Button>
															</ListItemSecondaryAction>
														</ListItem>
													);
												})}
											</>
										) : (
											<Typography className="no-member-txt">{t('community.no_member_found')}</Typography>
										)}
									</>
								)}
							</List>
						</Box>
					</Box>
				</Box>
			)}
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					modal === 'addinvite' ? (
						<InviteAddMembers modal={modal} />
					) : (
						<MakeAdminModeratorModal
							// memberList={memberList}
							modal={modal}
							// handleClick={handleClick}
							tab={tab}
							// searchString={searchString}
							updateMemberList={fetchCommunityMember}
						/>
					)
				}
			/>
		</>
	);
	// );
};

export default CommunityMembers;
