import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Avatar,
	Box,
	Button,
	Checkbox, // Chip,
	Fade,
	Grid,
	Typography,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	Link,
	ListItemText,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './update-post.scss';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useSelector } from 'react-redux';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BoldSvg from 'src/assets/images/icons/community/bold.svg';
import ItalicSvg from 'src/assets/images/icons/community/italic.svg';
import UnderlineSvg from 'src/assets/images/icons/community/underlined.svg';
import LinkSvg from 'src/assets/images/icons/community/link.svg';
import AlignRightSvg from 'src/assets/images/icons/community/align-right.svg';
import AlignCenterSvg from 'src/assets/images/icons/community/align-center.svg';
import AlignLeftSvg from 'src/assets/images/icons/community/align-left.svg';
import NumberedSvg from 'src/assets/images/icons/community/numbered.svg';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import CustomOption from '../../../../../../UI/CustomOption';
import { useTranslation } from 'react-i18next';

const CreatePost = ({
	user,
	token,
	postData,
	cancelBtnClick,
	fetchCommunityPostList,
	tab,
	editAccess,
	allUsersList,
}) => {
	const { id } = useParams();
	const { t } = useTranslation();
	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	const userImage = communityDetails && communityDetails?.communityData?.owner_item_profile_data;
	// const [checked, setChecked] = useState([]);
	const [sStatus, setSponsoredPost] = useState(false);
	const [stickyPost, setStickyPost] = useState(false);
	const [deletedItemArray, setDeletedItemArray] = useState([]);
	const [allowComments, setAllowCommentPost] = useState(false);
	const [text, setText] = useState(EditorState.createEmpty());
	const [allImages, setAllImages] = useState([]);
	const [pImages, setPreviewImg] = useState([]);
	const [textError, setTextError] = useState('');
	const [maxLength] = useState(1000);
	const [imageStatus, setImageStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	var textLength = text.getCurrentContent().getPlainText('').length;
	var charcterLimit = maxLength - textLength > 0 ? maxLength - textLength : 0;
	// const typeList = [
	//     // { id: 0, name: 'Featured Post' },
	//     { id: 1, name: 'Sticky Post' },
	//     { id: 2, name: 'Allow for comments' }
	// ];

	const updateCommunityPost = async (data) => {
		setIsLoading(true);
		const rawHTML = text ? draftToHtml(convertToRaw(text.getCurrentContent())) : '';
		var postListArray = [...allImages];
		var filteredArray = [];

		postListArray.map((item, i) => {
			var object = {
				type: item?.type,
				item_id: item?.id,
			};

			return (filteredArray[i] = object);
		});
		filteredArray.push({
			type: 'text',
			data: rawHTML,
		});

		if (text.getCurrentContent().hasText() || Boolean(allImages.length)) {
			var requestParameter = {
				post: {
					id: postData?.id,
					sponsored: sStatus ? 'yes' : 'no',
					sticky: stickyPost ? 'yes' : 'no',
					comments: allowComments ? 'yes' : 'no',
					name: data?.title,
					postList: filteredArray,
				},
			};

			try {
				const { status, data } = await axiosInstance.put(URLS.updatePost, requestParameter);

				if (status === 200) {
					const tabValue =
						tab === '1'
							? 'best'
							: tab === '2'
							? 'trending'
							: tab === '3'
							? 'date'
							: tab === '4'
							? 'top'
							: tab === '5'
							? 'recommended'
							: 'best';

					setApiMessage('success', data?.message);
					fetchCommunityPostList(tabValue);
					cancelBtnClick();
					// setCreateClick(false);
					// setChecked([]);
					// setSponsoredPost(false)
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			} finally {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
			setTextError(t('validation.required_message'));
		}
	};
	const deletePostItem = (id) => {
		var deletedImage = allImages && allImages?.filter((item, index) => index !== id);

		setDeletedItemArray(deletedImage);
		setAllImages(deletedImage);
	};
	const handleImageLoaded = () => {
		setImageStatus(false);
	};

	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const showItems = (item, index) => {
		switch (item?.type) {
			case 'doc':
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
			case 'image':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<Avatar
							src={imageStatus === true ? loaderGif : URLS.mediaURL + item?.location}
							alt="post-image"
							onError={(e) => {
								e.onerror = null;
								e.target.src = placeholder;
							}}
							onLoad={handleImageLoaded}
						/>
					</Box>
				);
			case 'video':
				return (
					<Box width="200px" className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player audio-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
		}
	};

	useEffect(() => {
		var postDescription = postData && postData?.PostLists.find((item) => item?.Item?.type === 'text');

		const contentBlock = htmlToDraft(postDescription?.Item?.data);

		setSponsoredPost(postData?.sponsored === 'yes');
		setStickyPost(postData?.sticky === 'yes');
		setAllowCommentPost(postData?.comments === 'yes');

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

			setText(EditorState.createWithContent(contentState));
		} else {
			setText(EditorState.createWithContent());
		}
	}, []);
	// var postDescription =
	//   postData && postData?.PostLists.find(item => item?.Item?.type === 'text');
	const setImageList = (img, pimg) => {
		img[0] && allImages.push(img[0]);
		setPreviewImg(pimg);
	};

	const handleChangeEditior = (editorState) => {
		setText(editorState);
		setTextError('');
	};
	var postDescription = postData && postData?.PostLists.find((item) => item?.Item?.type === 'text');

	useEffect(() => {
		if (postData) {
			var postAllData = postData?.PostLists.filter((item) => item?.Item?.type !== 'text');
			var formattedAllData =
				postAllData &&
				postAllData?.map((item, index) => {
					var splitdata = item?.Item?.data.split('/');

					splitdata = splitdata && splitdata[splitdata.length - 1];

					return {
						id: item?.item_id,
						type: item?.Item?.type,
						location: splitdata,
					};
				});
			var postDataLink =
				postAllData &&
				postAllData?.map((item, index) => {
					return item?.Item?.data;
				});

			setAllImages(formattedAllData);
			setPreviewImg(postDataLink);
		}
	}, [postData]);

	return (
		<Fade in>
			<Box className="creating-post-box">
				<Box className="user-details">
					<Avatar src={postData?.user_profile_data} />
					<Typography className="user-name">
						{/* {communityDetails &&
                            communityDetails?.communityData?.owner_user_full_name} */}
						{postData?.user_full_name}
					</Typography>
				</Box>
				<Box className="text-field-grid post-title-input">
					<Formik
						initialValues={{
							title: postData?.name ? postData?.name : '', //   text: postDescription?.Item?.data
							//     ? postDescription?.Item?.data
							//     : ''
						}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required(t('validation.required_message')), //   text: Yup.string().required(t('validation.required_message'))
						})}
						onSubmit={(requestData) => {
							updateCommunityPost(requestData);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12} className="text-field-grid">
										<MatrixTextField
											error={Boolean(touched.title && errors.title)}
											fullWidth
											helperText={
												touched.title ? (
													touched.title && (
														<Box disply="flex" justifyContent="center">
															{errors.title}
															<span className="field-character-count">{values.title.length}/150</span>
														</Box>
													)
												) : (
													<span className="field-character-count">{values.title.length}/150</span>
												)
											}
											variant="filled"
											InputProps={{
												maxLength: 500,
												endAdornment: touched.title && errors.title ? <ErrorIcon /> : null,
											}}
											label={t('community.post_title')}
											placeholder={t('community.post_title')}
											className="matrix-textfield"
											size="small"
											name="title"
											onBlur={handleBlur}
											onChange={handleChange}
											inputProps={{ maxLength: 150 }}
											value={values.title}
										/>
									</Grid>
									<Grid item xs={12} md={12} className="text-field-grid cp-cnt">
										<Box className="custome-text-editior">
											<Editor
												handleBeforeInput={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													if (val && textLength >= maxLength) {
														return 'handled';
													}

													return 'not-handled';
												}}
												handlePastedText={(val) => {
													const textLength = text.getCurrentContent().getPlainText().length;

													return val.length + textLength >= maxLength;
												}}
												mention={{
													separator: ' ',
													trigger: '@',
													suggestions: allUsersList,
												}}
												editorState={text}
												toolbarCustomButtons={[
													<CustomOption
														setImageList={setImageList}
														token={token}
														deletedItemArray={deletedItemArray}
													/>,
												]}
												wrapperClassName="demo-wrapper"
												editorClassName="demo-editor"
												onEditorStateChange={(editorState) => {
													handleChangeEditior(editorState);
												}}
												placeholder={t('community.text')}
												toolbar={{
													options: ['link'], // HIDE-MVP: CM-75 CM-33
													// options: ['inline', 'link', 'textAlign', 'list'],
													// inline: {
													// 	inDropdown: false,
													// 	className: 'demo-option-custom',
													// 	options: ['bold', 'italic', 'underline'],
													// 	bold: {
													// 		icon: BoldSvg,
													// 	},
													// 	italic: {
													// 		icon: ItalicSvg,
													// 	},
													// 	underline: {
													// 		icon: UnderlineSvg,
													// 	},
													// },
													// textAlign: {
													// 	options: ['left', 'right', 'center'],
													// 	left: {
													// 		icon: AlignLeftSvg,
													// 	},
													// 	right: {
													// 		icon: AlignRightSvg,
													// 	},
													// 	center: {
													// 		icon: AlignCenterSvg,
													// 	},
													// },
													// list: {
													// 	inDropdown: false,
													// 	className: undefined,
													// 	component: undefined,
													// 	dropdownClassName: undefined,
													// 	options: ['ordered'],
													// 	ordered: {
													// 		icon: NumberedSvg,
													// 	},
													// },
													link: {
														inDropdown: false,
														className: undefined,
														component: undefined,
														dropdownClassName: undefined,
														options: ['link'],
														link: {
															icon: LinkSvg,
														},
													},
												}}
											/>
											<span className="field-character-count" style={{ paddingRight: '18px', marginTop: '4px' }}>
												{`${charcterLimit}/${maxLength}`}
											</span>
											<span className="editior-error-message">{textError}</span>
										</Box>
									</Grid>
									<Grid item xs={12} md={12} className="image-wrapper">
										<Box className="image-wrapper-inner">
											{allImages.map((imageSrc, index) => {
												return showItems(imageSrc, index);
											})}
										</Box>
									</Grid>
									{/* <Grid item xs={12} md={12} className="text-field-grid cp-cnt">
                      <MatrixTextField
                        error={Boolean(touched.text && errors.text)}
                        fullWidth
                        helperText={
                          touched.text ? (
                            touched.text && (
                              <Box disply="flex" justifyContent="center">
                                {errors.text}
                                <span className="field-character-count">
                                  {values.text.length}/500
                                </span>
                              </Box>
                            )
                          ) : (
                            <span className="field-character-count">
                              {values.text.length}/500
                            </span>
                          )
                        }
                        variant="filled"
                        InputProps={{
                          maxLength: 500,
                          endAdornment:
                            touched.text && errors.text ? <ErrorIcon /> : null
                        }}
                        label={t('community.text')}
                        placeholder={t('community.text')}
                        className="matrix-textfield"
                        size="small"
                        name="text"
                        multiline
                        rows={8}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 500 }}
                        value={values.text}
                      />
                    </Grid> */}
									<>
										{(editAccess || user?.isAdmin) && (
											<Grid item xs={12} md={12}>
												<Box className="select-posting-type">
													<Typography className="check-heading">{t('community.verified_user_options')}</Typography>
													<List>
														<ListItem dense button onClick={() => setSponsoredPost(!sStatus)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={sStatus}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{
																		'aria-labelledby': 'checkbox-list-label',
																	}}
																/>
															</ListItemIcon>
															<ListItemText
																id="checkbox-list-label"
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{t('community.sponsored_post')}</Box>
																	</Box>
																}
															/>
														</ListItem>
													</List>
												</Box>
											</Grid>
										)}

										<Grid item xs={12} md={12}>
											<Box className="select-posting-type">
												<Typography className="check-heading">{t('community.admin_options')}</Typography>
												{(editAccess || user?.isAdmin) && (
													<List>
														<ListItem dense button onClick={() => setStickyPost(!stickyPost)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={stickyPost}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{
																		'aria-labelledby': 'checkbox-list-label1',
																	}}
																/>
															</ListItemIcon>
															<ListItemText
																id="checkbox-list-label1"
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{t('community.sticky_post')}</Box>
																	</Box>
																}
															/>
														</ListItem>
													</List>
												)}

												<List>
													<ListItem dense button onClick={() => setAllowCommentPost(!allowComments)}>
														<ListItemIcon>
															<Checkbox
																icon={<CheckBoxIcon />}
																checkedIcon={<CheckedBoxIcon />}
																edge="start"
																checked={allowComments}
																tabIndex={-1}
																disableRipple
																disableTouchRipple
																disableFocusRipple
																inputProps={{
																	'aria-labelledby': 'checkbox-list-label2',
																}}
															/>
														</ListItemIcon>
														<ListItemText
															id="checkbox-list-label2"
															primary={
																<Box className="type-text-block">
																	<Box className="label-name">{t('community.allow_for_comments')}</Box>
																</Box>
															}
														/>
													</ListItem>
												</List>
											</Box>
										</Grid>
									</>
									<Grid item xs={12} md={12} className="form-btn">
										<Button
											fullWidth
											variant="contained"
											type="submit"
											className="create-post-btn cancel-btn"
											onClick={cancelBtnClick}
										>
											{t('button_text.cancel')}
										</Button>
										<Button
											fullWidth
											variant="contained"
											disabled={isLoading}
											type="submit"
											className="create-post-btn"
										>
											{t('community.edit_post')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
		</Fade>
	);
};

export default CreatePost;
