import {
	AUTH_USER,
	LOGOUT_USER,
	GET_COMMUNITY,
	GET_COMMUNITY_DIRECTORY,
	GET_COMMUNITY_DETAILS,
	GET_COMMUNITY_POSTS_DETAILS,
	GET_NEWSFEED_POST_DETAILS,
	GET_ACCOUNT_SETTING_DETAILS,
	GET_ALL_ACTIVITY,
	GET_ALL_MESSAGE_LIST,
	UPDATE_COMMUNITY_POST_DETAILS,
	REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY,
} from '../constants';

export const authUser = (data) => {
	return {
		type: AUTH_USER,
		data: data,
	};
};

export const logoutUser = (data) => {
	return {
		type: LOGOUT_USER,
		data: data,
	};
};

// COMMUNITY ACTION
export const getCommunityList = (data) => {
	return {
		type: GET_COMMUNITY,
		data: data,
	};
};
export const getCommunitiesDirectoryList = (data) => {
	return {
		type: GET_COMMUNITY_DIRECTORY,
		data: data,
	};
};

export const getCommunityDetails = (data) => {
	return {
		type: GET_COMMUNITY_DETAILS,
		data: data,
	};
};

export const getCommunityPostList = (data) => {
	return {
		type: GET_COMMUNITY_POSTS_DETAILS,
		data: data,
	};
};

export const updateCommunityPostAC = (data) => {
	return {
		type: UPDATE_COMMUNITY_POST_DETAILS,
		data: data,
	};
};

export const removeOrAddStickyPostInCommunityAC = (data) => {
	return {
		type: REMOVE_OR_ADD_STICKY_POST_IN_COMMUNITY,
		data: data,
	};
};

// NEWSFEED ACTION
export const getNewsfeedPostList = (data) => {
	return {
		type: GET_NEWSFEED_POST_DETAILS,
		data: data,
	};
};

// ACCOUNT SETTING
export const getAccountSettingData = (data) => {
	return {
		type: GET_ACCOUNT_SETTING_DETAILS,
		data: data,
	};
};

// ALL ACTIVITY
export const getAllActivity = (data) => {
	return {
		type: GET_ALL_ACTIVITY,
		data: data,
	};
};

// MESSAGE
export const getAllMessageList = (data) => {
	return {
		type: GET_ALL_MESSAGE_LIST,
		data: data,
	};
};
