import { memo, useState, useEffect } from 'react';
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import './type.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';

const ProfileType = ({
	isModalLoading,
	setIsModalLoading,
	typeData,
	token,
	getMyProfile,
	fetchUserBadges,
	closeDialog,
}) => {
	const { t } = useTranslation();

	let ttypes =
		typeData?.profile_types?.length > 1 ? typeData?.profile_types?.filter((x) => x !== 0) : typeData?.profile_types;

	const [checked, setChecked] = useState(ttypes || []);

	// PROFILE TYPE LIST
	const typeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	// PROFILE TYPE SELECT HANDLER
	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked.filter((x) => x !== 0)];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	// PROFILE TYPE SAVE HANDLER
	const submitHandler = async () => {
		let types = checked?.length > 0 ? checked?.reduce((a, b) => a + b) : 0;

		try {
			setIsModalLoading(true);
			const { status } = await axiosInstance.put(
				URLS.updateProfile,
				{
					type: types,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			);

			if (status === 200) {
				setApiMessage('success', t('myprofile.profile_updated_success'));
				getMyProfile();
				fetchUserBadges();
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		if (checked.length === 0) {
			setChecked([0]);
		}
		// eslint-disable-next-line
	}, [checked]);

	return (
		<Box className="profile-type-form">
			<List>
				<ListItem
					role={undefined}
					dense
					button
					// onClick={() => {
					//   setChecked([0]);
					// }}
				>
					<ListItemIcon>
						<Checkbox
							icon={<CheckBoxIcon />}
							checkedIcon={<CheckedBoxIcon />}
							edge="start"
							checked={
								true
								// checked.length === 1 && checked.includes(0) ? true : false
							}
							tabIndex={-1}
							disableRipple
							disableTouchRipple
							disableFocusRipple
						/>
					</ListItemIcon>
					<ListItemText
						primary={
							<Box className="type-text-block">
								<Box className="label-name">{t('profile_type.enthusiast')}</Box>
							</Box>
						}
					/>
				</ListItem>
				{typeList.map((type, index) => {
					const labelId = `checkbox-list-label-${type?.id}`;

					return (
						<ListItem key={index} role={undefined} dense button onClick={handleToggle(type?.id)}>
							<ListItemIcon>
								<Checkbox
									icon={<CheckBoxIcon />}
									checkedIcon={<CheckedBoxIcon />}
									edge="start"
									checked={checked.indexOf(type?.id) !== -1}
									tabIndex={-1}
									disableRipple
									disableTouchRipple
									disableFocusRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={
									<Box className="type-text-block">
										<Box className="label-name">{type?.name}</Box>
									</Box>
								}
							/>
						</ListItem>
					);
				})}
			</List>

			<Box className="dialog-submit-button dialog-sticky-btn">
				<Button disabled={isModalLoading} variant="contained" onClick={submitHandler} className="dialog-submit">
					{t('save')}
				</Button>
			</Box>
		</Box>
	);
};

export default memo(ProfileType);
