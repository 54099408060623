import { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './Eventdeatails.scss';

const EventDetail = ({ side1, title, side2 }) => {
	const { t } = useTranslation();

	return (
		<Grid container className="event-detail-sec">
			<Grid xs={5} className="left-side-sec">
				{side1}
			</Grid>
			<Grid xs={2} className="center-sec">
				{title}
			</Grid>
			<Grid xs={5} className="right-side-sec">
				{side2}
			</Grid>
		</Grid>
	);
};

export default EventDetail;
