import { Config } from '../context/config';

export const URLS = {
	// AUTH MODULE
	signIn: '/public/user/login',
	signUp: '/public/user/register',
	verifyOTP: '/public/user/verifyOtp',
	resendOTP: '/public/user/resendOtp',
	resetPassword: '/public/user/resetPassword',
	forgotPassword: '/public/user/forgotPassword',
	socialLogin: '/public/user/sociallogin',
	mediaUpload: '/private/user/uploadFile',
	multipleMediaUpload: '/private/user/uploadMultipleFile',
	mediaURL: Config.mediaURL,

	// SETUP ORGANIZATION
	getOrgTypes: '/public/organisation/get-organisation-types',
	getOrgs: '/public/organisation/get-organisations',

	// CLAIM PROFILE
	searchUser: '/private/user/getUserBySearch',
	profileDatas: '/private/user/getEventFightContestantById/',
	claimUserProfile: '/private/user/claimUserProfile',
	followOtherUser: '/private/user/followOtherUser',
	getClaimUserList: '/private/user/getClaimUserList',
	lockedUserOtherProfile: '/private/user/lockedUserOtherProfile',
	updateUserOtherActual: '/private/user/updateUserOtherActual',

	// PROFILE
	updateProfile: '/private/user',
	getMyprofile: '/private/user',
	getUserProfile: '/public/user/',
	getStatics: '/private/user/getStaticsUserData',
	getUserByUserName: '/private/user/get-user-by-user-name',
	otherUserDetails: '/public/user/other-user',
	getFollowUserList: '/private/user/other-user/getFollowUserList',
	getCommunitiesByUser: '/public/community/get-communities-by-user',
	otpVerifyForUpdateProfile: '/private/user/verify-account-otp',

	// ADMIN PANEL
	getAllUsers: '/private/user/get-users',
	verifyUser: '/private/user/verify-user',
	getAllBadgeList: '/private/badge/get-badges',
	getBadgeCategory: '/private/badge/get-badge-categories',
	createBadge: '/private/badge/create-badge',
	createBadgeCategory: '/private/badge/create-badge-category',
	updateBadge: '/private/badge/update-badge',
	createFightMethod: '/private/fight/create-meta-fight-method',
	getFightMethod: '/private/fight/get-meta-fight-methods',
	updateFightMethod: '/private/fight/update-meta-fight-method',
	createMetaWeightClass: '/private/fight/create-meta-weight-class',
	updateMetaWeightClass: '/private/fight/update-meta-weight-class',
	getMetaWeightClass: '/private/fight/get-meta-weight-classes',
	getCategory: '/private/category/get-meta-categories',
	createCategory: '/private/category/create-meta-category',
	updateCategory: '/private/category/update-meta-category',
	getDisciplinesData: '/private/discipline/get-disciplines',
	createDiscipline: '/private/discipline/create-discipline',
	updateDiscipline: '/private/discipline/update-discipline',
	getMetaSkills: '/private/skills/getMetaSkills',
	createMetaSkills: '/private/skills/createMetaSkills',
	updateMetaSkills: '/private/skills/updateMetaSkills',
	deleteUsers: '/private/user/deleteUser/',
	getAllUsersList: '/public/user/get-all-users/list',

	// NOTIFICATION
	getNotification: '/private/notification/getNotification',
	readNotification: '/private/notification/updateNotificationStatus/',

	// GET USERLIST FOR CONNECTIONS
	getUserData: '/private/user/getUserList',

	// USER BADGE
	getUserBadge: '/public/user/getUserBadge/',

	// CLAIMED PROFILE
	getClaimedProfile: '/private/user/other-user/getUserList',

	// USER FIGHT
	getEventFightContestantUsers: '/public/endorsement/getEventFightContestantUsers/',
	deleteUserEventFightContestantEndorsement: '/private/endorsement/deleteUserEventFightContestantEndorsement',
	createUserEventFightContestantEndorsement: '/private/endorsement/createUserEventFightContestantEndorsement',
	getUserFight: (id) => `/public/fight/get-user-fights/${id}`,
	getMetaFightMethod: '/private/fight/get-meta-fight-methods',
	createFightByuser: '/private/fight/create-fight-by-user',
	getEventFightSides: '/private/fight/get-event-fight-sides',

	// FOLLOWING
	doFollow: '/private/user/followUser?type=follow',
	doUnfollow: '/private/user/followUser?type=unfollow',
	// getFollowing: '/private/user/getFollowUserList?type=following',
	getFollowing: '/public/user/getFollowUser/list?type=following',
	// getFollowers: '/private/user/getFollowUserList?type=follower',
	getFollowers: '/public/user/getFollowUser/list?type=follower',
	addConnection: '/private/user/sendRequest',
	connectionRequestUpdate: '/private/user/updateRequestStatus',
	// getPendingConnectionUser: '/private/user/getPendingConnectionUser',
	getPendingConnectionUser: '/public/user/getPendingConnectionUser/list',
	deleteConnection: '/private/user/request',
	// getConnection: '/private/user/getConnectionUser',
	getConnection: 'public/user/getConnectionUser/list',

	// DISCIPLINE
	getdisciplineTypes: '/public/discipline/get-disciplines',
	getDisciplineRankSuggestions: '/private/discipline/get-discipline-rank/',
	createUserDiscipline: '/private/discipline/create-user-discipline',
	createUserDisciplineRank: '/private/discipline/create-user-discipline-rank',
	getUserDisciplines: '/public/discipline/get-user-discipline-rank/',
	deleteUserDiscipline: '/private/discipline/deleteUserDisciplineRank/',
	updateUserDiscipline: '/private/discipline/update-user-discipline-rank',
	getDisciplines: '/private/endorsement/getDisciplines',
	createUserDisciplineRankEndorsement: '/private/endorsement/createUserDisciplineRankEndorsement',
	deleteUserDisciplineRankEndorsement: `/private/endorsement/deleteUserDisciplineRankEndorsement`,

	// FIGHT RECORDS
	getUserTypes: '/private/endorsement/getUserTypes',
	searchEventDetails: '/private/event/getEventsBySearch',
	getWeightClasses: '/private/fight/get-meta-weight-classes',
	getFightsMethod: '/private/endorsement/getMethods',
	getSkills: '/private/endorsement/getSkills',
	createFightRecord: '/private/fight/create-fight-by-user',
	updateFightRecord: '/private/fight/update-fight-by-user',
	deleteFightRecord: '/private/fight/delete-fight-by-user/',

	// ENDORSEMENT
	createEndorsement: '/private/endorsement/createEndorsement',
	addSelfEndorsement: '/private/endorsement/add-self-endorsement',
	getEndorsement: '/public/endorsement/getEndorsement/',
	deleteEndorsement: '/private/endorsement/deleteEndorsement',
	getFightMethodVerificationUsers: '/public/endorsement/getFightMethodVerificationUsers/',
	getSkillVerificationUsers: '/public/endorsement/getSkillVerificationUsers/',
	getDisciplineVerificationUsers: '/public/endorsement/getDisciplineVerificationUsers/',
	getUserDisciplineRankUsers: '/public/endorsement/getUserDisciplineRankUsers/',
	getUserTypeVerificationUsers: '/public/endorsement/getUserTypeVerificationUsers/',

	// RECENT ACTIVITY

	getRecentActivity: '/public/community/get-recent-activity-list',

	//SEARCH
	getAllSearchData: '/private/user/get-all-search-data',

	// COMMUNITY
	getCommunities: '/private/community/get-communities',
	getCommunitiesDirectory: '/private/community/get-communities-directory',
	createCommunity: '/private/community/create-community',
	joinCommunity: '/private/community/join_community',
	getCommunityDetails: '/public/community/get-community',
	updateCommunity: '/private/community/update-community',
	getCommunityMember: '/private/community/get-community-member',
	assignRole: '/private/community/assign-role',
	getCommunityPostList: '/public/community/get-community-post-list',
	createPost: '/private/community/createPost',
	deletePost: '/private/community/deletePost',
	updatePost: '/private/community/updatePost',
	addVote: '/private/community/addVote',
	addLike: '/private/community/addLike',
	getReactionsLike: '/private/meta-like/list',
	getPostDetails: '/public/community/get-post-details',
	getReportReasonList: '/private/report/get-report-reason-list',
	addReport: '/private/report/addReport',
	inviteMember: '/private/community/invite-members',
	closeCommunity: '/private/community/close-community/',
	transferOwnership: '/private/community/transfer-ownership-community/',
	getReactionsList: '/public/community/get-post-reaction-list/',

	// COMMENTS
	getAllComments: '/public/community/get-comments/',
	createComment: '/private/community/addComment',
	updateComment: '/private/community/updateComment',
	deleteComment: '/private/community/deleteComment/',

	// REFERRAL
	createReferralCode: '/private/referral/createReferralCode',
	getReferralCodes: '/private/referral/get-referral-code',
	deactiveCode: '/private/referral/deactiveCode/',
	updateReferral: '/private/referral/updateReferral/',
	referralUse: '/public/referral/referral-use',
	getReferralUser: '/public/user/getReferralUser/list',

	// SHARE
	shareLink: '/private/referral/share',

	//newsfeed
	getNewsfeedPostList: '/private/community/get-newsfeed-post-list',

	//ACCOUNT SETTING
	getAccountSettingData: '/private/user/update-account-setting',
	getOtp: '/private/user/verify-account-otp ',
	resendProfOTP: '/private/user/resend-account-otp',

	// ALL ACTIVITY
	getAllActivity: 'private/community/get-hot-activity-list',

	//INVITE
	acceptInvitation: '/private/community/accept-invitation',

	// ORGANISATION

	createOrganisation: '/private/organisation/create-organisation',
	getOrganisationType: '/public/organisation/get-organisation-types',
	updateOrganisation: '/private/organisation/update-organisation',
	getMyOrganisations: '/private/organisation/get-my-organisations',
	getOrganisationDetails: '/private/organisation/get-organisation/',
	getOrganisationUsers: '/public/organisation/get-organisation-users',
	followOrganisation: '/private/organisation/follow-organisation',
	inviteOrganisationMember: '/private/organisation/invite-organisation-members',
	blockUnblockMember: '/private/organisation/block-unblock-member',
	getAdminUserList: '/private/organisation/get-admin-user-list/',
	assignOrganisationRole: '/private/organisation/assign-organisation-role',
	acceptOrgInvitation: 'private/organisation/accept-organisation-invitation',
	getAllOrganisation: '/public/organisation/get-organisations',

	// RECENT ACTIVITY
	getOrgRecentActivity: '/public/organisation/get-organisation-recent-activity',

	//USER CHANGE EMAIL
	updateEmailAddress: '/private/user/update-user',

	//MESSAGES
	getGroupList: '/private/message/get-group-list',
	getMessageList: '/private/message/message-list',
	createGroup: '/private/message/create-group',
	AddRemoveMembers: '/private/message/add-remove-member',
	removeMessage: '/private/message/remove-messages/',
	getGroupDetails: '/private/message/get-group-details/',
	updateGroup: 'private/message/update-group/',

	//EVENTS
	getEventTypeList: '/private/event/get-event-type-list',
	getEventsUserComOrgList: '/private/event/get-event-admin-list',
	getCategoryList: '/private/event/get-category-list',
	createEvent: '/private/event/create-event',
	updateEvent: '/private/event/update-event',
	getTitleFightList: '/private/event/get-title-fight-list',
	getTournamentFightList: '/private/event/get-tournament-fight-list',
	getFightRuleList: '/private/event/get-fight-rules-list',
	getTitleTypeList: '/private/event/get-title-type-list',
	getEventList: '/private/event/get-event-list',
	getEventDetails: '/private/event/get-event-details/',
	getSidebarList: '/private/event/get-sidebar-list',
	getCardsTab: '/private/event/get-cards-tab/',
	getTimeZoneList: '/private/event/get-timezone-list',
	getEventFightCardList: '/private/event/get-event-fight-card-list/',
	addEventsVote: '/private/event/add-event-vote',
	addReaction: '/private/event/add-event-reaction',
	addEventFightVote: '/private/event/add-event-fight-vote',
	addEventFightReaction: '/private/event/add-event-fight-reaction',
	getCommunityEvent: '/private/event/get-community-event/',
	getTaggedEvents: '/private/event/get-tagged-event/',
	getEventReactionList: '/private/event/event-reaction-list/',
	getUserOrgList: '/private/event/get-org-list',
	joinEvent: '/private/event/join-event',
	updateFightContestantResults: '/private/event/update-fight-results',
	getCardsTypeList: '/private/event/get-cards-type-list',

	//USERS
	getVerifyUsersList: 'public/user/get-verify-users/list',
};
