import { useContext } from 'react';
import { ViewPortContext } from 'src/App';
import { Avatar } from '@material-ui/core';
import AdBanner from 'src/assets/images/banner/CM_BANNER_V.jpg';
import MobileAdBanner from 'src/assets/images/banner/mobile_AdBanner.jpg';
import DeepAdBanner from 'src/assets/images/banner/deepadd-forcm.jpg';
import MobileWideAdBanner from 'src/assets/images/banner/widead-forcm.jpg';
import './ad.scss';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const AdOptions = [
	{
		DesktopAdBanner: AdBanner,
		MobileAdBanner: MobileAdBanner,
		href: 'https://www.patreon.com/fullmetaltv',
	},
	{
		DesktopAdBanner: DeepAdBanner,
		MobileAdBanner: MobileWideAdBanner,
		href: 'https://www.frontpagefightsports.com/',
	},
];

const AdBlock = ({ className }) => {
	const viewPortContext = useContext(ViewPortContext);
	const width = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	// return <Box className="right-ad-block">AD</Box>;
	return (
		<>
			<Slide autoplay={true} duration={30000} arrows={false}>
				{AdOptions?.map((item) => {
					return (
						<a className={`right-ad-block ${className}`} target="_blank" href={item?.href} rel="noreferrer">
							<Avatar
								className="right-ad-banner"
								src={width > breakPoint ? item?.DesktopAdBanner : item?.MobileAdBanner}
							/>
						</a>
					);
				})}
			</Slide>
		</>
	);
};

export default AdBlock;
