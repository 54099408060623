import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LoginPage from 'src/views/component/auth/Login';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#fff',
		height: '100%',
	},
});

const LoginView = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Sign In">
			<LoginPage />
		</Page>
	);
};

export default LoginView;
