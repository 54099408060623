import { useState } from 'react';
import {
	Avatar,
	Popover,
	Box,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Tooltip,
	Link,
} from '@material-ui/core';
import {
	NavLink,
	//  useNavigate
} from 'react-router-dom';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import { ReactComponent as CommentIcon } from 'src/assets/images/icons/comment.svg';
import { ReactComponent as ReferralsIcon } from 'src/assets/images/icons/referrals.svg';
import { ReactComponent as SharesIcon } from 'src/assets/images/icons/shares.svg';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
// import { ReactComponent as DownIcon } from 'src/assets/images/icons/community/down-icon.svg';
import './community-post.scss';
import CombatDialog from 'src/views/component/UI/dialogBox';
import Share from '../Share';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useTranslation } from 'react-i18next';

const CommunityStickiedPost = ({
	postName,
	topVal,
	downVal,
	date,
	postedBy,
	contentText,
	contentImg,
	isView,
	comment,
	shares,
	reactions,
	referrals,
	data,
	handleDeletePost,
	updateCommunityPost,
	updatePostData,
	cancelBtnClick,
	addVote,
	addLike,
	PostId,
	user,
	postUserId,
	editAccess,
	userURI,
	isJoin,
	closeCommunity,
	likedStatus,
	reactionData,
	fetchPostDetails,
}) => {
	const { t } = useTranslation();

	// const navigate = useNavigate();
	// const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [opens, setOpen] = useState(false);
	const userId = user?.user_id;
	const [commentClick, setCommentClick] = useState(false);
	const [anchorElReact, setAnchorElReact] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const openReact = Boolean(anchorElReact);
	const idReact = openReact ? 'simple-popover' : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const AddReactions = (event) => {
		setAnchorElReact(event.currentTarget);
	};
	const handleCloseReact = () => {
		setAnchorElReact(null);
	};
	const combatDialogHandler = (title) => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	const scrooTop = () => {
		const anchor = (window.document || document).querySelector('#back-to-top-post');

		if (anchor) {
			anchor.scrollIntoView();
		}
	};
	// var urlValue = location?.pathname?.split('/');
	// console.log(userOwnerId, "dddddddddd");

	const postContent = contentText && contentText.length > 70 ? contentText.substring(0, 70 - 3) + '...' : contentText;

	return (
		<Box className="community-stickied-post">
			<Box className="post-header">
				<Box className="post-name-statics">
					<Box className="post-left-sec">
						<Link href={`/post/${data?.id}`} className="post-name">
							{postName}
						</Link>

						{/* <Typography
              className="post-name"
              onClick={() => navigate(`/post/${data?.id}`)}
            >
              {postName}
            </Typography> */}
						<Typography className="post-date-time">{date}</Typography>
						{/* <Typography className="posted-by" onClick={() => navigate(`/user/${userURI}`)}>{postedBy}</Typography> */}
						<Link href={`/user/${userURI}`} className="posted-by">
							{postedBy}
						</Link>
					</Box>
					{user && (
						<Box className="post-statics">
							<Box display="flex" alignItems="center">
								<IconButton
									className={data?.vote_up_status === 'yes' && 'fill-blue'}
									onClick={() => {
										!closeCommunity && addVote(data, 'vote_up');
									}}
								>
									<StaticsUpIcon />
								</IconButton>
								<Typography className="statics-val up-val">{topVal || 0}</Typography>
							</Box>
							<Box display="flex" alignItems="center">
								<IconButton
									className={data?.vote_down_status === 'yes' && 'fill-red'}
									onClick={() => {
										!closeCommunity && addVote(data, 'vote_down');
									}}
								>
									<StaticsDownIcon />
								</IconButton>
								<Typography className="statics-val">{downVal || 0}</Typography>
							</Box>
						</Box>
					)}
				</Box>
				{/* <Typography className="post-date-time">{date}</Typography> */}
				{/* <Typography className="posted-by">{postedBy}</Typography> */}
			</Box>

			{/* POST CONTENT */}
			<Box className="post-content">
				{postContent ? (
					<Typography className="event-text">
						{postContent && // USER PROFILE TYPE ABOUT TEXT
							renderHTML(
								anchorme({
									input: postContent.toString().replace(/\n/g, '<br/>'),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
					</Typography>
				) : null}
				{/* {contentImg ? ( */}
				{contentImg && <Avatar variant="square" className="event-image" src={contentImg} />}
				{/* ) : null} */}
			</Box>
			{/* POST ACTIONS */}
			<Box className="post-actions">
				<Box className="action-row act-rw-1">
					{data?.comments === 'yes' && (
						<Box className="action-box">
							<Button
								variant="contained"
								disableElevation
								disableRipple
								disableFocusRipple
								disableTouchRipple
								className="act-btn"
								disabled={!user}
								startIcon={<CommentIcon />}
								component={window.location.pathname.includes('/post') ? Button : NavLink}
								to={window.location.pathname.includes('/post') ? null : `/post/${data?.id}`}
								onClick={() => {
									isJoin
										? window.location.pathname.includes('/post') && setCommentClick(!commentClick)
										: window.location.pathname.includes('/post') && setApiMessage('error', 'please join community');
									// navigate(`/post/${data?.id}`, { state: { commentOP: "commentOP" } });
								}}
							>
								{t('community.comment')} ({comment || 0})
							</Button>
						</Box>
					)}

					<Box className="action-box">
						<Button
							variant="contained"
							disableElevation
							disableRipple
							disableFocusRipple
							disableTouchRipple
							className="act-btn"
							disabled={!user}
							startIcon={<SharesIcon />}
							onClick={() => {
								combatDialogHandler(t('community.share'));
							}}
						>
							{t('community.shares')} ({shares || 0})
						</Button>
					</Box>

					{/*<Box className="action-box">*/}
					{/*	<Button*/}
					{/*		variant="contained"*/}
					{/*		disableElevation*/}
					{/*		disableRipple*/}
					{/*		disableFocusRipple*/}
					{/*		disableTouchRipple*/}
					{/*		className="act-btn"*/}
					{/*		disabled={!user}*/}
					{/*		startIcon={<ReferralsIcon />}*/}
					{/*	>*/}
					{/*		{t('community.referrals')} ({referrals || 0})*/}
					{/*	</Button>*/}
					{/*</Box>*/}
				</Box>

				<Box className="action-row">
					{/*<Box className="action-box">*/}
					{/*	<Popover*/}
					{/*		className="cr-popover reaction-popover"*/}
					{/*		id={idReact}*/}
					{/*		open={openReact}*/}
					{/*		anchorEl={anchorElReact}*/}
					{/*		onClose={handleCloseReact}*/}
					{/*		anchorOrigin={{*/}
					{/*			vertical: 'top',*/}
					{/*			horizontal: 'left',*/}
					{/*		}}*/}
					{/*		transformOrigin={{*/}
					{/*			vertical: 'bottom',*/}
					{/*			horizontal: 'left',*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		{reactionData?.map((item, index) => {*/}
					{/*			return (*/}
					{/*				<Tooltip title={item?.name} arrow>*/}
					{/*					<IconButton*/}
					{/*						className={item?.id === likedStatus ? 'react-icon react-selected' : 'react-icon'}*/}
					{/*						onClick={() => {*/}
					{/*							if (isJoin) {*/}
					{/*								addLike(data, item?.id);*/}
					{/*								setAnchorElReact(null);*/}
					{/*							}*/}
					{/*						}}*/}
					{/*					>*/}
					{/*						<Avatar src={item?.item_data} className="reaction-img" />*/}
					{/*					</IconButton>*/}
					{/*				</Tooltip>*/}
					{/*			);*/}
					{/*		})}*/}
					{/*	</Popover>*/}
					{/*	<Button*/}
					{/*		variant="contained"*/}
					{/*		disableElevation*/}
					{/*		disableRipple*/}
					{/*		disableFocusRipple*/}
					{/*		disableTouchRipple*/}
					{/*		disabled={!user}*/}
					{/*		className="act-btn"*/}
					{/*		onClick={(e) => isJoin && AddReactions(e)}*/}
					{/*		startIcon={<ReactionsIcon />}*/}
					{/*	>*/}
					{/*		{t('community.reactions')} ({reactions || 0})*/}
					{/*	</Button>*/}
					{/*</Box>*/}
				</Box>

				{editAccess || postUserId === userId || user?.isAdmin ? (
					<>
						<Popover
							className="admin-tools-popover"
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							TransitionProps={{
								onExited: () => {},
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Box className="admin-tools">
								<List>
									{editAccess || user?.isAdmin ? (
										<ListItem>
											<ListItemText
												onClick={() => {
													updateCommunityPost(data, 'no');
													handleClose();
												}}
											>
												{t('community.remove_from_sp')}
											</ListItemText>
										</ListItem>
									) : (
										<></>
									)}
									<ListItem>
										<ListItemText
											onClick={() => {
												handleClose();
												updatePostData(data);
												setTimeout(() => {
													scrooTop();
												}, 600);
											}}
										>
											{t('community.edit_post')}
										</ListItemText>
									</ListItem>
									<ListItem
										onClick={() => {
											handleClose();
											handleDeletePost(data);
										}}
									>
										<ListItemText>{t('community.delete_post')}</ListItemText>
									</ListItem>
								</List>
							</Box>
						</Popover>
						{/* {urlValue && urlValue[1] === 'news-feed' && ( */}
						{(editAccess || user?.isAdmin) && (
							<Button
								variant="contained"
								fullWidth
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="admin-button"
								disabled={closeCommunity || !user}
								onClick={(e) => {
									handleClick(e);
									cancelBtnClick();
								}}
								// endIcon={<DownIcon />}
							>
								{user?.isAdmin ? t('community.admin') : t('community.manage_post')}
							</Button>
						)}
					</>
				) : (
					''
				)}
			</Box>

			<CombatDialog
				className="share-dailog-box"
				open={opens}
				handleClose={closeDialog}
				// title={title}
				content={
					<Share
						fetchPostDetails={fetchPostDetails}
						fromWhere="post/"
						urlId={data?.id}
						landType="post"
						handleClose={closeDialog}
					/>
				}
			/>
		</Box>
	);
};

export default CommunityStickiedPost;
