import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { countries } from 'country-data';
import Autocompletes, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { setApiMessage } from 'src/helpers/commonFunctions';
import {
	Box,
	Button,
	Chip,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Grid,
	MenuItem,
	Typography,
	Tooltip,
	withStyles,
	RadioGroup,
	Radio,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@material-ui/icons/Cancel';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as CorrectIcon } from 'src/assets/images/icons/corret-sign.svg';
import { ReactComponent as InfoIcon } from 'src/assets/images/icons/info-icon.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
// import { ReactComponent as RedCross } from 'src/assets/images/icons/red-cross.svg';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { useNavigate } from 'react-router-dom';
import './edit-profile.scss';
import { useTranslation } from 'react-i18next';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const EditProfileDetails = ({
	getMyProfile,
	closeDialog,
	userData,
	user,
	token,
	isModalLoading,
	setIsModalLoading,
}) => {
	console.log(user);
	const navigate = useNavigate();
	const { t } = useTranslation();
	let nicknameVal = user?.UserMetaData?.filter((data) => data?.type === 'other')[0]?.data;
	let weight_kg = user?.UserMetaData?.filter((data) => data?.type === 'weight_low_kg')[0]?.data;
	let weight_lb = user?.UserMetaData?.filter((data) => data?.type === 'weight_low_lb')[0]?.data;
	let location_fighting = user?.UserMetaData?.filter((data) => data?.type === 'location_fighting')[0]?.data;
	let isDefault = user?.UserMetaData?.filter((data) => data?.type === 'URI')[0]?.default;

	let uri = isDefault === 'no' ? user?.UserMetaData?.filter((data) => data?.type === 'URI')[0]?.data : '';
	let uNameCheck = user?.UserMetaData?.filter((data) => data?.type === 'other')?.length > 0 ? true : false;

	let weight_kg_high = user?.UserMetaData?.filter((data) => data?.type === 'weight_high_kg')[0]?.data;

	let weight_lb_high = user?.UserMetaData?.filter((data) => data?.type === 'weight_high_lb')[0]?.data;

	let weight_kg_low = user?.UserMetaData?.filter((data) => data?.type === 'weight_low_kg')[0]?.data;

	let weight_lb_low = user?.UserMetaData?.filter((data) => data?.type === 'weight_low_lb')[0]?.data;

	const checkMeasurment = () => {
		if (weight_kg_low || weight_kg_high) {
			return 'Kilograms';
		}

		if (weight_lb_low || weight_lb_high) {
			return 'Pounds';
		}

		return 'Kilograms';
	};

	const [searchAddress, SetSearchAddress] = useState(userData?.address?.address || '');
	const [city, setCity] = useState(userData?.address?.city || '');
	const [state, setState] = useState(userData?.address?.state || '');
	const [country, setCountry] = useState(userData?.country || '');
	const [lattitude, setLattitude] = useState(userData?.address?.lat || '');
	const [longitude, setLongitude] = useState(userData?.address?.long || '');
	// const [weightClasses, setWeightClasses] = useState([]);
	const [availableStatus, setStatus] = useState(false);
	const [statusFirstTime, setStatusFirstTime] = useState(true);
	const [measurment, setMeasurement] = useState(checkMeasurment());

	const checkURIFieldIcon = () => {
		if (statusFirstTime) {
			return null;
		} else {
			if (availableStatus && !statusFirstTime) {
				return (
					<>
						<CorrectIcon />
					</>
				);
			} else {
				return (
					<>
						<ErrorIcon />
					</>
				);
			}
		}
	};

	const formikRef = useRef();
	const countriesList = countries.all.map((x) => x?.name) || location_fighting?.inputValue;
	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const AddressHandler = (address) => {
		SetSearchAddress(address);
	};

	const changeMeasurementHandler = (e) => {
		setMeasurement(e.target.value);
	};

	// COUNT AGE
	const getAge = (d1) => {
		let d2 = new Date();
		var diff = d2.getTime() - d1.getTime();

		return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	};

	// const fetchWeightClassesData = async () => {
	//   try {
	//     let { status, data } = await axiosInstance.get(
	//       URLS.getWeightClasses,
	//       authHeader
	//     );

	//     if (status === 200) {
	//       setWeightClasses(data?.getMetaWeightClassesData);
	//     }
	//   } catch (error) {
	// TODO: Add logging
	// }
	// };

	const HandleSelectAddress = (address) => {
		geocodeByAddress(address).then((results) => {
			setCity('');
			setState('');
			setLattitude('');
			setLongitude('');

			let address = results[0].address_components;
			let geoCode = results[0].geometry.location;
			let name = results[0].formatted_address;

			SetSearchAddress(name);
			setLattitude(geoCode.lat());
			setLongitude(geoCode.lng());

			address.map((val) => {
				return val.types.map((com) => {
					switch (com) {
						case 'country':
							setCountry(val.long_name);

							return val.long_name;
						case 'administrative_area_level_1':
							setState(val.long_name);

							return val.long_name;
						case 'administrative_area_level_2':
							setCity(val.long_name);

							return val.long_name;
						default:
							return val;
					}
				});
			});
		});
	};

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setFieldValue('address', searchAddress);
			formikRef.current.setFieldValue('city', city);
			formikRef.current.setFieldValue('state', state);
			formikRef.current.setFieldValue('country', country);
		}
		// eslint-disable-next-line
	}, [searchAddress, city, state, country]);

	useEffect(() => {
		// fetchWeightClassesData();
		// eslint-disable-next-line
	}, []);
	const filter = createFilterOptions();
	const checkAvailableStatus = async (e) => {
		setStatusFirstTime(false);

		try {
			const { status, data } = await axiosInstance.get(URLS.getUserByUserName + `?username=${e.target.value}`);

			if (status === 200) {
				setStatus(data?.available_status);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// const isValidUrl = (url) => {
	//   try {
	//     new URL(url);
	//   } catch (e) {
	//     return true;
	//   }
	//   return false;
	// };
	// const isInclude = (url) => {
	//   var flag = url.includes('/') || url.includes('.') || url.includes('?') || url.includes(' ') ? false : true
	//   return flag;
	// };
	// const isHtmlIncludes = val => {
	//   var regexAllTags = /<([a-zA-Z1-6]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/gim;
	//   let result = val.match(regexAllTags);
	//   return !result;
	// };
	// const checkForNumber = (val) => {
	//   const reg = new RegExp('^[0-9]+$');
	//   let result = val.match(reg)
	//   return result
	// }
	// const alphanumericAllow = (val, flag) => {
	//   let result = val.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)
	//   var res = flag ? result : !result
	//   console.log('result', res, result, val)
	//   return res
	// }
	return (
		<Box className="edit-profile">
			<Formik
				innerRef={formikRef}
				initialValues={{
					firstName: userData?.first_name || '',
					surName: userData?.last_name || '',
					nickname: uNameCheck ? nicknameVal : '',
					dob: moment.utc(userData?.dob).format('YYYY-MM-DD'),
					age: (userData?.dob !== null && getAge(new Date(userData?.dob))) || '',
					phone: userData?.phone || '',
					address: userData?.address?.address || '',
					country: userData?.country || '',
					lower_weight: weight_kg_low !== '' ? weight_kg_low : weight_lb_low !== '' ? weight_lb_low : '',
					height_weight: weight_kg_high !== '' ? weight_kg_high : weight_lb_high !== '' ? weight_lb_high : '',
					// weight_class: weight_class_val ? weight_class_val : [],
					location_fighting: location_fighting ? location_fighting : '',
					uri: uri ? uri : '',
				}}
				validationSchema={Yup.object().shape({
					firstName: Yup.string().required(t('validation.required_message')),
					// .test('firstName', t('validation.enter_valid_text'), value =>
					//   isHtmlIncludes(value)
					// ),
					surName: Yup.string().required(t('validation.required_message')),
					// .test('surName', t('validation.enter_valid_text'), value =>
					//   isHtmlIncludes(value)
					// ),
					// nickname: Yup.string()
					//   .required(t('validation.required_message')),
					// .test('nickname', t('validation.enter_valid_text'), value =>
					//   isHtmlIncludes(value)
					// ),
					// uri: Yup.string().required(t('validation.required_message')),
					// .test('is-url-valid', t('personal_details.is_url'), (value) => isValidUrl(value))
					// .test('uri', t('personal_details.enter_valid_vanity_url'), (value) => isInclude(value))
					// .test('uri', t('validation.enter_valid_text'), (value) => isHtmlIncludes(value)),
					// uri: Yup.string().test('uri', t('personal_details.alphanumeric_validate'), (value) => {
					//   console.log('alphanumericAllow', value)
					//   value === undefined ? alphanumericAllow(value, false) : alphanumericAllow(value, true)
					// }),

					// uri: Yup.string().when('uri', {
					//   is: (surName) => surName === 'IT',
					//   then: Yup.string().required('Field is required'),
					//   otherwise: Yup.string(),
					// }),

					// dob: Yup.string()
					//   // .required(t('validation.required_message'))
					//   .test('dob', t('personal_details.wrong_dob'), function (dob) {
					//     return (
					//       dob <
					//       moment(
					//         new Date(new Date()).setDate(new Date(new Date()).getDate())
					//       ).format()
					//     );
					//   }),
					// age: Yup.string()
					//   // .required(t('validation.required_message'))
					//   .test('dob', t('personal_details.wrong_age'), function (age) {
					//     return Number(age) === getAge(new Date(this.parent.dob));
					//   }),
					dob: Yup.string(),
					age: Yup.string().when('dob', {
						is: (value) => value && value !== '' && value !== 'Invalid date',
						then: Yup.string().test('dob', t('personal_details.wrong_age'), function (age) {
							return Number(age) === getAge(new Date(this.parent.dob));
						}),
						otherwise: Yup.string(),
					}),
					// phone: Yup.string().test('phone', t('validation.only_number_allow'), (value) => checkForNumber(value)),
					// address: Yup.string().test(
					//   'address',
					//   t('validation.enter_valid_text'),
					//   value => isHtmlIncludes(value)
					// ),
					// country: Yup.string().test(
					//   'country',
					//   t('validation.enter_valid_text'),
					//   value => isHtmlIncludes(value)
					// )
				})}
				onSubmit={async (data) => {
					let socials = user?.UserMetaData?.filter((data) => data?.type === 'other');
					let userNameVall = { type: 'other', data: data?.nickname };
					// let weight_class = { type: 'weight_class', data: [] };
					let measure_hight_kg = {
						type: 'weight_high_kg',
						data: data?.height_weight,
					};
					let measure_low_lb = {
						type: 'weight_low_lb',
						data: data?.lower_weight,
					};
					let measure_high_lb = {
						type: 'weight_high_lb',
						data: data?.height_weight,
					};
					let location_fighting = {
						type: 'location_fighting',
						data: data.location_fighting?.inputValue ? data.location_fighting?.inputValue : data.location_fighting,
					};
					let uri = {
						type: 'URI',
						data: data.uri !== '' ? data.uri : fetchFromStorage('authData')?.user_id,
						// data: data.uri !== '' ? data.uri : user?.UserMetaData?.filter(data => data?.type === 'URI')[0]?.data
					};
					let uri_val = {
						type: 'URI',
						data: data.uri,
						// data: data.uri !== '' ? data.uri : user?.UserMetaData?.filter(data => data?.type === 'URI')[0]?.data
					};

					socials.push(userNameVall);
					// socials.push(weight_class);
					socials.push(location_fighting);
					socials.push({
						type: 'weight_low_kg',
						data: measurment === 'Kilograms' ? data?.lower_weight : '',
					});
					socials.push({
						type: 'weight_high_kg',
						data: measurment === 'Kilograms' ? data?.height_weight : '',
					});
					socials.push({
						type: 'weight_low_lb',
						data: measurment === 'Pounds' ? data?.lower_weight : '',
					});
					socials.push({
						type: 'weight_high_lb',
						data: measurment === 'Pounds' ? data?.height_weight : '',
					});
					socials.push(uri_val);
					let requestData = {
						personal_details: {
							first_name: data.firstName,
							last_name: data.surName,
							dob: data.dob !== '' ? moment(data.dob).format('YYYY-MM-DD') : '',
							// dob: data.dob !== '' ? moment(data.dob)
							//   .utc()
							//   .format() : "",
							country: data.country,
							// weight_class: data.weight_class,
							// location_fighting: data.location_fighting
						},
						user_meta: socials,
						phone: data.phone,
						address: {
							address: data?.address,
							lat: lattitude,
							long: longitude,
							city: city,
							state: state,
							country: data?.country,
						},
					};

					try {
						setIsModalLoading(true);
						const { status } = await axiosInstance.put(URLS.updateProfile, requestData, authHeader);

						if (status === 200) {
							setApiMessage('success', t('myprofile.profile_updated_success'));
							let authObj = {
								...fetchFromStorage('authData'),
								user_meta: {
									data: data?.uri,
									type: 'URI',
								},
							};

							saveToStorage('authData', authObj);
							navigate(`/user/${uri?.data}`);
							setTimeout(() => {
								getMyProfile(uri?.data);
							}, 3000);
							closeDialog();
						}
					} catch (error) {
						// let authObj = {
						//   ...fetchFromStorage('authData'),
						//   user_meta: {
						//     data: data?.uri,
						//     type: 'URI'
						//   }
						// };
						// saveToStorage('authData', authObj);
						// navigate(`/user/${uri?.data}`);
						// setTimeout(() => {
						//   getMyProfile(uri?.data);
						// }, 3000);

						setApiMessage('error', error?.response?.data?.message);
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.firstName && errors.firstName)}
									fullWidth
									helperText={
										touched.firstName ? (
											touched.firstName && (
												<Box disply="flex" justifyContent="center">
													{errors.firstName}
													<span className="field-character-count">{values.firstName.length}/200</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.firstName.length}/200</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 200,
										endAdornment: touched.firstName && errors.firstName ? <ErrorIcon /> : null,
									}}
									inputProps={{ maxLength: 200 }}
									label={t('personal_details.firstName')}
									placeholder={t('personal_details.firstName')}
									className="matrix-textfield"
									size="small"
									name="firstName"
									onBlur={handleBlur}
									// onChange={handleChange}
									onChange={(e) => {
										var re = /[a-zA-Z0-9\_]$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									value={values.firstName}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.surName && errors.surName)}
									fullWidth
									helperText={
										touched.surName ? (
											touched.surName && (
												<Box disply="flex" justifyContent="center">
													{errors.surName}
													<span className="field-character-count">{values.surName.length}/200</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.surName.length}/200</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 200,
										endAdornment: touched.surName && errors.surName ? <ErrorIcon /> : null,
									}}
									inputProps={{ maxLength: 200 }}
									label={t('personal_details.surName')}
									placeholder={t('personal_details.surName')}
									className="matrix-textfield"
									size="small"
									name="surName"
									onBlur={handleBlur}
									// onChange={handleChange}
									onChange={(e) => {
										var re = /[a-zA-Z0-9\_]$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									value={values.surName}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.nickname && errors.nickname)}
									fullWidth
									helperText={
										touched.nickname ? (
											touched.nickname && (
												<Box disply="flex" justifyContent="center">
													{errors.nickname}
													<span className="field-character-count">{values.nickname.length}/200</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.nickname.length}/200</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 200,
										endAdornment: touched.nickname && errors.nickname ? <ErrorIcon /> : null,
									}}
									inputProps={{ maxLength: 200 }}
									label={t('personal_details.nickname')}
									placeholder={t('personal_details.nickname')}
									className="matrix-textfield"
									size="small"
									name="nickname"
									onBlur={handleBlur}
									// onChange={handleChange}
									onChange={(e) => {
										var re = /[a-zA-Z0-9\_]$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									value={values.nickname}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.uri && errors.uri)}
									fullWidth
									helperText={
										touched.uri ? (
											touched.uri && (
												<Box disply="flex" justifyContent="center">
													{errors.uri}
													<span className="field-character-count">{values.uri.length}/200</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.uri.length}/200</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 200,
										endAdornment:
											touched.uri && errors.uri ? (
												<>
													<ErrorIcon />
												</>
											) : (
												checkURIFieldIcon()
											),
									}}
									inputProps={{ maxLength: 200 }}
									label={t('myprofile.uri')}
									placeholder={t('myprofile.uri')}
									className="matrix-textfield"
									size="small"
									name="uri"
									onBlur={handleBlur}
									value={values.uri}
									// onChange={handleChange}
									onPaste={(e) => {
										e.preventDefault();

										return false;
									}}
									onChange={(e) => {
										// var re = /[a-zA-Z0-9_]$/
										var re = /[a-zA-Z0-9\_]$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);

											if (uri === e.target.value || e.target.value === '') {
												setStatusFirstTime(true);
											}

											uri !== e.target.value && e.target.value !== '' && checkAvailableStatus(e);
										}
									}}

									// onChange={e => {
									//   handleChange(e);
									//   uri !== e.target.value && e.target.value !== '' && checkAvailableStatus(e);
									// }}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid date-birth-field">
								<Grid item xs={12} sm={12} md={10} lg={10} className="date-field">
									<MatrixTextField
										error={Boolean(touched.dob && errors.dob)}
										fullWidth
										helperText={touched.dob && errors.dob}
										variant="filled"
										InputProps={{
											endAdornment: touched.dob && errors.dob ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.dob')}
										placeholder={t('personal_details.dob')}
										className="matrix-textfield"
										size="small"
										name="dob"
										type="date"
										onBlur={handleBlur}
										onChange={(e) => {
											let vall = getAge(new Date(e.target.value));

											handleChange(e);
											setFieldValue('age', vall > 0 && vall < 150 ? vall : '');
										}}
										InputLabelProps={{
											shrink: true,
										}}
										value={values.dob}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} className="age-field">
									<MatrixTextField
										error={Boolean(touched.age && errors.age)}
										fullWidth
										helperText={touched.age && errors.age}
										variant="filled"
										InputProps={{
											endAdornment: touched.age && errors.age ? <ErrorIcon /> : null,
										}}
										label={t('personal_details.age')}
										placeholder={t('personal_details.age')}
										className="matrix-textfield"
										size="small"
										name="age"
										onBlur={handleBlur}
										onChange={(e) => {
											const re = /^[0-9\b]+$/;

											if (e.target.value === '' || re.test(e.target.value)) {
												handleChange(e);
											}
										}}
										// inputProps={{ maxLength: 4 }}
										value={values.age}
										// InputProps={{
										//   readOnly: true
										// }}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.phone && errors.phone)}
									fullWidth
									helperText={touched.phone && errors.phone}
									variant="filled"
									InputProps={{
										endAdornment: touched.phone && errors.phone ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.phone')}
									placeholder={t('personal_details.phone')}
									className="matrix-textfield"
									size="small"
									name="phone"
									onBlur={handleBlur}
									onChange={(e) => {
										const re = /^[0-9\b]+$/;

										if (e.target.value === '' || re.test(e.target.value)) {
											handleChange(e);
										}
									}}
									// onChange={handleChange}
									value={values.phone}
								/>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<PlacesAutocomplete value={searchAddress} onChange={AddressHandler} onSelect={HandleSelectAddress}>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<MatrixTextField
												error={Boolean(touched.address && errors.address)}
												fullWidth
												helperText={touched.address && errors.address}
												variant="filled"
												InputProps={{
													endAdornment: touched.address && errors.address ? <ErrorIcon /> : null,
												}}
												label={t('personal_details.address')}
												placeholder={t('personal_details.address')}
												className="matrix-textfield"
												size="small"
												name="address"
												{...getInputProps()}
												onBlur={handleBlur}
												InputLabelProps={{
													shrink: true,
												}}
											/>

											<div className="autocomplete-dropdown-container">
												{suggestions.map((suggestion, index) => {
													const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
													// inline style for demonstration purpose
													const style = suggestion.active
														? {
																background: '#fff',
																color: '#000',
																cursor: 'pointer',
														  }
														: {
																backgroundColor: 'transparent',
																cursor: 'pointer',
														  };

													return (
														<div
															key={index}
															{...getSuggestionItemProps(suggestion, {
																className,
																style,
															})}
														>
															<MenuItem disableGutters dense className="address-menuitem">
																{suggestion.description}
															</MenuItem>
														</div>
													);
												})}
											</div>
										</div>
									)}
								</PlacesAutocomplete>
							</Grid>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.country && errors.country)}
									fullWidth
									helperText={touched.country && errors.country}
									variant="filled"
									InputProps={{
										endAdornment: touched.country && errors.country ? <ErrorIcon /> : null,
									}}
									label={t('personal_details.country')}
									placeholder={t('personal_details.country')}
									className="matrix-textfield"
									size="small"
									name="country"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.country}
								/>
							</Grid>

							{/* WEIGHT RANGE */}
							<Grid item xs={12} md={12} className="text-field-grid">
								<Typography className="weight-range-title">
									Weight RANGE
									<LightTooltip
										placement="right"
										arrow
										style={{ maxWidth: 250 }}
										title={
											<>
												<Typography
													style={{
														fontSize: 14,
														marginBottom: 9,
														color: '#000',
														fontWeight: 600,
													}}
												>
													Weight Range
												</Typography>

												<Typography style={{ fontSize: 10, color: '#000' }}>
													Please enter the weight range you would like to complete at. Lowest weight and Heighest
													weight. This will help you complete more often!
												</Typography>
											</>
										}
									>
										<InfoIcon style={{ marginLeft: 9 }} />
									</LightTooltip>
								</Typography>

								<Typography className="measurment-text">Select the unit of measurement:</Typography>

								<RadioGroup className="weighclass-measurments" value={measurment} onChange={changeMeasurementHandler}>
									<FormControlLabel value="Kilograms" control={<Radio />} label="Kilograms" />
									<FormControlLabel value="Pounds" control={<Radio />} label="Pounds" />
								</RadioGroup>
							</Grid>

							{/* LOWER WEIGHT */}
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.lower_weight && errors.lower_weight)}
									fullWidth
									helperText={touched.lower_weight && errors.lower_weight}
									variant="filled"
									InputProps={{
										endAdornment: touched.lower_weight && errors.lower_weight ? <ErrorIcon /> : null,
									}}
									label="Lower Weight"
									placeholder="Lower Weight"
									className="matrix-textfield"
									size="small"
									name="lower_weight"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.lower_weight}
								/>
							</Grid>

							{/* HEIGHEST WEIGHT */}
							<Grid item xs={12} md={12} className="text-field-grid" style={{ marginBottom: 18 }}>
								<MatrixTextField
									error={Boolean(touched.height_weight && errors.height_weight)}
									fullWidth
									helperText={touched.height_weight && errors.height_weight}
									variant="filled"
									InputProps={{
										endAdornment: touched.height_weight && errors.height_weight ? <ErrorIcon /> : null,
									}}
									label="Upper Weight"
									placeholder="Upper Weight"
									className="matrix-textfield"
									size="small"
									name="height_weight"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.height_weight}
								/>
							</Grid>

							{/* <Grid item xs={12} md={12} className="text-field-grid">
                <Autocomplete
                  multiple
                  id="fixed-tags-demo"
                  value={values.weight_class}
                  onChange={(event, newValue) => {
                    setFieldValue('weight_class', newValue);
                  }}
                  options={weightClasses.map(x => x?.name)}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        deleteIcon={<CancelIcon />}
                        className="weightclass-chip"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={params => (
                    <MatrixTextField
                      {...params}
                      fullWidth
                      variant="filled"
                      label={t('myprofile.weight_class')}
                      placeholder={t('myprofile.weight_class')}
                      className="matrix-textfield"
                      size="small"
                    />
                  )}
                />
              </Grid> */}

							<Grid item xs={12} md={12} className="text-field-grid">
								<Autocompletes
									id="country-list"
									options={countriesList}
									value={values.location_fighting}
									onChange={(e, newValue) => {
										setFieldValue('location_fighting', newValue);
									}}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									filterOptions={(options, params) => {
										const filtered = filter(options, params);
										const { inputValue } = params;

										// Suggest the creation of a new value
										const isExisting = options.some((option) => inputValue === option.name);

										if (inputValue !== '' && !isExisting) {
											filtered.push({
												inputValue,
												name: `Add "${inputValue}"`,
											});
										}

										return filtered;
									}}
									getOptionLabel={(option) => {
										// Value selected with enter, right from the input
										if (typeof option === 'string') {
											return option;
										}

										// Add "xxx" option created dynamically
										if (option.inputValue) {
											return option.inputValue;
										}

										// Regular option
										return String(option.name);
									}}
									// renderOption={(props, option) => {
									//   return (<span {...props} >
									//     {props}
									//   </span>
									//   );
									// }}
									renderInput={(params) => (
										<MatrixTextField
											{...params}
											error={Boolean(touched.location_fighting && errors.location_fighting)}
											fullWidth
											helperText={touched.location_fighting && errors.location_fighting}
											variant="filled"
											label={t('myprofile.location_fighting')}
											placeholder={t('myprofile.location_fighting')}
											className="matrix-textfield"
											size="small"
											autoComplete="off"
										/>
									)}
								/>

								{/* <MatrixTextField
                  error={Boolean(
                    touched.location_fighting && errors.location_fighting
                  )}
                  fullWidth
                  helperText={
                    touched.location_fighting && errors.location_fighting
                  }
                  variant="filled"
                  InputProps={{
                    endAdornment:
                      touched.location_fighting && errors.location_fighting ? (
                        <ErrorIcon />
                      ) : null
                  }}
                  label={t('myprofile.location_fighting')}
                  placeholder={t('myprofile.location_fighting')}
                  className="matrix-textfield"
                  size="small"
                  name="location_fighting"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location_fighting}
                /> */}
							</Grid>

							{/* SAVE BUTTON */}
							<Grid item xs={12} md={12} className="dialog-submit-container dialog-sticky-btn">
								<Box className="dialog-submit-button">
									<Button disabled={isModalLoading} variant="contained" type="submit" className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default EditProfileDetails;
