import { TextField, withStyles } from '@material-ui/core';

export const MatrixTextField = withStyles({
	root: {
		'& label': {
			color: 'white',
		},
		'& label.Mui-focused': {
			color: '#438AC1',
		},
		'& label:hover': {
			color: '#438AC1',
		},
		'& label.Mui-focused.Mui-error': {
			color: '#C01515',
		},
		'& .MuiFormLabel-root.Mui-error': {
			color: '#C01515',
		},
		'& .MuiFormHelperText-root.Mui-error': {
			color: '#C01515',
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: '#fff',
		},
		'& .Mui-error.MuiFilledInput-root': {
			borderColor: 'red',
			'&:hover': {
				borderColor: 'red',
			},
			'&:focus': {
				borderColor: 'red',
			},
		},
		'& .MuiFilledInput-underline': {
			'&:before': { top: 0, borderBottom: 'none' },
			'&:after': { borderBottom: 'none' },
		},
		'& .MuiFilledInput-inputMarginDense': {
			border: 'none',
			paddingTop: '20px',
		},
		'& .MuiFilledInput-root': {
			backgroundColor: '#1B1B1B',
			borderRadius: '3px',
			border: '1px solid #2E353A',
			'&:hover': {
				borderColor: '#438AC1',
			},
			'&:focus': {
				borderColor: '#438AC1',
			},
		},
		'& .MuiInputBase-input': {
			color: '#fff',
		},
		'& .MuiInputBase-inputMultiline': {
			paddingTop: '5px',
		},
	},
})(TextField);
