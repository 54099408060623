import { useContext } from 'react';
import '../fight.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Container, Grid, Button, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

const AddSkills = ({ skillsData, itemData, isUpdate, fetchMetaSkillsData, currentPage, closeDialog }) => {
	const { t } = useTranslation();

	const addUpdateSkills = async (data) => {
		const requestParams = {
			parent_id: data?.skillparent,
			name: data?.skillsname,
			// name_short: data?.shortname,
			description: data?.skilldescription,
			...(isUpdate && { id: itemData?.id }),
		};
		const method = isUpdate ? 'put' : 'post';
		const apiEndPoint = isUpdate ? 'updateMetaSkills' : 'createMetaSkills';

		try {
			const { status, data } = await axiosInstance?.[method](URLS?.[apiEndPoint], requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchMetaSkillsData(currentPage);
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-form-main">
					<Box className="form-content">
						<Formik
							initialValues={{
								skillsname: itemData?.name !== null ? itemData?.name : '',
								skilldescription: itemData?.description !== null ? itemData?.description : '',
								skillparent: itemData?.parent_id !== null ? itemData?.parent_id : '',
							}}
							validationSchema={Yup.object().shape({
								skillsname: Yup.string().required(t('validation.required_message')),
								skillparent: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => addUpdateSkills(requestdata)}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.skillparent && errors.skillparent)}
												fullWidth
												helperText={touched.skillparent && errors.skillparent}
												variant="filled"
												InputProps={{
													endAdornment: touched.skillparent && errors.skillparent ? <ErrorIcon /> : null,
												}}
												label={t('admin_panel.combat_skills.parentskill')}
												placeholder={t('admin_panel.combat_skills.parentskill')}
												className="matrix-textfield"
												size="small"
												name="skillparent"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.skillparent}
												select
											>
												{skillsData.map((item, index) => {
													return (
														<MenuItem key={index} value={item?.id}>
															{item?.name}
														</MenuItem>
													);
												})}
											</MatrixTextField>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.skillsname && errors.skillsname)}
												fullWidth
												helperText={touched.skillsname && errors.skillsname}
												variant="filled"
												placeholder={t('admin_panel.combat_skills.skillsname')}
												label={t('admin_panel.combat_skills.skillsname')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.skillsname && errors.skillsname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="skillsname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.skillsname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.skilldescription && errors.skilldescription)}
												fullWidth
												helperText={touched.skilldescription && errors.skilldescription}
												variant="filled"
												placeholder={t('admin_panel.combat_skills.skilldescription')}
												label={t('admin_panel.combat_skills.skilldescription')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.skilldescription && errors.skilldescription ? <ErrorIcon /> : null,
												}}
												size="small"
												name="skilldescription"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.skilldescription}
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Button variant="contained" fullWidth type="submit" className="signup-button">
												{t('button_text.submit')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default AddSkills;
