export const Config = {
	baseURL: process.env.REACT_APP_API_URL,
	mediaURL: process.env.REACT_APP_MEDIA_URL,
	GoogleAppID: process.env.REACT_APP_GOOGLE_ID,
	FacebookAppID: process.env.REACT_APP_FACEBOOK_ID,
	AppleAppID: process.env.REACT_APP_APPLE_ID,
	DomainName: process.env.REACT_APP_DOMAIN_NAME,
	socketPath: process.env.REACT_APP_API_SOCKET_PATH,
	socketUrl: process.env.REACT_APP_API_SOCKET_BASE_URL,
	isEventUnable: process.env.REACT_APP_EVENT_UNABLE,
	isMessageUnable: process.env.REACT_APP_MESSAGE_UNABLE,
	oneSignalAppId: process.env.SIGNAL_APP_ID,
	siteVersion: process.env.REACT_APP_VERSION,
	siteVersionMin: process.env.REACT_APP_VERSION_MIN,
};
