import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SignupPage from 'src/views/component/auth/Register';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#fff',
		height: '100%',
	},
});

const RegisterView = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Sign Up">
			<SignupPage />
		</Page>
	);
};

export default RegisterView;
