import { memo, useState } from 'react';
import moment from 'moment';
import {
	Avatar,
	Box,
	Button,
	IconButton,
	Snackbar,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Tooltip,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as EndorsementSuccessIcon } from 'src/assets/images/icons/endrose-sucess.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
// import { ReactComponent as SortDownIcon } from 'src/assets/images/icons/sort-down.svg';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import { ReactComponent as CAddIcon } from 'src/assets/images/icons/fightRecord/add-circle.svg';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import AddEditDiscipline from './AddEditDiscipline';
import './discipline.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import FightingStyleModal from 'src/views/component/application/Profile/RightBlock/FightRecord/FightRecordData/FightingStyleModal';
import { useTranslation } from 'react-i18next';

const Discipline = ({
	user,
	token,
	isView,
	loader,
	getMyProfile,
	fetchUserDisciplines,
	disciplineLoader,
	disciplines,
	currentPage,
	limit,
	handlePaginationButtonClick,
	handlePaginationClick,
	totalRecord,
	isModalLoading,
	setIsModalLoading,
	onDialogTransitionEnd,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [modal, setModal] = useState('ae');
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState('');
	const [type, setType] = useState('add');
	const [selectedId, setSelectedId] = useState(null);
	const [selectedData, setSelectedData] = useState({});
	const [recentPage, setCurrentPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(''); //setTotalRecords
	const [list] = useState(10); //setList
	const [endorseUserlist, setEndorseUserlist] = useState([]);

	// const [andorsement, setAndorsement] = useState([]);

	// const handleMsg = () => {
	//   setShow(true);
	// };
	const authToken = fetchFromStorage(identifiers?.authData);
	const verificationWall = fetchFromStorage('verificationWall');

	const authHeader = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const handleCloseMsg = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setShow(false);
	};

	const handleToggle = async (value) => {
		var requestData = {
			user_id: value?.user_id,
			user_discipline_rank_id: value?.id,
		};

		if (verificationWall?.isEndorsement) {
			try {
				const { status, data } = await axiosInstance.post(URLS.createUserDisciplineRankEndorsement, requestData);

				if (status === 200) {
					fetchUserDisciplines('', currentPage);
					setApiMessage('success', data?.message);
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
				// console.log(error.response);
			}
		} else {
			setApiMessage('success', 'You must be verified to make this action');
		}
		// const currentIndex = andorsement.indexOf(value);
		// const newAndorsement = [...andorsement];

		// if (currentIndex === -1) {
		//   handleMsg();
		//   newAndorsement.push(value);
		// } else {
		//   newAndorsement.splice(currentIndex, 1);
		// }

		// setAndorsement(newAndorsement);
	};

	// DELETE DISCIPLINE HANDLER
	const deleteDisciplineHandler = async (id) => {
		setIsModalLoading(true);

		try {
			let { status } = await axiosInstance.delete(URLS.deleteUserDiscipline + id, authHeader);

			if (status === 200) {
				setApiMessage('success', t('myprofile.discipline_deleted_successfully'));
				closeDialog();
				fetchUserDisciplines('', currentPage);
				getMyProfile();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// DELETE DISCIPLINE ENDORSEMENT RANK
	const deleteUserDisciplineRankEndorsement = async (requestData) => {
		// var requestData = {
		//   user_id: disciplineData?.user_id,
		//   user_discipline_rank_id: disciplineData?.id,
		//   user_verified_by_id: authToken?.user_id,
		// };
		try {
			const { status, data } = await axiosInstance.delete(URLS.deleteUserDisciplineRankEndorsement, {
				data: requestData,
				headers: authHeader,
			});

			if (status === 200) {
				closeDialog();
				setApiMessage('success', data?.message);
				fetchUserDisciplines('', currentPage);
			}
		} catch (error) {
			closeDialog();
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	// DISCIPLINE DIALOG HANDLERS
	const combatDialogHandler = (type, title, modal, id, data) => {
		setOpen(true);
		setType(type);
		setTitle(title);
		setModal(modal);

		if (id) {
			setSelectedId(id);
		}

		if (data) {
			setSelectedData(data);
		}
	};

	const closeDialog = () => {
		setOpen(false);
	};

	const fetchUserDisciplineRankUsers = async (page) => {
		try {
			const { status, data } = await axiosInstance.get(
				URLS.getUserDisciplineRankUsers + `${selectedData?.id}?page=${page}&limit=${list}`,
			);

			if (status === 200) {
				setEndorseUserlist(data?.authUser);
				setTotalRecords(data?.totalRecords);
				// setTitle(title);

				// setTitle(
				//   ` [${t('myprofile.fighting_style')}] (${data?.authUser?.length})`
				// );
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// PAGINATION FOR ENDORSE USER LIST
	const handlePaginationButtonClicks = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setCurrentPage(recentPage - 1);
				fetchUserDisciplineRankUsers(recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(totalRecords / list)) {
				setCurrentPage(recentPage + 1);
				fetchUserDisciplineRankUsers(recentPage + 1);
			}
		}
	};
	const handlePaginationClicks = async (event, page) => {
		if (recentPage !== page) {
			setCurrentPage(Number(page));
			fetchUserDisciplineRankUsers(Number(page));
		}
	};

	return (
		<>
			{/* THANK YOU FOR ENDORSING SNACKBAR */}
			<Snackbar open={show} autoHideDuration={4000} onClose={handleCloseMsg}>
				<Box className="endorement-snackbar">
					<Typography>{t('myprofile.thankyou_for_endorsing') + ' ' + user?.first_name}</Typography>
					<IconButton onClick={handleCloseMsg}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Snackbar>

			<Box className="discipline-tab">
				<Box className="title-block">
					<Typography className="tab-block-title">{t('myprofile.discipline_rank')}</Typography>

					{/* ADD DISCIPLINE BUTTON */}
					{isView ? null : disciplineLoader ? null : (
						<IconButton
							onClick={() => {
								combatDialogHandler('add', t('myprofile.add_discipline'), 'ae');
							}}
						>
							<BaddIcon />
						</IconButton>
					)}
				</Box>

				{/* DISCIPLINE TABLE */}
				{disciplineLoader ? (
					<Skeleton variant="rect" width={'100%'} height={'130px'} />
				) : disciplines?.length > 0 ? (
					<>
						<Box className="discipline-table-container">
							<TableContainer className="user-discipline">
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell className="style-cell">
												{t('myprofile.fighting_style')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>

											<TableCell className="rank-cell">
												{t('myprofile.rank')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>

											<TableCell className="endorsement-cell">
												{t('myprofile.endorsements')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>

											<TableCell className="gym-cell">
												{t('myprofile.granted_by')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>

											<TableCell className="gym-cell">
												{t('myprofile.granted_at')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>

											<TableCell className="date-generated-cell">
												{t('myprofile.date_granted')}
												{/* <IconButton>
                        <SortDownIcon />
                      </IconButton> */}
											</TableCell>
											<TableCell className="discipline-actions"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{disciplines &&
											disciplines.map((disc, index) => {
												var uriVal =
													disc?.user_meta && Object.keys(disc?.user_meta).length !== 0 && disc?.user_meta?.data;

												var urlValue =
													disc?.user_type === 'user_other' ? `/user/shadow/${disc?.user_issuer_id}` : `/user/${uriVal}`;
												var flag = true;

												return (
													<TableRow key={index}>
														{/* FIGHTING STYLE */}
														<Tooltip title={disc?.Discipline?.name} arrow>
															<TableCell className="style-cell" style={{ cursor: 'pointer' }}>
																{disc?.Discipline?.name}
															</TableCell>
														</Tooltip>

														{/* RANK */}
														<Tooltip
															title={
																disc?.discipline_meta_rank_sort_order !== null
																	? disc?.discipline_meta_rank_sort_order
																	: disc?.rank_other
															}
															arrow
														>
															<TableCell className="rank-cell" style={{ cursor: 'pointer' }}>
																{disc?.discipline_meta_rank_sort_order !== null
																	? disc?.discipline_meta_rank_sort_order
																	: disc?.rank_other}
															</TableCell>
														</Tooltip>

														{/* ENDORSEMENT */}
														<TableCell className="endorsement-cell">
															<Box width="100%" display="flex" justifyContent="center">
																<IconButton
																	style={{
																		pointerEvents: disc?.Endorsements_count === 0 && 'none',
																	}}
																	onClick={() => {
																		disc?.Endorsements_count > 0 &&
																			combatDialogHandler('', disc?.Discipline?.name, 'endorse', null, disc);
																	}}
																>
																	<Avatar>{disc?.Endorsements_count}</Avatar>
																</IconButton>
															</Box>
														</TableCell>

														{/* GRANTED BY */}
														{Object.keys(disc?.user_details).length !== 0 && disc?.user_details?.user_name !== '' ? (
															<Tooltip
																title={
																	Object.keys(disc?.user_details).length !== 0 && disc?.user_details?.user_name !== ''
																		? disc?.user_details?.user_name
																		: 'N/A'
																}
																arrow
															>
																<TableCell
																	className="gym-cell"
																	onClick={() => flag && navigate(urlValue)}
																	style={{ cursor: 'pointer' }}
																>
																	{Object.keys(disc?.user_details).length !== 0 && disc?.user_details?.user_name !== ''
																		? disc?.user_details?.user_name
																		: 'N/A'}
																</TableCell>
															</Tooltip>
														) : (
															<TableCell
																className="gym-cell"
																onClick={() => {
																	flag && navigate(urlValue);
																}}
															>
																{}
																{Object.keys(disc?.user_details).length !== 0 && disc?.user_details?.user_name !== ''
																	? disc?.user_details?.user_name
																	: 'N/A'}
															</TableCell>
														)}

														{/* GRANTED AT */}
														{/* HIDE-MVP: CM-79 */}
														{/*<Tooltip title={disc?.Organization?.name ? disc?.Organization?.name : 'N/A'} arrow>*/}
														{/*	<TableCell*/}
														{/*		className="gym-cell"*/}
														{/*		style={{ cursor: 'pointer' }}*/}
														{/*		onClick={() => navigate(`/organisation-details/${disc?.Organization?.id}`)}*/}
														{/*	>*/}
														{/*		{disc?.Organization?.name ? disc?.Organization?.name : 'N/A'}*/}
														{/*	</TableCell>*/}
														{/*</Tooltip>*/}

														{/* DATE GRANTED */}
														<TableCell className="date-generated-cell">
															{disc?.date_start ? moment(disc?.date_start).format('DD/MM/YYYY') : 'N/A'}
														</TableCell>
														{/* ENDORSEMENT EDIT AND DELETE ACTIONS */}
														<TableCell className="discipline-actions">
															{!isView ? (
																<Box className="edit-delete">
																	{/* EDIT DISCIPLINE BUTTON */}
																	<IconButton
																		onClick={() => {
																			combatDialogHandler('edit', t('myprofile.edit_discipline'), 'ae', null, disc);
																		}}
																	>
																		<EditIcon />
																	</IconButton>

																	{/* DELETE DISCIPLINE BUTTON */}
																	<IconButton
																		onClick={() => {
																			combatDialogHandler('edit', t('myprofile.delete_discipline'), 'delete', disc?.id);
																			// deleteDisciplineHandler(disc?.discipline_id);
																		}}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Box>
															) : (
																<Box>
																	{/* DISCIPLINE ENDORSEMENT ACTION FOR VISITOR */}
																	<Box className="add">
																		<IconButton
																			onClick={() => !disc?.login_user_endorsement_status && handleToggle(disc)}
																		>
																			{disc?.login_user_endorsement_status ? (
																				<EndorsementSuccessIcon
																					onClick={() =>
																						deleteUserDisciplineRankEndorsement({
																							user_id: disc?.user_id,
																							user_discipline_rank_id: disc?.id,
																							user_verified_by_id: authToken?.user_id,
																						})
																					}
																				/>
																			) : (
																				<CAddIcon />
																			)}
																			{/* {andorsement?.indexOf(
                                      disc?.discipline_id
                                    ) !== -1 ? (
                                      <EndorsementSuccessIcon />
                                    ) : (
                                      <CAddIcon />
                                    )} */}
																		</IconButton>
																	</Box>
																</Box>
															)}
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<CustomPagination
							paginationArray={disciplines}
							currentPage={currentPage}
							list={limit}
							handlePaginationButtonClick={handlePaginationButtonClick}
							handlePaginationClick={handlePaginationClick}
							totalRecord={totalRecord}
						/>
					</>
				) : (
					<Typography className="no-info-txt">
						{isView ? t('myprofile.user_has_discipline') : t('myprofile.no_discipline')}
					</Typography>
				)}
			</Box>

			{/* ADD-EDIT FORM DIALOG */}
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				onDialogTransitionEnd={onDialogTransitionEnd}
				title={title}
				className={'user-profile-dialog'}
				content={
					modal === 'ae' ? (
						<AddEditDiscipline
							token={token}
							type={type}
							discData={selectedData}
							handleClose={closeDialog}
							getMyProfile={getMyProfile}
							fetchUserDisciplines={fetchUserDisciplines}
							currentPage={currentPage}
							isModalLoading={isModalLoading}
							setIsModalLoading={setIsModalLoading}
						/>
					) : modal === 'endorse' ? (
						<FightingStyleModal
							fightContestant={endorseUserlist}
							fetchEventFightContestantUsers={fetchUserDisciplineRankUsers}
							deleteFightContestantEndorsement={deleteUserDisciplineRankEndorsement}
							currentPage={recentPage}
							limit={list}
							handlePaginationButtonClick={handlePaginationButtonClicks}
							handlePaginationClick={handlePaginationClicks}
							totalRecord={totalRecords}
							fromWhere="disciplinerank"
							closeDialog={closeDialog}
						/>
					) : (
						<Box className="dialog-submit-button">
							<Button style={{ marginRight: 9 }} variant="contained" onClick={closeDialog} className="dialog-submit">
								{t('cancel')}
							</Button>
							<Button
								variant="contained"
								disabled={isModalLoading}
								onClick={() => {
									deleteDisciplineHandler(selectedId);
								}}
								className="dialog-submit"
							>
								{t('confirm')}
							</Button>
						</Box>
					)
				}
			/>
		</>
	);
};

export default memo(Discipline);
