import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Tab, Typography, List } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AssetData from 'src/views/component/UI/FollowerAsset';
import './cffc.scss';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';

const CFFCBlock = ({
	token,
	isView,
	connectionLoader,
	followingLoader,
	followersLoader,
	following,
	followers,
	connections,
	user,
	communitiesList,
	location,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { id } = useParams();
	const [tab, setTab] = useState('1');
	// location.state !== null ? location.state.showSelectedTab === 'member' ? 3 : 1 : 1);
	// console.log(location, "ssss");
	// if (location.state !== null) {
	//   if (location.state.commentOP === 'commentOP') {
	//     setTab(3);

	//   }
	// }
	let statistics = user?.UserStatisticsDetails;
	const owner_list = [
		{ id: 1, name: t('myprofile.tab_connections') },
		{ id: 2, name: t('myprofile.tab_followers') },
		{ id: 3, name: t('myprofile.tab_following') },
		{ id: 4, name: t('myprofile.tab_communities') },
	];
	const visiter_list = [{ id: 1, name: t('myprofile.tab_connections') }];
	// const list = isView ? visiter_list : owner_list;
	const list = owner_list;
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		setTab('1');
		// eslint-disable-next-line
	}, [id]);

	return (
		<Box className="social-activity-container">
			<TabContext value={String(tab)}>
				<Box className="actions-header">
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler} aria-label="action tabs">
						{list.map((obj, index) => {
							return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
						})}
					</TabList>
				</Box>
				<Box className="act-data-list">
					{/* CONNECTION */}
					<TabPanel value="1">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_connections')} {!connectionLoader && `(${statistics?.connections || 0})`}
								{!connectionLoader && (
									<>
										{connections?.length > 0 && (
											<Button
												disableElevation
												disableTouchRipple
												disableFocusRipple
												disableRipple
												className="see-all-btn-right"
												onClick={() => {
													navigate('/connections/connection', {
														state: {
															USERID: fetchFromStorage(identifiers.visitorUserId),
															tabValue: 'connections',
														},
													});
													saveToStorage('connectionPageStatus', true);
												}}
											>
												{t('see_all')}
											</Button>
										)}
									</>
								)}
							</Typography>

							<Box className="cffc-data-container">
								{connectionLoader ? (
									<Box display="flex" justifyContent="center" alignItems="center">
										<CircularProgress size={18} color="secondary" />
									</Box>
								) : connections?.length > 0 ? (
									<List dense>
										{connections?.map((data, index) => {
											return (
												<AssetData
													key={index}
													img={data?.authUser?.item_profile_id}
													userId={data?.authUser?.id}
													uri={Object.keys(data?.user_meta).length === 0 ? data?.authUser?.id : data?.user_meta?.data}
													name={`${data?.authUser?.first_name ? data?.authUser?.first_name : ''} ${
														data?.authUser?.last_name ? data?.authUser?.last_name : ''
													}${!data?.authUser?.first_name && !data?.authUser?.last_name ? 'Combat Matrix User' : ''}`}
													data={data}
												/>
											);
										})}
									</List>
								) : (
									<Typography className="no-data-txt">
										{isView ? t('myprofile.user_has_no_connections') : t('myprofile.you_have_no_connections')}
									</Typography>
								)}
							</Box>
						</Box>
					</TabPanel>

					{/* FOLLOWERS */}
					<TabPanel value="2">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_followers')} {!followersLoader && `(${statistics?.followers || 0})`}
								{!followersLoader && (
									<>
										{followers?.length > 0 && (
											<Button
												disableElevation
												disableTouchRipple
												disableFocusRipple
												disableRipple
												className="see-all-btn-right"
												onClick={() => {
													navigate('/connections/followers', {
														state: {
															USERID: fetchFromStorage(identifiers.visitorUserId),
															tabValue: 'followers',
														},
													});
													saveToStorage('connectionPageStatus', true);
													// navigate(`/followers`);
												}}
											>
												{t('see_all')}
											</Button>
										)}
									</>
								)}
							</Typography>

							<Box className="cffc-data-container">
								{followersLoader ? (
									<Box display="flex" justifyContent="center" alignItems="center">
										<CircularProgress size={18} color="secondary" />
									</Box>
								) : followers?.length > 0 ? (
									<List dense>
										{followers?.map((data, index) => {
											return (
												<AssetData
													key={index}
													img={data?.authUser?.item_profile_id}
													userId={data?.authUser?.id}
													uri={Object.keys(data?.user_meta).length === 0 ? data?.authUser?.id : data?.user_meta?.data}
													name={`${data?.authUser?.first_name ? data?.authUser?.first_name : ''} ${
														data?.authUser?.last_name ? data?.authUser?.last_name : ''
													}${!data?.authUser?.first_name && !data?.authUser?.last_name ? 'Combat Matrix User' : ''}`}
													data={data}
												/>
											);
										})}
									</List>
								) : (
									<Typography className="no-data-txt">
										{isView ? t('myprofile.user_has_no_followers') : t('myprofile.you_have_no_followers')}
									</Typography>
								)}
							</Box>
						</Box>
					</TabPanel>

					{/* FOLLOWING */}
					<TabPanel value="3">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_following')} {!followingLoader && `(${statistics?.following || 0})`}
								{!followingLoader && (
									<>
										{following?.length > 0 && (
											<Button
												disableElevation
												disableTouchRipple
												disableFocusRipple
												disableRipple
												className="see-all-btn-right"
												onClick={() => {
													navigate('/connections/following', {
														state: {
															USERID: fetchFromStorage(identifiers.visitorUserId),
															tabValue: 'following',
														},
													});
													saveToStorage('connectionPageStatus', true);
													// navigate(`/followers`);
												}}
											>
												{t('see_all')}
											</Button>
										)}
									</>
								)}
								{/* {t('myprofile.tab_following')}{' '} */}
								{/* {!followingLoader && `(${following?.length || 0})`}
                {!followingLoader && (
                  <Button
                    disableElevation
                    disableTouchRipple
                    disableFocusRipple
                    disableRipple
                    className="see-all-btn-right"
                    onClick={() => {
                      navigate(`/following`);
                    }}
                  >
                    {t('see_all')}
                  </Button>
                )} */}
							</Typography>

							<Box className="cffc-data-container">
								{followingLoader ? (
									<Box display="flex" justifyContent="center" alignItems="center">
										<CircularProgress size={18} color="secondary" />
									</Box>
								) : following?.length > 0 ? (
									<List dense>
										{following?.map((data, index) => {
											return (
												<AssetData
													key={index}
													img={data?.authUser?.item_profile_id}
													userId={data?.authUser?.id}
													uri={
														data?.user_meta && Object.keys(data?.user_meta).length === 0
															? data?.authUser?.id
															: data?.user_meta?.data
													}
													name={`${data?.authUser?.first_name ? data?.authUser?.first_name : ''} ${
														data?.authUser?.last_name ? data?.authUser?.last_name : ''
													}${!data?.authUser?.first_name && !data?.authUser?.last_name ? 'Combat Matrix User' : ''}`}
													data={data}
												/>
											);
										})}
									</List>
								) : (
									<Typography className="no-data-txt">
										{isView ? t('myprofile.user_has_no_following') : t('myprofile.you_have_no_following')}
									</Typography>
								)}
							</Box>
						</Box>
					</TabPanel>

					{/* COMMUNITIES */}
					<TabPanel value="4">
						<Box className="activity-tab">
							<Typography className="tab-title">
								{t('myprofile.tab_communities')} {`(${communitiesList?.totalRecords || 0})`}
								{communitiesList?.communitiesData?.length > 0 && (
									<Button
										disableElevation
										disableTouchRipple
										disableFocusRipple
										disableRipple
										className="see-all-btn-right"
										onClick={() => {
											// navigate(`/communities`);
											navigate('/connections/community', {
												state: {
													USERID: fetchFromStorage(identifiers.visitorUserId),
													tabValue: 'community',
												},
											});
										}}
									>
										{t('see_all')}
									</Button>
								)}
							</Typography>

							<Box className="cffc-data-container">
								{communitiesList?.communitiesData?.length > 0 ? (
									<List dense>
										{communitiesList &&
											communitiesList?.communitiesData.map((data, index) => {
												return (
													<AssetData
														key={index}
														img={data?.item_profile_data}
														name={data?.name}
														isCommunity={true}
														data={data}
														userId={data?.id}
													/>
												);
											})}
									</List>
								) : (
									<Typography className="no-data-txt">
										{isView ? t('myprofile.user_has_no_communities') : t('myprofile.you_have_no_communities')}
									</Typography>
								)}
							</Box>
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	);
};

export default CFFCBlock;
