import { Avatar, Box, Typography } from '@material-ui/core';
import './fight-details.scss';
import moment from 'moment';

const FightDetails = ({ item }) => {
	return (
		<Box className="fr-details">
			<Box className="fr-content">
				<Box className="fr-ctop">
					<Box className="eventData">
						<Avatar src="" variant="square" className="fr-eimg" />
						<Box className="evntd">
							<Box className="evnt-lft">
								<Box className="bck-data">
									<Box className="databck">
										<Box className="lftbck">
											<Typography className="bck-tle">EVENT NAME</Typography>
										</Box>
										<Box className="rgtbck">
											<Typography className="bck-cnt">{item?.EventData?.name}</Typography>
										</Box>
									</Box>
								</Box>

								<Box className="bck-data">
									<Box className="databck">
										<Box className="lftbck">
											<Typography className="bck-tle">Start time</Typography>
										</Box>
										<Box className="rgtbck">
											<Typography className="bck-cnt">
												{/* {item?.start_time} */}
												{moment(item?.EventData?.start_time).format('hh:mm A')}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box className="evnt-rgt">
								<Box className="bck-data">
									<Box className="databck">
										<Box className="lftbck">
											<Typography className="bck-tle">END time</Typography>
										</Box>
										<Box className="rgtbck">
											<Typography className="bck-cnt">{moment(item?.EventData?.end_time).format('hh:mm A')}</Typography>
										</Box>
									</Box>
								</Box>

								<Box className="bck-data">
									<Box className="databck">
										<Box className="lftbck">
											<Typography className="bck-tle">LOCATION</Typography>
										</Box>
										<Box className="rgtbck">
											<Typography className="bck-cnt">{item?.EventData?.location_type}</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
					<Typography className="fstime">FIGHT START TIME: {moment(item?.end_time).format('hh:mm A')}</Typography>
					<Typography className="fgt-tlt">{item?.name}</Typography>

					<Box className="fgtrs">
						<Box className="fgtr1">
							<Box className="fgtr-data">
								<Typography className="fgtr-side">{item?.EventFightContestants[0]?.EventFightSide?.name}</Typography>
								<Typography className="fgtrname">
									{item?.EventFightContestants[0]?.EventFightContestants[0]?.user_name}
								</Typography>
							</Box>
							<Avatar
								src={item?.EventFightContestants[0]?.EventFightContestants[0]?.user_item_profile_data}
								className="fgtrimg"
							/>
						</Box>
						<Typography className="fgtrs-vtxt">v</Typography>
						<Box className="fgtr2">
							<Avatar
								src={item?.EventFightContestants[1]?.EventFightContestants[0]?.user_item_profile_data}
								className="fgtrimg"
							/>
							<Box className="fgtr-data">
								<Typography className="fgtr-side">{item?.EventFightContestants[1]?.EventFightSide?.name}</Typography>
								<Typography className="fgtrname">
									{item?.EventFightContestants[1]?.EventFightContestants[0]?.user_name}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className="fr-cbottom">
					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.MetaWeightClass?.name}
						</Typography>
						<Typography className="fgthead">weight division</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[1]?.EventFightContestants[0]?.MetaWeightClass?.name}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.result}
						</Typography>
						<Typography className="fgthead">Result</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[1]?.EventFightContestants[0]?.result}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.round_number}
						</Typography>
						<Typography className="fgthead">Round</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[1]?.EventFightContestants[0]?.round_number}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.round_name}
						</Typography>
						<Typography className="fgthead">Round Name</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.round_name}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.score}
						</Typography>
						<Typography className="fgthead">Score</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.score}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.time}
						</Typography>
						<Typography className="fgthead">RESULT Time</Typography>
						<Typography className="fgtri rgt">
							{item?.EventFightContestants[0]?.EventFightContestants[0]?.time}
						</Typography>
					</Box>

					<Box className="fgd-i">
						<Typography className="fgtri lft">
							{
								item?.EventFightContestants[0]?.EventFightContestants[0]?.EventFightContestantMethods?.MetaFightMethod
									?.name
							}
						</Typography>
						<Typography className="fgthead">MethoD</Typography>
						<Typography className="fgtri rgt">
							{
								item?.EventFightContestants[1]?.EventFightContestants[0]?.EventFightContestantMethods?.MetaFightMethod
									?.name
							}
						</Typography>
					</Box>
				</Box>

				{/* <Box className="frd-acts">Endorsement</Box> */}
			</Box>
		</Box>
	);
};

export default FightDetails;
