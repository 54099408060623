import { useState } from 'react';
import { Avatar, Box } from '@material-ui/core';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import CreateNewCommunity from './create';
// import Viewer from 'react-viewer';
import './createCommunity.scss';
import { useTranslation } from 'react-i18next';

const CreateCommunityBox = ({ token, user, fetchCommunityList }) => {
	const { t } = useTranslation();

	const userImage = user ? user?.profile_pic : '';
	const verificationWall = fetchFromStorage('verificationWall');
	const [open, setOpen] = useState(false);
	// const [visible, setVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const combatDialogHandler = () => {
		setOpen(true);
	};

	const closeDialog = () => {
		setOpen(false);
	};

	return (
		<div className="create-community-show-box-container">
			<Box className="create-community-show-box">
				<>
					<Avatar
						src={userImage}
						// style={{ cursor: 'pointer' }}
						// onClick={() => {
						//   setVisible(true);
						// }}
					/>
					{/* {(userImage !== '' && userImage !== undefined && userImage !== null) && (
            <Viewer
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              images={[
                {
                  src: userImage,
                  title: 'cover image'
                }
              ]}
              downloadable
              noFooter={true}
              drag={false}
              zoomable={false}
              rotatable={false}
              scalable={false}
              disableMouseZoom={true}
            />
          )} */}
				</>
				<Box
					onClick={() => {
						verificationWall?.isEndorsement
							? combatDialogHandler()
							: setApiMessage('success', 'You must be verified to make this action');
					}}
					className="create-comm-text"
				>
					{t('community.create_community')}
				</Box>
			</Box>

			{/* Add / Edit Dialog */}
			<CombatDialog
				onDialogTransitionEnd={() => setIsLoading(false)}
				open={open}
				handleClose={closeDialog}
				title={t('community.create_community')}
				content={
					<CreateNewCommunity
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						token={token}
						fetchCommunityList={fetchCommunityList}
						handleClose={closeDialog}
					/>
				}
			/>
		</div>
	);
};

export default CreateCommunityBox;
