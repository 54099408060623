import { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, IconButton, Tab, Typography } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Skeleton from '@material-ui/lab/Skeleton';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import Athelete from './Athelete';
import Discipline from './Discipline';
import ProfileType from './ProfileType';
import RecentActivity from './RecentActivity';
import EditProfile from '../../RightBlock/EditProfile';
import './type.scss';
import { useTranslation } from 'react-i18next';

const ProfileTypeSlider = ({ isView, loader, user, token, getMyProfile }) => {
	const { t } = useTranslation();
	const typeData = user?.data || [];
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [model, setModel] = useState('');
	const [list, setList] = useState([]);
	const [tab, setTab] = useState();

	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	const combatDialogHandler = (type, title, modal) => {
		setOpen(true);
		setTitle(title);
	};

	const closeDialog = () => {
		setOpen(false);
	};

	useEffect(() => {
		let val = typeData?.profile_types?.map((obj) => {
			return String(obj);
		});

		if (typeData?.profile_types?.length > 0) {
			let tabO = String(typeData?.profile_types[0]);

			setTab(tabO);
		}

		setList(val);
	}, [typeData]);

	let abouttext = user?.UserData?.UserAboutData?.Post?.name;

	let username = user?.UserMetaData?.filter((data) => data?.type === 'other')[0]?.data || '';
	let dob = user?.data?.dob || '';
	let country = user?.data?.country || '';
	let userData = user?.data;

	return (
		<>
			{/* Profile Details */}
			<Box className="left-profile-details-box">
				<Box display="flex" justifyContent="space-between">
					<Typography className="profile-left-title">Profile</Typography>
					{loader ? null : isView ? null : (
						<IconButton
							onClick={() => {
								combatDialogHandler('edit', t('myprofile.edit_profile'));
								setModel('profile');
							}}
						>
							<BpencilIcon />
						</IconButton>
					)}
				</Box>

				<Box className="profile-details-data">
					{/* Username */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.username') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : username}
						</Typography>
					</Box>

					{/* Date Of Birth */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.dob') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : moment(dob).format('MM/DD/YYYY')}
						</Typography>
					</Box>

					{/* Age */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.age') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? (
								<Skeleton variant="text" width={'100px'} />
							) : (
								dob && new Date().getFullYear() - new Date(dob).getFullYear()
							)}
						</Typography>
					</Box>

					{/* weight Classes */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.weight_classes') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : 'Light weight'}
						</Typography>
					</Box>

					{/* Country */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.country') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : country}
						</Typography>
					</Box>

					{/* Organisations */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.organsiationss') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : 'Bangkok Fight Club'}
						</Typography>
					</Box>

					{/* Fighting Out Of */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.fighting_out_of') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : 'Cincinnati, Ohio'}
						</Typography>
					</Box>

					{/* Fight Records */}
					<Box display="flex">
						<Typography className="profile-details-field">
							{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.fight_record') + ':'}
						</Typography>
						<Typography className="profile-details-field-val">
							{loader ? <Skeleton variant="text" width={'100px'} /> : '11-4-0'}
						</Typography>
					</Box>
				</Box>
			</Box>

			{/* Profile Types ADD-EDIT & About Profile Type */}
			<Box className="profile-type-slider">
				<TabContext value={String(tab)}>
					<Box className="about-tab">
						<Box className="title-block">
							<Typography className="tab-block-title">
								{loader ? (
									<Skeleton variant="text" width={200} height={28} />
								) : (
									`${t('myprofile.about_this')} ${typeList?.filter((data) => String(data?.id) === tab)[0]?.name}`
								)}
							</Typography>
							<Box>
								{loader ? null : isView ? null : (
									<Box display={'flex'}>
										<IconButton
											style={{ marginRight: '11px' }}
											onClick={() => {
												combatDialogHandler('edit', t('profile_type.title'));
												setModel('profileType');
											}}
										>
											<BaddIcon />
										</IconButton>
										<IconButton
											onClick={() => {
												combatDialogHandler('edit', `About`);
												setModel('athelete');
											}}
										>
											<BpencilIcon />
										</IconButton>
									</Box>
								)}
							</Box>
						</Box>

						<Box className="slider-container">
							<Box display="flex" justifyContent="space-between">
								{loader ? (
									<Box display="flex">
										<Skeleton variant="rect" className="skel-prof-type-tab" />
										<Skeleton variant="rect" className="skel-prof-type-tab" />
										<Skeleton variant="rect" className="skel-prof-type-tab" />
									</Box>
								) : (
									<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
										{list?.map((obj, index) => {
											return (
												<Tab
													label={typeList?.filter((data) => String(data?.id) === String(obj))[0]?.name}
													key={index}
													value={String(obj)}
												/>
											);
										})}
									</TabList>
								)}
							</Box>
						</Box>

						{loader ? (
							<Box mt={1}>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</Box>
						) : abouttext ? (
							<Typography className="profile-type-about-txt">{abouttext}</Typography>
						) : (
							<Typography className="no-info-txt">
								{isView ? t('myprofile.user_has_no_info') : t('myprofile.you_have_no_info')}
							</Typography>
						)}
					</Box>

					{/* Discipline */}
					<Discipline isView={isView} token={token} loader={loader} />

					{/* Recent Activity */}
					<RecentActivity isView={isView} token={token} />
				</TabContext>
			</Box>

			{/* Add / Edit Dialog */}
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'athelete' ? (
						<Athelete
							title={list.filter((data) => data?.id === tab)[0]?.name}
							id={tab}
							data={user}
							token={token}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
						/>
					) : model === 'profile' ? (
						<EditProfile
							data={userData}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'profileType' ? (
						<ProfileType typeData={typeData} token={token} getMyProfile={getMyProfile} closeDialog={closeDialog} />
					) : (
						<Discipline token={token} closeDialog={closeDialog} getMyProfile={getMyProfile} />
					)
				}
			/>
		</>
	);
};

export default ProfileTypeSlider;
