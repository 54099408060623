import { useState, useContext } from 'react';
import moment from 'moment';
import { Avatar, Box, Button, Grid, Typography, Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
import { ReactComponent as SherdogIcon } from 'src/assets/images/icons/sherdog.svg';
// import { ReactComponent as VerifiedAthelete } from 'src/assets/images/badge/verifiedAthelete.svg';
// import { ReactComponent as VerifiedCoach } from 'src/assets/images/badge/verifiedCoach.svg';
import CFFCBlock from './CFFC';
import FightRecord from './FightRecord';
import EditProfile from './EditProfile';
import EditSocialLink from './EditSocialLink';
import EditBio from './EditBio';

function SocialIconHandler(social) {
	switch (social) {
		case 'URL_facebook':
			return <FaceBookIcon />;
		case 'URL_instagram':
			return <InstagramIcon />;
		case 'URL_twitter':
			return <TwitterIcon />;
		case 'URL_tapology':
			return <TapologyIcon />;
		case 'URL_snapchat':
			return <SnapchatIcon />;
		case 'URL_youtube':
			return <YouTubeIcon />;
		case 'URL_twitch':
			return <TwitchIcon />;
		case 'URL_linkedin':
			return <LinkedInIcon />;
		case 'URL_tiktok':
			return <TikTokIcon />;
		case 'URL_sherdog':
			return <SherdogIcon />;
		default:
			return <FaceBookIcon />;
	}
}

const ProfileRightBlock = ({ isView, ProfileAvatar, getMyProfile, user, token, loader }) => {
	const { t } = useTranslation();
	let userName = user?.UserMetaData?.filter((data) => data?.type === 'other')[0]?.data;
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [model, setModel] = useState('');

	const combatDialogHandler = (type, title, modal) => {
		setOpen(true);
		setTitle(title);
	};

	const closeDialog = () => {
		setOpen(false);
	};
	let userData = user?.data;
	let socialMeta = user?.UserMetaData?.filter((data) => data?.data !== '');
	let statistics = user?.UserStatisticsDetails;
	let userBio = user?.UserData?.UserBioData?.name;

	return (
		<>
			<Box className="my-profile-right">
				<Box className="my-right-top">
					<Box className="my-right-img">
						<Box className="my-right-avatar">
							<Box className="right-img">
								{loader ? (
									<Skeleton variant="circle" width={115} height={115} animation="wave" />
								) : isView ? (
									<Avatar src={userData?.user_profile} />
								) : (
									ProfileAvatar
								)}
							</Box>
						</Box>

						<Box className="myright-names">
							{/* NickName */}
							<Typography className="myright-wildcard">
								{loader ? <Skeleton variant="text" /> : userName ? `"${userName}"` : null}
							</Typography>

							<Typography className="right-username">
								{loader ? (
									<Skeleton variant="text" />
								) : (
									`${userData?.first_name ? userData?.first_name : null} ${
										userData?.last_name ? userData?.last_name : null
									}`
								)}
							</Typography>

							<Typography className="member-since">
								{loader ? (
									<Skeleton variant="text" />
								) : (
									`${t('myprofile.member_since')}: ${moment(userData?.timestamp_created).format('DD MMM YY')}`
								)}
							</Typography>

							<Box className="social-icons">
								<Grid container>
									{loader ? (
										<>
											<Skeleton variant="circle" className="social-link" width={18} height={18} />
											<Skeleton variant="circle" className="social-link" width={18} height={18} />
											<Skeleton variant="circle" className="social-link" width={18} height={18} />
											<Skeleton variant="circle" className="social-link" width={18} height={18} />
										</>
									) : socialMeta ? (
										socialMeta.length > 0 ? (
											socialMeta
												?.filter((data) => data?.type !== 'other')
												?.map((data, index) => {
													return (
														<Grid item className="social-link" key={index}>
															<Link href={data?.data} target="_blank">
																{SocialIconHandler(data?.type)}
															</Link>
														</Grid>
													);
												})
										) : null
									) : null}
								</Grid>
							</Box>
						</Box>
					</Box>

					{isView ? null : (
						<Box className="social-right-edit-actions">
							{loader ? (
								<Skeleton variant="rect" width={'49%'} className="skel-edit-ps" />
							) : (
								<Button
									variant="contained"
									className="edt-scl-lnkbtn"
									startIcon={<SmallPencil />}
									onClick={() => {
										combatDialogHandler('edit', t('myprofile.edit_social'));
										setModel('social');
									}}
								>
									{t('myprofile.edit_social')}
								</Button>
							)}
							{loader ? (
								<Skeleton variant="rect" width={'49%'} className="skel-edit-ps" />
							) : (
								<Button
									variant="contained"
									className="edt-profile-btn"
									startIcon={<SmallPencil color={'#fff'} />}
									onClick={() => {
										combatDialogHandler('edit', t('myprofile.edit_profile'));
										setModel('profile');
									}}
								>
									{t('myprofile.edit_profile')}
								</Button>
							)}
						</Box>
					)}

					<Box className="right-prof-counts">
						<Box className="social-count-bx">
							<Typography className="social-count">
								{loader ? <Skeleton variant="text" /> : statistics?.followers}
							</Typography>
							<Typography className="social-act-name">{t('myprofile.folowers')}</Typography>
						</Box>

						<Box className="social-count-bx">
							<Typography className="social-count">
								{loader ? <Skeleton variant="text" /> : statistics?.connections}
							</Typography>
							<Typography className="social-act-name">{t('myprofile.connections')}</Typography>
						</Box>

						<Box className="social-count-bx">
							<Typography className="social-count">
								{loader ? <Skeleton variant="text" /> : statistics?.following}
							</Typography>
							<Typography className="social-act-name">{t('myprofile.following')}</Typography>
						</Box>

						<Box className="social-count-bx">
							<Typography className="social-count">
								{loader ? <Skeleton variant="text" /> : statistics?.refferrals}
							</Typography>
							<Typography className="social-act-name">{t('myprofile.refferals')}</Typography>
						</Box>
					</Box>

					<Box className="bio-block">
						<Typography className="bio-title">{t('myprofile.bio')}</Typography>

						<Typography className="bio-text" style={{ textAlign: userBio ? 'left' : 'center' }}>
							{loader ? (
								<>
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</>
							) : userBio ? (
								userBio
							) : isView ? (
								t('myprofile.user_has_no_bio')
							) : (
								''
							)}
						</Typography>

						{isView ? null : loader ? (
							<Skeleton variant="rect" width={'100%'} style={{ marginTop: '18px' }} className="skel-edit-ps" />
						) : (
							<Button
								variant="contained"
								className="edit-bio-btn"
								startIcon={<SmallPencil />}
								onClick={() => {
									combatDialogHandler('edit', t('myprofile.edit_bio'));
									setModel('bio');
								}}
								style={{ marginTop: userBio ? '18px' : '0px' }}
							>
								{t('myprofile.edit_bio')}
							</Button>
						)}
					</Box>
				</Box>

				{/* Badges */}
				<Box className="badge-container">
					<Typography className="badge-title">{t('myprofile.badges')}</Typography>

					{/* <Box className="badges-container">
            <Grid container spacing={3}>
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Box className="badge-block">
                  <VerifiedAthelete />
                  <Typography className="verified-badge-text">
                    Verified Athelete
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Box className="badge-block">
                  <VerifiedCoach />
                  <Typography className="verified-badge-text">
                    Verified Coach
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box> */}

					{/* No Badge Text */}
					<Typography className="no-badge-txt">
						{isView ? t('myprofile.user_has_no_badge') : t('myprofile.you_have_no_badge')}
					</Typography>
				</Box>

				{/* Connections | Followers | Following | Communities */}
				<CFFCBlock token={token} isView={isView} />

				{/* Fight Records */}
				<FightRecord isView={isView} token={token} />
			</Box>

			{/* Add / Edit Dialog */}
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'profile' ? (
						<EditProfile
							data={userData}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'social' ? (
						<EditSocialLink data={socialMeta} getMyProfile={getMyProfile} closeDialog={closeDialog} token={token} />
					) : model === 'bio' ? (
						<EditBio user={user} getMyProfile={getMyProfile} closeDialog={closeDialog} token={token} />
					) : (
						<EditProfile
							data={userData}
							user={user}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					)
				}
			/>
		</>
	);
};

// Edit PencilIcon Component
const SmallPencil = ({ color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11.238" height="11.236" viewBox="0 0 11.238 11.236">
			<g id="Group_490" data-name="Group 490" transform="translate(0.5 0.609)">
				<path
					id="Path_99"
					data-name="Path 99"
					d="M3.754,10.425,10.678,3.5a1.448,1.448,0,0,1,2.048,2.048L5.8,12.473a1.132,1.132,0,0,1-.578.309L3,13.227,3.445,11A1.132,1.132,0,0,1,3.754,10.425Z"
					transform="translate(-3 -3.1)"
					fill="none"
					stroke={color ? '#d9e8f3' : '#9d9d9d'}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1"
				/>
				<path
					id="Path_100"
					data-name="Path 100"
					d="M14.5,6.5l1.7,1.7"
					transform="translate(-7.994 -4.576)"
					fill="none"
					stroke={color ? '#d9e8f3' : '#9d9d9d'}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1"
				/>
			</g>
		</svg>
	);
};

export default ProfileRightBlock;
