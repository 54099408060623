import React from 'react'; // useContext
import { Box } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import Banner from './ReferralBanner';
import ReferralSection from './ReferralSection';
import './referrals.scss';

const ReferralView = () => {
	return (
		<Box>
			<Box className="referral-container">
				<Banner />
				<ReferralSection />
			</Box>
		</Box>
	);
};

export default ReferralView;
