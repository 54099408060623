import { Box } from '@material-ui/core';

const Logo = (props) => {
	return (
		<Box className="app-logo">
			<img alt="Logo" src="/static/logo.svg" {...props} style={{ marginTop: 3 }} />
		</Box>
	);
};

export default Logo;
