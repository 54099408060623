import { useContext } from 'react';
import { Box, Grid, Avatar, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EventDetail from './EventDetail';
import EventDetailsAry from './EventDetail/EventdetailAry.js';
import { ReactComponent as Endrosesucess } from 'src/assets/images/icons/endrose-sucess.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/report-icon.svg';
import './right.scss';

const RightSide = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Box className="fight-record-right-first-box">
				<Typography className="start-time-sec">{t('combat_fight_record.fight_start_time')}</Typography>
				<Typography className="event-text-sec">
					{t('combat_fight_record.main_event')}|155 {t('combat_fight_record.lightweight_champ')}|
					{t('combat_fight_record.lbs')}
				</Typography>
				<Grid container className="fight-sides-sec">
					<Grid xs={5} className="fight-left-side-sec">
						<Box className="left-text-side-sec">
							<Typography className="side-text">{t('combat_fight_record.Side_1')}</Typography>
							<Typography className="side-name">Jason Ponet</Typography>
						</Box>
						<Avatar className="avatar-sec"></Avatar>
					</Grid>
					<Grid xs={2} className="fight-center-side-sec">
						{' '}
						{t('combat_fight_record.v')}
					</Grid>
					<Grid xs={5} className="fight-right-side-sec">
						<Avatar className="avatar-sec"></Avatar>
						<Box className="right-text-side-sec">
							<Typography className="side-text">{t('combat_fight_record.Side_2')}</Typography>
							<Typography className="side-name">TOMMY hAYDEN</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box className="fight-record-right-second-box">
				{EventDetailsAry?.map((data, index) => {
					return <EventDetail side1={data.side1} title={data.title} side2={data.side2} />;
				})}
			</Box>
			<Box className="fight-record-right-third-box">
				<Typography className="check-icon">
					<Endrosesucess />
				</Typography>
				<Typography className="text-number-icon">32</Typography>
				<Typography className="text-Endorsements">{t('combat_fight_record.Endorsements')}</Typography>
				<Typography className="flag-icon">
					<ReportIcon />
				</Typography>
			</Box>
		</Box>
	);
};
export default RightSide;
