import { memo, useState, useEffect, useContext, useImperativeHandle } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'src/utils/common/isEqual';
import PropTypes from 'prop-types';
import {
	AppBar,
	Avatar,
	Badge,
	Box,
	Button,
	Hidden,
	IconButton,
	Menu,
	Link,
	// MenuItem,
	// ListItemText,
	Toolbar,
	makeStyles,
	Typography,
	withStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { fetchFromStorage, saveToStorage, clearStorage } from 'src/helpers/context';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as BAddIcon } from 'src/assets/images/icons/addBlueIcon.svg';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';
import { ReactComponent as NotificationIcon } from 'src/assets/images/icons/notification.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search.svg';
import { ReactComponent as MobileTopSlogan } from 'src/assets/images/slogan.svg';
// import { Config } from 'src/helpers/context/config';
// import { identifiers } from 'src/helpers/constants/identifier';
import { ReactComponent as MsgIcon } from 'src/assets/images/icons/com-msg.svg';
import Share from 'src/views/component/UI/Share';
import { ViewPortContext } from 'src/App';
import CombatDialog from 'src/views/component/UI/dialogBox';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import AddEditOrg from 'src/views/component/application/Organisation/AddEditOrg';
import items from './menu';
import { useTranslation } from 'react-i18next';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #2e353a',
		background: '#171717',
		marginTop: 15,
		borderRadius: '3px',
		boxShadow: '',
		color: '#fff',
		paddingLeft: 10,
		paddingRight: 28,
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
));

const useStyles = makeStyles((theme) => ({
	root: { backgroundColor: '#151515' },

	mobileToolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px',
	},
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '21px',
		fontFamily: `Staatliches`,
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent' },
		'&:last-child': {
			marginRight: 0,
		},
	},
	avatar: { width: 36, height: 36, cursor: 'pointer' },
	active: {
		color: '#438AC1',
		fontSize: '21px',
		fontFamily: `Staatliches`,
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:hover': { backgroundColor: 'transparent' },
		'&:last-child': {
			marginRight: 0,
		},
	},
}));

const TopBar = ({ auth, user_statics, verify_count, className, onMobileNavOpen, onRef, ...rest }) => {
	const { t } = useTranslation();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const user_id = auth ? auth?.user_id : fetchFromStorage('authData')?.user_id;
	const isAdmin = auth ? auth?.isAdmin : fetchFromStorage('authData')?.isAdmin;
	const [avt, setAvt] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [opens, setOpen] = useState(false);
	const [organisationData, setOrganisation] = useState([]);
	const [modal, setModal] = useState('');
	const [orgData, setOrgData] = useState([]);

	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	useImperativeHandle(onRef, () => ({
		setOpenDialog: () => {
			setOpen(true);
		},
	}));

	var USER_DATA = fetchFromStorage('authData') !== null && fetchFromStorage('authData')?.user_meta;

	let USER_ID = USER_DATA !== undefined ? USER_DATA?.data : user_id;
	let USERID = USER_ID !== '' ? USER_ID : fetchFromStorage('authData')?.user_id;

	//  TO OPEN USER ACTION DIALOG
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setAvatarChangeValue = (val) => {
		setAvt(val);
		var userData = fetchFromStorage('authData');
		let authData = { ...userData, profile_pic: val };

		saveToStorage('authData', authData);
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	useEffect(() => {
		EventEmitter.subscribe('user_image_change', setAvatarChangeValue);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (auth?.profile_pic !== '') {
			setAvt(auth?.profile_pic);
		}
		// eslint-disable-next-line
	}, [auth?.profile_pic]);

	const fetchMyOrganisation = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getMyOrganisations + `?page=${1}&limit=${2}`, headers);

			if (status === 200) {
				setOrganisation(data?.orgData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const handleNewOrg = () => {
		setOpen(true);
		setModal('newOrg');
	};

	const handleUpdate = (orgData) => {
		setOpen(true);
		setModal('updateOrg');
		// setTitle(t('organisation.edit_organisation'));
		setOrgData(orgData);
	};

	useEffect(() => {
		fetchMyOrganisation();
		setModal('');
	}, []);

	return (
		<AppBar
			className={
				isAdmin ? `${clsx(classes.root, className)} admin-app-bar` : `${clsx(classes.root, className)} app-bar`
			}
			elevation={0}
			{...rest}
		>
			{windowWidth > breakPoint ? (
				<>
					<Toolbar>
						<NavLink to="/news-feed" className="logo-anchor">
							<Logo className={classes.logo} />
							{/* <Hidden lgUp>
            {auth ? (
              <Typography className="cm-user-title show-md">
                CM USERS:&nbsp;<span>{user_statics?.verify_user_count}</span>
              </Typography>
            ) : verify_count !== undefined ? (
              verify_count !== null ? (
                <Typography className="cm-user-title show-md">
                  CM USERS:&nbsp;
                  <span>
                    {verify_count !== undefined
                      ? verify_count !== null
                        ? verify_count
                        : null
                      : null}
                  </span>
                </Typography>
              ) : null
            ) : null}
          </Hidden> */}
						</NavLink>

						{/* <Hidden lgDown> */}
						<Box className="app-bar-cm-user">
							{auth ? (
								<Typography className="cm-user-title">
									CM USERS:&nbsp;<span>{user_statics?.verify_user_count}</span>
								</Typography>
							) : verify_count !== undefined ? (
								verify_count !== null ? (
									<Typography className="cm-user-title">
										CM USERS:&nbsp;
										<span>{verify_count !== undefined ? (verify_count !== null ? verify_count : null) : null}</span>
									</Typography>
								) : null
							) : null}
						</Box>
						{/* </Hidden> */}

						<Box flexGrow={1} />
						{/* MENU BUTTONS */}
						{items.length > 0 &&
							items.map((menu, index) => {
								// let menuPath =
								//   menu.href === '/events/discover' ? '/events' : menu?.href;
								return (
									<>
										{menu.href === '/events/discover' ? (
											<Link
												href={menu.href}
												className={
													location.pathname.includes(menu.href)
														? `${classes.active} app-menu-btn`
														: `${classes.button} app-menu-btn`
												}
											>
												{menu.title}
											</Link>
										) : (
											<Button
												key={index}
												className={
													location.pathname.includes(menu.href)
														? `${classes.active} app-menu-btn`
														: `${classes.button} app-menu-btn`
												}
												component={NavLink}
												to={menu.href}
												disableElevation
												disableFocusRipple
												disableRipple
											>
												<span className={classes.title}>{menu.title}</span>
											</Button>
										)}
									</>
								);
							})}

						{/* ADMIN DASHBOARD */}
						{isAdmin && (
							<Button
								className={`${classes.button} app-menu-btn`}
								component={NavLink}
								to="/admin/dashboard"
								disableElevation
								disableFocusRipple
								disableRipple
							>
								<span className={classes.title}>Dashboard</span>
							</Button>
						)}

						{/* SEARCH BUTTON */}

						{/*HIDE-MVP: CM-78*/}
						{/*{auth ? (*/}
						{/*	<IconButton*/}
						{/*		className="app-bar-searchbtn"*/}
						{/*		onClick={() => {*/}
						{/*			navigate('/search');*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		<SearchIcon />*/}
						{/*	</IconButton>*/}
						{/*) : (*/}
						{/*	<Box width={'36px'} className="app-bar-searchbtn" />*/}
						{/*)}*/}

						{/* NOTIFICATION BUTTON */}
						{auth ? (
							<IconButton
								className="app-bar-notification-btn"
								onClick={() => {
									navigate('/notification');
								}}
							>
								<Badge badgeContent={user_statics?.unreadNotification} color="error">
									<NotificationIcon />
								</Badge>
							</IconButton>
						) : (
							<Box width={'31.27px'} />
						)}
						{/* MESSAGES */}
						{/* HIDE-MVP: CM-76 */}
						{/*{auth ? (*/}
						{/*	<IconButton*/}
						{/*		className="app-bar-notification-btn message-icon"*/}
						{/*		onClick={() => {*/}
						{/*			navigate('/messages');*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		{user_statics?.unreadMessages > 0 ? (*/}
						{/*			<Badge badgeContent={user_statics?.unreadMessages} color="error">*/}
						{/*				<MsgIcon className="msg-icon" />*/}
						{/*			</Badge>*/}
						{/*		) : (*/}
						{/*			<MsgIcon className="msg-icon" />*/}
						{/*		)}*/}
						{/*	</IconButton>*/}
						{/*) : (*/}
						{/*  <Box width={'36px'} />*/}
						{/*)}*/}

						{/* USER IMAGE */}
						{auth ? (
							<Avatar
								src={avt ? avt : UserPlaceholderIcon}
								className={classes.avatar}
								onClick={handleClick}
								// onClick={() => {
								//   // removeFromStorage(identifiers.visitorUsers);
								//   navigate(`/user/${USERID}`);
								// }}
							/>
						) : (
							<Box width={'36px'} />
						)}

						{/* USER ACTION MODAL */}
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							className="customized-menu-class"
						>
							{/* <MenuItem
              className={'my-option-menu'}
              onClick={() => {
                navigate(`/user/${USERID}`);
                handleClose();
              }}
            >
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              className={'my-option-menu'}
              onClick={() => {
                clearStorage();
                window.location = '/';
                handleClose();
              }}
            >
              <ListItemText primary="Log Out" />
            </MenuItem> */}
							<Box className="user-action-modalbody">
								{/* HIDE-MVP: CM-79 */}
								{/*<Box className="modal-h">*/}
								{/*	<Typography>Organisations</Typography>*/}
								{/*	<Button*/}
								{/*		disableElevation*/}
								{/*		disableRipple*/}
								{/*		disableTouchRipple*/}
								{/*		disableFocusRipple*/}
								{/*		className="seeall-btn"*/}
								{/*		onClick={() => {*/}
								{/*			navigate('/your-organisation');*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		See All*/}
								{/*	</Button>*/}
								{/*</Box>*/}
								{/* ORGANISATION LIST */}
								{/* HIDE-MVP: CM-79 */}
								{/*{organisationData.map((org, i) => {*/}
								{/*	return (*/}
								{/*		<Box key={i} className="org-item">*/}
								{/*			<Box className="org-data">*/}
								{/*				<Avatar className="org-img" src={org?.item_profile_id} />*/}
								{/*				<Typography*/}
								{/*					className="org-name"*/}
								{/*					onClick={() => {*/}
								{/*						navigate(`/organisation-details/${org?.id}`);*/}
								{/*					}}*/}
								{/*				>*/}
								{/*					{org?.name}*/}
								{/*				</Typography>*/}
								{/*			</Box>*/}

								{/*			<IconButton*/}
								{/*				onClick={() => {*/}
								{/*					handleUpdate(org);*/}
								{/*				}}*/}
								{/*			>*/}
								{/*				<EditIcon />*/}
								{/*			</IconButton>*/}
								{/*		</Box>*/}
								{/*	);*/}
								{/*})}*/}
								{/* HIDE-MVP: CM-79 */}
								{/*<Button*/}
								{/*	startIcon={<BAddIcon />}*/}
								{/*	variant="contained"*/}
								{/*	fullWidth*/}
								{/*	className="neworg-btn"*/}
								{/*	onClick={() => handleNewOrg()}*/}
								{/*>*/}
								{/*	New Organisation*/}
								{/*</Button>*/}
								<Box className="modal-h">
									<Link href={`/user/${USERID}`} className="seeall-btn wbtn">
										{t('nav_menu_options.my_profile')}
									</Link>
									{/* <Button
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  className="seeall-btn wbtn"
                  component={NavLink}
                  to={`/user/${USERID}`}
                  onClick={handleClose}
                >
                  My Profile
                </Button> */}
								</Box>
								{/* HIDE-MVP: CM-72 */}
								{/*<Box className="modal-h">*/}
								{/*	<Link href={`/claim-profile`} className="seeall-btn wbtn">*/}
								{/*		LINKED PROFILES*/}
								{/*	</Link>*/}
								{/*</Box>*/}
								<Box className="modal-h">
									<Link href={`/referral-code`} className="seeall-btn wbtn">
										{t('nav_menu_options.my_referrals')}
									</Link>
									{/* <Button
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  className="seeall-btn wbtn"
                  component={NavLink}
                  to={`/referral-code`}
                  onClick={handleClose}
                >
                  MY REFERRALS
                </Button> */}
								</Box>
								<Box className="modal-h">
									<Button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										className="seeall-btn wbtn"
										// component={NavLink}
										// to={`/user/${USERID}`}
										// onClick={handleClose}
										onClick={() => {
											setModal('');
											combatDialogHandler(t('community.share'));
										}}
									>
										{t('nav_menu_options.share_your_profile')}
									</Button>
								</Box>
								<Box className="modal-h">
									<Button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										className="seeall-btn wbtn"
										onClick={() => {
											navigate('/Account-settings');
										}}
									>
										{t('nav_menu_options.account_setting')}
									</Button>
								</Box>

								<Box className="modal-h">
									<Button
										disableElevation
										disableRipple
										disableTouchRipple
										disableFocusRipple
										className="seeall-btn wbtn"
										// component={NavLink}
										// to={`/user/${USERID}`}
										// onClick={handleClose}
										onClick={() => {
											clearStorage();
											window.location = '/';
										}}
									>
										{t('nav_menu_options.logout')}
									</Button>
								</Box>
							</Box>
						</StyledMenu>
						{/* </Hidden> */}
						{/* <Hidden lgUp>
          {auth ? (
            <IconButton
              // className="app-bar-searchbtn"
              onClick={() => {
                navigate('/search');
              }}
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <Box width={'36px'} className="app-bar-searchbtn" />
          )}
        </Hidden> */}
						{/* <Hidden lgUp >
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
						{!auth && (
							<Button
								className="auth-login-button"
								variant="contained"
								onClick={() => {
									navigate('/sign-in');
								}}
							>
								{t('login.signin')}
							</Button>
						)}
					</Toolbar>
				</>
			) : (
				<>
					{/* when the phone size is mobile size, the toolbar will change */}
					<Toolbar className={classes.mobileToolBar}>
						<IconButton color="inherit" className={classes.mobileMenuIcon} onClick={onMobileNavOpen}>
							<MenuIcon />
						</IconButton>
						{/*HIDE-MVP: CM-78*/}
						{/*add style to center navlink*/}
						<NavLink to="/news-feed" style={{ margin: '0 auto' }}>
							<MobileTopSlogan />
						</NavLink>
						{/*HIDE-MVP: CM-78*/}
						{/*<Box>*/}
						{/*	{auth ? (*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				navigate('/search');*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<SearchIcon />*/}
						{/*		</IconButton>*/}
						{/*	) : (*/}
						{/*		<Box width={'36px'} className="app-bar-searchbtn" />*/}
						{/*	)}*/}
						{/*</Box>*/}
					</Toolbar>
				</>
			)}
			{/* <Box className="site-banner">
        <Typography className='banner-title'> */}
			{/* {Config?.DomainName} */}
			{/* {t('banner_text')}
        </Typography>
      </Box> */}
			<CombatDialog
				className={modal === 'newOrg' || modal === 'updateOrg' ? 'mw-740' : 'share-dailog-box'}
				open={opens}
				handleClose={closeDialog}
				title={
					modal === 'newOrg'
						? t('organisation.new_organisation')
						: modal === 'updateOrg'
						? t('organisation.edit_organisation')
						: ''
				}
				content={
					modal === 'newOrg' ? (
						<AddEditOrg
							fetchMyOrganisation={fetchMyOrganisation}
							// currentPage={currentPage}
							// limit={list}
							modal={modal}
							orgData={organisationData}
							handleClose={closeDialog}
						/>
					) : modal === 'updateOrg' ? (
						<AddEditOrg
							fetchMyOrganisation={fetchMyOrganisation}
							// currentPage={currentPage}
							// limit={list}
							modal={modal}
							orgData={orgData}
							handleClose={closeDialog}
							// searchText={searchText}
						/>
					) : (
						<Share
							fromWhere="user/"
							urlId={fetchFromStorage('authData')?.user_id}
							landType="user"
							handleClose={closeDialog}
						/>
					)
				}
			/>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default memo(TopBar, isEqual);
