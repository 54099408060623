import { Avatar, Box, Typography, Button } from '@material-ui/core';
import CommunityBadges from 'src/views/component/application/Community/CommunityView/RightSide/Badges';
import AdBlock from 'src/views/component/application/Community/CommunityView/LeftSide/Discussions/AD';
import '../UserNewsFeed.scss';
import { useTranslation } from 'react-i18next';

const PostDetailsRight = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="post-details-main">
				<Box className="post-banner">
					<Avatar src="/static/com-event-1.png" />
				</Box>
				<Box className="post-image">
					<Avatar src="/static/card-1.png" />
					<Typography className="post-name">muay thai official</Typography>
				</Box>
				<Box className="p-16">
					<Box className="post-content">
						<Typography className="post-description">
							Sometimes referred to as "Thai boxing", is a martial art and combat sport that uses stand-up striking
							along with various clinching techniques. This discipline is known as the "art of eight limbs" as it is
							characterised by the combined use of fists, elbows, knees and shins. Muay Thai became widespread
							internationally in the late-20th to 21st century, when Westernised practitioners from Thailand began
							competing in kickboxing and mixed-rules matches as well as matches under Muay Thai rules around the world.
						</Typography>
					</Box>
					<Box className="post-other-data">
						<Typography className="post-description">
							<Typography variant="span">CREATED:</Typography>
							<Typography variant="span" className="color-blue">
								[MMM DD YYYYY], [00:00]
							</Typography>
						</Typography>
						<Typography className="post-description">
							<Typography variant="span">MEMBERS:</Typography>
							<Typography variant="span" className="color-blue">
								14,542
							</Typography>
						</Typography>
					</Box>
					<Box className="post-other-data">
						<Button variant="contained" fullWidth className="leave-btn">
							{t('button_text.leave')}
						</Button>
					</Box>
				</Box>
			</Box>
			<CommunityBadges />
			<AdBlock />
		</>
	);
};

export default PostDetailsRight;
