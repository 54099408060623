import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { fetchFromStorage } from 'src/helpers/context';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';
import 'src/layouts/layout.scss';
import Footer from '../MainLayout/Footer';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%',
	},
	contentContainer: {
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
	},
	content: {
		flex: '1 1 auto',
		height: '100%',
		overflow: 'auto',
	},
}));

const DashboardLayout = ({ home }) => {
	const classes = useStyles();
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const verify_count = useSelector((state) => state?.Combat?.user_profile_count);

	return (
		<div className={classes.root}>
			<ToastContainer />
			<TopBar home={home} onMobileNavOpen={() => setMobileNavOpen(true)} />
			<NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
			<div className="layout-wrapper">
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<Outlet />
					</div>
				</div>
			</div>
			<Footer auth={auth} user_statics={user_statics} verify_count={verify_count} />
		</div>
	);
};

export default DashboardLayout;
