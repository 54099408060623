import { memo } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails, Box, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import '../skills.scss';
// import { useParams } from 'react-router-dom';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';

function EditEndorsement({ endorsementData, deleteEndorsement }) {
	const { t } = useTranslation();

	// const { id } = useParams();
	let visitorId =
		fetchFromStorage(identifiers.visitorUserId) !== null
			? fetchFromStorage(identifiers.visitorUserId)
			: fetchFromStorage(identifiers.authData)?.user_id;

	return (
		<Box className="mui-accordion">
			<Box>
				{endorsementData?.disciplineData.length === 0 &&
					endorsementData?.methodData.length === 0 &&
					endorsementData?.skillData.length === 0 && (
						<Typography className="skills-heading">
							{Number(visitorId) === Number(fetchFromStorage(identifiers.authData)?.user_id)
								? t('myprofile.noendorsementskill')
								: t('myprofile.no_endorsementskill')}
						</Typography>
					)}

				{endorsementData?.disciplineData.length > 0 && (
					<Accordion defaultExpanded={true}>
						<AccordionSummary expandIcon={<ExpandMore />}>{t('myprofile.discipline')}</AccordionSummary>
						{endorsementData &&
							endorsementData?.disciplineData.map((item, index) => {
								var requestData = {
									user_id: Number(visitorId),
									discipline_id: item?.discipline?.id,
								};

								return (
									<AccordionDetails key={index}>
										<Typography className="profile-type-about-txt">{item?.discipline?.name}</Typography>
										<IconButton
											onClick={() => {
												deleteEndorsement('discipline', requestData);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</AccordionDetails>
								);
							})}
					</Accordion>
				)}
				{endorsementData?.methodData.length > 0 && (
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMore />}>{t('myprofile.fightmethod')}</AccordionSummary>
						{endorsementData &&
							endorsementData?.methodData.map((item, index) => {
								var requestData = {
									user_id: Number(visitorId),
									meta_fight_method_id: item?.meta_fight_method?.id,
								};

								return (
									<AccordionDetails key={index}>
										<Typography className="profile-type-about-txt">{item?.meta_fight_method?.name}</Typography>
										<IconButton
											onClick={() => {
												deleteEndorsement('method', requestData);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</AccordionDetails>
								);
							})}
					</Accordion>
				)}
				{endorsementData?.skillData.length > 0 && (
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMore />}>{t('myprofile.skills')}</AccordionSummary>
						{endorsementData &&
							endorsementData?.skillData.map((item, index) => {
								var requestData = {
									user_id: Number(visitorId),
									meta_skills_id: item?.meta_skills?.id,
								};

								return (
									<AccordionDetails key={index}>
										<Typography className="profile-type-about-txt">{item?.meta_skills?.name}</Typography>
										<IconButton
											onClick={() => {
												deleteEndorsement('skill', requestData);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</AccordionDetails>
								);
							})}
					</Accordion>
				)}
			</Box>
		</Box>
	);
}

export default memo(EditEndorsement);
