import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Content from 'src/views/component/application/Search';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const SearchPage = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Search | Combat Matrix">
			<Content />
		</Page>
	);
};

export default SearchPage;
