import {
	NavLink,
	// , useLocation
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
// import items from './menu';

const useStyles = makeStyles((theme) => ({
	root: { background: 'transparent' },
	title: {
		marginRight: 'auto',
	},
	button: {
		color: '#fff',
		fontSize: '21px',
		fontFamily: 'Staatliches',
		fontWeight: '400',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '0px 8px',
		textTransform: 'none',
		marginRight: '25px',
		'&:last-child': {
			marginRight: 0,
		},
	},
	active: {
		color: 'red',
	},
}));

const TopBar = ({ className, home, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	// const location = useLocation();

	return (
		<AppBar className={`${clsx(classes.root, className)} app-bar`} elevation={0} {...rest}>
			<Toolbar>
				<NavLink to={home ? home : '/'} className="logo-anchor">
					<Logo />
				</NavLink>
				<Box flexGrow={1} />
				{/* <Hidden mdDown> */}
				{/* {items.length > 0 &&
            items.map((menu, index) => {
              return (
                <Button
                  key={index}
                  className={classes.button}
                  component={NavLink}
                  to={menu.href}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  activeClassName={
                    location.pathname.includes(menu.href) ? classes.active : ''
                  }
                >
                  <span className={classes.title}>{menu.title}</span>
                </Button>
              );
            })} */}

				{/* <Button
            className="auth-login-button"
            variant="contained"
            component={NavLink}
            to="/"
          >
            Sign in
          </Button> */}
				{/* </Hidden> */}
				{/* <Hidden lgUp> */}
				<Hidden xsUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
