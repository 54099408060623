import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, NavLink, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, Box, Grid, IconButton, Typography, Checkbox, Link, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { Config } from 'src/helpers/context/config';
import { OldSocialLogin as SocialLogin } from 'react-social-login';
// import AppleLogin from 'react-apple-login';
import { saveToStorage, fetchFromStorage, removeFromStorage } from 'src/helpers/context';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as FacebookLogo } from 'src/assets/images/social/facebook.svg';
// import { ReactComponent as AppleLogo } from 'src/assets/images/social/apple.svg';
import { ReactComponent as GoogleLogo } from 'src/assets/images/social/google.svg';
import { connect } from 'react-redux';
import { authUser } from 'src/redux-state/actions/actions';
import '../auth.scss';
import { ReactComponent as EyeSlash } from 'src/assets/images/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'src/assets/images/icons/eye.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import { parse } from 'query-string';

const RegisterPage = ({ authUser }) => {
	const location = useLocation();
	const query = parse(location.search);

	const [view, setView] = useState(false);
	const [flag, setFlag] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();
	var previousPath = fetchFromStorage(identifiers?.previousPath);
	// const shareLink = async (item) => {
	//   var requestParams = {
	//     referral_code: query?.referralcode,
	//     land_type: identifiers?.referralType?.user_signup,
	//     land_id: item?.user_id,
	//     user_id: item?.user_id,
	//   }
	//   const authHeader = {
	//     headers: {
	//       Authorization: `Bearer ${item?.token}`,
	//       'Content-Type': 'application/json'
	//     }
	//   };
	//   console.log('authHeader', authHeader)
	//   try {
	//     const { status, data } = await axiosInstance.post(
	//       URLS.shareLink,
	//       requestParams,
	//       authHeader
	//     );
	//     if (status === 200) {
	//       // setApiMessage('success', data?.message);
	//     }
	//   } catch (error) {
	//     // setApiMessage('error', error?.response?.data?.message);
	//   }
	// }

	const linkReferralUser = async (userData) => {
		const referralCode = fetchFromStorage(identifiers?.referralcode);

		const requestParameter = {
			referral_code: referralCode,
			land_type: 'user_signup',
			land_id: null,
			user_id: userData?.user_id,
		};

		if (referralCode !== undefined && referralCode !== null) {
			try {
				const { status } = await axiosInstance.post(URLS.referralUse, requestParameter);

				if (status === 200) {
					removeFromStorage(identifiers?.referralcode);
				}
			} catch (error) {
				// TODO: Do we need to show error toast here?
				// setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	const SocialLoginHandler = async (token, type) => {
		const requestData = { access_token: token, loginType: 'register' };

		try {
			const { status, data } = await axiosInstance.post(URLS.socialLogin + `?type=${type}`, requestData);

			if (status === 200) {
				if (data?.data?.token) {
					setApiMessage('success', data?.message);
					await linkReferralUser(data?.data);
					// shareLink(data?.data);
					await tokenSaveHandler(data?.data);
					await removeFromStorage(identifiers?.referralcode);
				}
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const tokenSaveHandler = (data) => {
		let token = data?.token;
		let user = data?.user_id;
		let status = data?.profile_status;

		if (token) {
			authUser({ ...data }); // isAdmin: true
			saveToStorage('authData', { ...data }); // isAdmin: true

			if (status) {
				navigate(`/user/${user}`);
				previousPath && navigate(previousPath);
			} else {
				navigate('/profile/details');
			}
		}
	};

	useEffect(() => {
		// location && location?.pathname === '/sign-up' && referralUse();
		// query?.referralcode !== undefined && saveToStorage(identifiers?.referralcode, query?.referralcode);
	}, [window.location.pathname]);

	return (
		<Box className="auth-section-box">
			<Grid container className="auth-grid-container">
				<Grid item xs={12} sm={12} md={12} lg={5} className="auth-left-grid">
					<Box className="content sign-container">
						<Box className="mobile-auth-logo">
							<img src="/static/logo.svg" alt="auth-logo" />
						</Box>
						<Typography className="title">{t('register.signup')}</Typography>
						<Typography className="desription">{t('register.description')}</Typography>
						<Box className="form-content">
							<Formik
								initialValues={{
									email: '',
									password: '',
									confirmPassword: '',
									policy: false,
								}}
								validationSchema={Yup.object().shape({
									email: Yup.string()
										.email(t('validation.valid_email'))
										.max(255)
										.required(t('validation.required_message')),
									password: Yup.string()
										.required(t('validation.required_message'))
										.min(6, t('validation.passowrd_length')),
									confirmPassword: Yup.string()
										.required(t('validation.required_message'))
										.min(6, t('validation.passowrd_length'))
										.when('password', {
											is: (val) => (val && val.length > 0 ? true : false),
											then: Yup.string().oneOf([Yup.ref('password')], t('validation.password_confpass_not_match')),
										}),
									policy: Yup.boolean().oneOf([true], t('validation.accept_tc')),
								})}
								onSubmit={async (requestData) => {
									let sendData = {
										email: requestData.email,
										password: requestData.password,
									};

									try {
										const { status, data } = await axiosInstance.post(URLS.signUp, sendData);

										if (status === 200) {
											await linkReferralUser(data?.data);
											// shareLink(data?.data);
											await setApiMessage('success', data?.message);
											await navigate(`/verify-otp`, {
												replace: true,
												state: `${requestData.email}`,
											});
											// await removeFromStorage(identifiers?.referralcode);
										}
									} catch (error) {
										setApiMessage('error', error?.response?.data?.message);
									}
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} className="text-field-grid">
												<MatrixTextField
													error={Boolean(touched.email && errors.email)}
													fullWidth
													helperText={touched.email && errors.email}
													variant="filled"
													InputProps={{
														endAdornment: touched.email && errors.email ? <ErrorIcon /> : null,
													}}
													label={`${t('email')}*`}
													placeholder={`${t('email')}*`}
													className="matrix-textfield"
													size="small"
													name="email"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.email}
												/>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid position-relative">
												<MatrixTextField
													error={Boolean(touched.password && errors.password)}
													fullWidth
													helperText={touched.password && errors.password}
													variant="filled"
													InputProps={{
														endAdornment: touched.password && errors.password ? <ErrorIcon /> : null,
													}}
													label={`${t('password')}*`}
													placeholder={`${t('password')}*`}
													className="matrix-textfield"
													size="small"
													name="password"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.password}
													type={!flag ? 'password' : 'text'}
												/>
												<IconButton className="eye-icons" onClick={() => setFlag(!flag)}>
													{!flag ? <EyeSlash /> : <EyeIcon />}
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid position-relative">
												<MatrixTextField
													error={Boolean(touched.confirmPassword && errors.confirmPassword)}
													fullWidth
													helperText={touched.confirmPassword && errors.confirmPassword}
													variant="filled"
													InputProps={{
														endAdornment: touched.confirmPassword && errors.confirmPassword ? <ErrorIcon /> : null,
													}}
													label={`${t('register.repeat_password')}*`}
													placeholder={`${t('register.repeat_password')}*`}
													className="matrix-textfield"
													size="small"
													name="confirmPassword"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.confirmPassword}
													type={!view ? 'password' : 'text'}
												/>
												<IconButton className="eye-icons" onClick={() => setView(!view)}>
													{!view ? <EyeSlash /> : <EyeIcon />}
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid">
												<Box className="terms-cond-text">
													<Checkbox checked={values.policy} name="policy" onChange={handleChange} />
													<Typography color="textSecondary" variant="body1">
														{t('register.accept_tac')}{' '}
														<Link
															href="https://info.combatmatrix.com/terms-and-conditions"
															target="_blank"
															underline="always"
															variant="h6"
														>
															{/* <Link
                                color="primary"
                                component={RouterLink}
                                to='https://info.combatmatrix.com/terms-and-conditions'
                                underline="always"
                                variant="h6"
                              > */}
															{t('register.tac')}
														</Link>
													</Typography>
												</Box>
												{Boolean(touched.policy && errors.policy) && (
													<FormHelperText style={{ marginLeft: '13px', color: '#C01515' }} error>
														{t('validation.accept_tc')}
													</FormHelperText>
												)}
											</Grid>
											<Grid item xs={12} md={12}>
												<Button variant="contained" fullWidth type="submit" className="signup-button">
													{t('register.agreesignup')}
												</Button>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>
						</Box>

						<p className="quote">
							<span>{t('or')}</span>
						</p>

						{/* {/ Signup Using Social Media /} */}
						<Box className="social-login-block">
							<SocialLogin
								provider="facebook"
								appId={Config.FacebookAppID}
								callback={(user, error) => {
									if (user) {
										SocialLoginHandler(user['_token'].accessToken, 'facebook');
									}
								}}
							>
								<IconButton>
									<FacebookLogo />
								</IconButton>
							</SocialLogin>

							{/* <IconButton>
                <AppleLogo />
              </IconButton> */}

							{/* <AppleLogin
                clientId="au.com.wearunitedsid"
                redirectURI="https://wearunited.com.au/"
              /> */}

							<SocialLogin
								provider="google"
								appId={Config.GoogleAppID}
								callback={(user, error) => {
									if (user) {
										SocialLoginHandler(user['_token'].accessToken, 'google');
									}
								}}
							>
								<IconButton>
									<GoogleLogo />
								</IconButton>
							</SocialLogin>
						</Box>

						<Box className="fancy-link">
							<NavLink className="switch_to_signin" to="/sign-in">
								{t('register.already_member')}
							</NavLink>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (data) => dispatch(authUser(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
