import { Avatar, Box } from '@material-ui/core';
import './picture.scss';

const ProfilePictureView = ({ src }) => {
	return (
		<Box className="profile-view-box">
			<Avatar src={src} className="user-picture" />
		</Box>
	);
};

export default ProfilePictureView;
