import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Box, Typography } from '@material-ui/core';
import './card.scss';

const UserConnectionCard = ({ user }) => {
	const [buttonText, setButtonText] = useState('view Profile');
	const navigate = useNavigate();

	return (
		<Box className="followers-list">
			<Box className="user-image">
				<Avatar src={user?.item_profile_id} />
			</Box>

			<Box className="user-name">
				<Typography className="name">{`${user?.first_name ? user?.first_name : ''} ${
					user?.last_name ? user?.last_name : ''
				}${!user?.first_name && !user?.last_name ? 'Combat Matrix User' : ''}`}</Typography>
			</Box>

			<Button
				variant="contained"
				className="visit-button"
				onMouseOver={() => setButtonText('Add Connection')}
				onMouseLeave={() => setButtonText('view Profile')}
				onClick={() => {
					user?.user_id && navigate(`/user/${user?.user_id}`);
				}}
			>
				{buttonText}
			</Button>
		</Box>
	);
};

export default UserConnectionCard;
