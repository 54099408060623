import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Content from 'src/views/component/application/AllBadges';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const AllBadgesPage = () => {
	const classes = useStyles();

	return (
		<>
			<Page className={classes.root} title="All Badges | Combat Matrix">
				<Content />
			</Page>
		</>
	);
};
export default AllBadgesPage;
