import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Container, IconButton, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import Page from 'src/components/Page';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
import LeftBlock from './LeftBlock';
import RightBlock from './RightBlock';
import './shadowP.scss';
import { useTranslation } from 'react-i18next';
// import { setFollowersData } from 'src/helpers/store/Actions/userProfile';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

// GET USER DETAILS
const ShadowProfile = () => {
	const { id } = useParams();
	const classes = useStyles();
	const auth = useSelector((state) => state?.Combat?.authData);
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;

	const [loader, setLoader] = useState(false);
	// const [isView, setIsView] = useState(false);
	const [user, setUser] = useState({});
	const [followersData, setFollowersData] = useState([]);
	const [followersTotalCount, setFollowersTotalCount] = useState('');

	const fetchShadowUserData = async () => {
		setLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.otherUserDetails + `/${id}`);

			if (status === 200) {
				setUser(data);
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	// GET FOLLOWERS LIST
	const fetchShadowUserFollowList = async () => {
		setLoader(true);

		try {
			let { status, data } = await axiosInstance.get(URLS.getFollowUserList + `?userId=${id}`);

			if (status === 200) {
				setFollowersData(data?.data);
				setFollowersTotalCount(data?.totalCount);
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	// CLAIM UNCLAIM USER
	const claimUserProfile = async (value) => {
		var requestParams = {
			user_other_id: value?.OtherUserDetails?.id,
			user_actual_id: fetchFromStorage('authData')?.user_id,
		};
		var claimStatus = value?.OtherUserDetails?.isClaim ? 'unclaim' : 'claim';

		try {
			const { status, data } = await axiosInstance.post(URLS.claimUserProfile + `?type=${claimStatus}`, requestParams);

			if (status === 200) {
				fetchShadowUserData();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// FOLLOW UNFOLLOW USER
	const followOtherUser = async (value) => {
		var requestParams = {
			user_other_id: value?.OtherUserDetails?.id,
			// user_following_id: fetchFromStorage('authData')?.user_id
		};
		var followStatus = value?.OtherUserDetails?.isFollow ? 'unfollow' : 'follow';

		try {
			const { status, data } = await axiosInstance.post(URLS.followOtherUser + `?type=${followStatus}`, requestParams);

			if (status === 200) {
				fetchShadowUserData();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchShadowUserData();
		fetchShadowUserFollowList();
		// eslint-disable-next-line
	}, []);

	const userNotFound = false;

	return (
		<Page className={classes.root} title={`shadow profile`}>
			<Container maxWidth="lg">
				<Box className="my-profile-page combat-container">
					{!userNotFound ? (
						<Box className="myprofile-grid-container">
							{/* SHOW BEFORE 1279PX */}
							<Box className="myprofile-right show-1279">
								<RightBlock
									loader={loader}
									// isView={isView}
									isView={true}
									claimUserProfile={claimUserProfile}
									followOtherUser={followOtherUser}
									user={user}
									token={token}
									followersData={followersData}
									ProfileAvatar={<ProfileAvatar />}
									followersTotalCount={followersTotalCount}
								/>
							</Box>

							<Box className="myprofile-left">
								<LeftBlock
									loader={loader}
									// isView={isView}
									isView={true}
									user={user}
									token={token}
									ProfileAvatar={<ProfileAvatar />}
								/>
							</Box>

							{/* SHOW AFTER 1279PX */}
							<Box className="myprofile-right hide-1279">
								<RightBlock
									loader={loader}
									// isView={isView}
									isView={true}
									claimUserProfile={claimUserProfile}
									followOtherUser={followOtherUser}
									user={user}
									token={token}
									followersData={followersData}
									ProfileAvatar={<ProfileAvatar />}
									followersTotalCount={followersTotalCount}
								/>
							</Box>
						</Box>
					) : (
						<Typography className="user-not-found-text">USER NOT FOUND</Typography>
					)}
				</Box>
			</Container>
		</Page>
	);
};

// PROFILE PICTURE COMPONENT
const ProfileAvatar = ({ src, getMyProfile, token }) => {
	const { t } = useTranslation();

	const [user, setUser] = useState('');
	const imgHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			//  CHECK MEDIA TYPE
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					let id = data?.data?.itemId || data?.data?.id;
					let requestData = { item_profile_id: id };

					try {
						let { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							setApiMessage('success', t('myprofile.profilepic_updated_success'));
							getMyProfile();
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		if (src !== '') {
			setUser(src);
			EventEmitter.dispatch('user_image_change', src);
		}
		// eslint-disable-next-line
	}, [src]);

	return (
		<>
			<input
				accept="image/*"
				id="contained-button-file"
				type="file"
				multiple
				onChange={imgHandler}
				style={{ display: 'none' }}
			/>
			<label htmlFor="contained-button-file">
				<IconButton component="span">
					<Avatar src={user ? user : UserPlaceholderIcon} />
					<CameraIcon className="camera-icon" />
				</IconButton>
			</label>
		</>
	);
};

export default ShadowProfile;
