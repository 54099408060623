import { Navigate } from 'react-router-dom';

// Layouts
import AuthLayout from 'src/layouts/AuthLayout';
import AdminDashboardLayout from 'src/layouts/AdminDashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import ProfileDetails from 'src/layouts/SignupProfileDetails';
import NotificationView from 'src/views/pages/application/Notification';
import SearchView from 'src/views/pages/application/Search';

// Application
import NewsFeed from 'src/views/pages/application/NewsFeed';
import UserNewsFeed from 'src/views/component/application/NewsFeed/UserNewsFeed';
import Communities from 'src/views/pages/application/Community';
import CommunityView from 'src/views/component/application/Community/CommunityView';
import AllCommunityDirectory from 'src/views/component/application/Community/AllCommunityDirectory';
import CommunityPostView from 'src/views/component/application/Community/CommunityPostView';
// HIDE-MVP: CM-74
// import NewEvents from 'src/views/component/application/Events/NewEvent';
// import Events from 'src/views/pages/application/Events';
// import EventDetailsPage from 'src/views/component/application/Events/EventDetails';
import SeeFightRecord from 'src/views/component/application/SeeFightRecord';
import Messages from 'src/views/pages/application/Messages';
import UserMessages from 'src/views/pages/application/Messages';
import UsersPage from 'src/views/pages/application/Users';
import UserConnection from 'src/views/pages/application/Connections';
import ClaimProfile from 'src/views/pages/application/ClaimProfile';
import FightRecordPage from 'src/views/pages/application/FightRecord';
import Followers from 'src/views/pages/application/Followers';
import Following from 'src/views/pages/application/Following';
import UserProfileView from 'src/views/pages/application/Profile';
import ShadowProfileView from 'src/views/pages/application/ShadowProfile';
import SignUpProfileView from 'src/views/pages/auth/profile';
import ReferralView from 'src/views/pages/application/Referrals';
// HIDE-MVP: CM-79
// import OrganisationPage from 'src/views/pages/application/Organisation';
// import YourOrganisation from 'src/views/component/application/Organisation/YourOrg';
// import OrgSocialActivity from 'src/views/component/application/Organisation/OrganisactionActivity';
// import AddEditOrg from 'src/views/component/application/Organisation/AddEditOrg';
import AllBadgesPage from 'src/views/pages/application/AllBadges';
import ViewAllBadgesPage from 'src/views/pages/application/ViewAllBadge';
import FaqPage from 'src/views/pages/application/Faqpage';
import AccountSettings from 'src/views/component/application/AccountSettings';
import SeeAllActivity from 'src/views/component/application/seeAllActivity';
import MobileViewFilter from 'src/views/component/application/Community/CommunityList/MobileViewFilter';
// Authentication
import LoginView from 'src/views/pages/auth/login';
import RegisterView from 'src/views/pages/auth/register';
import ForgotPasswordView from 'src/views/pages/auth/forgotPassword';
import ResetPassword from 'src/views/pages/auth/resetPassword';

// Admin Dashboard
import AdminDashboardView from 'src/views/pages/admin/dashboard';
import AdminUsersView from 'src/views/pages/admin/users';
import AdminManageUserView from 'src/views/component/admin/users/ManageUser';
import AdminBadgeView from 'src/views/pages/admin/badge';
import AdminFightView from 'src/views/pages/admin/fight';
import VerifyOTPView from './views/pages/auth/verifyOTP';

// Common Public Routes for user profile etc...
const publicData = {
	path: '/',
	element: <MainLayout />,
	children: [
		{ path: '/user/:id', element: <UserProfileView /> },
		{ path: '/user/shadow/:id', element: <ShadowProfileView /> },
		{ path: '/connections/:type', element: <UserConnection /> },
		// { path: '/users', element: <UsersPage /> },
		{ path: '/post/:id', element: <CommunityPostView /> },
		{ path: '/communities/:id', element: <CommunityView /> },
		{ path: '/communities/communities/:id', element: <CommunityView /> },
		{ path: '*', element: <Navigate to="/" /> },
	],
};

// authenticatedRoute
const authenticateData = {
	path: '/',
	element: <MainLayout />,
	children: [
		{ path: '/', element: <Navigate to="/news-feed" /> },
		{ path: '/news-feed', element: <NewsFeed /> },
		{ path: '/news-feed/:id', element: <UserNewsFeed /> },
		{ path: '/communities', element: <Communities /> },
		{ path: '/community-directory', element: <AllCommunityDirectory /> },
		{ path: '/communities/mobile-view', element: <MobileViewFilter /> },
		// { path: '/new-event', element: <NewEvents /> },
		// HIDE-MVP: CM-74
		// { path: '/events/:type', element: <Events /> },
		// { path: '/events/details/:id', element: <EventDetailsPage /> },
		// HIDE-MVP: CM-76
		// { path: '/messages/:id', element: <Messages /> },
		// { path: '/messages', element: <UserMessages /> },
		// { path: '/connections/:type', element: <UserConnection /> },
		{ path: '/users', element: <UsersPage /> },
		//  HIDE-MVP: CM-72
		// { path: '/claim-profile', element: <ClaimProfile /> },
		// { path: '/fight-record', element: <FightRecordPage /> },
		{ path: '/see-fight-record', element: <SeeFightRecord /> },
		{ path: '/followers', element: <Followers /> },
		{ path: '/following', element: <Following /> },
		{ path: '/notification', element: <NotificationView /> },
		// HIDE-MVP: CM-78
		// { path: '/search', element: <SearchView /> },
		// HIDE-MVP: CM-79
		// { path: '/organisation-details/:id', element: <OrganisationPage /> },
		// { path: '/organisation-user/:type', element: <OrgSocialActivity /> },
		// { path: '/your-organisation', element: <YourOrganisation /> },
		// { path: '/create-organisation', element: <AddEditOrg /> },
		{ path: '/allbadge', element: <AllBadgesPage /> },
		{ path: '/viewallbadges', element: <ViewAllBadgesPage /> },
		{ path: '/faq', element: <FaqPage /> },
		{ path: '/referral-code', element: <ReferralView /> },
		{ path: '/Account-settings', element: <AccountSettings /> },
		{ path: '/all-activity', element: <SeeAllActivity /> },
		{ path: '*', element: <Navigate to="/news-feed" /> },
	],
};

// Authenticated App Routes
export const authRoute = [authenticateData, publicData];

// Signup Profile Details Route
export const profileRoute = [
	{
		path: '/',
		element: <ProfileDetails home="/profile/details" />,
		children: [
			{ path: '/', element: <Navigate to="/profile/details" /> },
			{ path: '/profile/:type', element: <SignUpProfileView /> },
			{ path: '*', element: <Navigate to="/profile/details" /> },
		],
	},
];

// Public Routes
export const publicRoute = [
	{
		path: '/',
		element: <AuthLayout />,
		children: [
			{ path: '/', element: <Navigate to="/sign-up" /> },
			{ path: '/sign-in', element: <LoginView /> },
			{ path: '/sign-up', element: <RegisterView /> },
			{ path: '/verify-otp', element: <VerifyOTPView /> },
			{ path: '/forgot-password', element: <ForgotPasswordView /> },
			{ path: '/reset-password', element: <ResetPassword /> },
			{ path: '*', element: <Navigate to="/" /> },
		],
	},
	publicData,
];

// Admin Dashboard's Routes
export const adminRoutes = [
	authenticateData,
	{
		path: '/admin',
		element: <AdminDashboardLayout />,
		children: [
			{ path: '', element: <Navigate to="dashboard" /> },
			{ path: 'dashboard', element: <AdminDashboardView /> },
			{ path: 'users', element: <AdminUsersView /> },
			{ path: 'users/:id', element: <AdminManageUserView /> },
			{ path: 'badge', element: <AdminBadgeView /> },
			{ path: 'fight', element: <AdminFightView /> },
			{ path: '*', element: <Navigate to="dashboard" /> },
		],
	},
	publicData,
];
