import { Slide } from 'react-slideshow-image';
import { Box, Link, Avatar, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const MultiMediaLightBox = ({ mediArray, defaultIndex }) => {
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const renderMedia = (item) => {
		switch (item?.type) {
			case 'doc':
				return (
					<Box className="message-doc-view">
						<Link href={item?.data} target="_blank" className="doc-link">
							{renderSwitchForDocumentIcon(item?.data)}
						</Link>
					</Box>
				);
			case 'image':
				return <Avatar src={item?.data} />;
			case 'video':
				return (
					<Box width="600px">
						<ReactPlayer
							className="react-player video-player"
							url={item?.data}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<ReactPlayer
						className="react-player audio-player"
						url={item?.data}
						playing={false}
						width="270px"
						height="30px"
						controls={true}
					/>
				);
			default:
				return (
					<Box className="message-doc-view">
						<Link href={item} target="_blank" className="doc-link">
							{renderSwitchForDocumentIcon(item?.data)}
						</Link>
					</Box>
				);
		}
	};

	return (
		<Slide autoplay={true} duration={30000} className="multislider-main" defaultIndex={defaultIndex}>
			{mediArray &&
				mediArray?.map((item) => {
					return <Box className="multimedia-container">{renderMedia(item)}</Box>;
				})}
		</Slide>
	);
};

export default MultiMediaLightBox;
