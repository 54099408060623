import CircularProgress from '@material-ui/core/CircularProgress';
import 'src/assets/scss/loader.scss';

export default function PreLoader() {
	return (
		<div className="combat-loader">
			<CircularProgress />
		</div>
	);
}
