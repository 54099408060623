import { useContext, useState, useEffect } from 'react';
import { Box, Container, Typography, Tab, Button, Grid } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import './allCommunityDir.scss';
import { useSelector, connect } from 'react-redux';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { getCommunitiesDirectoryList } from 'src/redux-state/actions/actions';

const AllComunityDirectory = ({ getCommunitiesDirectoryList }) => {
	const [limit] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [value, setValue] = useState('1');
	const [totalRecord, setTotalRecords] = useState('');
	const { t } = useTranslation();
	const alphabeticTab = [
		// { id: '1', name: t('alphabets.a') },
		// { id: '2', name: t('alphabets.b') },
		// { id: '3', name: t('alphabets.c') },
		// { id: '4', name: t('alphabets.d') },
		// { id: '5', name: t('alphabets.e') },
		// { id: '6', name: t('alphabets.f') },
		// { id: '7', name: t('alphabets.g') },
		// { id: '8', name: t('alphabets.h') },
		// { id: '9', name: t('alphabets.i') },
		// { id: '10', name: t('alphabets.j') },
		// { id: '11', name: t('alphabets.k') },
		// { id: '12', name: t('alphabets.l') },
		// { id: '13', name: t('alphabets.m') },
		// { id: '14', name: t('alphabets.n') },
		// { id: '15', name: t('alphabets.o') },
		// { id: '16', name: t('alphabets.p') },
		// { id: '17', name: t('alphabets.q') },
		// { id: '18', name: t('alphabets.r') },
		// { id: '19', name: t('alphabets.s') },
		// { id: '20', name: t('alphabets.t') },
		// { id: '21', name: t('alphabets.u') },
		// { id: '22', name: t('alphabets.v') },
		// { id: '23', name: t('alphabets.w') },
		// { id: '24', name: t('alphabets.x') },
		// { id: '25', name: t('alphabets.y') },
		// { id: '26', name: t('alphabets.z') },
		{ id: '1', name: 'a' },
		{ id: '2', name: 'b' },
		{ id: '3', name: 'c' },
		{ id: '4', name: 'd' },
		{ id: '5', name: 'e' },
		{ id: '6', name: 'f' },
		{ id: '7', name: 'g' },
		{ id: '8', name: 'h' },
		{ id: '9', name: 'i' },
		{ id: '10', name: 'j' },
		{ id: '11', name: 'k' },
		{ id: '12', name: 'l' },
		{ id: '13', name: 'm' },
		{ id: '14', name: 'n' },
		{ id: '15', name: 'o' },
		{ id: '16', name: 'p' },
		{ id: '17', name: 'q' },
		{ id: '18', name: 'r' },
		{ id: '19', name: 's' },
		{ id: '20', name: 't' },
		{ id: '21', name: 'u' },
		{ id: '22', name: 'v' },
		{ id: '23', name: 'w' },
		{ id: '24', name: 'x' },
		{ id: '25', name: 'y' },
		{ id: '26', name: 'z' },
		{ id: '27', name: '#' },
	];
	const handleChange = (event, newValue) => {
		var charData = alphabeticTab?.find((item) => item?.id === newValue);

		setValue(newValue);
		fetchCommunityDirectory(charData?.name, currentPage);
	};
	const communityDirectoryList = useSelector((state) => state?.Combat?.communityDirectoryList);
	const fetchCommunityDirectory = async (charData, page) => {
		try {
			const { status, data } = await axiosInstance.get(
				URLS.getCommunitiesDirectory + `?name=${charData}&page=${page}&limit=${limit}`,
			);

			if (status === 200) {
				getCommunitiesDirectoryList(data?.communitiesData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		var charData = alphabeticTab?.find((item) => item?.id === value);

		fetchCommunityDirectory(charData?.name, currentPage);
		// fetchCommunityDirectory(charData?.name, currentPage);
	}, []);
	const navigate = useNavigate();

	return (
		<Box className="community-page">
			<Box className="community-page-main">
				<Container maxWidth="lg">
					<Box className="combat-container">
						<Box className="community-title-box">
							<Typography variant="h1" align="center">
								{t('community.title')}
							</Typography>
							<Box className="bpdtr-box  d-block">
								<Typography className="tab-title">{t('community.community_directory')}</Typography>
								<TabContext value={String(value)}>
									<TabList
										onChange={handleChange}
										className="alphabet-number"
										variant="scrollable"
										scrollButtons={false}
									>
										{alphabeticTab?.map((data, index) => {
											return (
												<Tab
													key={index}
													label={data?.name}
													value={data?.id}
													disableRipple
													disableTouchRipple
													disableFocusRipple
												/>
											);
										})}
									</TabList>
								</TabContext>
							</Box>
							<Box className="bpdtr-box  ">
								<TabContext value={String(value)}>
									<TabPanel value={String(value)} className="tabpanel-sec p-0">
										{communityDirectoryList &&
											communityDirectoryList.map((item, index) => (
												<Typography
													className="comm-dir-title"
													onClick={() => {
														navigate(`/communities/${item?.id}`);
													}}
												>
													{item?.name}
												</Typography>
											))}
									</TabPanel>
								</TabContext>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};
const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunitiesDirectoryList: (data) => dispatch(getCommunitiesDirectoryList(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllComunityDirectory);
