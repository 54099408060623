import { useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import './about.scss';
import CombatDialog from 'src/views/component/UI/dialogBox';
import EditAbout from './EditAbout';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CommunityAbout = ({ isView, isJoin, fetchCommunityDetails }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const combatDialogHandler = () => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	var post_content =
		communityDetails && communityDetails?.post_about?.PostLists.find((item) => item?.Item?.type === 'text');

	return (
		<>
			<Box className="community-about-box">
				<Box className="about-header">
					<Typography className="about-title">{t('community.about')}</Typography>
					{isView || communityDetails?.communityData?.status === 'closed' ? null : (
						<IconButton onClick={combatDialogHandler}>
							<BpencilIcon />
						</IconButton>
					)}
				</Box>
				<Typography className="community-about-txt">
					{post_content && // USER PROFILE TYPE ABOUT TEXT
						renderHTML(
							anchorme({
								input: post_content?.Item?.data.toString().replace(/\n/g, '<br/>'),
								options: {
									attributes: {
										target: '_blank',
										class: 'detected',
									},
								},
							}),
						)}
				</Typography>
			</Box>
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={t('community.edit_about')}
				content={<EditAbout handleClose={closeDialog} fetchCommunityDetails={fetchCommunityDetails} />}
			/>
		</>
	);
};

export default CommunityAbout;
