import { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Box, Grid, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import '../auth.scss';
import { ReactComponent as EyeSlash } from 'src/assets/images/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'src/assets/images/icons/eye.svg';

const ResetPasswordPage = () => {
	const [view, setView] = useState(false);
	const [flag, setFlag] = useState(false);
	const navigate = useNavigate();
	const { email } = useParams();
	const location = useLocation();
	const { t } = useTranslation();

	var emailAddress = location?.state !== undefined && location?.state;

	const resendOTP = async () => {
		const requestData = {
			email: emailAddress ? emailAddress : email ? email : '',
			type: 'forgot',
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.resendOTP, requestData);

			if (status === 200) {
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Box className="auth-section-box">
			<Grid container className="auth-grid-container">
				<Grid item xs={12} sm={12} md={12} lg={5} className="auth-left-grid">
					<Box className="content">
						<Typography className="title">{t('resetpassword.forgot_password')}</Typography>
						<Typography className="desription">{t('resetpassword.description')}</Typography>
						<Box className="form-content">
							<Formik
								initialValues={{
									otp: '',
									password: '',
									confirmPassword: '',
								}}
								validationSchema={Yup.object().shape({
									otp: Yup.string().min(4, t('resetpassword.invalid_otp')).required(t('validation.required_message')),
									password: Yup.string()
										.required(t('validation.required_message'))
										.min(6, t('validation.passowrd_length')),
									confirmPassword: Yup.string()
										.required(t('validation.required_message'))
										.min(6, t('validation.passowrd_length'))
										.when('password', {
											is: (val) => (val && val.length > 0 ? true : false),
											then: Yup.string().oneOf([Yup.ref('password')], t('validation.password_confpass_not_match')),
										}),
								})}
								onSubmit={async (requestData) => {
									const sendData = {
										email: emailAddress ? emailAddress : email,
										otp: requestData.otp,
										password: requestData.password,
									};

									try {
										const { status, data } = await axiosInstance.post(URLS.resetPassword, sendData);

										if (status === 200) {
											setApiMessage('success', data?.message);
											navigate('/sign-in', { replace: true });
										}
									} catch (error) {
										setApiMessage('error', error?.response?.data?.message);
									}
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} className="text-field-grid">
												<MatrixTextField
													error={Boolean(touched.otp && errors.otp)}
													fullWidth
													helperText={touched.otp && errors.otp}
													variant="filled"
													InputProps={{
														endAdornment: touched.otp && errors.otp ? <ErrorIcon /> : null,
													}}
													label={t('resetpassword.otp')}
													placeholder={t('resetpassword.otp')}
													className="matrix-textfield"
													size="small"
													name="otp"
													onBlur={handleBlur}
													onChange={(e) => {
														const re = /^[0-9\b]+$/;

														if (e.target.value === '' || re.test(e.target.value)) {
															handleChange(e);
														}
													}}
													inputProps={{ maxLength: 4 }}
													value={values.otp}
												/>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid position-relative">
												<MatrixTextField
													error={Boolean(touched.password && errors.password)}
													fullWidth
													helperText={touched.password && errors.password}
													variant="filled"
													InputProps={{
														endAdornment: touched.password && errors.password ? <ErrorIcon /> : null,
													}}
													label={t('password')}
													placeholder={t('password')}
													className="matrix-textfield"
													size="small"
													name="password"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.password}
													type={!view ? 'password' : 'text'}
												/>
												<IconButton className="eye-icons" onClick={() => setView(!view)}>
													{!view ? <EyeSlash /> : <EyeIcon />}
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12} className="text-field-grid position-relative">
												<MatrixTextField
													error={Boolean(touched.confirmPassword && errors.confirmPassword)}
													fullWidth
													helperText={touched.confirmPassword && errors.confirmPassword}
													variant="filled"
													InputProps={{
														endAdornment: touched.confirmPassword && errors.confirmPassword ? <ErrorIcon /> : null,
													}}
													label={t('register.repeat_password')}
													placeholder={t('register.repeat_password')}
													className="matrix-textfield"
													size="small"
													name="confirmPassword"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.confirmPassword}
													type={!flag ? 'password' : 'text'}
												/>
												<IconButton className="eye-icons" onClick={() => setFlag(!flag)}>
													{!flag ? <EyeSlash /> : <EyeIcon />}
												</IconButton>
											</Grid>
											<Grid item xs={12} md={12}>
												<Button variant="contained" fullWidth type="submit" className="signup-button">
													{t('resetpassword.reset_password')}
												</Button>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>

							<Typography className="resend-otp-text" onClick={resendOTP}>
								{t('verify_otp.resend_otp')}
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ResetPasswordPage;
