import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid } from '@material-ui/core';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditBio = ({ handleClose, fetchCommunityDetails, isLoading, setIsLoading }) => {
	const { id } = useParams();

	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	const { t } = useTranslation();

	const updateCommunity = async (data) => {
		setIsLoading(true);
		var requestParams = {
			community: {
				description: data?.welcome,
				id: Number(id),
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateCommunity, requestParams);

			if (status === 200) {
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
				handleClose();
			}
		} catch (error) {
			setIsLoading(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Box>
			<Formik
				initialValues={{
					welcome: communityDetails?.communityData?.description ? communityDetails?.communityData?.description : '',
				}}
				// validationSchema={Yup.object().shape({
				//   welcome: Yup.string().required(t('validation.required_message'))
				// })}
				onSubmit={(requestdata) => updateCommunity(requestdata)}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} className="text-field-grid">
								<MatrixTextField
									error={Boolean(touched.welcome && errors.welcome)}
									fullWidth
									helperText={
										touched.welcome ? (
											touched.welcome && (
												<Box disply="flex" justifyContent="center">
													{errors.welcome}
													<span className="field-character-count">{values.welcome.length}/10000</span>
												</Box>
											)
										) : (
											<span className="field-character-count">{values.welcome.length}/10000</span>
										)
									}
									variant="filled"
									InputProps={{
										maxLength: 10000,
										endAdornment: touched.welcome && errors.welcome ? <ErrorIcon /> : null,
									}}
									inputProps={{ maxLength: 10000 }}
									label={t('community.welcome')}
									placeholder={t('community.welcome')}
									className="matrix-textfield"
									size="small"
									name="welcome"
									multiline
									rows={10}
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.welcome}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<Box className="dialog-submit-button">
									<Button variant="contained" type="submit" disabled={isLoading} className="dialog-submit">
										{t('save')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default EditBio;
