import { useRef, useState, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles, Hidden } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { ViewPortContext } from 'src/App';
import NavBar from './NavBar';
import TopBar from './TopBar';
import 'src/layouts/layout.scss';
import MobileMenu from './Mobilemenu/index';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%',
	},
	contentContainer: {
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
		background: '#202020',
	},
	content: {
		flex: '1 1 auto',
		height: '100%',
		overflow: 'auto',
		position: 'relative',
	},
	// The tablet menu will show between 480px - 1280px screen size
}));

const DashboardLayout = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	const redux_auth = useSelector((state) => state?.Combat?.authData);
	const statics = useSelector((state) => state?.Combat?.user_statics);
	const user_statics = statics ? statics : fetchFromStorage('user_statics');
	const auth = redux_auth ? redux_auth : fetchFromStorage('authData');
	const verify_count = useSelector((state) => state?.Combat?.user_profile_count);
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const token = fetchFromStorage('authData')?.token;
	// ref, to use the openDialog state from the parent component.
	const topBarRef = useRef();
	// setOpenDialog attri is exposed by the child component TopBar.
	// the dialog window is in the TopBar component, but need to be opened in the NavBar component
	const openDialog = () => {
		topBarRef.current.setOpenDialog();
	};

	const redirectGuestToSignUp = (e) => {
		if (!token) {
			e.stopPropagation();
			e.preventDefault();
			navigate('/');
		}

		return undefined;
	};

	const openMobileMenu = (e) => {
		redirectGuestToSignUp(e);
		setMobileNavOpen(true);
	};

	return (
		<div className={classes.root}>
			<ToastContainer style={{ width: '400px' }} />
			<TopBar
				auth={auth}
				user_statics={user_statics}
				verify_count={verify_count}
				onMobileNavOpen={openMobileMenu}
				onRef={(ref) => (topBarRef.current = ref)}
			/>

			{windowWidth <= breakPoint ? <MobileMenu auth={auth} user_statics={user_statics} /> : <></>}

			<NavBar
				auth={auth}
				onMobileClose={() => setMobileNavOpen(false)}
				openShareDialog={openDialog}
				openMobile={isMobileNavOpen}
			/>

			<div className="layout-wrapper">
				<div className={classes.contentContainer}>
					<div id="pageScrollableBody" className={classes.content} onClick={redirectGuestToSignUp}>
						<Outlet />
					</div>
				</div>
			</div>
			<Footer auth={auth} user_statics={user_statics} verify_count={verify_count} />
		</div>
	);
};

export default DashboardLayout;
