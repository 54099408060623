import { useState, useCallback } from 'react';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import Viewer from 'react-viewer';

function LightBox(props) {
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [imageStatus, setImageStatus] = useState(true);

	const images = props.imageurl;
	const image = props.image;
	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const handleImageLoaded = () => {
		setImageStatus(false);
	};
	const addClass = props.otherUserId === false ? 'pb-25 h-100' : 'h-100';

	return (
		<div className={addClass}>
			{images.map((src, index) => (
				<>
					{src.src === image ? (
						<>
							<img
								src={imageStatus === true ? loaderGif : src.src}
								onClick={() => openImageViewer(index)}
								// width='300'
								key={index}
								style={{ width: 'auto' }}
								alt=""
								onError={(e) => {
									e.onerror = null;
									e.target.src = placeholder;
								}}
								onLoad={handleImageLoaded}
							/>
						</>
					) : (
						''
					)}
				</>
			))}
			{isViewerOpen && (
				<Viewer
					visible={isViewerOpen}
					onClose={() => {
						setIsViewerOpen(false);
					}}
					activeIndex={currentImage}
					downloadable={true}
					downloadInNewWindow={true}
					images={images}
					zoomSpeed={0.5}
				/>
			)}
		</div>
	);
}
export default LightBox;
