import { Box, Button, Typography } from '@material-ui/core';
import '../users.scss';
import { useTranslation } from 'react-i18next';

const VerifyUser = ({ message, handleClick, handleClose, heading }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="verify-user">
				<Typography className="modal-heading">{heading}</Typography>
				<Button variant="outlined" onClick={handleClose}>
					{t('button_text.cancel')}
				</Button>
				<Button variant="contained" onClick={handleClick}>
					{t('button_text.confirm')}
				</Button>
			</Box>
		</>
	);
};

export default VerifyUser;
