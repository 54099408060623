import { Avatar, Box, Button, Grid, Typography, Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as FaceBookIcon } from 'src/assets/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/images/icons/twitter.svg';
import { ReactComponent as TapologyIcon } from 'src/assets/images/icons/tapology.svg';
import { ReactComponent as SnapchatIcon } from 'src/assets/images/icons/snapchat.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/images/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from 'src/assets/images/icons/twitch.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/images/icons/linkedin.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/images/icons/tiktok.svg';
import { ReactComponent as SmoothcompIcon } from 'src/assets/images/icons/smoothcomp.svg';
// import { ReactComponent as AddConnectionIcon } from 'src/assets/images/icons/add-connection.svg';
import SherdogIcon from 'src/assets/images/icons/bsherdog.png';
import ShadowCFFC from './CFFC';
import './right.scss';
import moment from 'moment';

var linksObj = {
	facebook: ' https://www.facebook.com',
	instagram: 'https://www.instagram.com',
	twitter: 'https://twitter.com',
	tapology: 'https://tapology.com/fightcenter/fighters',
	snapchat: 'https://www.snapchat.com',
	youtube: 'https://youtube.com/channel/',
	twitch: 'https://twitch.tv',
	linkedin: 'https://www.linkedin.com/in',
	tiktok: 'https://www.tiktok.com/@',
	sherdog: 'https://sherdog.com/fighter/',
	smoothcomp: 'https://smoothcomp.com/en/profile',
};

// SOCIAL MEDIA ICON HANDLER
function SocialIconHandler(data) {
	var checkForSlash = data?.data?.charAt(0) === '/' ? true : false;

	switch (data?.type) {
		case 'URL_facebook':
			var socialLink = checkForSlash ? linksObj?.facebook + data?.data : linksObj?.facebook + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<FaceBookIcon />
				</Link>
			);
		case 'URL_instagram':
			socialLink = checkForSlash ? linksObj?.instagram + data?.data : linksObj?.instagram + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<InstagramIcon />
				</Link>
			);
		case 'URL_twitter':
			socialLink = checkForSlash ? linksObj?.twitter + data?.data : linksObj?.twitter + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitterIcon />
				</Link>
			);
		case 'URL_tapology':
			socialLink = checkForSlash ? linksObj?.tapology + data?.data : linksObj?.tapology + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TapologyIcon />
				</Link>
			);
		case 'URL_snapchat':
			socialLink = checkForSlash ? linksObj?.snapchat + data?.data : linksObj?.snapchat + '/add/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SnapchatIcon />
				</Link>
			);
		case 'URL_youtube':
			socialLink = checkForSlash ? linksObj?.youtube + data?.data : linksObj?.youtube + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<YouTubeIcon />
				</Link>
			);
		case 'URL_twitch':
			socialLink = checkForSlash ? linksObj?.twitch + data?.data : linksObj?.twitch + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TwitchIcon />
				</Link>
			);
		case 'URL_linkedin':
			socialLink = checkForSlash ? linksObj?.linkedin + data?.data : linksObj?.linkedin + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<LinkedInIcon />
				</Link>
			);
		case 'URL_tiktok':
			socialLink = checkForSlash ? linksObj?.tiktok + data?.data : linksObj?.tiktok + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<TikTokIcon />
				</Link>
			);
		case 'URL_sherdog':
			socialLink = checkForSlash ? linksObj?.sherdog + data?.data : linksObj?.sherdog + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<img src={SherdogIcon} alt="sherdog-icon" />
				</Link>
			);
		case 'URL_smoothcomp':
			socialLink = checkForSlash ? linksObj?.smoothcomp + data?.data : linksObj?.smoothcomp + '/' + data?.data;

			return (
				<Link href={socialLink} target="_blank">
					<SmoothcompIcon />
				</Link>
			);
		default:
		// socialLink = checkForSlash
		//   ? linksObj?.facebook + data?.data
		//   : linksObj?.facebook + '/' + data?.data;
		// return (
		//   <Link href={socialLink} target="_blank">
		//     <FaceBookIcon />
		//   </Link>
		// );
	}
}

const RightBlock = ({
	loader,
	isView,
	user,
	token,
	ProfileAvatar,
	followersData,
	claimUserProfile,
	followOtherUser,
	followersTotalCount,
}) => {
	var userBio = user?.OtherUserDetails?.UserBioData?.PostLists.find((item) => item?.Item?.type === 'text');

	return (
		<Box className="my-profile-right">
			<Box className="my-right-top">
				<Box className="my-right-img">
					<Box className="my-right-avatar">
						<Box className="right-img">
							{loader ? (
								<Skeleton variant="circle" width={115} height={115} animation="wave" />
							) : isView ? (
								<Avatar
									style={{ cursor: 'pointer' }}
									src={user?.OtherUserDetails?.item_profile_data}
									// src={UserPlaceholderIcon}
								/> // VISITER VIEW PROFILE IMAGE
							) : (
								ProfileAvatar // OWNER IMAGE EDIT COMPONENT
							)}
						</Box>
					</Box>

					<Box className="myright-names">
						{/* NICKNAME */}
						<Typography className="myright-wildcard">
							{loader ? <Skeleton variant="text" /> : ''}
							{/* {loader ? <Skeleton variant="text" /> : '"Wildcard"'} */}
						</Typography>

						<Typography className="right-username">
							{loader ? <Skeleton variant="text" /> : user?.OtherUserDetails?.user_name}
						</Typography>

						<Typography className="member-since">
							{loader ? (
								<Skeleton variant="text" width={'100px'} />
							) : (
								`MEMBER SINCE:  ${moment(user?.OtherUserDetails?.timestamp_created).format('DD MMM YY')}`
							)}
						</Typography>

						<Box className="social-icons">
							<Grid container>
								{loader ? (
									<>
										{/* SOCIAL MEDIA LOADER SKELETON */}
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
										<Skeleton variant="circle" className="social-link" width={18} height={18} />
									</>
								) : (
									<>
										{user?.UserOtherMetaData &&
											user?.UserOtherMetaData.map((data, index) => {
												return (
													// eslint-disable-next-line react/no-array-index-key
													<Grid item className="social-link" key={index}>
														{SocialIconHandler(data)}
													</Grid>
												);
											})}
									</>
								)}
							</Grid>
						</Box>
					</Box>
				</Box>

				<Box display="flex" justifyContent="space-between">
					{/* FOLLOW BUTTON */}
					<Button
						style={{ width: '49%' }}
						variant="contained"
						className="connection-send-btn"
						onClick={() => followOtherUser(user)}
					>
						{user?.OtherUserDetails?.isFollow ? 'Unfollow' : 'Follow'}
					</Button>

					{/* FOLLOW BUTTON */}
					<Button
						style={{ width: '49%' }}
						variant="contained"
						className="connection-send-btn"
						onClick={() => claimUserProfile(user)}
					>
						{user?.OtherUserDetails?.isClaim ? 'Unclaim' : 'Claim'}
					</Button>
				</Box>

				{/* USER FOLLOWRS COUNT */}
				<Box className="right-prof-counts">
					<Box className="social-count-bx">
						<Typography className="social-count">
							{loader ? <Skeleton variant="text" /> : user?.UserStatisticsDetails?.followers}
						</Typography>
						<Typography className="social-act-name">followers</Typography>
					</Box>
				</Box>

				{/* USER BIO */}
				<Box className="bio-block">
					<Typography className="bio-title">Bio</Typography>

					<Typography className="bio-text" style={{ textAlign: 'left' }}>
						{loader ? (
							<>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</>
						) : (
							<>{userBio?.Item?.data}</>
						)}
					</Typography>
				</Box>
			</Box>

			{/* USER FOLLOWES */}
			<ShadowCFFC followersData={followersData} followersTotalCount={followersTotalCount} />
		</Box>
	);
};

export default RightBlock;
