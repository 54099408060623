import { useEffect, useState, useRef } from 'react';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
import { Box, Button, Typography } from '@material-ui/core';
// import { MatrixTextField } from 'src/views/component/UI/textfield';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';
// import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import './comment.scss';
// import { setApiMessage } from 'src/helpers/commonFunctions';
// import { MentionsInput, Mention } from 'react-mentions';
import { useParams, useLocation } from 'react-router-dom';
// import moment from 'moment';
// import renderHTML from 'react-render-html';
// import anchorme from 'anchorme';
import { parse } from 'query-string';
import CommentBox from './CommentBox';
import { AddComment } from './commentComponent';
import { useTranslation } from 'react-i18next';

const PostComment = ({
	isJoin,
	allUsersList,
	postId,
	isView,
	commentData,
	reportReasonList,
	fetchReportReasonList,
	fetchCommentData,
	hasMoreItems,
	user,
	navigate,
	notificationCmt,
	postCommentData,
	fetchNotificationCommentData,
	fetchPostDetails,
	previousParentId,
	setCommentData,
}) => {
	const { t } = useTranslation();

	// const defaultStyle = {
	//   control: {
	//     backgroundColor: "#1B1B1B",
	//     fontSize: 16,
	//     fontWeight: "normal"
	//   },
	//   highlighter: {
	//     overflow: "hidden"
	//   },
	//   input: {
	//     margin: 0,
	//     overflow: "auto",
	//     minHeight: 100,
	//     color: "#fff",
	//   },
	//   "&multiLine": {
	//     control: {
	//       fontFamily: "Roboto",
	//       border: "1px solid #2E353A"
	//     },
	//     highlighter: {
	//       padding: 9
	//     },
	//     input: {
	//       padding: 9,
	//       minHeight: 100,
	//       outline: 0,
	//       border: 0
	//     }
	//   },
	//   suggestions: {
	//     bottom: "0",
	//     top: "unset",
	//     list: {
	//       backgroundColor: "white",
	//       border: "1px solid rgba(0,0,0,0.15)",
	//       fontSize: 14
	//     },
	//     item: {
	//       padding: "5px 15px",
	//       borderBottom: "1px solid rgba(0,0,0,0.15)",
	//       "&focused": {
	//         backgroundColor: "#cee4e5"
	//       }
	//     }
	//   }
	// };
	const location = useLocation();
	const params = useParams();
	const query = parse(location.search);
	const storage_authData = fetchFromStorage('authData');
	// const [commentData, setCommentData] = useState([]);
	const [editClick, setEditClick] = useState(false);
	const [selectedComment, setSelectedComment] = useState(null);
	// const [flag, setFlag] = useState(false);
	const [userComment, setUserComment] = useState('');
	const [loader, setLoader] = useState(false);
	const [reactionData, setReactionData] = useState([]);
	const [seeAllVal, setSeeAllValue] = useState(true);
	const [selectedCommentData, setSelectedCommentData] = useState(null);
	// const [reportReasonList, setReportReasonList] = useState([]);
	const formikRef = useRef();
	const token = fetchFromStorage('authData')?.token;

	const { id } = useParams();
	// // FETCH REPORT REASON LIST
	// const fetchReportReasonList = async () => {
	//   try {
	//     const { status, data } = await axiosInstance.get(URLS.getReportReasonList);
	//     if (status === 200) {
	//       setReportReasonList(data?.getReasonList)
	//     }
	//   } catch (error) { }
	// }
	// // FETCH ALL COMMENT DATA
	// const fetchCommentData = async () => {
	//   try {
	//     let { status, data } = await axiosInstance.get(
	//       URLS.getAllComments + postId
	//     );

	//     if (status === 200) {
	//       setCommentData(data?.post_list);
	//     }
	//   } catch (err) { }
	// };

	const showAllComments = () => {
		setSeeAllValue(true);
	};

	// CREATE COMMENT
	const createComment = async (val) => {
		setLoader(true);

		try {
			const content = [{ type: 'text', data: val }];

			let requestData = {
				comment: {
					post_id: postId,
					status: 'active',
					main_post_id: id,
					postList: content,
				},
			};

			let { status, data } = await axiosInstance.post(URLS.createComment, requestData);

			if (status === 200) {
				fetchCommentData('', '', 1);
				setLoader(false);

				if (formikRef.current) {
					formikRef.current.setFieldValue('bio', '');
				}

				setUserComment();
				showAllComments();
				fetchPostDetails();
				location?.state?.comment_id && fetchNotificationCommentData();
			}
		} catch (err) {
			setLoader(false);
		}
	};

	const updatePartOrCommentsArray = async (id) => {
		const commentIndex = commentData.findIndex((comment) => comment.post_id === id);
		const pageLimit = 10;
		const page = commentIndex === -1 ? 1 : Math.ceil((commentIndex + 1) / pageLimit);

		console.log(page, commentIndex);

		try {
			const { status, data } = await axiosInstance.get(URLS.getAllComments + params.id, {
				params: {
					page: page,
					limit: pageLimit,
				},
			});

			if (status === 200) {
				setLoader(false);

				const copyCommentData = [...commentData];

				const from = page === 1 ? page - 1 : (page - 1) * pageLimit;

				copyCommentData.splice(from, pageLimit, ...data.post_list);

				setCommentData(copyCommentData);
			}
		} catch (e) {
			console.log(e);
		}
	};

	// UPDATE COMMENT
	const updateComment = async (val) => {
		setLoader(true);

		try {
			var requestParameter = {
				post: {
					id: selectedComment?.post_id,
					sponsored: 'no',
					sticky: 'no',
					comments: 'no',
					postList: [{ type: 'text', data: val, item_id: selectedComment?.item_id }],
				},
			};

			const { status } = await axiosInstance.put(URLS.updatePost, requestParameter);

			if (status === 200) {
				setLoader(false);

				if (formikRef.current) {
					formikRef.current.setFieldValue('bio', '');
				}
				//TODO: temporary desicion, need backed research

				updatePartOrCommentsArray(selectedComment?.post_id);

				setEditClick(false);
				setSelectedComment(null);
				setSelectedCommentData(null);
				// fetchCommentData();
				setUserComment();
			}
		} catch (err) {
			setLoader(false);
		}
	};

	// EDIT POST COMMENT CLICK
	const editCommentClick = (val, cmt, cmtData) => {
		setSelectedComment(cmt);
		setEditClick(true);
		setSelectedCommentData(cmtData);

		if (formikRef.current) {
			formikRef.current.setFieldValue('bio', val);
			setUserComment(val);
		}
	};

	const addVote = async (value, type) => {
		if (!token) {
			navigate('/');

			return;
		}

		var requestParams = {
			post_id: value?.id,
			vote_name: type,
			main_post_id: Number(id),
		};

		try {
			const { status } = await axiosInstance.post(URLS.addVote, requestParams);

			if (status === 200) {
				//TODO: temporary desicion, need backed research
				updatePartOrCommentsArray(value?.id);
				location?.state?.comment_id && fetchNotificationCommentData();
			}
		} catch (error) {
			// setApiMessage('error', error?.response?.data?.message);
		}
	};

	// const setTagUserFlag = (e) => {
	//   const { value } = e.target;
	//   const charLast = value.charAt(value.length - 1)
	//   charLast === '@' ? setFlag(true) : setFlag(false);
	// }
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const onInputChange = (event) => {
		const value = event.target.value;

		const regex = /[^{}]+(?=})/g;
		const mentions = value.match(regex);

		setUserComment(event.target.value);
	};

	useEffect(() => {
		var flag = location?.state?.comment_id || query?.highlight_post_id ? false : true;

		setSeeAllValue(flag);
	}, [location?.state?.comment_id]);
	useEffect(() => {
		// fetchCommentData();
		fetchReportReasonList();
		fetchReactions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCommentData(true);
		// fetchCommentData();
		// eslint-disable-next-line
	}, [location?.state?.previous_parent_id]);

	// commentData postCommentData

	return (
		<Box className="comment-box">
			{/* CREATE COMMENT BOX */}
			{/* {
        user &&
        <Box className="create-cmt-bx">
          <Box className="user-box">
            <Avatar src={storage_authData?.profile_pic} className="user-image" />
            <Typography className="user-name">{`${storage_authData?.OauthUserData?.first_name
              ? storage_authData?.OauthUserData?.first_name
              : ''
              } ${storage_authData?.OauthUserData?.last_name
                ? storage_authData?.OauthUserData?.last_name
                : ''
              }`}</Typography>
          </Box>
          <Box className="cmt-form">
            <Formik
              innerRef={formikRef}
              initialValues={{
                bio: '',
              }}
              onSubmit={val => {
                if (!isJoin) {
                  if (editClick) {
                    updateComment(val.bio);
                  } else {
                    createComment(val.bio);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} className="text-field-grid">
                      <MentionsInput
                        value={userComment}
                        onChange={(e) => { onInputChange(e); setFieldValue('bio', e.target.value) }}
                        style={defaultStyle}
                        placeholder='Comment'
                        rows={3}
                      >
                        <Mention
                          markup="<a data-value='__id__' href='#'>__display__</a>"
                          data={allUsersList}
                        />
                      </MentionsInput>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="comment-btnbox">
                        <Button
                          variant="contained"
                          type="submit"
                          className="cmt-btn"
                          disabled={loader}
                          onClick={() => {
                            isJoin && setApiMessage('error', 'please join community');
                          }}
                        >
                          {editClick ? 'Update comment' : t('community.comment')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      } */}
			<AddComment
				user={user}
				storage_authData={storage_authData}
				isJoin={isJoin}
				formikRef={formikRef}
				updateComment={updateComment}
				createComment={createComment}
				onInputChange={onInputChange}
				editClick={editClick}
				userComment={userComment}
				allUsersList={allUsersList}
				loader={loader}
			/>
			<Box className="comment-box" id={'comment-box'}>
				{/* {postCommentData !== '' && (
          <Box className="comment-list-box-main">
            <Box className="comment-list-box">
              <Box className="user-box">
                <Avatar src={postCommentData?.user_item_profile_data} className="user-img" />
                <Box className="name-time">
                  <Typography className="user-name">
                    {postCommentData?.user_full_name}
                  </Typography>
                  <Typography className="post-time">
                    {moment.utc(postCommentData?.timestamp_created).fromNow().replace('in ', '')}
                  </Typography>
                </Box>
              </Box>
              <Box className="ml-55">
                <Box className="user-comments">
                  <Typography className="cmt-hdln">
                    {postCommentData && postCommentData?.PostLists[0]?.Item?.data &&
                      renderHTML(
                        anchorme({
                          input: postCommentData?.PostLists[0]?.Item?.data.toString().replace(/\n/g, '<br/>'),
                          options: {
                            attributes: {
                              target: '_blank',
                              class: 'detected'
                            }
                          }
                        })
                      )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )} */}
				{!seeAllVal && (
					<Button
						disableElevation
						disableRipple
						disableTouchRipple
						disableFocusRipple
						className="see-all-cmt-btn"
						onClick={() => setSeeAllValue(true)}
					>
						See All Comments
					</Button>
				)}
				{!seeAllVal ? (
					<Box className="comment-list-box-main">
						{postCommentData?.length > 0 ? (
							postCommentData.map((cmt, i) => {
								// console.log('postCommentData', postCommentData)
								return (
									<CommentBox
										setCommentData={setCommentData}
										hideShowMore={seeAllVal}
										fetchPostDetails={fetchPostDetails}
										showAllComments={showAllComments}
										fetchNotificationCommentData={fetchNotificationCommentData}
										cmt={cmt}
										isView={isView}
										storage_authData={storage_authData}
										fetchCommentData={fetchCommentData}
										editCommentClick={editCommentClick}
										addVote={addVote}
										postId={postId}
										reportReasonList={reportReasonList}
										allUsersList={allUsersList}
										user={user}
										reactionData={reactionData}
										likedStatus={cmt?.like_status}
										reactions={cmt?.total_likes}
										navigate={navigate}
									/>
								);
							})
						) : (
							<Typography className="yhno-info-txt">{t('myprofile.you_have_no_info')}</Typography>
						)}
					</Box>
				) : (
					<Box className="comment-list-box-main">
						{/* {location?.state?.previous_parent_id && Number(id) !== location?.state?.previous_parent_id && (
              <Button className="continue-thread"
                onClick={() =>
                  navigate(`/post/${id}`, {
                    state: { previous_parent_id: previousParentId }
                  })
                }
              >
                Show parent comments
              </Button>
            )} */}
						{commentData?.length > 0 ? (
							commentData.map((cmt, i) => {
								// console.log('postCommentData', postCommentData)
								return (
									<CommentBox
										setCommentData={setCommentData}
										commentLevel={cmt?.level}
										loader={loader}
										createComment={createComment}
										isJoin={isJoin}
										formikRef={formikRef}
										fetchPostDetails={fetchPostDetails}
										fetchNotificationCommentData={fetchNotificationCommentData}
										cmt={cmt}
										isView={isView}
										storage_authData={storage_authData}
										fetchCommentData={fetchCommentData}
										editCommentClick={editCommentClick}
										addVote={addVote}
										postId={postId}
										reportReasonList={reportReasonList}
										allUsersList={allUsersList}
										user={user}
										reactionData={reactionData}
										likedStatus={cmt?.like_status}
										reactions={cmt?.total_likes}
										navigate={navigate}
									/>
								);
							})
						) : (
							<Typography className="yhno-info-txt">{t('myprofile.you_have_no_info')}</Typography>
						)}
						{hasMoreItems && (
							<Button
								disableElevation
								disableRipple
								disableTouchRipple
								disableFocusRipple
								className="readmore-btn"
								onClick={() => fetchCommentData(true, 1)}
							>
								Read more
							</Button>
						)}
					</Box>
				)}
			</Box>
			{/* <AddComment addComment={addComment} />
      {commentData.length ? (
        <ul>
          {commentData.map((comment) => (
            <Comment
              addNewReply={addNewReply}
              key={comment?.PostLists[0]?.Item?.data}
              comment={comment}
              cmt={comment}
              fetchCommentData={fetchCommentData}
            />
          ))}
        </ul>
      ) : (
        <div>
          <p>No comment yet</p>
        </div>
      )} */}
		</Box>
	);
};
export default PostComment;
