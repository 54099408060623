import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { useParams } from 'react-router-dom';
import MessagePerPerson from './MessagePerPerson';

const MessagePersons = ({ onUserChatClick, fetchGroupList, fetchMessageList, currentGroupChat, userListData }) => {
	const { id } = useParams();

	const renderSwitch = (data) => {
		switch (data?.lastMessageObj?.message_type) {
			case 'string':
				return (
					<>
						{data?.lastMessageObj?.message && // USER PROFILE TYPE ABOUT TEXT
							renderHTML(
								anchorme({
									input: data?.lastMessageObj?.message.toString().replace(/\n/g, '<br/>'),
									options: {
										attributes: {
											target: '_blank',
											class: 'detected',
										},
									},
								}),
							)}
					</>
				);
			case 'image':
				return <>Image</>;
			case 'doc':
				return <>Document</>;
			case 'video':
				return <>Video</>;
			case 'audio':
				return <>Audio</>;
			default:
				return '';
		}
	};

	return (
		<>
			{userListData?.map((data, index) => {
				return (
					<MessagePerPerson
						singleItem={data}
						Name={data?.name}
						profileId={data?.item_profile_data}
						id={data?.id}
						msgContent={renderSwitch(data)}
						onUserChatClick={onUserChatClick}
						currentGroupChat={currentGroupChat}
						fetchGroupList={fetchGroupList}
						fetchMessageList={fetchMessageList}
						unRead={data?.total_unread_count}
						userListData={userListData}
						currentGroupId={Number(id)}
					/>
				);
			})}
		</>
	);
};
export default MessagePersons;
