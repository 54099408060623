import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	CircularProgress,
	Container,
	IconButton,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Button,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { URLS } from 'src/helpers/constants/urls';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { ReactComponent as UnlockIcon } from 'src/assets/images/icons/unlock.svg';
import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
import './users.scss';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
import { ReactComponent as ListView } from 'src/assets/images/icons/community/list.svg';
import { fetchFromStorage } from 'src/helpers/context';
import { useSelector } from 'react-redux';
import VerifyUser from './VerifyUser';
import DeleteUser from './DeleteUser';
// eslint-disable-next-line import/extensions
import ClaimUserList from './ClaimUserList';
import EditEmail from './EditEmail';

const AdminUsers = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [model, setModel] = useState('');
	const [users, setUsers] = useState([]);
	const [loader, setLoader] = useState(false);
	const [singleItemData, setSingleItemData] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(); // setTotalRecords
	const [limit] = useState(20); // setList
	const [claimedUserList, setClaimedUserList] = useState([]);
	const [heading, setHeading] = useState([]);

	const auth = useSelector((state) => state.Combat.authData);
	const token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const fetchUsersData = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(`${URLS.getAllUsers}?page=${page}&limit=${limit}&type=admin`);

			if (status === 200) {
				setLoader(false);
				setUsers(data?.usersData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				fetchUsersData(currentPage - 1);
			}
		} else if (currentPage < Math.ceil(totalRecord / limit)) {
			setCurrentPage(currentPage + 1);
			fetchUsersData(currentPage + 1);
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			fetchUsersData(Number(page));
		}
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
		setTitle(title);
	};

	const closeDialog = () => {
		setOpen(false);
	};
	const userVerify = async () => {
		setLoader(true);
		const requestData = {
			user_id: singleItemData?.user_id,
			status: singleItemData?.veritication_status === 'yes' ? 'no' : 'yes',
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.verifyUser, requestData);

			if (status === 200) {
				fetchUsersData(currentPage);
				setLoader(false);
				// setUsers(data?.usersData);
				closeDialog();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const deleteUser = async () => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.delete(
				`${URLS.deleteUsers}${singleItemData?.user_id}?type=${singleItemData?.type}`,
			);

			if (status === 200) {
				setLoader(false);
				// setUsers(data?.usersData);
				closeDialog();
				setApiMessage('success', data?.message);
				fetchUsersData(currentPage);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const fetchClaimUserList = async (id) => {
		try {
			const { status, data } = await axiosInstance.get(`${URLS.getClaimUserList}?userId=${id}`);

			// const { status, data } = await axiosInstance.get(URLS.getClaimUserList);
			if (status === 200) {
				setClaimedUserList(data?.getCliamUserList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	const userAction = async (value, status) => {
		const requestData = {
			user_other_id: value?.user_other_id,
			user_actual_id: value?.user_actual_id,
			status,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateUserOtherActual, requestData);

			if (status === 200) {
				setLoader(false);
				closeDialog();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const lockUnlockUser = async (value, status) => {
		const requestData = {
			user_other_id: value?.user_id,
			locked: status,
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.lockedUserOtherProfile, requestData);

			if (status === 200) {
				fetchUsersData(currentPage);
				setLoader(false);
				closeDialog();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchUsersData(currentPage);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg">
					<Typography className="admin-page-haeding">{t('admin_panel.combat_users.title')}</Typography>
					<Box className="admin-data-tables">
						{loader ? (
							<Box display="flex" justifyContent="center">
								<CircularProgress size={18} color="secondary" />
							</Box>
						) : (
							<>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>DID</TableCell>
												<TableCell>{t('admin_panel.combat_users.name')}</TableCell>
												{/* <TableCell>
                        {t('admin_panel.combat_users.username')}
                      </TableCell> */}
												<TableCell>{t('admin_panel.combat_users.email')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.phone')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.age')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.status')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.verified')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.verify_user')}</TableCell>
												<TableCell>{t('admin_panel.combat_users.user_type')}</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{users.map((data, index) => {
												return (
													<TableRow key={index}>
														<TableCell>{data?.user_id}</TableCell>
														<TableCell>
															{`${data?.first_name !== null ? data?.first_name : ''} ${
																data?.last_name !== null ? data?.last_name : ''
															}`}
														</TableCell>
														{/* <TableCell>----</TableCell> */}
														<TableCell>{data?.email}</TableCell>
														<TableCell>{data?.phone}</TableCell>
														<TableCell>{new Date().getFullYear() - new Date(data?.dob).getFullYear()}</TableCell>
														<TableCell>{data?.status}</TableCell>
														<TableCell>{data?.veritication_status}</TableCell>
														<TableCell>
															<Button
																variant="outlined"
																onClick={() => {
																	combatDialogHandler(
																		data?.veritication_status === 'yes'
																			? t('admin_panel.combat_users.unverify_user')
																			: t('admin_panel.combat_users.verify_user'),
																	);
																	setModel('verify');
																	setSingleItemData(data);
																	setHeading(
																		data?.veritication_status === 'yes'
																			? t('admin_panel.combat_users.unverify_text')
																			: t('admin_panel.combat_users.verify_text'),
																	);
																}}
															>
																{data?.veritication_status === 'yes'
																	? t('admin_panel.combat_users.unverify')
																	: t('admin_panel.combat_users.verify')}
															</Button>
														</TableCell>
														<TableCell>{data?.type}</TableCell>
														<TableCell align="right">
															<Box className="action-box">
																<IconButton
																	disableTouchRipple
																	disableRipple
																	disableFocusRipple
																	onClick={() => {
																		if (data?.type === 'user') {
																			combatDialogHandler(t('acount_setting.Change_email'));
																			setModel('editEmail');
																			setSingleItemData(data);
																		}
																	}}
																>
																	<EditIcon />
																</IconButton>
																<IconButton
																	disableTouchRipple
																	disableRipple
																	disableFocusRipple
																	onClick={() => {
																		combatDialogHandler(t('admin_panel.combat_users.delete_user'));
																		setModel('delete');
																		setSingleItemData(data);
																	}}
																>
																	<DeleteIcon />
																</IconButton>
																<IconButton
																	disableTouchRipple
																	disableRipple
																	disableFocusRipple
																	onClick={() => {
																		combatDialogHandler(t('admin_panel.combat_users.claim_user_list'));
																		fetchClaimUserList(data?.user_id);
																		setModel('claimlist');
																		setSingleItemData(data);
																	}}
																	className="list-btn"
																>
																	<ListView height={15} width={20} />
																</IconButton>
																{data?.type === 'user_other' && (
																	<>
																		{data?.locked === 'yes' ? (
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => lockUnlockUser(data, 'no')}
																				className="lu-btn"
																			>
																				<UnlockIcon height={15} width={20} />
																			</IconButton>
																		) : (
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => lockUnlockUser(data, 'yes')}
																				className="lu-btn"
																			>
																				<LockIcon height={15} width={20} />
																			</IconButton>
																		)}
																	</>
																)}
															</Box>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
								<CustomPagination
									paginationArray={users}
									currentPage={currentPage}
									list={limit}
									handlePaginationButtonClick={handlePaginationButtonClick}
									handlePaginationClick={handlePaginationClick}
									totalRecord={totalRecord}
								/>
							</>
						)}
					</Box>
				</Container>
			</Box>
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					model === 'verify' ? (
						<VerifyUser loader={loader} handleClick={userVerify} handleClose={closeDialog} heading={heading} />
					) : model === 'delete' ? (
						<DeleteUser loader={loader} handleClose={closeDialog} handleClick={deleteUser} />
					) : model === 'claimlist' ? (
						<ClaimUserList claimedUserList={claimedUserList} userAction={userAction} />
					) : model === 'editEmail' ? (
						<EditEmail
							token={token}
							singleItemData={singleItemData}
							handleClose={closeDialog}
							fetchUsersData={fetchUsersData}
							currentPage={currentPage}
						/>
					) : (
						<DeleteUser loader={loader} handleClose={closeDialog} handleClick={deleteUser} />
					)
				}
			/>
		</>
	);
};

export default AdminUsers;
