import { memo, useState, useEffect, useContext } from 'react';
import * as rdd from 'react-device-detect';
import isEqual from 'src/utils/common/isEqual';
import { Avatar, Box, Button, IconButton, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ViewPortContext } from 'src/App';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/cameraShadow.svg';
import MobileDrawer from 'src/views/component/UI/mobileDrawer';
import ProfileTypeTabSlider from './ProfileType';
import UploadContent from '../MobileBlock/UploadContent';
import ProfileCompletion from './ProfileCompletion';
import './left.scss';
// eslint-disable-next-line import/order
import Viewer from 'react-viewer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	input: {
		display: 'none',
	},
});

const LeftProfile = ({
	isView,
	loader,
	user,
	token,
	getMyProfile,
	fetchUserBadges,
	fetchUserDisciplines,
	disciplineLoader,
	disciplines,
	fetchSkills,
	fetchProfileTypeData,
	fetchMetaFightMethod,
	fetchDisciplineTypesData,
	fetchEndorsementData,
	handleEndorsementPagination,
	handleEndorsementPaginationClick,
	endorsPage,
	tabHandlerForEndorsement,
	endorseTotalCount,
	fetchRecentActivity,
	skillsData,
	fightMethod,
	endorseloader,
	endorsementData,
	endorseLimit,
	disciplineData,
	recentActivityData,
	currentPage,
	limit,
	handlePaginationButtonClick,
	handlePaginationClick,
	totalRecord,
	userType,
	CPage,
	activityLimit,
	// paginationButtonClick,
	// paginationClick,
	activityTotal,
	hasMore,
	setRecentActivity,
}) => {
	const classes = useStyles();
	let cover = user?.UserData?.item_cover_path ? user?.UserData?.item_cover_path : '';
	const viewPortContext = useContext(ViewPortContext);
	const { t } = useTranslation();
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	const [coverImg, setCoverImg] = useState('');
	const [visible, setVisible] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [isprofileCompletion, setProfileCompletion] = useState(false);

	const toggleDrawer = (open) => {
		setOpenDrawer(open);
	};
	const profileCompletionpage = () => {
		isprofileCompletion ? setProfileCompletion(false) : setProfileCompletion(true);
	};
	const coverHandler = async (e) => {
		e.persist();
		let formData = new FormData();

		if (e.target.files.length > 0) {
			// MEDIA FILE TYPE
			let type = e.target.files[0].type.includes('image')
				? 'image'
				: e.target.files[0].type.includes('video')
				? 'video'
				: e.target.files[0].type.includes('text')
				? 'text'
				: e.target.files[0].type.includes('audio')
				? 'audio'
				: e.target.files[0].type.includes('blob')
				? 'blob'
				: '';

			formData.append('location', e.target.files[0]);
			formData.append('type', type);

			try {
				const { status, data } = await axiosInstance.post(URLS.mediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					let id = data?.data?.itemId || data?.data?.id;
					let requestData = { item_cover_id: id };

					try {
						let { status } = await axiosInstance.put(URLS.updateProfile, requestData, {
							headers: {
								Authorization: `Bearer ${token}`,
								'Content-Type': 'application/json',
							},
						});

						if (status === 200) {
							getMyProfile();
							setApiMessage('success', t('myprofile.profile_updated_success'));
						}
					} catch (error) {
						setApiMessage('error', error?.response?.data?.message);
					}
				}
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		// if (cover !== '') {
		setCoverImg(cover);
		// }
		// eslinr-disable-next-line
	}, [cover]);

	// MAIN CONTENT
	const domContent = (
		<>
			{/* COVER PHOTO */}
			<Box className="cover-photo-container">
				{loader ? (
					<Skeleton variant="rect" className="preview-cover" />
				) : isView ? (
					<>
						<Avatar
							src={coverImg}
							variant="square"
							className="preview-cover"
							onClick={() => {
								setVisible(true);
							}}
						/>
						{coverImg !== '' && (
							<Viewer
								visible={visible}
								onClose={() => {
									setVisible(false);
								}}
								images={[
									{
										src: coverImg,
										title: 'cover image',
									},
								]}
								downloadable
								noFooter={true}
								drag={false}
								zoomable={false}
								rotatable={false}
								scalable={false}
								disableMouseZoom={true}
							/>
						)}
					</>
				) : coverImg !== '' ? (
					// JUDGE WINDOW SIZE, MOBILE SIZE, CLICK COVER IAMGE WILL OPEN A DRAWER
					// ON THE DESKTOP SIZE, CLICK COVER IMAGE WILL UPLOAD FILE
					<>
						{windowWidth > breakPoint ? (
							<>
								<input
									accept="image/*"
									className={classes.input}
									id="cover-button-file"
									type="file"
									onChange={coverHandler}
								/>
								<label htmlFor={'cover-button-file'}>
									<IconButton variant="contained" component="span">
										<Avatar src={coverImg} variant="square" className="preview-cover ddd" />
										<CameraIcon className="camera-icon" />
									</IconButton>
								</label>
							</>
						) : (
							<>
								{rdd.isIOS && (
									<input
										accept="image/*"
										className={classes.input}
										id="cover-button-file"
										type="file"
										onChange={coverHandler}
									/>
								)}
								<label htmlFor={rdd.isIOS && 'cover-button-file'}>
									<IconButton
										variant="contained"
										component="span"
										onClick={
											rdd.isIOS
												? () => {}
												: () => {
														toggleDrawer(true);
												  }
										}
									>
										<Avatar src={coverImg} variant="square" className="preview-cover ddd" />
										<CameraIcon className="camera-icon" />
									</IconButton>
								</label>
							</>
						)}
					</>
				) : (
					<>
						{windowWidth > breakPoint ? (
							<>
								<input
									accept="image/*"
									className={classes.input}
									id="cover-button-file"
									type="file"
									onChange={coverHandler}
								/>
								<label htmlFor={'cover-button-file'}>
									<Button variant="contained" endIcon={<CameraIcon />} className="upload-button" component="span">
										{t('myprofile.cover_photo')}
									</Button>
								</label>
							</>
						) : (
							<>
								{rdd.isIOS && (
									<input
										accept="image/*"
										className={classes.input}
										id="cover-button-file"
										type="file"
										onChange={coverHandler}
									/>
								)}
								<label htmlFor={rdd.isIOS && 'cover-button-file'}>
									<Button
										variant="contained"
										endIcon={<CameraIcon />}
										className="upload-button"
										component="span"
										onClick={
											rdd.isIOS
												? () => {}
												: () => {
														toggleDrawer(true);
												  }
										}
									>
										{t('myprofile.cover_photo')}
									</Button>
								</label>
							</>
						)}
					</>
				)}
			</Box>

			{/* PROFILE TYPE SLIDER  */}
			{isprofileCompletion ? (
				<ProfileCompletion
					profileCompletionpage={profileCompletionpage}
					ProfileCompletionData={user?.Profile_Completion}
				/>
			) : (
				<ProfileTypeTabSlider
					isView={isView}
					user={user}
					token={token}
					loader={loader}
					getMyProfile={getMyProfile}
					fetchUserBadges={fetchUserBadges}
					fetchUserDisciplines={fetchUserDisciplines}
					disciplineLoader={disciplineLoader}
					disciplines={disciplines}
					fetchProfileTypeData={fetchProfileTypeData}
					fetchSkills={fetchSkills}
					fetchMetaFightMethod={fetchMetaFightMethod}
					fetchDisciplineTypesData={fetchDisciplineTypesData}
					fetchEndorsementData={fetchEndorsementData}
					endorsPage={endorsPage}
					tabHandlerForEndorsement={tabHandlerForEndorsement}
					handleEndorsementPagination={handleEndorsementPagination}
					handleEndorsementPaginationClick={handleEndorsementPaginationClick}
					endorseTotalCount={endorseTotalCount}
					endorseLimit={endorseLimit}
					fetchRecentActivity={fetchRecentActivity}
					skillsData={skillsData}
					fightMethod={fightMethod}
					endorseloader={endorseloader}
					endorsementData={endorsementData}
					disciplineData={disciplineData}
					recentActivityData={recentActivityData}
					setRecentActivity={setRecentActivity}
					currentPage={currentPage}
					limit={limit}
					handlePaginationButtonClick={handlePaginationButtonClick}
					handlePaginationClick={handlePaginationClick}
					totalRecord={totalRecord}
					userType={userType}
					CPage={CPage}
					activityLimit={activityLimit}
					// paginationButtonClick={paginationButtonClick}
					// paginationClick={paginationClick}
					activityTotal={activityTotal}
					hasMore={hasMore}
					profileCompletionpage={profileCompletionpage}
					profileCompleted={user?.Profile_Completion?.total_profile_completion}
				/>
			)}

			{/* MOBILE SIZE DRAWER */}
		</>
	);

	return (
		<>
			{/* CHECK THE SCREEN SIZE */}
			{/* Need to remove the box container as each part needs to be reordered */}
			{windowWidth > breakPoint ? (
				<Box className="left-profile-container">{domContent}</Box>
			) : (
				<>
					{domContent}
					<MobileDrawer
						anchor={'bottom'}
						open={openDrawer}
						onClose={() => toggleDrawer(false)}
						className="take-upload-photo"
						title={t('myprofile.upload_profile_cover_image')}
						content={<UploadContent imgHandler={coverHandler} toggleDrawer={toggleDrawer} />}
					/>
				</>
			)}
		</>
	);
};

export default memo(LeftProfile, isEqual);
