import { useState } from 'react';
import { Box, Container, Typography, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import CategoryQuestion from '../CateoryQuestion';
import './category.scss';

const Category = () => {
	const [tab, setTab] = useState('1');
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};
	const tab_list = [
		{ id: 1, name: 'category 1' },
		{ id: 2, name: 'category 2' },
		{ id: 3, name: 'category 3' },
		{ id: 4, name: 'category 4' },
	];

	return (
		<>
			<TabContext value={String(tab)}>
				<Box className="question-header">
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler} aria-label="action tabs">
						{tab_list.map((obj, index) => {
							return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
						})}
					</TabList>
				</Box>

				<TabPanel value="1" className="cat_tabpanel">
					<CategoryQuestion />
				</TabPanel>
				<TabPanel value="2" className="cat_tabpanel">
					<CategoryQuestion />
				</TabPanel>
				<TabPanel value="3" className="cat_tabpanel">
					<CategoryQuestion />
				</TabPanel>
				<TabPanel value="4" className="cat_tabpanel">
					<CategoryQuestion />
				</TabPanel>
			</TabContext>
		</>
	);
};
export default Category;
