import { useContext, useEffect, useState } from 'react';
import { Box, Container, Typography, InputAdornment, withStyles, TextField, Button } from '@material-ui/core';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { useTranslation } from 'react-i18next';
import { useSelector, connect } from 'react-redux';
import { ViewPortContext } from 'src/App';
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/community/filter.svg';
// import ComingSoon from 'src/views/component/UI/comingSoon';
import './community.scss';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
import { getCommunityList } from 'src/redux-state/actions/actions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { parse } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
import BrowseCommunity from './Browse';
import CreateCommunityBox from './createCommunity';

const SearchTextField = withStyles({
	root: {
		backgroundColor: '#202020',
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

const Communities = ({ getCommunityList }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const query = parse(location.search);
	const auth = useSelector((state) => state?.Combat?.authData) || fetchFromStorage('authData');
	const isAdmin = !!auth?.isAdmin;
	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	var COMM_FILTER = fetchFromStorage('communityfilter');
	const { t } = useTranslation();
	const viewPortContext = useContext(ViewPortContext);
	const width = viewPortContext?.width;
	const breakpoint = viewPortContext?.breakPoint;

	const [searchVal, setSearchVal] = useState('');
	const [ordderByVal, setOrdderByVal] = useState('best');
	const [loader, setloader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	const handleChange = (e) => {
		setSearchVal(e.target.value);
	};
	const handleSearchBtnMobile = () => {
		var obj = {
			...COMM_FILTER,
			search: searchVal,
		};

		saveToStorage('communityfilter', obj);
		fetchCommunityList(query, '', 1);
	};
	const communityList = useSelector((state) => state?.Combat?.communityList);
	const fetchCommunityList = async (QS, order, pageVal) => {
		var queryString = QS !== undefined && QS !== '' ? QS : query;
		var OB = order !== undefined && order !== '' ? order : ordderByVal;
		// setloader(true)
		var pageNumber = pageVal !== undefined && pageVal !== '' ? pageVal : currentPage;

		try {
			let { status, data } = await axiosInstance.get(
				URLS.getCommunities,
				{
					params: {
						order_by: OB,
						...(searchVal !== '' && { name: searchVal }),
						badges: queryString?.badges,
						categories: queryString?.categories,
						disciplines: queryString?.disciplines,
						profile_types: queryString?.profile_types,
						membership_type: queryString?.membership_type,
						// page: currentPage,
						page: pageNumber,
						limit: 5,
					},
				},
				headers,
			);

			if (status === 200) {
				var hasMore = data?.communitiesData?.length < 5 ? false : true;

				pageVal !== undefined && pageVal !== '' ? setCurrentPage(2) : setCurrentPage(currentPage + 1);
				var COMMUNITY_LISTING = pageVal !== undefined && pageVal !== '' ? [] : communityList;
				var mergedArray = currentPage === 1 ? data?.communitiesData : COMMUNITY_LISTING.concat(data?.communitiesData);

				// getCommunityList(data?.communitiesData);
				getCommunityList(mergedArray);
				setloader(false);
				setHasMore(hasMore);
				// var mergedArray = allCommunityList.concat(data?.communitiesData)
				// setCommunityList(mergedArray)
			}
		} catch (error) {
			setloader(false);
		}
	};
	const setTabValue = (val) => {
		setOrdderByVal(val);
	};

	const communityAction = async (data, status) => {
		var requestParams = {
			user_id: auth?.user_id,
			community_id: data?.id,
			status: status,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.joinCommunity, requestParams);

			if (status === 200) {
				setApiMessage('success', data?.message);
				fetchCommunityList();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		COMM_FILTER?.QUERY_STRING ? navigate(`/communities?${COMM_FILTER?.QUERY_STRING}`) : navigate(`/communities`);
		fetchCommunityList(COMM_FILTER?.queryParams, COMM_FILTER?.orderBy);
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		COMM_FILTER && COMM_FILTER?.search && setSearchVal(COMM_FILTER?.search);
	}, [COMM_FILTER?.search]);

	// const [loader, setloader] = useState(false);
	// const [isView, setIsView] = useState(true);
	return (
		<Box className="community-page">
			<Container maxWidth="lg">
				<Box className="combat-container">
					<Box className="community-title-box">
						<Typography variant="h1" align="center">
							{t('community.title')}
						</Typography>
					</Box>
					{/* CREATE COMMUNITY BOX */}
					{/* MVP-HIDE: CM-77 */}
					{isAdmin ? <CreateCommunityBox token={token} user={auth} fetchCommunityList={fetchCommunityList} /> : null}
					{/*SEARCH COMMUNITY */}
					{width > breakpoint ? (
						<>
							<Box className="community-search-box">
								<Box className="search-field-box">
									<SearchTextField
										onChange={handleChange}
										fullWidth
										value={searchVal}
										placeholder={t('community.search_community')}
										size="small"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
									<Button type="submit" className="search-btn" onClick={() => fetchCommunityList(query, '', 1)}>
										{t('search_btn')}
									</Button>
								</Box>
							</Box>
						</>
					) : (
						<Box className="community-search-box">
							<Box className="search-field-box">
								<Box className="search-filter-sec" onClick={() => navigate('/communities/mobile-view')}>
									<FilterIcon />
								</Box>
								<SearchTextField
									onChange={handleChange}
									fullWidth
									value={searchVal}
									placeholder={t('community.search_community')}
									size="small"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
								<Button
									type="submit"
									className="search-btn"
									onClick={() => {
										handleSearchBtnMobile();
									}}
								>
									{t('search_btn')}
								</Button>
							</Box>
						</Box>
					)}

					{/* BROWSE */}
					<BrowseCommunity
						token={token}
						user={auth}
						fetchCommunityList={fetchCommunityList}
						communityAction={communityAction}
						setTabValue={setTabValue}
						loader={loader}
						hasMore={hasMore}
						width={width}
						breakpoint={breakpoint}
					/>

					{/* <ComingSoon /> */}
				</Box>
			</Container>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCommunityList: (data) => dispatch(getCommunityList(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Communities);
