import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import './badge.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import index from 'src/views/component/UI/UserCardSkeleton';

const CommunityBadges = () => {
	const { t } = useTranslation();

	const communityDetails = useSelector((state) => state.Combat.communityDetails);

	return (
		<Box className="community-badge-box">
			<Typography className="badge-box-title">{t('community.community_badges')}</Typography>

			<Box className="badges-container">
				<Grid container spacing={3}>
					{communityDetails && communityDetails?.community_badge.length > 0 ? (
						<>
							{communityDetails?.community_badge.map((item, i) => {
								return (
									<Grid key={i} item xs={3} sm={3} md={2} lg={2}>
										<Box className="badge-block">
											<Avatar className="badge-icon" variant="square" src={item?.Badge?.item_icon_data} />
											<Typography className="verified-badge-text">{item?.Badge?.name}</Typography>
										</Box>
									</Grid>
								);
							})}
						</>
					) : (
						<Typography className="no-community-text">{t('community.community_has_no_badges')}</Typography>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

export default CommunityBadges;
