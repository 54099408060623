import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CameraIcon } from 'src/assets/images/icons/uploadPhoto/camera.svg';
import { ReactComponent as GalleryIcon } from 'src/assets/images/icons/uploadPhoto/photoGallery.svg';
import { Box, Typography } from '@material-ui/core';
import './uploadContent.scss';

const UploadContent = ({ imgHandler, toggleDrawer, actionWithPost }) => {
	const { t } = useTranslation();

	return (
		<>
			{/* TAKE A PHOTO */}
			<>
				<input
					accept="image/*"
					style={{ display: 'none' }}
					id="take-photo-button"
					type="file"
					capture="camera"
					onChange={(e) => {
						imgHandler(e);
						toggleDrawer(false);
					}}
				/>
				<label htmlFor="take-photo-button">
					<Box className="line-container">
						<CameraIcon />
						<Typography className="upload-photos-text">{t('myprofile.take_photo')}</Typography>
					</Box>
				</label>
			</>
			{/* UPLOAD PHOTOS FROM PHONE */}
			<>
				<input
					accept="image/*"
					style={{ display: 'none' }}
					id="upload-from-photos-button"
					type="file"
					onChange={(e) => {
						imgHandler(e);
						toggleDrawer(false);
					}}
					multiple={actionWithPost}
				/>
				<label htmlFor="upload-from-photos-button">
					<Box className="line-container">
						<GalleryIcon />
						<Typography className="upload-photos-text">{t('myprofile.upload_from_photos')}</Typography>
					</Box>
				</label>
			</>
			{actionWithPost && (
				<>
					<input
						accept="video/*"
						style={{ display: 'none' }}
						id="upload-from-photos-button1"
						type="file"
						onChange={(e) => {
							imgHandler(e);
							toggleDrawer(false);
						}}
						multiple={actionWithPost}
					/>
					<label htmlFor="upload-from-photos-button1">
						<Box className="line-container">
							<GalleryIcon />
							<Typography className="upload-photos-text">{t('community.upload_from_videos')}</Typography>
						</Box>
					</label>
				</>
			)}
		</>
	);
};

export default UploadContent;
