import { useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import { ReactComponent as MainIcon } from 'src/assets/images/icons/main.svg';
import './type.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useTranslation } from 'react-i18next';

const ProfileType = ({ typeData, token, getMyProfile, closeDialog }) => {
	const { t } = useTranslation();

	const [checked, setChecked] = useState(typeData?.profile_types || []);
	const [main, setMain] = useState([]);

	const typeList = [
		{ id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			main.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const MainHandler = (value) => {
		const currentMain = main.indexOf(value);
		const newMain = [...main];

		if (currentMain === -1) {
			newMain.push(value);
		} else {
			newMain.splice(currentMain, 1);
		}

		setMain(newMain);
	};

	const submitHandler = async () => {
		let types = checked.reduce((a, b) => a + b);

		try {
			const { status } = await axiosInstance.put(
				URLS.updateProfile,
				{
					type: types,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			);

			if (status === 200) {
				setApiMessage('success', t('myprofile.profile_updated_success'));
				getMyProfile();
				closeDialog();
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	return (
		<Box className="profile-type-form">
			<List>
				{typeList.map((type, index) => {
					const labelId = `checkbox-list-label-${type?.id}`;

					return (
						<ListItem key={index} role={undefined} dense button onClick={handleToggle(type?.id)}>
							<ListItemIcon>
								<Checkbox
									icon={<CheckBoxIcon />}
									checkedIcon={<CheckedBoxIcon />}
									edge="start"
									checked={checked.indexOf(type?.id) !== -1}
									tabIndex={-1}
									disableRipple
									disableTouchRipple
									disableFocusRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={
									<Box className="type-text-block">
										<Box className="label-name">{type?.name}</Box>
										{checked.indexOf(type?.id) !== -1 ? (
											<Box className="type-pending">{t('profile_type.pending')}</Box>
										) : null}
									</Box>
								}
							/>
							{checked.indexOf(type?.id) !== -1 ? (
								<ListItemSecondaryAction>
									{main.indexOf(type?.id) !== -1 ? (
										<Button
											edge="end"
											className="make-this-main-btn"
											disableRipple
											disableFocusRipple
											onClick={() => {
												MainHandler(type?.id);
											}}
											startIcon={<MainIcon />}
										>
											{t('profile_type.main')}
										</Button>
									) : (
										<Button
											edge="end"
											className="make-this-main-btn"
											disableRipple
											disableFocusRipple
											onClick={() => {
												MainHandler(type?.id);
											}}
											style={{ textDecoration: 'underline' }}
										>
											{t('profile_type.makeMain')}
										</Button>
									)}
								</ListItemSecondaryAction>
							) : null}
						</ListItem>
					);
				})}
			</List>

			<Box className="dialog-submit-button">
				<Button variant="contained" onClick={submitHandler} className="dialog-submit">
					{t('save')}
				</Button>
			</Box>
		</Box>
	);
};

export default ProfileType;
