import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Drawer, Hidden, IconButton, List, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { clearStorage } from 'src/helpers/context';
import NavItem from './NavItem';
import items from '../menu';

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: '100%',
	},
}));

const NavBar = ({ onMobileClose, openMobile, auth }) => {
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column" className="menu-bar">
			<Box p={2}>
				<Box className="close-button">
					<IconButton onClick={onMobileClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<List>
					{items.map((item) => (
						<NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
					))}
					<NavItem href="/search" title="Search" />
					<NavItem href="/notification" title="Notification" />
					<NavItem href={`/user/${auth?.user_id}`} title="My Profile" />
					{auth?.isAdmin && <NavItem href={'/admin'} title="Dashboard" />}
					<Button
						fullWidth
						className="res-logout-app-btn"
						onClick={() => {
							clearStorage();
							window.location = '/';
						}}
					>
						Logout
					</Button>
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<Hidden lgUp>
			<Drawer
				anchor="left"
				classes={{ paper: classes.mobileDrawer }}
				onClose={onMobileClose}
				open={openMobile}
				variant="temporary"
			>
				{content}
			</Drawer>
		</Hidden>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
