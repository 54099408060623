import { Box, Grid } from '@material-ui/core';
import LeftSide from './Left-side';
import RightSide from './Right-side';

const FightRecordSec = () => {
	return (
		<Box className="fight-record-sec">
			<Grid container>
				<Grid xs={3} className="fight-record-left-sec">
					<LeftSide />
				</Grid>
				<Grid item xs={9} className="fight-record-right-sec">
					<RightSide />
				</Grid>
			</Grid>
		</Box>
	);
};
export default FightRecordSec;
