import { memo, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Box, IconButton, Tab, Typography } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Skeleton from '@material-ui/lab/Skeleton';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import CombatDialog from 'src/views/component/UI/dialogBox';
import isEqual from 'src/utils/common/isEqual';
import { ReactComponent as BaddIcon } from 'src/assets/images/icons/addBlue.svg';
import { ReactComponent as BpencilIcon } from 'src/assets/images/icons/pencilBlue.svg';
import EditProfile from 'src/views/component/UI/EditProfile';
import { ViewPortContext } from 'src/App';
import AboutProfileType from './AboutProfileType';
import Discipline from './Discipline';
import ProfileType from './ProfileType';
import RecentActivity from './RecentActivity';
import SkillsAndorsements from './SkillsAndorsements';
import './type.scss';
import { useTranslation } from 'react-i18next';

const getProfileTypes = (t) => [
	{ id: 0, name: t('profile_type.enthusiast') },
	{ id: 1, name: t('profile_type.athelete') },
	{ id: 2, name: t('profile_type.coach_instructor') },
	{ id: 4, name: t('profile_type.manager') },
	{ id: 8, name: t('profile_type.promoter') },
	{ id: 16, name: t('profile_type.sponsor') },
	{ id: 32, name: t('profile_type.journalist') },
	{ id: 64, name: t('profile_type.official') },
	// { id: 128, name: t('profile_type.gym') },
	// { id: 256, name: t('profile_type.promotion') },
	// { id: 512, name: t('profile_type.product_service') }
];

const ProfileTypeSlider = ({
	isView,
	loader,
	user,
	token,
	getMyProfile,
	fetchUserBadges,
	fetchUserDisciplines,
	disciplineLoader,
	disciplines,
	fetchSkills,
	fetchProfileTypeData,
	fetchMetaFightMethod,
	fetchDisciplineTypesData,
	fetchEndorsementData,
	endorsPage,
	handleEndorsementPagination,
	handleEndorsementPaginationClick,
	tabHandlerForEndorsement,
	endorseTotalCount,
	endorseLimit,
	fetchRecentActivity,
	skillsData,
	fightMethod,
	endorseloader,
	endorsementData,
	disciplineData,
	recentActivityData,
	setRecentActivity,
	currentPage,
	limit,
	handlePaginationButtonClick,
	handlePaginationClick,
	totalRecord,
	userType,
	CPage,
	activityLimit,
	activityTotal,
	profileCompletionpage,
	hasMore,
	profileCompleted,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const userData = user?.data || null;

	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;
	const auth = useSelector((state) => state.Combat.authData);
	const loginUser = auth ? auth : fetchFromStorage('authData');

	const PROFILE_TYPES = useMemo(() => getProfileTypes(t), [t]);

	const [tab, setTab] = useState();
	const [list, setList] = useState([]);
	const [title, setTitle] = useState('');
	const [model, setModel] = useState('');
	const [open, setOpen] = useState(false);
	const [rawTab, setRawTab] = useState([]);
	const [availableAbouts, setAvailableAbouts] = useState([]);
	const [isModalLoading, setIsModalLoading] = useState(false);

	// SET WINDOW SIZE TO COMPONENT STATE

	const tabChangeHandler = useCallback((event, newValue) => {
		setTab(newValue);
	}, []);

	// DIALOG BOX HANDLERS
	const combatDialogHandler = useCallback((title) => {
		setOpen(true);
		setTitle(title);
	}, []);

	const closeDialog = useCallback(() => {
		setOpen(false);
	}, []);

	const onDialogTransitionEnd = useCallback(() => {
		setIsModalLoading(false);
	}, []);

	// COUNT AGE
	const getAge = useCallback((d1) => {
		const d2 = new Date();
		const diff = d2.getTime() - d1.getTime();

		return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	}, []);

	// SET PROFILE TYPE SLIDER TAB
	useEffect(() => {
		const hasUserAboutData = user?.UserData?.UserAboutData?.length > 0;

		if (hasUserAboutData) {
			let addedData =
				user?.UserData?.UserAboutData?.length > 0
					? user?.UserData?.UserAboutData?.length === 1 &&
					  user?.UserData?.UserAboutData[0]?.userAvailableAboutTypes.length === 0 &&
					  JSON.stringify(user?.UserData?.UserAboutData[0]?.userAboutTypes) === JSON.stringify([0])
						? user?.UserData?.UserAboutData?.map((main) => main.userAboutTypes.map((s) => s))
						: user?.UserData?.UserAboutData?.map((main) => main.userAboutTypes.map((s) => s)).filter(
								(rr) => JSON.stringify(rr) !== JSON.stringify([0]),
						  ) // FILTER TO HIDE FAN TYPE
					: [];

			let availableData = [].concat.apply([], addedData);

			setAvailableAbouts(availableData);
			let pendingData = user?.UserData?.UserAboutData[0]?.userAvailableAboutTypes;
			let allshowData = [...addedData];

			if (allshowData.length > 0) {
				pendingData.filter((x) => x !== 0).map((pd) => allshowData.push([pd]));
			} else {
				pendingData.map((pd) => allshowData.push([pd]));
			}

			setList(allshowData);
			setTab(JSON.stringify(allshowData[0]));
			setRawTab(allshowData[0]);
		} else {
			let val =
				userData?.profile_types?.length > 0
					? userData?.profile_types
							?.filter((x) => x !== 0)
							.map((obj) => {
								return [obj];
							})
					: [[0]];

			setList(val);
			setTab(JSON.stringify(val[0]));
			setRawTab(val[0]);
		}
	}, [userData?.id]);

	// ABOUT PROFILE TYPES
	let abouttext = user?.UserData?.UserAboutData.filter(
		(data) => JSON.stringify(data?.userAboutTypes?.map((a) => a)) === tab,
	)[0]?.Post?.PostLists?.Item?.data;

	// User metadata
	const userMetadata = useMemo(
		() =>
			Array.isArray(user?.UserMetaData)
				? user.UserMetaData.reduce((acc, item) => ({
						...acc,
						[item?.type]: item?.data,
				  }))
				: {},
		[user?.UserMetaData],
	);

	const username = userMetadata?.other ?? 'N/A';
	const weight_kg = userMetadata?.weight_low_kg;
	const weight_lb = userMetadata?.weight_low_lb;
	const weight_kg_high = userMetadata?.weight_high_kg;
	const weight_lb_high = userMetadata?.weight_high_lb;
	const weight_kg_low = userMetadata?.weight_low_kg;
	const weight_lb_low = userMetadata?.weight_low_lb;

	const measurment = weight_kg !== '' ? 'Kg' : weight_lb !== '' ? 'lb' : '';

	// WEIGHT CLASS
	const weight_class = `${
		weight_kg_low !== '' ? weight_kg_low : weight_lb_low !== '' ? weight_lb_low : ''
	}${measurment} -
  ${weight_kg_high !== '' ? weight_kg_high : weight_lb_high !== '' ? weight_lb_high : ''}${measurment}`;

	// FIGHTING LOCATION
	const location_fighting = user?.UserMetaData?.filter((data) => data?.type === 'location_fighting')[0]?.data || 'N/A';

	// ORGANIZATION NAME
	// HIDE-MVP: CM-79
	// const organisation_name =
	// 	user?.OrganizationData !== null ? (user?.OrganizationData?.name ? user?.OrganizationData?.name : 'N/A') : 'N/A';

	// DATE OF BIRTH
	const dob = userData?.dob || 'N/A';

	// COUNTRY
	const country = userData?.country || 'N/A';

	const stastics = user?.UserStatisticsDetails?.fightRecord;

	// FIGHT RECORD
	// let fightRecord = `${stastics?.win !== undefined ? stastics?.tbd : 'N/A'}-${stastics?.win !== undefined ? stastics?.win : 'N/A'
	//   }-${stastics?.loss !== undefined ? stastics?.loss : 'N/A'}-${stastics?.draw !== undefined ? stastics?.draw : 'N/A'
	//   }-${stastics?.disqualified !== undefined ? stastics?.disqualified : 'N/A'}-${stastics?.other !== undefined ? stastics?.other : 'N/A'
	//   }`;

	const fightRecord = `${stastics?.win !== undefined ? stastics?.win : 0}-${
		stastics?.loss !== undefined ? stastics?.loss : 0
	}-${stastics?.draw !== undefined ? stastics?.draw : 0} (${
		stastics?.NoContest !== undefined ? stastics?.NoContest : 0
	} NC)`;

	let aboutAdding =
		availableAbouts.filter((cc) => {
			return rawTab.indexOf(cc) !== -1;
		}).length === 0;
	let pendingIds = userData?.profile_types?.filter((x) => {
		return availableAbouts.indexOf(x) === -1;
	});

	const domContent = (
		<>
			<TabContext value={String(tab)}>
				<Box className="about-tab">
					<Box className="title-block">
						<Typography className="tab-block-title">
							{loader ? (
								<Skeleton variant="text" width={200} height={28} />
							) : (
								`${t('myprofile.about_this')} ${list
									?.filter((data) => JSON.stringify(data) === tab)[0]
									?.map((nn) => PROFILE_TYPES.filter((s) => s?.id === Number(nn))[0]?.name)
									.join(' & ')}`
							)}
						</Typography>
						<Box>
							{loader ? null : isView ? null : (
								<Box display={'flex'}>
									<IconButton
										onClick={() => {
											combatDialogHandler(t('profile_type.title'));
											setModel('profileType');
										}}
									>
										<BaddIcon />
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>

					{/* PROFILE TYPES TABLIST */}
					{/* added sx for for HIDE-MVP: CM-131*/}
					<Box className="slider-container" sx={{ mb: 3 }}>
						<Box display="flex" justifyContent="space-between">
							{loader ? (
								<Box display="flex">
									<Skeleton variant="rect" className="skel-prof-type-tab" />
									<Skeleton variant="rect" className="skel-prof-type-tab" />
									<Skeleton variant="rect" className="skel-prof-type-tab" />
								</Box>
							) : (
								<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
									{list?.map((obj, index) => {
										return (
											<Tab
												label={obj
													.map((tname) => PROFILE_TYPES.filter((x) => x?.id === Number(tname))[0]?.name)
													.join(' & ')}
												key={index}
												value={JSON.stringify(obj)}
												onClick={() => {
													setRawTab(obj);
												}}
											/>
										);
									})}
								</TabList>
							)}
						</Box>
					</Box>
					{/* HIDE-MVP: CM-131 */}
					{/*{!isView && !loader ? (*/}
					{/*	<Box className="edit-about-btn">*/}
					{/*		<IconButton*/}
					{/*			onClick={() => {*/}
					{/*				combatDialogHandler(`About`);*/}
					{/*				setModel('athelete');*/}
					{/*			}}*/}
					{/*		>*/}
					{/*			<BpencilIcon />*/}
					{/*		</IconButton>*/}
					{/*	</Box>*/}
					{/*) : null}*/}
					{loader ? (
						<Box mt={1}>
							<Skeleton variant="text" />
							<Skeleton variant="text" />
							<Skeleton variant="text" />
						</Box>
					) : abouttext ? (
						<>
							<Typography className="profile-type-about-txt">
								{abouttext && // USER PROFILE TYPE ABOUT TEXT
									renderHTML(
										anchorme({
											input: abouttext.toString().replace(/\n/g, '<br/>'),
											options: {
												attributes: {
													target: '_blank',
													class: 'detected',
												},
											},
										}),
									)}
							</Typography>
						</>
					) : (
						<Typography className="no-info-txt">
							{isView ? t('myprofile.user_has_no_info') : t('myprofile.you_have_no_info')}
						</Typography>
					)}
				</Box>
			</TabContext>
			{/* DISCIPLINE COMPONENT */}
			{/* HIDE-MVP: CM-72 */}
			{/*<Discipline*/}
			{/*  onDialogTransitionEnd={onDialogTransitionEnd}*/}
			{/*  isModalLoading={isModalLoading}*/}
			{/*  setIsModalLoading={setIsModalLoading}*/}
			{/*  isView={isView}*/}
			{/*  token={token}*/}
			{/*  user={userData}*/}
			{/*  loader={loader}*/}
			{/*  getMyProfile={getMyProfile}*/}
			{/*  fetchUserDisciplines={fetchUserDisciplines}*/}
			{/*  disciplineLoader={disciplineLoader}*/}
			{/*  disciplines={disciplines}*/}
			{/*  currentPage={currentPage}*/}
			{/*  limit={limit}*/}
			{/*  handlePaginationButtonClick={handlePaginationButtonClick}*/}
			{/*  handlePaginationClick={handlePaginationClick}*/}
			{/*  totalRecord={totalRecord}*/}
			{/*/>*/}

			{/* SKILLS AND ENDORSEMENTS */}
			{/* HIDE-MVP: CM-72 */}
			{/*<SkillsAndorsements*/}
			{/*  onDialogTransitionEnd={onDialogTransitionEnd}*/}
			{/*  isModalLoading={isModalLoading}*/}
			{/*  setIsModalLoading={setIsModalLoading}*/}
			{/*  getMyProfile={getMyProfile}*/}
			{/*  isView={isView}*/}
			{/*  token={token}*/}
			{/*  fetchSkills={fetchSkills}*/}
			{/*  fetchProfileTypeData={fetchProfileTypeData}*/}
			{/*  fetchMetaFightMethod={fetchMetaFightMethod}*/}
			{/*  fetchDisciplineTypesData={fetchDisciplineTypesData}*/}
			{/*  fetchEndorsementData={fetchEndorsementData}*/}
			{/*  endorsPage={endorsPage}*/}
			{/*  endorseTotalCount={endorseTotalCount}*/}
			{/*  endorseLimit={endorseLimit}*/}
			{/*  tabHandlerForEndorsement={tabHandlerForEndorsement}*/}
			{/*  handleEndorsementPagination={handleEndorsementPagination}*/}
			{/*  handleEndorsementPaginationClick={handleEndorsementPaginationClick}*/}
			{/*  skillsData={skillsData}*/}
			{/*  fightMethod={fightMethod}*/}
			{/*  endorseloader={endorseloader}*/}
			{/*  endorsementData={endorsementData}*/}
			{/*  disciplineData={disciplineData}*/}
			{/*  recentActivityData={recentActivityData}*/}
			{/*  userType={userType}*/}
			{/*/>*/}

			{/* RECENT ACTIVITY COMPONENT */}
			<RecentActivity
				isView={isView}
				token={token}
				user={user}
				fetchRecentActivity={fetchRecentActivity}
				setRecentActivity={setRecentActivity}
				recentActivityData={recentActivityData}
				currentPage={CPage}
				limit={activityLimit}
				// handlePaginationButtonClick={paginationButtonClick}
				// handlePaginationClick={paginationClick}
				totalRecord={activityTotal}
				hasMore={hasMore}
			/>
		</>
	);

	return (
		<>
			{/* PROFILE DETAILS */}
			<Box className="left-profile-details-box">
				<Box display="flex" justifyContent="space-between">
					<Box display="flex">
						<Typography className="profile-left-title">{t('myprofile.profile')}</Typography>
						{/* HIDE-MVP: CM-72 */}
						{/*{user?.UserData?.user_id === loginUser?.user_id && (*/}
						{/*	<Typography className="profile-completion-text" onClick={() => profileCompletionpage()}>*/}
						{/*		{profileCompleted}% complete*/}
						{/*	</Typography>*/}
						{/*)}*/}
					</Box>

					{loader ? null : isView ? null : (
						<IconButton
							onClick={() => {
								combatDialogHandler(t('myprofile.edit_profile'));
								setModel('profile');
							}}
						>
							<BpencilIcon />
						</IconButton>
					)}
				</Box>

				<Box className="profile-details-data">
					{/* USERNAME */}
					{username !== 'N/A' && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.username') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? <Skeleton variant="text" width={'100px'} /> : username}
							</Typography>
						</Box>
					)}
					{/* DATE OF BIRTH */}
					{userData?.dob !== null && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.dob') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? (
									<Skeleton variant="text" width={'100px'} />
								) : (
									<>{userData?.dob !== null ? moment.utc(dob).format('MM/DD/YYYY') : 'N/A'}</>
								)}
							</Typography>
						</Box>
					)}

					{/* AGE */}
					{userData?.dob !== null && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('personal_details.age') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? (
									<Skeleton variant="text" width={'100px'} />
								) : (
									<>{userData?.dob !== null ? dob && getAge(new Date(dob)) : 'N/A'}</>
								)}
							</Typography>
						</Box>
					)}

					{/* WEIGHT CLASSES */}
					{(weight_kg_low !== '' || weight_lb_low !== '' || weight_kg_high !== '' || weight_lb_high !== '') && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : 'Weight Range' + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? <Skeleton variant="text" width={'100px'} /> : weight_class}
							</Typography>
						</Box>
					)}

					{/* COUNTRY */}
					{country !== 'N/A' && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.country') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? <Skeleton variant="text" width={'100px'} /> : country}
							</Typography>
						</Box>
					)}

					{/* ORGANISATIONS */}
					{/* HIDE-MVP: CM-79 */}
					{/*{organisation_name !== 'N/A' && (*/}
					{/*	<Box display="flex">*/}
					{/*		<Typography className="profile-details-field">*/}
					{/*			{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.organsiationss') + ':'}*/}
					{/*		</Typography>*/}
					{/*		<Typography*/}
					{/*			className="profile-details-field-val"*/}
					{/*			onClick={() => navigate(`/organisation-details/${user?.OrganizationData?.id}`)}*/}
					{/*		>*/}
					{/*			{loader ? <Skeleton variant="text" width={'100px'} /> : organisation_name}*/}
					{/*		</Typography>*/}
					{/*	</Box>*/}
					{/*)}*/}

					{/* FIGHTING OUT OF */}
					{location_fighting !== 'N/A' && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.fighting_out_of') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? <Skeleton variant="text" width={'100px'} /> : location_fighting}
							</Typography>
						</Box>
					)}

					{/* FIGHT RECORDS */}
					{fightRecord !== '0-0-0 (0 NC)' && (
						<Box display="flex">
							<Typography className="profile-details-field">
								{loader ? <Skeleton variant="text" width={'100px'} /> : t('myprofile.fightRecord') + ':'}
							</Typography>
							<Typography className="profile-details-field-val">
								{loader ? <Skeleton variant="text" width={'100px'} /> : fightRecord}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>

			{/* PROFILE TYPES ADD-EDIT & ABOUT PROFILE TYPE */}
			{/* CHECK THE VIEWPORT SIZE*/}
			{/* Remove the Box container as each part need to be reordered */}
			{windowWidth > breakPoint ? <Box className="profile-type-slider">{domContent}</Box> : <>{domContent}</>}
			{/* ADD-EDIT FORM DIALOG */}
			<CombatDialog
				onDialogTransitionEnd={onDialogTransitionEnd}
				open={open}
				handleClose={closeDialog}
				title={title}
				className={'user-profile-dialog'}
				content={
					model === 'athelete' ? (
						<AboutProfileType
							isModalLoading={isModalLoading}
							setIsModalLoading={setIsModalLoading}
							id={rawTab}
							isCreating={aboutAdding}
							availableAbouts={availableAbouts}
							pendingIds={pendingIds}
							data={user?.UserData?.UserAboutData}
							types={userData?.profile_types || [0]}
							token={token}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
						/>
					) : model === 'profile' ? (
						<EditProfile
							isModalLoading={isModalLoading}
							setIsModalLoading={setIsModalLoading}
							data={userData}
							user={user}
							userData={userData}
							getMyProfile={getMyProfile}
							closeDialog={closeDialog}
							token={token}
						/>
					) : model === 'profileType' ? (
						<ProfileType
							typeData={userData}
							isModalLoading={isModalLoading}
							setIsModalLoading={setIsModalLoading}
							token={token}
							getMyProfile={getMyProfile}
							fetchUserBadges={fetchUserBadges}
							closeDialog={closeDialog}
						/>
					) : (
						<Discipline
							onDialogTransitionEnd={onDialogTransitionEnd}
							isModalLoading={isModalLoading}
							setIsModalLoading={setIsModalLoading}
							token={token}
							closeDialog={closeDialog}
							getMyProfile={getMyProfile}
						/>
					)
				}
			/>
		</>
	);
};

export default memo(ProfileTypeSlider, isEqual);
