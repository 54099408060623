import {
	// useNavigate,
	NavLink,
} from 'react-router-dom';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import './asset.scss';

// import { saveToStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';

// const AssetData = ({ img, name, userId, data, uri }) => {
const AssetData = ({ img, name, userId, uri, data, isCommunity }) => {
	// const navigate = useNavigate();
	// const setData = () => {
	//   saveToStorage(identifiers.visitorUserId, userId);
	//   // saveToStorage(identifiers.visitorUsers, data);
	// };
	var uriValue = data?.type === 'user_other' ? `/user/shadow/${data?.user_following_id}` : `/user/${uri}`;
	var URI_VALUE = isCommunity ? `/communities/${data?.id}` : uriValue;

	return (
		// <NavLink to={userId ? `/user/${uri}` : '/'}>
		<NavLink to={userId ? URI_VALUE : '/'}>
			{/* <NavLink to="/connections"> */}
			<Box
				className="cffc-asset-block"
				// onClick={() => {
				//   setData();
				//   navigate('/connections', { state: { USERID: userId } });
				//   saveToStorage('connectionPageStatus', true);
				// }}
			>
				<ListItem>
					<ListItemAvatar>
						<Avatar src={img ? img : UserPlaceholderIcon} />
					</ListItemAvatar>
					<ListItemText primary={name} />
				</ListItem>
			</Box>
		</NavLink>
	);
};

export default AssetData;
