import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Drawer, Hidden, IconButton, List, Link, ListItem, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { clearStorage, fetchFromStorage } from 'src/helpers/context';
import { ReactComponent as MobileTopSlogan } from 'src/assets/images/slogan.svg';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';
import items from '../menu';

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: '100%',
	},
	item: {
		display: 'flex',
		padding: 0,
	},
	button: {
		color: '#fff',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '10px 8px',
		textTransform: 'none',
		width: '100%',
		fontFamily: 'Staatliches',
	},
	title: {
		marginRight: 'auto',
		marginLeft: '0',
	},
}));

const NavBar = ({ onMobileClose, openMobile, openShareDialog, auth }) => {
	const classes = useStyles();
	const location = useLocation();

	const { t } = useTranslation();
	var USER_DATA = fetchFromStorage('authData') !== null && fetchFromStorage('authData')?.user_meta;
	let USERID = USER_DATA !== undefined ? USER_DATA?.data : auth?.user_id;

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column" className="menu-bar">
			<Box p={2}>
				<Box className="back-arrow-button">
					<IconButton onClick={onMobileClose}>
						<ArrowBackIosIcon />
					</IconButton>
					<Box className="mobile-nav-top">
						<MobileTopSlogan />
					</Box>
				</Box>

				<List>
					{/* {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <NavItem href="/search" title="Search" />
          <NavItem href="/notification" title="Notification" />
          <NavItem href={`/user/${USERID}`} title="My Profile" /> */}
					{/* <NavItem href={`/user/${auth?.user_id}`} title="My Profile" /> */}
					{/* {auth?.isAdmin && <NavItem href={'/admin'} title="Dashboard" />} */}
					{/* <Button
            fullWidth
            className="res-logout-app-btn"
            onClick={() => {
              clearStorage();
              window.location = '/';
            }}
          >
            Logout
          </Button> */}
					<NavItem
						href={`/user/${USERID}`}
						title={`${t('nav_menu_options.my_profile')}`}
						className={'mobile-nav-item'}
					/>
					{/* HIDE-MVP: CM-72 */}
					{/*<NavItem*/}
					{/*	href={`/claim-profile`}*/}
					{/*	title={`${t('nav_menu_options.linked_profiles')}`}*/}
					{/*	className={'mobile-nav-item'}*/}
					{/*/>*/}
					<NavItem
						href={`/referral-code`}
						title={`${t('nav_menu_options.my_referrals')}`}
						className={'mobile-nav-item'}
					/>
					{/* Pop up dialog window, no href, so doesnt use NavItem */}
					{/* This is share the link */}
					<ListItem className={classes.item}>
						<Button
							className={classes.button}
							onClick={() => {
								// close the drawer window
								onMobileClose();
								// after close drawer, then open the share dialog pop up window
								openShareDialog();
							}}
						>
							<span className={`${classes.title} mobile-nav-item`}>
								{`${t('nav_menu_options.share_your_profile')}`}
							</span>
						</Button>
					</ListItem>

					{/* This button is jumping to organisations page */}
					{/* HIDE-MVP: CM-79 */}
					{/*<NavItem*/}
					{/*	href={'/your-organisation'}*/}
					{/*	title={`${t('nav_menu_options.organisations')}`}*/}
					{/*	className={'mobile-nav-item'}*/}
					{/*/>*/}

					{/* This button jump to account setting page */}
					<NavItem
						href={`/Account-settings`}
						title={`${t('nav_menu_options.account_setting')}`}
						className={'mobile-nav-item'}
					/>

					{/* This button jumps to dashboard */}
					{auth?.isAdmin && (
						<NavItem href={'/admin/users'} title={`${t('nav_menu_options.dashboard')}`} className={'mobile-nav-item'} />
					)}

					{/* This is the logout button */}
					<ListItem className={classes.item}>
						<Button
							className={classes.button}
							onClick={() => {
								clearStorage();
								window.location = '/';
							}}
						>
							<span className={`${classes.title} mobile-nav-item`}>{`${t('nav_menu_options.logout')}`}</span>
						</Button>
					</ListItem>
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<Hidden lgUp>
			<Drawer
				anchor="left"
				classes={{ paper: classes.mobileDrawer }}
				onClose={onMobileClose}
				open={openMobile}
				variant="temporary"
			>
				{content}
			</Drawer>
		</Hidden>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
