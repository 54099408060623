import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'src/assets/images/icons/fightRecord/add-circle.svg';
import { ReactComponent as CorrectIcon } from 'src/assets/images/icons/correct-blue.svg';
import './dts.scss';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

const DTS = ({ data, createEndorsement, deleteEndorsement, user }) => {
	const { t } = useTranslation();

	return (
		<Box className="disciplines-techniques-skills-sec">
			<Grid container>
				<Grid xs={6} sm={8} className="dts-left-sec">
					<Box className="update-details-sec">
						{data && data?.profile_update_data?.records?.disciplineData?.length > 0 && (
							<>
								<Typography className="update-details-header"> {t('myprofile.discipline')}</Typography>
								{data?.profile_update_data?.records?.disciplineData.map((sitem, index) => (
									<Typography className="update-details-data">{sitem?.discipline?.name}</Typography>
								))}
							</>
						)}
					</Box>
					<Box className="update-details-sec">
						{data && data?.profile_update_data?.records?.methodData?.length > 0 && (
							<>
								<Typography className="update-details-header">{t('myprofile.fightmethod')}</Typography>
								{data?.profile_update_data?.records?.methodData.map((sitem, index) => (
									<Typography className="update-details-data">{sitem?.meta_fight_method?.name}</Typography>
								))}
							</>
						)}
					</Box>
					<Box className="update-details-sec">
						{data && data?.profile_update_data?.records?.skillData?.length > 0 && (
							<>
								<Typography className="update-details-header">{t('myprofile.skills')}</Typography>
								{data?.profile_update_data?.records?.skillData.map((sitem, index) => (
									<Typography className="update-details-data">{sitem?.meta_skills?.name}</Typography>
								))}
							</>
						)}
					</Box>
				</Grid>
				<Grid xs={6} sm={4} className="dts-right-sec">
					<Box className="update-details-right-sec">
						{data &&
							data?.profile_update_data?.records?.disciplineData?.length > 0 &&
							data?.profile_update_data?.records?.disciplineData.map((sitem, index) => {
								return (
									<>
										<Box className="update-details-right-data">
											<IconButton className="endorse-btn">{sitem?.Endorsements_count}</IconButton>
											<Typography className="endorse-text"> {t('myprofile.endorsements')}</Typography>
											{user?.user_id !== sitem?.user_id ? (
												<IconButton
													className="endorse-addicon"
													onClick={() => {
														var requestData = {
															user_id: sitem?.user_id,
															discipline_id: sitem?.discipline_id,
														};

														{
															sitem?.login_user_endorsement_status
																? deleteEndorsement(requestData, 'discipline')
																: createEndorsement(requestData, 'discipline');
														}
													}}
												>
													{sitem?.login_user_endorsement_status ? <CorrectIcon /> : <AddIcon />}
												</IconButton>
											) : (
												<></>
											)}
										</Box>
									</>
								);
							})}
					</Box>
					<Box className="update-details-right-sec">
						{data &&
							data?.profile_update_data?.records?.methodData?.length > 0 &&
							data?.profile_update_data?.records?.methodData.map((sitem, index) => {
								return (
									<>
										<Box className="update-details-right-data">
											<IconButton className="endorse-btn">{sitem?.Endorsements_count}</IconButton>
											<Typography className="endorse-text">{t('myprofile.endorsements')}</Typography>
											{user?.user_id !== sitem?.user_id ? (
												<IconButton
													className="endorse-addicon"
													onClick={() => {
														var requestData = {
															user_id: sitem?.user_id,
															meta_fight_method_id: sitem?.meta_fight_method_id,
														};

														{
															sitem?.login_user_endorsement_status
																? deleteEndorsement(requestData, 'method')
																: createEndorsement(requestData, 'method');
														}
													}}
												>
													{sitem?.login_user_endorsement_status ? <CorrectIcon /> : <AddIcon />}
												</IconButton>
											) : (
												<></>
											)}
										</Box>
									</>
								);
							})}
					</Box>
					<Box className="update-details-right-sec">
						{data &&
							data?.profile_update_data?.records?.skillData?.length > 0 &&
							data?.profile_update_data?.records?.skillData.map((sitem, index) => {
								return (
									<>
										<Box className="update-details-right-data">
											<IconButton className="endorse-btn">{sitem?.Endorsements_count}</IconButton>
											<Typography className="endorse-text">{t('myprofile.endorsements')}</Typography>
											{user?.user_id !== sitem?.user_id ? (
												<IconButton
													className="endorse-addicon"
													onClick={() => {
														var requestData = {
															user_id: sitem?.user_id,
															profile_type: sitem?.meta_skills_id,
														};

														{
															sitem?.login_user_endorsement_status
																? deleteEndorsement(requestData, 'skill')
																: createEndorsement(requestData, 'skill');
														}
													}}
												>
													{sitem?.login_user_endorsement_status ? <CorrectIcon /> : <AddIcon />}
												</IconButton>
											) : (
												<></>
											)}
										</Box>
									</>
								);
							})}
					</Box>
					{/* <Box className='update-details-right-sec'>
                        <Box className='update-details-right-data'>
                            <IconButton className='endorse-btn'>0</IconButton>
                            <Typography className='endorse-text'>{t('myprofile.endorsements')}</Typography>
                            <IconButton className='endorse-addicon' onClick={() => { navigate(`/user/${data?.profile_update_data?.user_id}`) }}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box> */}
				</Grid>
			</Grid>
		</Box>
	);
};
export default DTS;
