import { useState, useContext } from 'react';
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Typography,
	Button,
	InputAdornment,
	TextField,
	withStyles,
	Grid,
	Chip,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/community/member-search-icon.svg';
// import axiosInstance from 'src/helpers/axios/axiosInstance';
// import { URLS } from 'src/helpers/constants/urls';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
// import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from 'react-i18next';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { SocketContext } from 'src/helpers/context/socket';
import { useParams } from 'react-router-dom';

const SearchTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2E353A',
			border: '1px solid #2E353A',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2E353A',
			},
			'&:hover fieldset': {
				borderColor: '#2E353A',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #2E353A',
			},
		},
	},
})(TextField);

const ForwardMessage = ({
	msgList,
	addForwardMessage,
	forwardedData,
	handleClose,
	user,
	currentGroupChat,
	userListData,
	fetchGroupList,
}) => {
	const { t } = useTranslation();

	const [checked, setChecked] = useState([]);
	const [checkedAll, setCheckedAll] = useState([]);
	const [searchString, setSearchString] = useState('');
	// const [allUsersList, setAllUsersList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState();
	const [limit] = useState(10);
	const userSocket = useContext(SocketContext)?.socket;
	const { id } = useParams();
	// const fetchAllUsersList = async (page, name) => {
	//     try {
	//         const { status, data } = await axiosInstance.get(
	//             URLS.getAllUsersList + `?page=${page}&limit=${limit}&name=${name}`
	//         );
	//         if (status === 200) {
	//             const filteredData = data?.getAllUsers;
	//             var finalArray = filteredData.filter(item => item?.user_full_name !== '');
	//             setAllUsersList(finalArray);
	//             setTotalRecord(data?.totalRecord);
	//         }
	//     } catch (error) { }
	// };
	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			// fetchAllUsersList(Number(page), searchString);
		}
	};
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				// fetchAllUsersList(currentPage - 1, searchString);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / limit)) {
				setCurrentPage(currentPage + 1);
				// fetchAllUsersList(currentPage + 1, searchString);
			}
		}
	};
	const handleChange = (e) => {
		// fetchAllUsersList(currentPage, e.target.value);
		setSearchString(e.target.value);
	};
	const onMemberSelect = (value) => {
		const currentIndex = checked.indexOf(value?.id);
		const newChecked = [...checked];
		const objChecked = [...checkedAll];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
			objChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
			objChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
		setCheckedAll(objChecked);
	};
	const onForwardMsg = () => {
		// const rawHTML = text ? draftToHtml(convertToRaw(text.getCurrentContent())) : '';
		var forwardContent =
			forwardedData?.type === 'image'
				? forwardedData?.item_id
				: forwardedData?.type === 'video'
				? forwardedData?.item_id
				: forwardedData?.type === 'doc'
				? forwardedData?.item_id
				: forwardedData?.text;

		checkedAll.map((data, index) => {
			var forwardMsg = {
				group_id: data?.id,
				user_id: user?.user_id,
				type: forwardedData?.type,
				content: forwardContent,
				reply: null,
				forward: { originalMessageID: forwardedData?._id },
				taggedUsersID: [],
				chatType: data?.private_group === 1 ? 'private' : 'group',
				link: [],
				user: {
					_id: user?.user_id,
					name: user?.OauthUserData?.user_name,
					avatar: user?.profile_pic,
				},
			};

			userSocket.emit('add-message', forwardMsg);

			return '';
		});
		userSocket.on('add-message', (data) => {
			if (msgList !== undefined) {
				const groupChatHistory = [...msgList];

				groupChatHistory.push(data);
				Number(data?.group_id) === Number(id) && addForwardMessage(groupChatHistory);
			}
		});

		handleClose();
	};
	// useEffect(() => {
	//     fetchAllUsersList(currentPage, searchString);
	// }, [])

	return (
		<>
			<Box className="member-listing-main">
				<Box className="search-box">
					<SearchTextField
						variant="outlined"
						fullWidth
						size="small"
						placeholder="Search"
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Box className="restriction-box">
						<Box className="restriction-chips">
							{/* <Grid container> */}
							{checkedAll?.map((data, index) => {
								return (
									<Grid item key={index} className="rest-chip">
										<Chip
											size="small"
											className="checked-chip"
											deleteIcon={<CancelIcon />}
											label={data?.name}
											onDelete={() => onMemberSelect(data)}
											// onClick={() => onMemberSelect(data)}
											// onClick={handleClick}
										/>
									</Grid>
								);
							})}
							{/* </Grid> */}
						</Box>
					</Box>
					<Box className="member-inner">
						<List>
							{userListData && userListData?.length ? (
								<>
									{userListData.map((item, index) => {
										return (
											<ListItem key={index}>
												<Avatar src={item?.item_profile_data} />
												<ListItemText>
													<Typography variant="span" className="member-name">
														{item?.name}
													</Typography>
												</ListItemText>
												<Button
													disableRipple
													disableFocusRipple
													disableTouchRipple
													edge="end"
													className={checked.indexOf(item?.id) !== -1 ? 'member-btn gray-background' : 'member-btn '}
													onClick={() => onMemberSelect(item)}
												>
													{checked.indexOf(item?.id) !== -1 ? t('messages.forwarded') : t('messages.forward')}
												</Button>
											</ListItem>
										);
									})}
								</>
							) : (
								<Typography className="no-data-txt">{t('community.no_member_found')}</Typography>
							)}
						</List>
					</Box>
					<Box className="create-btn-main dialog-sticky-btn">
						<Button
							disableRipple
							disableFocusRipple
							disableTouchRipple
							edge="end"
							className="create-button"
							onClick={() => {
								checked?.length > 0 ? onForwardMsg() : setApiMessage('success', t('messages.creategrpmsg'));
							}}
						>
							{t('messages.forward')}
						</Button>
					</Box>
					<CustomPagination
						paginationArray={userListData}
						currentPage={currentPage}
						list={limit}
						handlePaginationButtonClick={handlePaginationButtonClick}
						handlePaginationClick={handlePaginationClick}
						totalRecord={totalRecord}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ForwardMessage;
