import { Box, Avatar, List, ListItem, ListItemText, Typography, Button } from '@material-ui/core';
import './claim-user.scss';
import { useTranslation } from 'react-i18next';

const ClaimUserList = ({ message, claimedUserList, userAction }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box className="claim-user-main">
				<Box className="claim-user-inner">
					{claimedUserList && claimedUserList.length > 0 ? (
						<>
							{claimedUserList?.map((item, index) => {
								return (
									<List index={index}>
										<ListItem key="{index}">
											<ListItemText className="d-flex">
												<Avatar
													className="cu-image"
													// src={item?.item_profile_id}
												/>
												<Box className="d-block">
													<Typography variant="span" className="cu-name">
														{item?.owner_user_full_name}
													</Typography>
													<Typography variant="span" className="cu-status">
														{item?.claim_status}
													</Typography>
												</Box>
											</ListItemText>
											{item?.claim_status === 'pending' && (
												<>
													<Button
														variant="contained"
														className="remove-button mr-5"
														onClick={() => userAction(item, 'active')}
													>
														{t('admin_panel.combat_users.active')}
													</Button>
													<Button
														variant="contained"
														className="remove-button"
														onClick={() => userAction(item, 'suspended')}
													>
														{t('admin_panel.combat_users.suspended')}
													</Button>
												</>
											)}
										</ListItem>
									</List>
								);
							})}
						</>
					) : (
						<Typography className="no-data-txt">{t('admin_panel.combat_users.no_user_found')}</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};

export default ClaimUserList;
