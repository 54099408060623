import { Fragment, useEffect, useState } from 'react';
import { Box, Tab, Toolbar, Typography } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
// import AdBlock from './AD';
import CommunityPost from 'src/views/component/UI/CommunityPost';
import { ReactComponent as BestIcon } from 'src/assets/images/icons/best.svg';
import { ReactComponent as PopularIcon } from 'src/assets/images/icons/popular.svg';
import { ReactComponent as DateIcon } from 'src/assets/images/icons/date.svg';
import { ReactComponent as TopIcon } from 'src/assets/images/icons/top.svg';
import { ReactComponent as RecommendedIcon } from 'src/assets/images/icons/recommend.svg';
import './discussions.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { Config } from 'src/helpers/context/config';
import StickiedPost from './StickiedPost';
import CreatePost from './createPost';
import UpdatePost from './UpdatePost';
import { useTranslation } from 'react-i18next';
import {
	removeOrAddStickyPostInCommunityAC,
	updateCommunityPostAC,
} from '../../../../../../../redux-state/actions/actions';

const CreateCommunity = ({ loader, isView, isJoin, token, user, fetchCommunityPostList }) => {
	const { t } = useTranslation();

	const [tab, setTab] = useState('3');
	const [isUpdate, setIsUpdate] = useState(false);
	const [postData, setPostData] = useState({});
	const [allUsersList, setAllUsersList] = useState([]);
	const [reactionData, setReactionData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const communityPostList = useSelector((state) => state.Combat.communityPostList);
	const communityDetails = useSelector((state) => state.Combat.communityDetails);
	const dispatch = useDispatch();

	const updateCommunityPostDetails = async (postId) => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getPostDetails + `/${postId}`);

			if (status === 200) {
				dispatch(updateCommunityPostAC(data.data.post_data));
			}
		} catch (err) {
			console.log(err);
		}
	};

	const tabChangeHandler = async (event, newValue) => {
		// eslint-disable-next-line no-nested-ternary
		const tabValue =
			newValue === '1'
				? 'best'
				: newValue === '2'
				? 'trending'
				: newValue === '3'
				? 'date'
				: newValue === '4'
				? 'top'
				: newValue === '5'
				? 'recommended'
				: 'best';

		if (newValue === tab) {
			return;
		}

		setIsLoading(true);

		try {
			await fetchCommunityPostList(tabValue);
			setTab(newValue);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};
	// DELETE COMMUNITY POST
	const handleDeletePost = async (item) => {
		setIsLoading(true);

		if (isLoading) {
			return;
		}

		try {
			const { status, data } = await axiosInstance.delete(`${URLS.deletePost}/${item?.id}`);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};
	// UPDATE COMMUNITY POST
	const updateCommunityPost = async (value, status) => {
		setIsLoading(true);

		if (isLoading) {
			return;
		}

		const requestParams = {
			post: {
				id: value?.id,
				sticky: status,
				postList: [],
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updatePost, requestParams);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				dispatch(removeOrAddStickyPostInCommunityAC(data.updatePost));
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};
	const updatePostData = async (value) => {
		setIsUpdate(true);
		setPostData(value);
	};

	// ADD VOTE
	const addVote = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			vote_name: type,
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addVote, requestParams);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				updateCommunityPostDetails(value.id);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	// REACTION
	const addLike = async (value, type) => {
		var requestParams = {
			post_id: value?.id,
			meta_like_id: type, // heart, thumbs_up, clap_hands, light_bulb
			main_post_id: value?.id,
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.addLike, requestParams);

			if (status === 200) {
				const tabValue =
					tab === '1'
						? 'best'
						: tab === '2'
						? 'trending'
						: tab === '3'
						? 'date'
						: tab === '4'
						? 'top'
						: tab === '5'
						? 'recommended'
						: 'best';

				setApiMessage('success', data?.message);
				fetchCommunityPostList(tabValue);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchReactions = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsLike);

			if (status === 200) {
				setReactionData(data?.data);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH ALL USER LIST FOR TAG PEOPLE
	const fetchAllUsersList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getAllUsersList);

			if (status === 200) {
				const filteredData = data?.getAllUsers;
				var formattedArray = [];

				filteredData.map((item, i) => {
					var urlType =
						item?.type === 'user'
							? `user/${item?.id}`
							: item?.type === 'community'
							? `communities/${item?.id}`
							: `organisation-details/${item?.id}`;
					var urlValue = urlType;

					const object = {
						text: item?.user_full_name,
						value: item?.user_full_name,
						url: urlValue,
					};

					return (formattedArray[i] = object);
				});
				var finalArray = formattedArray.filter((item) => item?.text !== '');

				setAllUsersList(finalArray);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// BUTTON TAG LIST
	const btnList = [
		// HIDE-MVP: CM-107
		// { id: '1', name: t('community.best') },
		{ id: '2', name: t('community.popular') },
		{ id: '3', name: t('community.date') },
		{ id: '4', name: t('community.top') },
		// HIDE-MVP: CM-107
		// { id: '5', name: t('community.recommended') },
	];

	// BUTTON ICON HANDLER
	const btnIconHandler = (type) => {
		switch (type) {
			case '1':
				return <BestIcon />;
			case '2':
				return <PopularIcon />;
			case '3':
				return <DateIcon />;
			case '4':
				return <TopIcon />;
			case '5':
				return <RecommendedIcon />;
			default:
				return <BestIcon />;
		}
	};

	useEffect(() => {
		fetchAllUsersList();
		fetchReactions();
	}, []);

	return (
		<>
			{/* CREATE COMMUNITY POST */}
			<Toolbar style={{ minHeight: 0, maxHeight: 0 }} id="back-to-top-post" />
			{isUpdate ? (
				<UpdatePost
					postData={postData}
					cancelBtnClick={() => setIsUpdate(false)}
					tab={tab}
					token={token}
					fetchCommunityPostList={fetchCommunityPostList}
					editAccess={communityDetails?.communityData?.edit_access}
					allUsersList={allUsersList}
					user={user}
				/>
			) : (
				<CreatePost
					loader={loader}
					token={token}
					user={user}
					isView={isView}
					isJoin={isJoin}
					tab={tab}
					editAccess={communityDetails?.communityData?.edit_access}
					fetchCommunityPostList={fetchCommunityPostList}
					updatePostData={updatePostData}
					allUsersList={allUsersList}
				/>
			)}

			<Box className="bpdtr-box">
				<TabContext value={String(tab)}>
					<TabList variant="scrollable" scrollButtons="off" onChange={tabChangeHandler}>
						{btnList?.map((data, index) => {
							return (
								<Tab
									disabled={isLoading}
									key={index}
									icon={btnIconHandler(data?.id)}
									label={data?.name}
									value={data?.id}
									disableRipple
									disableTouchRipple
									disableFocusRipple
								/>
							);
						})}
					</TabList>
				</TabContext>
			</Box>

			{/* STICKIED POSTS */}
			{communityPostList && communityPostList?.stickied_post_list?.length !== 0 && (
				<StickiedPost
					updateCommunityPostDetails={updateCommunityPostDetails}
					isView={isView}
					isJoin={isJoin}
					handleDeletePost={handleDeletePost}
					updateCommunityPost={updateCommunityPost}
					updatePostData={updatePostData}
					cancelBtnClick={() => setIsUpdate(false)}
					addVote={addVote}
					addLike={addLike}
					user={user}
					reactionData={reactionData}
					editAccess={communityDetails?.communityData?.edit_access}
					closeCommunity={communityDetails?.communityData?.status === 'closed' ? true : false}
				/>
			)}

			{/* COMMUNITY POSTS */}
			{communityPostList && communityPostList?.post_list?.length > 0 ? (
				<>
					{communityPostList?.post_list.map((item, index) => {
						const postContent = item?.PostLists.find((d) => d?.Item?.type === 'text');
						var postImage = item?.PostLists.filter((d) => d?.Item?.type === 'image');
						var postVideos = item?.PostLists.filter((d) => d?.Item?.type === 'video');
						var postAudio = item?.PostLists.filter((d) => d?.Item?.type === 'audio');
						var postDoc = item?.PostLists.filter((d) => d?.Item?.type === 'doc');

						return (
							<Fragment key={index + 'community post'}>
								<CommunityPost
									fetchPostDetails={updateCommunityPostDetails}
									linkList={postContent?.Item?.links_list}
									isView={isView}
									isJoin={isJoin}
									postName={item?.name}
									time={
										item?.timestamp_created !== null && moment.utc(item?.timestamp_created).fromNow().replace('in ', '')
									}
									postedBy={item?.user_full_name}
									topVal={item?.total_vote_up}
									downVal={item?.total_vote_down}
									contentText={postContent?.Item?.data}
									// contentImg="/static/collage.png"
									editAccess={communityDetails?.communityData?.edit_access}
									closeCommunity={communityDetails?.communityData?.status === 'closed' ? true : false}
									comment={item?.total_comments}
									postUserId={item?.user_id}
									shares={item?.total_shares}
									referrals={item?.total_referrals}
									reactions={item?.total_likes}
									user={user}
									communityPost={false}
									updateCommunityPost={updateCommunityPost}
									updatePostData={updatePostData}
									handleDeletePost={handleDeletePost}
									data={item}
									cancelBtnClick={() => setIsUpdate(false)}
									addVote={addVote}
									addLike={addLike}
									userURI={item?.user_meta?.data}
									postProfile={item?.user_profile_data}
									reactionData={reactionData}
									likedStatus={item?.like_status}
									contentImg={postImage}
									postVideo={postVideos}
									postAudio={postAudio}
									postDoc={postDoc}
									verifyUser={item?.veritication_status === 'yes' ? true : false}
								/>
								{/* <AdBlock /> */}
							</Fragment>
						);
					})}
				</>
			) : (
				<Typography className="no-post-text">{/* {t('community.no_post_txt')} */}</Typography>
			)}

			{/* ADVERTISEMENT BANNER */}
			{/* <CommunityPost
        isView={isView}
        postName="[Post Name]"
        time="2 hours ago"
        postedBy="[Posted By]"
        topVal={45}
        downVal={10}
        // contentText=""
        contentImg="/static/collage.png"
        comment={58}
        shares={8}
        referrals={12}
        reactions={3}
        communityPost={true}
      /> */}

			{/* NO POST TEXT */}
			{/* <Box className="post-container-box">
        <Typography className="no-post-text">
          Community has no posts.
        </Typography>
      </Box> */}
		</>
	);
};

export default CreateCommunity;
