/* eslint-disable react/jsx-no-undef */
/* eslint-disable linebreak-style */
import { useContext, useState, useEffect } from 'react';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {
	Box,
	Container,
	Button,
	Typography,
	CircularProgress,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Tab,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CombatDialog from 'src/views/component/UI/dialogBox';
import { adminFightMethod, adminFightWeight } from 'src/redux-state/actions/adminAction';
import { connect, useSelector } from 'react-redux';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/smallBEdit.svg';
import CustomPagination from 'src/views/component/UI/CustomPaginations';
import MetaCategory from './MetaCategory';
import AddWeight from './AddWeight';
import FightMethod from './FightMethod';
import AddSpecialities from './AddSpecialities';
import AddSkills from './AddSkills';
// import AddTechnique from './AddTechnique';

const AdminFight = ({ adminFightMethod, adminFightWeight }) => {
	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecords] = useState(''); //setTotalRecords
	const [skillsTR, setSkillsTR] = useState('');
	const [categoryTR, setCategoryTR] = useState('');
	const [weightTR, setWeightTR] = useState('');
	const [fightTR, setFightTR] = useState('');
	const [limit] = useState(20); //setList
	const [itemData, setItemData] = useState('');
	const [tab, setTab] = useState('1');
	const [loader, setLoader] = useState(false);
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState();
	const [model, setModel] = useState('');
	const [categoryData, setCategoryData] = useState([]);
	const [disciplineData, setDisciplineData] = useState([]);
	const [skillsData, setSkillsData] = useState([]);
	const [allCategoryData, setAllCategoryData] = useState([]);
	// const [allWeightData, setAllWeightData] = useState([]);
	// const [allFightData, setAllFightData] = useState([]);
	const [allDisciplineData, setAllDisciplineData] = useState([]);
	const [allSkillsData, setAllSkillsData] = useState([]);

	const closeDialog = () => {
		setOpen(false);
	};
	const fetchAdminFightMethod = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getFightMethod + `?page=${page}&limit=${limit}`);

			if (status === 200) {
				setLoader(false);
				adminFightMethod(data?.getMetaFightsData);
				setFightTR(data?.totalRecord);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	const fetchAdminWeight = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getMetaWeightClass + `?page=${page}&limit=${limit}`);

			if (status === 200) {
				setLoader(false);
				adminFightWeight(data?.getMetaWeightClassesData);
				setWeightTR(data?.totalRecords);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchCategory = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory + `?page=${page}&limit=${limit}`);

			if (status === 200) {
				setLoader(false);
				setCategoryData(data?.metaCategoriesData);
				setCategoryTR(data?.totalRecords);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchDisciplineData = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getDisciplinesData + `?page=${page}&limit=${limit}`);

			if (status === 200) {
				setLoader(false);
				setDisciplineData(data?.getDisciplinesData);
				setTotalRecords(data?.totalRecords);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchMetaSkillsData = async (page) => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getMetaSkills + `?page=${page}&limit=${limit}`);

			if (status === 200) {
				setLoader(false);
				setSkillsData(data?.getMetaSkillData);
				setSkillsTR(data?.totalRecord);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchAllCategoryData = async () => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getCategory);

			if (status === 200) {
				setLoader(false);
				setAllCategoryData(data?.metaCategoriesData);
				setCategoryTR(data?.totalRecords);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchAllDisciplineData = async () => {
		setLoader(true);

		try {
			// eslint-disable-next-line prefer-template
			const { status, data } = await axiosInstance.get(URLS.getDisciplinesData);

			if (status === 200) {
				setLoader(false);
				setAllDisciplineData(data?.getDisciplinesData);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const fetchAllSkillsData = async () => {
		setLoader(true);

		try {
			const { status, data } = await axiosInstance.get(URLS.getMetaSkills);

			if (status === 200) {
				setLoader(false);
				setAllSkillsData(data?.getMetaSkillData);
				setSkillsTR(data?.totalRecord);
			}
		} catch (error) {
			setLoader(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		fetchAdminFightMethod(currentPage);
		fetchAdminWeight(currentPage);
		fetchCategory(currentPage);
		fetchDisciplineData(currentPage);
		fetchMetaSkillsData(currentPage);
		fetchAllCategoryData();
		// fetchAllWeightData();
		// fetchAllFightData();
		fetchAllDisciplineData();
		fetchAllSkillsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fightMethodList = useSelector((state) => state?.Combat?.fightMethodList);
	const fightWeightList = useSelector((state) => state?.Combat?.fightWeightList);

	const combatDialogHandler = (heading) => {
		setOpen(true);
		setTitle(heading);
	};

	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		setTab('1');
	}, []);

	// DISCIPLINE PAGINATION
	const handlePaginationButtonClick = async (navDirection) => {
		if (navDirection === 'prev') {
			if (currentPage > 1) {
				setCurrentPage(currentPage - 1);
				tab === '1' && fetchCategory(currentPage - 1);
				tab === '2' && fetchAdminWeight(currentPage - 1);
				tab === '3' && fetchAdminFightMethod(currentPage - 1);
				tab === '4' && fetchDisciplineData(currentPage - 1);
				tab === '6' && fetchMetaSkillsData(currentPage - 1);
			}
		} else {
			if (currentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(currentPage + 1);
				tab === '1' && fetchCategory(currentPage + 1);
				tab === '2' && fetchAdminWeight(currentPage + 1);
				tab === '3' && fetchAdminFightMethod(currentPage + 1);
				tab === '4' && fetchDisciplineData(currentPage + 1);
				tab === '6' && fetchMetaSkillsData(currentPage + 1);
			}
		}
	};

	const handlePaginationClick = async (event, page) => {
		if (currentPage !== page) {
			setCurrentPage(Number(page));
			tab === '1' && fetchCategory(Number(page));
			tab === '2' && fetchAdminWeight(Number(page));
			tab === '3' && fetchAdminFightMethod(Number(page));
			tab === '4' && fetchDisciplineData(Number(page));
			tab === '6' && fetchMetaSkillsData(Number(page));
		}
	};
	const list = [
		{ id: 1, name: t('admin_panel.combat_category.meta_category') },
		{ id: 2, name: t('admin_panel.combat_weight.meta_weight') },
		{ id: 3, name: t('admin_panel.combat_fight.meta_fight') },
		{ id: 4, name: t('admin_panel.combat_speciality.speciality') },
		// { id: 5, name: t('admin_panel.combat_technique.meta_technique') },
		{ id: 6, name: t('admin_panel.combat_skills.meta_skills') },
	];

	return (
		<>
			<Box className="admin-container-box">
				<Container maxWidth="lg" className="badge-main">
					<Typography className="admin-page-haeding">{t('admin_panel.combat_fight.title')}</Typography>
					<Box className="d-flex justify-content-space-between">
						<Box>
							<Button
								variant="outlined"
								className="mr-10"
								onClick={() => {
									combatDialogHandler(t('admin_panel.combat_category.addcategory'));
									setModel('addcategory');
								}}
							>
								{t('admin_panel.combat_category.addcategory')}
							</Button>
							<Button
								variant="outlined"
								className="mr-10"
								onClick={() => {
									combatDialogHandler(t('admin_panel.combat_fight.addweight'));
									setModel('addweight');
								}}
							>
								{t('admin_panel.combat_fight.addweight')}
							</Button>
							<Button
								variant="outlined"
								className="mr-10"
								onClick={() => {
									combatDialogHandler(t('admin_panel.combat_fight.addtechnique'));
									setModel('addfightmethod');
								}}
							>
								{t('admin_panel.combat_fight.addtechnique')}
							</Button>
							<Button
								variant="outlined"
								className="mr-10"
								onClick={() => {
									combatDialogHandler(t('admin_panel.combat_speciality.add_speciality'));
									setModel('addspeciality');
								}}
							>
								{t('admin_panel.combat_speciality.add_speciality')}
							</Button>
							{/* <Button
                variant="outlined"
                className="mr-10"
                onClick={() => {
                  combatDialogHandler(
                    t('admin_panel.combat_technique.add_technique')
                  );
                  setModel('addtechnique');
                }}
              >
                {t('admin_panel.combat_technique.add_technique')}
              </Button> */}
							<Button
								variant="outlined"
								className="mr-10"
								onClick={() => {
									combatDialogHandler(t('admin_panel.combat_skills.add_skills'));
									setModel('addskills');
								}}
							>
								{t('admin_panel.combat_skills.add_skills')}
							</Button>
						</Box>
					</Box>
					<Box className="admin-data-tables">
						{loader ? (
							<Box display="flex" justifyContent="center">
								<CircularProgress size={18} color="secondary" />
							</Box>
						) : (
							<>
								<TabContext value={String(tab)}>
									<Box className="actions-header">
										<TabList
											variant="scrollable"
											scrollButtons="off"
											onChange={tabChangeHandler}
											aria-label="action tabs"
										>
											{list.map((obj, index) => {
												return <Tab key={index} label={obj.name} value={String(obj?.id)} />;
											})}
										</TabList>
									</Box>
									{/* category listing */}
									<TabPanel value="1">
										<Typography className="admin-page-haeding fs-32">
											{t('admin_panel.combat_category.title')}
										</Typography>
										{/* category listing */}
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>ID</TableCell>
														<TableCell>{t('admin_panel.combat_category.categoryname')}</TableCell>
														<TableCell>{t('admin_panel.combat_category.categorydescription')}</TableCell>
														<TableCell>{t('admin_panel.combat_category.locked_status')}</TableCell>
														<TableCell>{t('admin_panel.combat_users.status')}</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody>
													{categoryData &&
														categoryData.map((data, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{data?.id}</TableCell>
																	<TableCell>{data?.name}</TableCell>
																	<TableCell>{data?.description}</TableCell>
																	<TableCell>{data?.locked}</TableCell>
																	<TableCell>{data?.status}</TableCell>
																	<TableCell align="right">
																		<Box className="action-box">
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => {
																					combatDialogHandler(t('admin_panel.combat_fight.update_fightmethod'));
																					setModel('updatecategory');
																					setItemData(data);
																				}}
																			>
																				<EditIcon />
																			</IconButton>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<CustomPagination
											paginationArray={categoryData}
											currentPage={currentPage}
											list={limit}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={categoryTR}
										/>
									</TabPanel>
									{/* weight listing */}
									<TabPanel value="2">
										<Typography className="admin-page-haeding fs-32">{t('admin_panel.combat_weight.title')}</Typography>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>ID</TableCell>
														<TableCell>{t('admin_panel.combat_weight.weightname')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.weightdescription')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.lowBound')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.lowLb')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.lowKg')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.highBound')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.highLb')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.highKg')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.allowanceLb')}</TableCell>
														<TableCell>{t('admin_panel.combat_weight.allowanceKg')}</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody>
													{fightWeightList &&
														fightWeightList.map((data, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{data?.id}</TableCell>
																	<TableCell>{data?.name}</TableCell>
																	<TableCell>{data?.description}</TableCell>
																	<TableCell>{data?.low_bound}</TableCell>
																	<TableCell>{data?.low_lb}</TableCell>
																	<TableCell>{data?.low_kg}</TableCell>
																	<TableCell>{data?.high_bound}</TableCell>
																	<TableCell>{data?.high_lb}</TableCell>
																	<TableCell>{data?.high_kg}</TableCell>
																	<TableCell>{data?.allowance_lb}</TableCell>
																	<TableCell>{data?.allowance_kg}</TableCell>
																	<TableCell align="right">
																		<Box className="action-box">
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => {
																					combatDialogHandler(t('admin_panel.combat_fight.update_fightmethod'));
																					setModel('updateweight');
																					setItemData(data);
																				}}
																			>
																				<EditIcon />
																			</IconButton>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<CustomPagination
											paginationArray={fightWeightList}
											currentPage={currentPage}
											list={limit}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={weightTR}
										/>
									</TabPanel>
									{/* fight listing */}
									<TabPanel value="3">
										<Typography className="admin-page-haeding fs-32">{t('admin_panel.combat_fight.title')}</Typography>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>ID</TableCell>
														<TableCell>{t('admin_panel.combat_fight.fightname')}</TableCell>
														<TableCell>{t('admin_panel.combat_fight.fightdescription')}</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody>
													{fightMethodList &&
														fightMethodList.map((data, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{data?.id}</TableCell>
																	<TableCell>{data?.name}</TableCell>
																	<TableCell>{data?.description}</TableCell>
																	<TableCell align="right">
																		<Box className="action-box">
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => {
																					combatDialogHandler(t('admin_panel.combat_fight.update_fightmethod'));
																					setModel('updatefightmethod');
																					setItemData(data);
																				}}
																			>
																				<EditIcon />
																			</IconButton>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<CustomPagination
											paginationArray={fightMethodList}
											currentPage={currentPage}
											list={limit}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={fightTR}
										/>
									</TabPanel>
									<TabPanel value="4">
										<Typography className="admin-page-haeding fs-32">
											{t('admin_panel.combat_speciality.title')}
										</Typography>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>ID</TableCell>
														<TableCell>{t('admin_panel.combat_speciality.specialityname')}</TableCell>
														<TableCell>{t('admin_panel.combat_speciality.specialitydescr')}</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody>
													{disciplineData &&
														disciplineData.map((data, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{data?.id}</TableCell>
																	<TableCell>{data?.name}</TableCell>
																	<TableCell>{data?.description}</TableCell>
																	<TableCell align="right">
																		<Box className="action-box">
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => {
																					combatDialogHandler(t('admin_panel.combat_speciality.update_speciality'));
																					setModel('updatediscipline');
																					setItemData(data);
																				}}
																			>
																				<EditIcon />
																			</IconButton>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<CustomPagination
											paginationArray={disciplineData}
											currentPage={currentPage}
											list={limit}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={totalRecord}
										/>
									</TabPanel>
									{/* <TabPanel value="5">
                    <Typography className="admin-page-haeding fs-32">
                      {t('admin_panel.combat_technique.title')}
                    </Typography>
                  </TabPanel> */}
									<TabPanel value="6">
										<Typography className="admin-page-haeding fs-32">{t('admin_panel.combat_skills.title')}</Typography>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>ID</TableCell>
														<TableCell>{t('admin_panel.combat_skills.skillsname')}</TableCell>
														<TableCell>{t('admin_panel.combat_skills.skilldescription')}</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody>
													{skillsData &&
														skillsData.map((data, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>{data?.id}</TableCell>
																	<TableCell>{data?.name}</TableCell>
																	<TableCell>{data?.description}</TableCell>
																	<TableCell align="right">
																		<Box className="action-box">
																			<IconButton
																				disableTouchRipple
																				disableRipple
																				disableFocusRipple
																				onClick={() => {
																					combatDialogHandler(t('admin_panel.combat_skills.updateskills'));
																					setModel('updateskills');
																					setItemData(data);
																				}}
																			>
																				<EditIcon />
																			</IconButton>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<CustomPagination
											paginationArray={skillsData}
											currentPage={currentPage}
											list={limit}
											handlePaginationButtonClick={handlePaginationButtonClick}
											handlePaginationClick={handlePaginationClick}
											totalRecord={skillsTR}
										/>
									</TabPanel>
								</TabContext>
							</>
						)}
					</Box>
				</Container>
			</Box>
			<CombatDialog
				open={open}
				handleClose={closeDialog}
				title={title}
				content={
					// eslint-disable-next-line no-nested-ternary
					model === 'addfightmethod' ? (
						<FightMethod
							fetchAdminFightMethod={fetchAdminFightMethod}
							isUpdate={false}
							closeDialog={closeDialog}
							currentPage={currentPage}
						/>
					) : model === 'updatefightmethod' ? (
						<FightMethod
							fetchAdminFightMethod={fetchAdminFightMethod}
							isUpdate
							itemData={itemData}
							closeDialog={closeDialog}
							currentPage={currentPage}
						/>
					) : model === 'addweight' ? (
						<AddWeight
							isUpdate={false}
							currentPage={currentPage}
							fetchAdminWeight={fetchAdminWeight}
							closeDialog={closeDialog}
						/>
					) : model === 'updateweight' ? (
						<AddWeight
							isUpdate
							itemData={itemData}
							currentPage={currentPage}
							fetchAdminWeight={fetchAdminWeight}
							closeDialog={closeDialog}
						/>
					) : model === 'addcategory' ? (
						<MetaCategory
							categoryData={allCategoryData}
							isUpdate={false}
							currentPage={currentPage}
							fetchCategory={fetchCategory}
							closeDialog={closeDialog}
						/>
					) : model === 'updatecategory' ? (
						<MetaCategory
							categoryData={allCategoryData}
							isUpdate
							itemData={itemData}
							currentPage={currentPage}
							fetchCategory={fetchCategory}
							closeDialog={closeDialog}
						/>
					) : model === 'addspeciality' ? (
						<AddSpecialities
							currentPage={currentPage}
							isUpdate={false}
							disciplineData={allDisciplineData}
							fetchDisciplineData={fetchDisciplineData}
							closeDialog={closeDialog}
						/>
					) : model === 'updatediscipline' ? (
						<AddSpecialities
							currentPage={currentPage}
							isUpdate={true}
							disciplineData={allDisciplineData}
							itemData={itemData}
							fetchDisciplineData={fetchDisciplineData}
							closeDialog={closeDialog}
						/>
					) : model === 'addskills' ? (
						<AddSkills
							currentPage={currentPage}
							isUpdate={false}
							skillsData={allSkillsData}
							fetchMetaSkillsData={fetchMetaSkillsData}
							closeDialog={closeDialog}
						/>
					) : model === 'updateskills' ? (
						<AddSkills
							itemData={itemData}
							currentPage={currentPage}
							isUpdate
							skillsData={allSkillsData}
							fetchMetaSkillsData={fetchMetaSkillsData}
							closeDialog={closeDialog}
						/>
					) : (
						<FightMethod />
					)
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		adminFightMethod: (data) => dispatch(adminFightMethod(data)),
		adminFightWeight: (data) => dispatch(adminFightWeight(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminFight);
