import { useState, useEffect } from 'react';
import { Box, Button, Chip, Grid, Typography } from '@material-ui/core';
import { ReactComponent as FilterCheckIcon } from 'src/assets/images/icons/whiteSelect.svg';
import './edit-restriction.scss';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditRestrictions = ({
	user,
	token,
	getMyProfile,
	closeDialog,
	communityDetails,
	fetchCommunityDetails,
	isLoading,
	setIsLoading,
}) => {
	const { id } = useParams();
	const { t } = useTranslation();
	const [formData, setFormValue] = useState({ checkf: true, checkc: true, checkt: true, checkfr: true, checkp: true });
	const [selected, setSelected] = useState([0]);

	const restTypeList = [
		// { id: 0, name: t('profile_type.enthusiast') },
		{ id: 1, name: t('profile_type.athelete') },
		{ id: 2, name: t('profile_type.coach_instructor') },
		{ id: 4, name: t('profile_type.manager') },
		{ id: 8, name: t('profile_type.promoter') },
		{ id: 16, name: t('profile_type.sponsor') },
		{ id: 32, name: t('profile_type.journalist') },
		{ id: 64, name: t('profile_type.official') },
		// { id: 128, name: t('profile_type.gym') },
		// { id: 256, name: t('profile_type.promotion') },
		// { id: 512, name: t('profile_type.product_service') }
	];
	const handleToggle = (value) => () => {
		const currentIndex = selected.indexOf(value);
		const newSelected = [...selected];

		if (currentIndex === -1) {
			newSelected.push(value);
		} else {
			newSelected.splice(currentIndex, 1);
		}

		setSelected(newSelected);
	};
	const handleSelect = (name, value) => {
		setFormValue({ ...formData, [name]: !value });
	};

	const updateCommunity = async () => {
		setIsLoading(true);
		let types = selected.length > 0 && selected.reduce((a, b) => a + b);
		var requestParams = {
			community: {
				id: Number(id),
			},
			communityRule: {
				user_verified: formData?.checkf ? 'yes' : 'no',
				user_type: types,
				user_access: formData?.checkt ? 'post_create' : 'post_read',
				post_comment: formData?.checkfr ? 'yes' : 'no',
				user_age: formData?.checkc ? 'yes' : 'no',
				guest_access: formData?.checkp ? 'public' : 'private',
			},
		};

		try {
			const { status, data } = await axiosInstance.put(URLS.updateCommunity, requestParams);

			if (status === 200) {
				fetchCommunityDetails();
				setApiMessage('success', data?.message);
				closeDialog();
			}
		} catch (error) {
			setIsLoading(false);
			setApiMessage('error', error?.response?.data?.message);
		}
	};

	useEffect(() => {
		communityDetails && setSelected(communityDetails?.communityData?.user_type_value);
		communityDetails &&
			setFormValue({
				checkf: communityDetails?.communityData?.user_verified === 'yes' ? true : false,
				checkc: communityDetails?.communityData?.user_age === 'yes' ? true : false,
				checkt: communityDetails?.communityData?.user_access === 'post_create' ? true : false,
				checkfr: communityDetails?.communityData?.post_comment === 'yes' ? true : false,
				checkp: communityDetails?.communityData?.guest_access === 'public' ? true : false,
			});
	}, [communityDetails]);

	return (
		<Box className="edit-restrictions">
			<Box className="restriction-box">
				{/* Is this community public? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.is_community_public')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							<Grid>
								<Chip
									size="small"
									className={formData?.checkp ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={formData?.checkp ? <FilterCheckIcon /> : null}
									label={t('switch_txt.yes')}
									onClick={() => handleSelect('checkp', false)}
								/>
							</Grid>
							<Grid>
								<Chip
									size="small"
									className={!formData?.checkp ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={!formData?.checkp ? <FilterCheckIcon /> : null}
									label={t('switch_txt.no')}
									onClick={() => handleSelect('checkp', true)}
								/>
							</Grid>
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>

				{/* What types of users is this community for? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.community_for_txt')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							{restTypeList?.map((data, index) => {
								return (
									<Grid item key={index}>
										<Chip
											size="small"
											className={selected.indexOf(data?.id) !== -1 ? 'rest-chip checked-chip' : 'rest-chip'}
											icon={selected.indexOf(data?.id) !== -1 ? <FilterCheckIcon /> : null}
											label={data?.name}
											onClick={handleToggle(data?.id)}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>
				{/* Do users need to be verified to join this community? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.verify_comm_txt')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							<Grid>
								<Chip
									size="small"
									className={formData?.checkf ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={formData?.checkf ? <FilterCheckIcon /> : null}
									label={t('switch_txt.yes')}
									onClick={() => handleSelect('checkf', false)}
								/>
							</Grid>
							<Grid>
								<Chip
									size="small"
									className={!formData?.checkf ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={!formData?.checkf ? <FilterCheckIcon /> : null}
									label={t('switch_txt.no')}
									onClick={() => handleSelect('checkf', true)}
								/>
							</Grid>
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>
				{/* Is this community accessible to children? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.acc_for_childrn')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							<Grid container>
								<Grid>
									<Chip
										size="small"
										className={formData?.checkc ? 'rest-chip checked-chip' : 'rest-chip'}
										icon={formData?.checkc ? <FilterCheckIcon /> : null}
										label={t('switch_txt.yes')}
										onClick={() => handleSelect('checkc', false)}
									/>
								</Grid>
								<Grid>
									<Chip
										size="small"
										className={!formData?.checkc ? 'rest-chip checked-chip' : 'rest-chip'}
										icon={!formData?.checkc ? <FilterCheckIcon /> : null}
										label={t('switch_txt.no')}
										onClick={() => handleSelect('checkc', true)}
									/>
								</Grid>

								{/* {childAccesibilityArr?.map((data, index) => {
                            return (
                              <Grid item key={index}>
                                <Chip
                                  size="small"
                                  className={
                                    selectedVal === data?.name ? 'rest-chip checked-chip' : 'rest-chip'
                                  }
                                  icon={selectedVal === data?.name ? (<FilterCheckIcon />) : null}
                                  label={data?.name}
                                  onClick={() => setSelectedVal(data?.name)}
                                />
                              </Grid>
                            );
                          })} */}
							</Grid>
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>
				{/* Can users create posts in this community? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.cancrtcomnt_txt')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							<Grid>
								<Chip
									size="small"
									className={formData?.checkt ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={formData?.checkt ? <FilterCheckIcon /> : null}
									label={t('switch_txt.yes')}
									onClick={() => handleSelect('checkt', false)}
								/>
							</Grid>
							<Grid>
								<Chip
									size="small"
									className={!formData?.checkt ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={!formData?.checkt ? <FilterCheckIcon /> : null}
									label={t('switch_txt.no')}
									onClick={() => handleSelect('checkt', true)}
								/>
							</Grid>
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>
				{/* Can posts in this community have comments on them? */}
				<Grid item xs={12} md={12}>
					{/* <Box className="restriction-box"> */}
					<Typography className="restriction-txt">{t('community.conthave_comnts')}</Typography>
					<Box className="restriction-chips pb-20">
						<Grid container>
							<Grid>
								<Chip
									size="small"
									className={formData?.checkfr ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={formData?.checkfr ? <FilterCheckIcon /> : null}
									label={t('switch_txt.yes')}
									onClick={() => handleSelect('checkfr', false)}
								/>
							</Grid>
							<Grid>
								<Chip
									size="small"
									className={!formData?.checkfr ? 'rest-chip checked-chip' : 'rest-chip'}
									icon={!formData?.checkfr ? <FilterCheckIcon /> : null}
									label={t('switch_txt.no')}
									onClick={() => handleSelect('checkfr', true)}
								/>
							</Grid>
						</Grid>
					</Box>
					{/* </Box> */}
				</Grid>
			</Box>
			<Box className="dialog-submit-button dialog-sticky-btn">
				<Button
					variant="contained"
					type="submit"
					disabled={isLoading}
					className="dialog-submit"
					onClick={updateCommunity}
				>
					{t('save')}
				</Button>
			</Box>
		</Box>
	);
};

export default EditRestrictions;
