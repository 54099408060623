import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DashboardView from 'src/views/component/admin/dashboard';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#202020',
		height: '100%',
	},
}));

const AdminDashboard = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Dashboard">
			<DashboardView />
		</Page>
	);
};

export default AdminDashboard;
