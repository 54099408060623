import { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { Box, Grid, Container } from '@material-ui/core';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { identifiers } from 'src/helpers/constants/identifier';
import RightSide from './RightSide';
import LeftSide from './LeftSide';
// import { getAccountSettingData } from 'src/helpers/store/Actions/actions';
import './accountset.scss';

const AccountSettings = () => {
	//getAccountSettingData
	const [settingNo, setSettingNo] = useState(1);

	const auth = useSelector((state) => state.Combat.authData);
	const user = auth ? auth : fetchFromStorage('authData');

	let token = auth ? auth?.token : fetchFromStorage('authData')?.token;
	const authHeader = {
		headers: {
			Authorization: token && `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	// GET USER PROFILE
	const getMyProfile = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getMyprofile, authHeader);

			if (status === 200) {
				let obj = {
					...user,
					email: data?.OauthUserMetaData?.data,
				};

				saveToStorage(identifiers.authData, obj);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	// const fetchAccountSettingData = async () => {
	//     try {
	//         const { status, data } = await axiosInstance.put(URLS.getAccountSettingData);
	//         if (status === 200) {
	//             getAccountSettingData(data?.data);
	//         }
	//     } catch (error) { }

	// }
	useEffect(() => {
		getMyProfile();
	}, []);

	const handleSetting = (data) => {
		setSettingNo(data);
	};

	return (
		<Container maxWidth="lg">
			<Box className="combat-container account-setting-sec">
				<Grid container className="account-setting-grid">
					<Grid className="account-setting-left-sec">
						<LeftSide onClick={handleSetting} />
					</Grid>
					<Grid className="account-setting-right-sec">
						<RightSide setNo={settingNo} user={user} token={token} />
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
const mapStateToProps = (state) => {
	return {
		data: state.Combat,
	};
};
// const mapDispatchToProps = dispatch => {
//     return {

//         getAccountSettingData: data => dispatch(getAccountSettingData(data))
//     };
// };

export default connect(mapStateToProps)(AccountSettings);
