import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography, Button, CircularProgress, Grid, Link, IconButton } from '@material-ui/core';
import * as Yup from 'yup';
// import { ReactComponent as Send } from 'src/assets/images/icons/Messages/send.svg';
// import { ReactComponent as Bold } from 'src/assets/images/icons/Messages/bold.svg';
// import { ReactComponent as Italic } from 'src/assets/images/icons/Messages/italic.svg';
// import { ReactComponent as Underlined } from 'src/assets/images/icons/Messages/underlined.svg';
// import { ReactComponent as FontIcon } from 'src/assets/images/icons/Messages/font.svg';
// import { ReactComponent as Merge } from 'src/assets/images/icons/Messages/merge.svg';
// import { ReactComponent as Alignleft } from 'src/assets/images/icons/Messages/align-left.svg';
// import { ReactComponent as Aligncenter } from 'src/assets/images/icons/Messages/align-center.svg';
// import { ReactComponent as Alignright } from 'src/assets/images/icons/Messages/align-right.svg';
// import { ReactComponent as List } from 'src/assets/images/icons/Messages/list-numbered.svg';
import SendSvg from 'src/assets/images/icons/Messages/send.svg';
import BoldSvg from 'src/assets/images/icons/community/bold.svg';
import ItalicSvg from 'src/assets/images/icons/community/italic.svg';
import UnderlineSvg from 'src/assets/images/icons/community/underlined.svg';
import LinkSvg from 'src/assets/images/icons/community/link.svg';
import AlignRightSvg from 'src/assets/images/icons/community/align-right.svg';
import AlignCenterSvg from 'src/assets/images/icons/community/align-center.svg';
import AlignLeftSvg from 'src/assets/images/icons/community/align-left.svg';
import NumberedSvg from 'src/assets/images/icons/community/numbered.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import UploadIcon from 'src/assets/images/icons/community/upload.svg';
import { Formik } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { setApiMessage, getFields, removeHTML } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { ReactComponent as BackIcon } from 'src/assets/images/icons/back.svg';
import './MessageRight.scss';
// eslint-disable-next-line import/no-named-as-default
import { SocketContext } from 'src/helpers/context/socket';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { MatrixTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as AddIcon } from 'src/assets/images/icons/fightRecord/add-circle.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/sma;;BDelete.svg';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import Messagechats from './Messagechats';
import UpdateGroupMembers from './UpdateGroupMembers';
// import MessageArray from './MessagesArray';

const CustomOption = ({ onImageSend, token, messageSendLoaderSet, deletedItemArray, setImageList, userSocket, id }) => {
	const [images, setImages] = useState([]);
	const [previewImages, setPreviewImage] = useState([]);

	// const [imageURLS, setImageURLs] = useState([]);

	useEffect(() => {
		if (previewImages.length < 1) {
			return;
		}

		const newImageUrls = [];

		previewImages.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
		setImageList(images, newImageUrls);
	}, [images, previewImages]);

	useEffect(() => {
		userSocket.on('add-message', (data) => {
			setImages([]);
			setPreviewImage([]);
		});
	}, []);
	useEffect(() => {
		setImages([]);
		setPreviewImage([]);
	}, [id]);
	useEffect(() => {
		deletedItemArray && setImages(deletedItemArray);
	}, [deletedItemArray]);

	const onImageChange = async (e) => {
		e.persist();
		messageSendLoaderSet(true);
		let formData = new FormData();

		if (e.target.files.length > 0) {
			var typeArray = [];

			for (var i = 0; i < e.target.files.length; i++) {
				const type = e.target.files[i].type.includes('image')
					? 'image'
					: e.target.files[i].type.includes('video')
					? 'video'
					: e.target.files[i].type.includes('text')
					? 'text'
					: e.target.files[i].type.includes('audio')
					? 'audio'
					: e.target.files[i].type.includes('blob')
					? 'blob'
					: '';

				formData.append('location', e.target.files[i]);
				typeArray.push(type);
			}

			formData.append('type', typeArray);

			try {
				const { status, data } = await axiosInstance.post(URLS.multipleMediaUpload, formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				if (status === 200) {
					var tempArray = [...images];
					// tempArray.push(data?.data)
					var responseArray = data?.data;

					setImages([...tempArray, ...responseArray]);
					// setPreviewImage([...previewImages, ...e.target.files]);
					setImageList(images, previewImages);
					setPreviewImage([...previewImages, ...e.target.files]);
					setImageList(images, previewImages);
					onImageSend(data);
					messageSendLoaderSet(false);
				}
			} catch (error) {
				messageSendLoaderSet(false);
				setApiMessage('error', error?.response?.data?.message);
			}
		}
		// setPreviewImage([...previewImages, ...e.target.files]);
	};

	return (
		<Box className="post-image-uploader">
			<input
				// accept="image/*"
				type="file"
				id="select-image"
				style={{ display: 'none' }}
				multiple
				onChange={onImageChange}
			/>
			<label htmlFor="select-image">
				<Button variant="contained" color="primary" component="span">
					<svg
						id="Group_1194"
						data-name="Group 1194"
						xmlns="http://www.w3.org/2000/svg"
						width="23"
						height="23"
						viewBox="0 0 23 23"
					>
						<defs>
							<clipPath id="clip-path">
								<rect id="Rectangle_766" data-name="Rectangle 766" width="23" height="23" fill="none" />
							</clipPath>
						</defs>
						<g id="Group_1193" data-name="Group 1193" clipPath="url(#clip-path)">
							<path
								id="Path_431"
								data-name="Path 431"
								d="M66.761,29.207a.716.716,0,0,1-.508-.21L62.99,25.734,59.726,29a.718.718,0,0,1-1.016-1.016l3.771-3.77a.718.718,0,0,1,1.016,0l3.771,3.77a.718.718,0,0,1-.508,1.226"
								transform="translate(-51.496 -21.126)"
								fill="#fff"
							/>
							<path
								id="Path_432"
								data-name="Path 432"
								d="M90.718,35.513A.718.718,0,0,1,90,34.794V24.74a.718.718,0,0,1,1.437,0V34.794a.718.718,0,0,1-.718.718"
								transform="translate(-79.225 -21.146)"
								fill="#fff"
							/>
							<path
								id="Path_433"
								data-name="Path 433"
								d="M39.8,115.183H25.437A1.438,1.438,0,0,1,24,113.747v-5.028a.718.718,0,1,1,1.437,0v5.028H39.8v-5.028a.718.718,0,1,1,1.437,0v5.028a1.438,1.438,0,0,1-1.437,1.437"
								transform="translate(-21.127 -95.07)"
								fill="#fff"
							/>
						</g>
					</svg>
				</Button>
			</label>
		</Box>
	);
};

const CustomSendOption = ({ onMessageSend, onMsgLoader }) => {
	return (
		<Box
			className={
				onMsgLoader ? 'post-image-uploader send-image-sec loader-padding' : 'post-image-uploader send-image-sec'
			}
		>
			{onMsgLoader ? (
				<CircularProgress color="#438ac1" className="loader-chat" />
			) : (
				<Button onClick={() => onMessageSend()} variant="contained" color="primary" component="span">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="15.151"
						height="12.966"
						viewBox="0 0 15.151 12.966"
						fill="#fff"
					>
						<path
							id="Path_290"
							data-name="Path 290"
							className="cls-1"
							d="M7.519,8.874H3.395L2.016,3.741A.407.407,0,0,1,2,3.649a.7.7,0,0,1,1.018-.582L15.951,8.874,3.018,14.68A.7.7,0,0,1,2,14.114a.406.406,0,0,1,.023-.121l1.023-3.161"
							transform="translate(-1.399 -2.391)"
						/>
					</svg>
				</Button>
			)}
		</Box>
	);
};

const MessagesPageRight = ({
	token,
	handleForwardMessage,
	handleBack,
	breakPoint,
	windowWidth,
	msgLoader,
	user,
	currentGroupChat,
	groupMemberList,
	msgList,
	refs,
	fetchGroupList,
	userListData,
	fetchGroupDetails,
	groupDetails,
}) => {
	const { id } = useParams();
	const messagesEndRef = useRef(null);
	const { t } = useTranslation();
	const userSocket = useContext(SocketContext)?.socket;
	const [text, setText] = useState(EditorState.createEmpty());
	const [textError, setTextError] = useState('');
	const [allImages, setAllImages] = useState([]);
	const [pImages, setPreviewImg] = useState([]);
	const [chatHistory, setChatHistory] = useState([]);
	const [deletedItemArray, setDeletedItemArray] = useState([]);
	const [onMsgLoader, setOnMsgLoader] = useState(false);
	const [replyMessages, setReplyMessages] = useState(false);
	const [replyMessagesData, setReplyMessagesData] = useState('');
	const [updateGroup, setUpdateGroup] = useState(false);
	const [modal, setModal] = useState('');
	const [userName, setUserName] = useState('');
	const [groupData, setGroupData] = useState();
	const [imageStatus, setImageStatus] = useState(true);

	const handleChangeEditior = (editorState) => {
		setText(editorState);
		setTextError('');
	};
	const setImageList = (img, pimg) => {
		setAllImages(img);
		setPreviewImg(pimg);
	};
	const messageSendLoaderSet = (val) => {
		setOnMsgLoader(val);
	};
	const onImageSend = (item) => {
		// var file_type = item?.data?.type === 'image' ? 'image' : item?.data?.type === 'audio' ? 'audio' : item?.data?.type === 'video' ? 'video' : 'doc';
		// var mime = require("mime-types");
		// var fileType = mime.lookup(item?.data?.location).split("/");
		// var file_type;
		// if (fileType[0] === "video") {
		//     file_type = fileType[1] === "x-ms-wmv" || fileType[1] === "x-msvideo" ? "doc" : "video";
		// } else if (fileType[0] === "image") {
		//     file_type = fileType[1] === "svg+xml" ? "doc" : "image";
		// } else {
		//     file_type = "doc";
		// }
		setOnMsgLoader(true);
		setReplyMessages(false);
		// var messageContent = replyMessagesData?.type === 'string' ? replyMessagesData?.text : String(replyMessagesData?.item_id);
		// var addMessageObj = {
		//     // group_id: currentGroupChat?.id,
		//     group_id: Number(id),
		//     user_id: user?.user_id,
		//     type: file_type,
		//     content: String(item?.data?.id),
		//     reply: null,
		//     forward: null,
		//     taggedUsersID: [],
		//     chatType: currentGroupChat?.private_group === 1 ? 'private' : 'group',
		//     link: [],
		//     user: {
		//         _id: user?.user_id,
		//         name: user?.OauthUserData?.user_name,
		//         avatar: user?.profile_pic
		//     }
		// }
		// if (replyMessagesData !== '') {
		//     addMessageObj.reply = {
		//         messageID: replyMessagesData?._id,
		//         messageType: replyMessagesData?.type,
		//         messageFromName: replyMessagesData?.user?.name,
		//         messageFromID: replyMessagesData?.user?._id,
		//         messageContent: messageContent
		//     }
		// }
		// userSocket.emit('add-message', addMessageObj);
		// userSocket.on('add-message', (data) => {
		//     setOnMsgLoader(false);
		//     const groupChatHistory = [...chatHistory];
		//     groupChatHistory.push(data);
		//     setChatHistory(groupChatHistory);
		//     console.log(JSON.stringify(data, null, 2), 'add-message on');
		//     setText('');
		//     console.log('add-message on event', data)
		// });
	};
	const handleUpdateGroup = () => {
		updateGroup ? setUpdateGroup(false) : setUpdateGroup(true);
		setModal('');
	};
	const fetchUpdateGroup = async (data) => {
		var requestData = {
			name: data?.groupname,
			// item_profile_id: 3
		};
		var groupId = currentGroupChat?.id ? currentGroupChat?.id : id;

		try {
			const { status, data } = await axiosInstance.put(URLS.updateGroup + `${groupId}`, requestData);

			if (status === 200) {
				fetchGroupList('');
				fetchGroupDetails();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const addGroupMembers = () => {
		setModal('updateGroupMembers');
		setUpdateGroup(false);
	};
	const onRemoveGroup = async (deletedId) => {
		var groupId = currentGroupChat?.id ? currentGroupChat?.id : id;
		var requestParams = {
			type: 'remove',
			group_id: groupId,
			user_id_arr: [deletedId],
		};

		try {
			const { status, data } = await axiosInstance.post(URLS.AddRemoveMembers, requestParams);

			if (status === 200) {
				fetchGroupDetails();
				setApiMessage('success', data?.message);
			}
		} catch (error) {
			setApiMessage('error', error?.response?.data?.message);
		}
	};
	const deletePostItem = (id) => {
		var deletedImage = allImages && allImages?.filter((item, index) => index !== id);

		setDeletedItemArray(deletedImage);
		setAllImages(deletedImage);
	};
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	const handleImageLoaded = () => {
		setImageStatus(false);
	};
	const showItems = (item, index) => {
		switch (item?.type) {
			case 'doc':
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
			case 'image':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						{/* <Avatar src={URLS.mediaURL + item?.location} /> */}
						<Avatar
							src={imageStatus === true ? loaderGif : URLS.mediaURL + item?.location}
							alt="post-image"
							onError={(e) => {
								e.onerror = null;
								e.target.src = placeholder;
							}}
							onLoad={handleImageLoaded}
						/>
					</Box>
				);
			case 'video':
				return (
					<Box width="200px" className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player audio-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
			default:
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
		}
	};
	const onMessageSend = () => {
		const rawHTML = text ? draftToHtml(convertToRaw(text.getCurrentContent())) : '';
		var onlyText = removeHTML(rawHTML);

		setReplyMessages(false);
		var messageContent =
			replyMessagesData?.type === 'string' ? replyMessagesData?.text : String(replyMessagesData?.item_id);

		onlyText !== '' && setOnMsgLoader(true);

		var addMessageObj = {
			// group_id: currentGroupChat?.id,
			group_id: Number(id),
			user_id: user?.user_id,
			type: 'string',
			content: rawHTML,
			reply: null,
			forward: null,
			taggedUsersID: [],
			chatType: currentGroupChat?.private_group === 1 ? 'private' : 'group',
			link: [],
			user: {
				_id: user?.user_id,
				name: user?.OauthUserData?.user_name,
				avatar: user?.profile_pic,
			},
		};

		if (allImages.length > 0 && onlyText === '') {
			for (var i = 0; i < allImages.length; i++) {
				var file_type =
					allImages[i]?.type === 'image'
						? 'image'
						: allImages[i]?.type === 'audio'
						? 'audio'
						: allImages[i]?.type === 'video'
						? 'video'
						: 'doc';

				addMessageObj.type = file_type;
				addMessageObj.content = String(allImages[i]?.id);

				userSocket.emit('add-message', addMessageObj);
			}
		}

		if (allImages.length > 0 && onlyText !== '') {
			for (let i = 0; i < allImages.length; i++) {
				var mixType =
					allImages[i]?.type === 'image'
						? 'image_text'
						: allImages[i]?.type === 'audio'
						? 'audio_text'
						: allImages[i]?.type === 'video'
						? 'video_text'
						: 'doc_text';

				addMessageObj.type = mixType;
				addMessageObj.merge_text = rawHTML;
				addMessageObj.content = String(allImages[i]?.id);

				userSocket.emit('add-message', addMessageObj);
			}
		}

		if (replyMessagesData !== '') {
			addMessageObj.reply = {
				messageID: replyMessagesData?._id,
				messageType: replyMessagesData?.type,
				messageFromName: replyMessagesData?.user?.name,
				messageFromID: replyMessagesData?.user?._id,
				messageContent: messageContent,
				messageMergeText: rawHTML,
			};
		}

		onlyText !== '' && allImages.length === 0 && userSocket.emit('add-message', addMessageObj);
		userSocket.on('add-message', (data) => {
			setOnMsgLoader(false);
			// const groupChatHistory = [...chatHistory];
			// groupChatHistory.push(data);
			// setChatHistory(groupChatHistory);
			// console.log(JSON.stringify(data, null, 2), 'add-message on event');
			setText('');
			setAllImages([]);
			setPreviewImg([]);
			// console.log('chatHistorychatHistory', chatHistory)
		});
		setTimeout(() => {
			setOnMsgLoader(false);
		}, 15000);
		setReplyMessagesData('');
	};
	const handleReplyMessage = (data, flag) => {
		setReplyMessages(flag);
		setReplyMessagesData(data);
	};
	const handleDeleteMessage = (arrayvalue) => {
		setChatHistory(arrayvalue);
	};
	const addMembersToGroup = () => {
		setUpdateGroup(false);
		setModal('');
	};

	useEffect(() => {
		setAllImages([]);
		setPreviewImg([]);
	}, [id]);
	useEffect(() => {
		setChatHistory(msgList);
	}, [msgList]);
	useEffect(() => {
		setText('');
	}, [currentGroupChat]);
	const scrollToBottom = () => {
		// messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		messagesEndRef.current && messagesEndRef.current.scrollIntoView();
	};

	useEffect(() => {
		userSocket.on('disconnect', () => {
			setOnMsgLoader(false);
		});
	}, []);
	useEffect(() => {
		if (modal === '' || !updateGroup) {
			msgList && scrollToBottom();
		}

		setUpdateGroup(false);
		setModal('');
	}, [msgList]);
	const replyChatMessage = (item) => {
		// const otherUserId = item?.user?._id === user?.user_id ? false : true;
		switch (item?.type) {
			case 'string':
				return (
					<>
						<Typography className="messages-user-name">
							{replyMessagesData?.user?._id === user?.user_id ? t('messages.you') : replyMessagesData?.user?.name}
						</Typography>
						{renderHTML(
							anchorme({
								input: item?.text.toString(),
								options: {
									attributes: {
										target: '_blank',
										class: 'detected',
									},
								},
							}),
						)}
					</>
				);
			case 'image_text':
			case 'image':
				return (
					<Box className="message-image-sec">
						<Box className="message-left-sec">
							<Typography className="messages-user-name">
								image-wrapper
								{replyMessagesData?.user?._id === user?.user_id ? t('messages.you') : replyMessagesData?.user?.name}
							</Typography>
							<Typography className="message-type">{item?.type}</Typography>
						</Box>
						<Avatar variant="square" src={item?.image} />
					</Box>
				);
			case 'audio_text':
			case 'audio':
				return (
					<Box className="message-image-sec">
						<Box className="message-left-sec">
							<Typography className="messages-user-name">
								{replyMessagesData?.user?._id === user?.user_id ? t('messages.you') : replyMessagesData?.user?.name}
							</Typography>
							<Typography className="message-type">{item?.type}</Typography>
						</Box>
					</Box>
				);
			case 'video_text':
			case 'video':
				return (
					<Box className="message-image-sec">
						<Box className="message-left-sec">
							<Typography className="messages-user-name">
								{replyMessagesData?.user?._id === user?.user_id ? t('messages.you') : replyMessagesData?.user?.name}
							</Typography>
							<Typography className="message-type">{item?.type}</Typography>
						</Box>
					</Box>
				);
			case 'doc_text':
			case 'doc':
				return (
					<Box className="message-image-sec">
						<Box className="message-left-sec">
							<Typography className="messages-user-name">
								{replyMessagesData?.user?._id === user?.user_id ? t('messages.you') : replyMessagesData?.user?.name}
							</Typography>
							<Typography className="message-type">{item?.type}</Typography>
						</Box>
					</Box>
				);
		}
	};

	useEffect(() => {
		if (groupDetails) {
			if (groupDetails?.private_group === 0) {
				// var groupObj = userListData.find(item => item?.id === Number(id));
				// var groupObj = groupDetails && groupDetails.find(item => item?.id === Number(id));
				let userNameArray = groupDetails?.group_user_list && getFields(groupDetails?.group_user_list, 'user_full_name');
				// var userNameArray = currentGroupChat?.group_user_list && getFields(currentGroupChat?.group_user_list, 'user_full_name');
				var userNames = userNameArray && userNameArray.join(', ');

				setUserName(userNames);
				// setGroupData(groupObj);
			} else {
				// var userNameArray = groupDetails?.group_user_list && getFields(groupDetails?.group_user_list, 'user_full_name');
				let userNameArray = groupDetails?.group_user_list?.find((item) => item?.user_id !== user?.user_id);

				userNameArray && setUserName(userNameArray?.user_full_name);
			}
		}
	}, [groupDetails]);

	return (
		<Box className="messages-right-side-sec">
			<Box className="right-person-details">
				{windowWidth < breakPoint && <BackIcon className="back-icon" onClick={() => handleBack()} />}
				{updateGroup && <BackIcon className="back-icon" onClick={handleUpdateGroup} />}
				{modal === 'updateGroupMembers' && <BackIcon className="back-icon" onClick={() => setModal('')} />}
				<Avatar src={currentGroupChat?.item_profile_data} className="right-avatar-sec" />
				<Box
					className={groupDetails?.private_group === 0 ? 'right-person-name' : 'right-person-name right-single-name'}
				>
					{groupDetails?.private_group === 0 ? (
						<>
							<Typography className="right-name-sec" onClick={handleUpdateGroup}>
								{/* {currentGroupChat?.name} */}
								{groupDetails && groupDetails?.name}
							</Typography>
							<Typography className="group-members-name" onClick={addGroupMembers}>
								{userName}
							</Typography>
						</>
					) : (
						<>
							<Typography className="right-name-sec">{userName}</Typography>
						</>
					)}
				</Box>
			</Box>
			{updateGroup ? (
				<>
					<Box className="update-right-chat-sec">
						<Formik
							initialValues={{
								groupname: groupDetails?.name ? groupDetails?.name : '',
							}}
							validationSchema={Yup.object().shape({
								groupname: Yup.string().required(t('validation.required_message')),
							})}
							onSubmit={(requestdata) => {
								fetchUpdateGroup(requestdata);
								addMembersToGroup();
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={12} className="text-field-grid">
											<MatrixTextField
												error={Boolean(touched.groupname && errors.groupname)}
												fullWidth
												helperText={touched.groupname && errors.groupname}
												variant="filled"
												placeholder={t('messages.group_name')}
												label={t('messages.group_name')}
												className="matrix-textfield"
												InputProps={{
													endAdornment: touched.groupname && errors.groupname ? <ErrorIcon /> : null,
												}}
												size="small"
												name="groupname"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.groupname}
											/>
										</Grid>
										<Grid item xs={12} md={12} className="group-members-sec">
											<Box className="group-member-add-sec">
												<Typography className="groupmembers-text">{t('messages.group_members')}</Typography>
												<IconButton className="add-icon" onClick={addGroupMembers}>
													<AddIcon />
												</IconButton>
											</Box>
											{groupDetails &&
												groupDetails?.group_user_list.map((item, index) => {
													var userName = item?.user_id === user?.user_id ? 'You' : item?.user_full_name;

													return (
														<Box className="group-members-details-sec">
															<Box className="group-members">
																<Avatar className="group-members-profile" src={item?.user_profile_data} />
																<Typography className="group-members-name">{userName}</Typography>
															</Box>
															{item?.user_role !== 'owner' && (
																<IconButton
																	className="delete-member"
																	onClick={() => {
																		onRemoveGroup(item?.user_id);
																	}}
																>
																	<DeleteIcon />
																</IconButton>
															)}
														</Box>
													);
												})}
										</Grid>
										<Grid item xs={12} md={12}>
											<Button
												variant="contained"
												fullWidth
												type="submit"
												className="update-button"
												// onClick={() => fetchUpdateGroup()}
											>
												{t('button_text.update')}
											</Button>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</Box>
				</>
			) : modal === 'updateGroupMembers' ? (
				<UpdateGroupMembers
					// modal={modal}
					// tab={tab}
					// updateMemberList={fetchCommunityMember}
					groupId={currentGroupChat?.id ? currentGroupChat?.id : id}
					handleClose={''}
					// fetchGroupList={fetchGroupList}
					fetchGroupDetails={fetchGroupDetails}
					userSocket={userSocket}
					user={user}
					// currentGroupChat={currentGroupChat}
					groupDetails={groupDetails}
					addMembersToGroup={addMembersToGroup}
				/>
			) : (
				<>
					<Box className="right-chat-sec">
						{msgLoader ? (
							<CircularProgress color="#438ac1" className="loader-chat" />
						) : (
							<Messagechats
								handleDeleteMessage={handleDeleteMessage}
								chatHistory={msgList}
								user={user}
								currentGroupChat={currentGroupChat}
								handleForwardMessage={handleForwardMessage}
								handleReplyMessage={handleReplyMessage}
								refs={refs}
							/>
						)}
						<div ref={messagesEndRef} />
					</Box>
					<Grid item xs={12} md={12} className="image-wrapper">
						<Box className="image-wrapper-inner">
							{allImages &&
								allImages?.map((imageSrc, index) => {
									return showItems(imageSrc, index);
								})}
						</Box>
					</Grid>
					<Formik
						initialValues={{
							text: '',
						}}
						// validationSchema={Yup.object().shape({
						//     //    title: Yup.string().required(
						//     //      t('validation.required_message')
						//     //      ),
						//     text: Yup.string().required(t('validation.required_message'))
						// })}
						onSubmit={(requestData) => {
							// console.log('requestData', requestData);
							// setCreateClick(false);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
							<form onSubmit={handleSubmit}>
								{replyMessages && (
									<Box className="messages-reply-box">
										<Box className="reply-message-sec">{replyChatMessage(replyMessagesData)}</Box>
										<Box className="reply-close-sec">
											<CloseIcon onClick={() => handleReplyMessage('', false)} />
										</Box>
									</Box>
								)}
								<Box className="right-send-sec custome-text-editior">
									<Editor
										mention={{
											separator: ' ',
											trigger: '@',
											suggestions: groupMemberList,
										}}
										editorState={text}
										toolbarCustomButtons={[
											<>
												<CustomOption
													id={id}
													onImageSend={onImageSend}
													token={token}
													messageSendLoaderSet={messageSendLoaderSet}
													setImageList={setImageList}
													deletedItemArray={deletedItemArray}
													userSocket={userSocket}
												/>
												<CustomSendOption onMsgLoader={onMsgLoader} onMessageSend={onMessageSend} />
											</>,
										]}
										wrapperClassName="demo-wrapper"
										editorClassName="demo-editor"
										onEditorStateChange={(editorState) => {
											handleChangeEditior(editorState);
										}}
										placeholder={t('messages.write_something')}
										toolbar={{
											options: ['inline', 'link', 'textAlign', 'list'],
											inline: {
												inDropdown: false,
												className: 'demo-option-custom',
												options: ['bold', 'italic', 'underline'],
												bold: {
													icon: BoldSvg,
												},
												italic: {
													icon: ItalicSvg,
												},
												underline: {
													icon: UnderlineSvg,
												},
											},
											textAlign: {
												options: ['left', 'right', 'center'],
												left: {
													icon: AlignLeftSvg,
												},
												right: {
													icon: AlignRightSvg,
												},
												center: {
													icon: AlignCenterSvg,
												},
											},
											link: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['link'],
												link: {
													icon: LinkSvg,
												},
											},
											list: {
												inDropdown: false,
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
												options: ['ordered'],
												ordered: {
													icon: NumberedSvg,
												},
											},
											image: { icon: UploadIcon, className: 'demo-option-custom', popupClassName: 'demo-popup-custom' },
											send: { icon: SendSvg },
										}}
									/>
									<span className="editior-error-message">{textError}</span>
								</Box>
								{/* <Grid item xs={12} md={12} className="image-wrapper">
                            <Box className="image-wrapper-inner">
                                {pImages.map((imageSrc) => (
                                    <Avatar src={imageSrc} />
                                ))}
                            </Box>
                        </Grid> */}
							</form>
						)}
					</Formik>
				</>
			)}
		</Box>
	);
};

export default MessagesPageRight;
