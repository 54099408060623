import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Content from 'src/views/component/application/Messages';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#202020',
		height: '100%',
		color: '#fff',
	},
});

const EventsPage = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Messages | Combat Matrix">
			<Content />
		</Page>
	);
};

export default EventsPage;
